import $ from "jquery";

import React, {
	Fragment
	, PureComponent
	, memo
	, useCallback
	, useEffect
	, useMemo
} from 'react';
import { branch, renameProps, renderComponent, withProps } from 'recompose'
import classNames from 'classnames';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import {
	TABLE_BASE_CLASSNAME,
} from '../../../reactcomponents/common';
import {
	ListHeaderWrapper,
	ListContentWrapper,
	StandardEditorHeader,
	// TableHeader,
	Skeleton,
	editorTitleByView,
	LabeledDelete,
	ActionsWrapper,
	Delete as ActionDelete,
	Download as ActionDownload,
	Edit as ActionEdit,
	EditorBox,
	EditorFooter,
	EditorHeader,
	SaveButton,
	CopyButton,
	CancelButton,
	SingleListSekeleton,
} from '../../../reactcomponents/Admin'
import {
	addressValidation
	, formatValueToNameObj
	, getListSizeBasedOnHeight
	, isExternalExpert
	, isSST
	, isAdminList
} from '../../../common/v5/helpers';
import {
	ADMIN_TITLE,
	ADMINISTRATION,
	ADMIN_VIEW_MAP,
	INPUT_EMPTY_WARNING,
	INPUT_SPACE_WARNING,
	M_ACCOUNTS,
	M_ACC_EMAIL,
	M_ACC_LINE,
	M_ACC_INSTAGRAM,
	M_ACC_FACEBOOK,
	M_ACC_TWITTER,
	M_ACC_LINKEDIN,
	M_ACC_JIRA,
	M_ACC_GOOGLEREVIEW,
	M_ACC_GOOGLECHAT,
	M_ACC_GOOGLEPLAY,
	M_ACC_HUBSPOT,
	M_ACC_MSTEAMS,
	M_ACC_SMPP,
	M_ACC_TRUSTPILOT,
	M_ACC_TELEGRAM,
	M_ACC_WHATSAPP,
	M_ACC_TWILIO,
	M_ACC_VIBER,
	M_ACC_SLACK,
	M_ACC_YOUTUBE,
	SOCIAL_CHANNEL_VIEW_ICONS as channelListIcons,
	FORM_EMPTY,
	FORM_EDIT,
	FORM_CREATEGROUP,
	FORM_MANAGEGROUP,
	FORM_LISTGROUP,
	BTN_TXT_SAVE,
	BTN_TXT_SAVING
} from '../../../common/v5/constants';

import { I, webRoot } from '../../../common/v5/config';

import withAccount from "./accountsCtnr"
import {
	renderEditButton as renderActionButtons
	, compositeFileActionProps as compositeActionProps
} from "../admin"
import { MenuItem } from '../../../common/v5/menu';
import { TableIconicButton } from '../../../reactcomponents/Form';
import {
	composeWithDisplayName,
	createWithMountCondition,
	omitProps,
	withUnmountWhenHidden
} from '../../../reactcomponents/hocs'
import { AccountEmailForm } from './email'
import { AccountLINEForm } from './line'
import { AccountFacebookForm } from './facebook'
import AccountInstagramForm from './instagram'
import AccountTwitterForm from './twitter'
import AccountLinkedInForm from './linkedin';
import AccountJiraForm from './jira';
import AccountYoutubeForm from './youtube';
import AccountGoogleReviewForm from './googlereview';
import AccountGoogleChatForm from './googlechat';
import AccountGooglePlayForm from './googleplay';
import AccountHubSpotForm from './hubspot';
import AccountMSTeamsForm, { GroupListMSTeamsForm, MSTeamsCreateGroupForm, MSTeamsManageGroupForm } from './msteams';
import AccountSMPPForm from './smpp';
import AccountTrustpilotForm from './trustpilot';
import AccountTelegramForm from './telegram';
import AccountWhatsAppForm, { WAManageGroupForm, WACreateGroupForm, GroupListWAForm } from './whatsapp';
import AccountTwilioForm from './twilio';
import AccountViberForm from './viber';
import AccountSlackForm, { GroupListSlackForm } from './slack';
import {
	StyledListContentWrapper,
	SpacedEditorFooter,
	useDisableSave
  } from '../../receiptgreetings/list'
import {
	useCallbackMultiValues,
	useCallbackWithValue,
	useIgnorePromiseCatch
} from '../../../hooks/callback'
import { PlainSwitchCheckbox } from '../../../reactcomponents/SwitchCheckbox'
import { HotkeyLabel } from '../../../reactcomponents/Hotkeys';


const DEFAULT_ACCOUNT_MENU_ICON = "icon-open-ext";
// const FORM_EMPTY = 0, FORM_EDIT = 1, FORM_CREATEGROUP = 2, MANAGEGROUP = 3, LISTGROUP = 4;

export const tableHeaderStyle = {
	color: "#6f6f6f"
	, textTransform: "capitalize"
	, fontSize: "14px"
	, fontWeight: "900"
	, border: "none"
	, padding: "10px"
}

export const tableHeaderErrStyle = {
	color: "#F4213F"
    , textTransform: "capitalize"
    , fontSize: "14px"
    , fontWeight: "900"
    , border: "none"
    , padding: "0px"
    , width: "30px"
}

const NavItem = ({
	id
	, label
	, hotkey
	, link
	, onClick
	, icon
	, active
}) => {
	const handleClick = () => {
		onClick(link, label, id);
	}
	let channelIcon = icon ? icon : DEFAULT_ACCOUNT_MENU_ICON ;
	return (
		<div className={classNames("nav-item",{"active": active})} data-qa-id={"acc_menu_"+label} onClick={handleClick} title={label}>
			<i className={channelIcon}></i>
			<HotkeyLabel id={label} text={label} hotkey={hotkey}/>
		</div>
	)
}


export const AdminAccountSideMenu = ({
	view
	, item
	, onClick
}) => {
	const { sub } = item;
	const navItems = sub.map((subItem, i) => (
		<NavItem
			key={i}
			id={subItem.id}
			label={subItem.label}
			hotkey={subItem.hotkey}
			link={subItem.link}
			onClick={onClick}
			icon={channelListIcons[subItem.id]}
			active={view === subItem.id}
		/>
	))
	return (
		<div className="adminSidebar section">
			<h2 className="admin-account-title">Accounts</h2>
			<ul className="accounts-menu">
				{navItems}
			</ul>
		</div>
	)
}

const TestButton = ({id, areaID, onClick}) => {

	const handleTestClick = (e) => {
		e.stopPropagation();
		onClick(id, areaID, true);
	};

	return (
		<div className="transparent table-btns">
		 <button className={"test-email-btn"} title={I("Test email")} onClick={handleTestClick} >
			 <i className="icon-refresh"></i>
		 </button>
		</div>
	)
}
const CheckboxAccount = ({checked, id, areaID, onClick, view, data}) => {
	const handleCheck = () => {
		onClick(id, areaID, view, checked, data);
	};
	return (
		<PlainSwitchCheckbox
          active={checked}
          onClick={handleCheck}
        />
	)
}

const SubmitButton = ({className, text, disabled = false }) => {
	return ( <button
				type="submit"
				className={className}
				title={text}
				data-qa-id="btn-Save"
				disabled={disabled}
			>{text}</button>
	)
}

export const EditFormButtons = ({
	disableSave = false,
	hideDelete,
	onCancel,
	onDelete,
	onSave,
	saveTxt = BTN_TXT_SAVING
}) => (
	<EditorFooter
		left={<LabeledDelete hidden={hideDelete} onClick={onDelete} />}
	>
		<CancelButton onClick={onCancel} />
		{/* <SaveButton disabled={disableSave} onClick={onSave} type="submit" /> */}
		<SubmitButton className={"btn-blue"} text={saveTxt} disabled={disableSave} />
	</EditorFooter>
)

//table for listView
class AdminAccountsTable extends React.PureComponent {
	constructor(props){
		super(props);
		this.state = {
			showError: false
		};
	}

	rowClassNameFormat = (row, rowIdx) => {
		const p = this.props;
		if(p.activeId !== 0) {
			if(row.id === p.activeId) {
				return "admin-row active";
			}
		}
		return "admin-row";
	}

	handleCheckBox = (id, areaID, view, checked, data) => {
		this.props.onActivateAccount(id, areaID, view, checked, data);
	}

	renderCellWithTitle(cell, row){
		return <div title={cell}>{cell}</div>
	}

	renderCellWithErr = (cell, row) => {
		if (typeof this.props.id !== "undefined") {
			if (row.id == this.props.id) {
				if (this.state.showError == false) {
					this.setState({showError: !this.state.showError});
				}
				return <i className="icon-caution"></i>
			}
		} else {
			if (this.state.showError == true) {
				this.setState({showError: !this.state.showError});
			}
		}
	}

	renderCheckBox = (cell, row, formatExtraData, rowIndex) => {
		return (
			<CheckboxAccount
				view={formatExtraData}
				checked={row.enabled}
				id={row.id}
				onClick={this.handleCheckBox}
				areaID={row.areaID}
				data={row}
			/>
		)
	}

	toggleTestPopup = (id, area,val) => {
		this.props.onToggleEmailTestPopup(id,area,val);
	}

	renderTestEmailBtn = (cell, row) => {
		return <TestButton id={row.id} areaID={row.areaID} onClick={this.toggleTestPopup}/>
	}

	render(){

		const {
			areaList
			, fetchInfo
			, currentSortField
			, currentSortOrder
			, list
			, onHandleDelete
			, onHandleDownload
			, onHandleOpenEdit
			, onSortFields
			, onSortOrder
			, options
			, order
			, orgAreas
			, selectedAreas
			, view
		} = this.props;

		let keys, keyCol = "id";
		let tableStyle = "custom";
		let apiCol = "api";
		if(list && list.length > 0) {
			//Order controls the table column fields.
			//if new column is needed, have to include it in the order props from parent component
			if (typeof order !== "undefined") {
				keys = order;
			} else {
				keys = Object.keys(list[0]);
			}
			return (
				<div className={classNames("table-style", tableStyle)}>
					<BootstrapTable
						bordered={false}
						data={list}
						fetchInfo={{dataTotalSize: list.length}}
						hover
						pagination={true}
						options={options}
						tableBodyClass={classNames(TABLE_BASE_CLASSNAME, "c3-admin-table-v5")}
						tableHeaderClass={TABLE_BASE_CLASSNAME}
						trClassName={this.rowClassNameFormat}
					>
						<TableHeaderColumn
							key={"admin-table-err"}
							thStyle={tableHeaderErrStyle}
							tdStyle={tableHeaderErrStyle}
							dataFormat={this.renderCellWithErr}
							hidden={!this.state.showError}
						></TableHeaderColumn>

						{
							keys.map((col, index) => {
								if(col == keyCol){
									return <TableHeaderColumn
												key={"admin-table-th-"+index+"-"+col}
												hidden={true} isKey thStyle={tableHeaderStyle}
												dataField={keyCol}
												dataSort={true}
												columnTitle
												headerTitle
											>{col}</TableHeaderColumn>

								} else if (col === "enabled") {
									return <TableHeaderColumn
												key={"admin-table-th-"+index+"-"+col}
												dataField='enabled'
												thStyle={tableHeaderStyle}
												formatExtraData={view}
												dataFormat={this.renderCheckBox}
												dataSort={true}
											>{col}</TableHeaderColumn>
								} else if (col === "testAccount") {
									return <TableHeaderColumn
												key={"admin-table-th-"+index+"-"+col}
												dataField='testAccount'
												thStyle={tableHeaderStyle}
												dataFormat={this.renderTestEmailBtn}
												columnClassName={"action"}
												columnTitle
												headerTitle
											>{col}</TableHeaderColumn>
								} else if (col === apiCol) {
									return <TableHeaderColumn
										key={"admin-table-th-"+index+"-"+col}
										thStyle={tableHeaderStyle}
										dataField={apiCol}
										dataSort={true}
										columnTitle
										headerTitle>
										{col}
										</TableHeaderColumn>
								} else {
									return <TableHeaderColumn
												key={"admin-table-th-"+index+"-"+col}
												thStyle={tableHeaderStyle}
												dataFormat={this.renderCellWithTitle}
												dataField={col}
												dataSort={true}
												columnTitle
												headerTitle
											>{col}</TableHeaderColumn>
								}
							})
						}
						<TableHeaderColumn
							className="table-header action"
							columnClassName={"action"}
							key="admin-table-th-actionCol"
							thStyle={tableHeaderStyle}
							headerTitle
							dataField={"id"}
							width={'85px'}
							dataAlign={"center"}
							dataFormat={renderActionButtons}
							formatExtraData={compositeActionProps(
								onHandleDelete
								, onHandleDownload
								, onHandleOpenEdit
								, selectedAreas
								, view
							)}
							hidden={view === ADMIN_VIEW_MAP["agents"]}
						>{I("Action")}</TableHeaderColumn>
					</BootstrapTable>
				</div>

			)
		}else{
			return <div className="admin-list-no-data">{I("No data")}</div>
		}
	}
}

class AccountEditForm extends React.PureComponent {
	constructor(props){
		super(props);
	}
	render(){
		const {
			areaList
			, activeId
			, input
			, view
			, onHandleTextInputChange
			, onHandleChangeData
			, onHandleTextInputBlur
			, onChangeAdminInput
			, oauthConstants
			, onPopAlert
			, orgArea
			, userIsRoot
			, pageId
			, configId
			, isNew
			, onSave
			, onCancel
			, onDelete
			, baseButtons
			, dataSrc
			, isWaCloud
		} = this.props;

		let form = null;

		switch(view) {
			case M_ACCOUNTS:
			case M_ACC_EMAIL:
				form = <AccountEmailForm
						areaList={areaList}
						activeId={activeId}
						input={input}
						dataSrc={dataSrc}
						view={view}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleChangeData={onHandleChangeData}
						onHandleTextInputBlur={onHandleTextInputBlur}
						onChangeAdminInput={onChangeAdminInput}
						// warning={this.state.nameWarning}
						oauthConstants={oauthConstants}
						isNew={isNew}
						baseButtons={baseButtons}
						onSave={onSave}
					/>;
				break;
			case M_ACC_LINE:
				form = <AccountLINEForm
						areaList={areaList}
						activeId={activeId}
						input={input}
						view={view}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleChangeData={onHandleChangeData}
						onHandleTextInputBlur={onHandleTextInputBlur}
						onChangeAdminInput={onChangeAdminInput}
						isNew={isNew}
						baseButtons={baseButtons}
						onSave={onSave}
					/>;
				break;
			case M_ACC_FACEBOOK:
				form = <AccountFacebookForm
						areaList={areaList}
						activeId={activeId}
						input={input}
						view={view}
						pageId={pageId}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleChangeData={onHandleChangeData}
						onHandleTextInputBlur={onHandleTextInputBlur}
						onChangeAdminInput={onChangeAdminInput}
						isNew={isNew}
						baseButtons={baseButtons}
						onSave={onSave}
					/>;
				break;
			case M_ACC_INSTAGRAM:
				form = <AccountInstagramForm
						areaList={areaList}
						activeId={activeId}
						input={input}
						view={view}
						pageId={pageId}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleChangeData={onHandleChangeData}
						onHandleTextInputBlur={onHandleTextInputBlur}
						onChangeAdminInput={onChangeAdminInput}
						isNew={isNew}
						baseButtons={baseButtons}
						onSave={onSave}
					/>;
				break;
			case M_ACC_LINKEDIN:
				form = <AccountLinkedInForm
						areaList={areaList}
						activeId={activeId}
						input={input}
						view={view}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleChangeData={onHandleChangeData}
						onHandleTextInputBlur={onHandleTextInputBlur}
						onChangeAdminInput={onChangeAdminInput}
						isNew={isNew}
						baseButtons={baseButtons}
						onSave={onSave}
					/>;
				break;
			case M_ACC_JIRA:
				form = <AccountJiraForm
						areaList={areaList}
						activeId={activeId}
						input={input}
						view={view}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleChangeData={onHandleChangeData}
						onHandleTextInputBlur={onHandleTextInputBlur}
						onChangeAdminInput={onChangeAdminInput}
						isNew={isNew}
						baseButtons={baseButtons}
						onSave={onSave}
					/>;
				break;
			case M_ACC_MSTEAMS:
				form = <AccountMSTeamsForm
						areaList={areaList}
						activeId={activeId}
						input={input}
						view={view}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleChangeData={onHandleChangeData}
						onHandleTextInputBlur={onHandleTextInputBlur}
						onChangeAdminInput={onChangeAdminInput}
						isNew={isNew}
						baseButtons={baseButtons}
						onSave={onSave}
					/>;
				break;
			case M_ACC_TWITTER:
				form = <AccountTwitterForm
						areaList={areaList}
						activeId={activeId}
						input={input}
						view={view}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleChangeData={onHandleChangeData}
						onHandleTextInputBlur={onHandleTextInputBlur}
						onChangeAdminInput={onChangeAdminInput}
						isNew={isNew}
						baseButtons={baseButtons}
						onSave={onSave}
					/>;
				break;
			case M_ACC_GOOGLEREVIEW:
				form = <AccountGoogleReviewForm
						areaList={areaList}
						activeId={activeId}
						input={input}
						view={view}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleChangeData={onHandleChangeData}
						onHandleTextInputBlur={onHandleTextInputBlur}
						onChangeAdminInput={onChangeAdminInput}
						isNew={isNew}
						baseButtons={baseButtons}
						onSave={onSave}
					/>;
				break;
			case M_ACC_GOOGLECHAT:
				form = <AccountGoogleChatForm
						areaList={areaList}
						activeId={activeId}
						input={input}
						view={view}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleChangeData={onHandleChangeData}
						onHandleTextInputBlur={onHandleTextInputBlur}
						onChangeAdminInput={onChangeAdminInput}
						isNew={isNew}
						baseButtons={baseButtons}
						onSave={onSave}
					/>;
				break;
			case M_ACC_GOOGLEPLAY:
				form = <AccountGooglePlayForm
						areaList={areaList}
						activeId={activeId}
						input={input}
						view={view}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleChangeData={onHandleChangeData}
						onHandleTextInputBlur={onHandleTextInputBlur}
						onChangeAdminInput={onChangeAdminInput}
						isNew={isNew}
						baseButtons={baseButtons}
						onSave={onSave}
					/>;
				break;
			case M_ACC_HUBSPOT:
				form = <AccountHubSpotForm
						areaList={areaList}
						activeId={activeId}
						input={input}
						view={view}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleChangeData={onHandleChangeData}
						onHandleTextInputBlur={onHandleTextInputBlur}
						onChangeAdminInput={onChangeAdminInput}
						isNew={isNew}
						baseButtons={baseButtons}
						onSave={onSave}
					/>;
				break;
			case M_ACC_SMPP:
				form = <AccountSMPPForm
						activeId={activeId}
						input={input}
						view={view}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleChangeData={onHandleChangeData}
						onHandleTextInputBlur={onHandleTextInputBlur}
						onChangeAdminInput={onChangeAdminInput}
						baseButtons={baseButtons}
						onSave={onSave}
					/>;
				break;
			case M_ACC_TRUSTPILOT:
				form = <AccountTrustpilotForm
						areaList={areaList}
						activeId={activeId}
						input={input}
						view={view}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleChangeData={onHandleChangeData}
						onHandleTextInputBlur={onHandleTextInputBlur}
						onChangeAdminInput={onChangeAdminInput}
						isNew={isNew}
						baseButtons={baseButtons}
						onSave={onSave}
					/>;
				break;
			case M_ACC_TELEGRAM:
				form = <AccountTelegramForm
						areaList={areaList}
						activeId={activeId}
						input={input}
						view={view}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleChangeData={onHandleChangeData}
						onHandleTextInputBlur={onHandleTextInputBlur}
						onChangeAdminInput={onChangeAdminInput}
						isNew={isNew}
						baseButtons={baseButtons}
						onSave={onSave}
					/>;
				break;
			case M_ACC_WHATSAPP:
				form = <AccountWhatsAppForm
						areaList={areaList}
						activeId={activeId}
						input={input}
						view={view}
						pageId={pageId}
						configId={configId}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleChangeData={onHandleChangeData}
						onHandleTextInputBlur={onHandleTextInputBlur}
						onChangeAdminInput={onChangeAdminInput}
						isNew={isNew}
						baseButtons={baseButtons}
						isWaCloud={isWaCloud}
						onSave={onSave}
					/>;
				break;
			case M_ACC_VIBER:
				form = <AccountViberForm
						areaList={areaList}
						activeId={activeId}
						input={input}
						view={view}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleChangeData={onHandleChangeData}
						onHandleTextInputBlur={onHandleTextInputBlur}
						onChangeAdminInput={onChangeAdminInput}
						isNew={isNew}
						baseButtons={baseButtons}
						onSave={onSave}
					/>;
				break;
			case M_ACC_SLACK:
				form = <AccountSlackForm
						areaList={areaList}
						activeId={activeId}
						input={input}
						view={view}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleChangeData={onHandleChangeData}
						onHandleTextInputBlur={onHandleTextInputBlur}
						onChangeAdminInput={onChangeAdminInput}
						isNew={isNew}
						baseButtons={baseButtons}
						onSave={onSave}
					/>;
				break;
			case M_ACC_TWILIO:
				form = <AccountTwilioForm
						areaList={areaList}
						activeId={activeId}
						input={input}
						view={view}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleChangeData={onHandleChangeData}
						onHandleTextInputBlur={onHandleTextInputBlur}
						onChangeAdminInput={onChangeAdminInput}
						isNew={isNew}
						baseButtons={baseButtons}
						onSave={onSave}
					/>;
				break;
			case M_ACC_YOUTUBE:
				form = <AccountYoutubeForm
						areaList={areaList}
						activeId={activeId}
						input={input}
						view={view}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleChangeData={onHandleChangeData}
						onHandleTextInputBlur={onHandleTextInputBlur}
						onChangeAdminInput={onChangeAdminInput}
						isNew={isNew}
						baseButtons={baseButtons}
						onSave={onSave}
					/>;
				break;
			default:
				break
		}

		return (
			<Fragment>{form}</Fragment>
		)
	}
}

const EditFormBase = ({
	areaList,
	activeId,
	input,
	dataSrc,
	view,
	onHandleTextInputChange,
	onHandleChangeData,
	onHandleTextInputBlur,
	onChangeAdminInput,
	oauthConstants,
	onPopAlert,
	orgArea,
	pageId,
	configId,
	userIsRoot,
	isNew,
	onSave,
	onCancel,
	onDelete,
	isWaCloud,
	adminStatus,
	...props
}) => {
	let buttonSaveTxt = BTN_TXT_SAVE, disableSave = false;
	if(adminStatus && adminStatus.status === 1) {
		buttonSaveTxt = BTN_TXT_SAVING;
		disableSave = true;
	}
	const baseButtons = <EditFormButtons
		hideDelete={isNew}
		onCancel={onCancel}
		onDelete={useCallbackMultiValues(onDelete, activeId)}
		onSave={onSave}
		saveTxt={buttonSaveTxt}
		disableSave={disableSave}
	/>
	return (
		<EditorBox>
			<AccountEditForm
				areaList={areaList}
				activeId={activeId}
				input={input}
				view={view}
				dataSrc={dataSrc}
				pageId={pageId}
				configId={configId}
				onHandleTextInputChange={onHandleTextInputChange}
				onHandleChangeData={onHandleChangeData}
				onHandleTextInputBlur={onHandleTextInputBlur}
				onChangeAdminInput={onChangeAdminInput}
				// warning={this.state.nameWarning}
				oauthConstants={oauthConstants}
				isNew={isNew}
				baseButtons={baseButtons}
				onSave={onSave}
				isWaCloud={isWaCloud}
				{...props}
			/>
		</EditorBox>
	)
}

const EditForm = composeWithDisplayName(
	'EditForm',
	memo,
	withProps(({ activeId }) => {
		if (!activeId) {
			return { isNew: true }
		}
		return { isNew: false }
	}),
	withUnmountWhenHidden
)(EditFormBase)

const OptionHeader = ({ isNew, onClickClose, title, hidden }) => {
	return (
	<div className='admin-heading edit' hidden={hidden}>
	  <div className='col-split-left'>
		<span className='admin-icons left mb-0'>
		  <i
			className={'icon-edit'}
		  />
		</span>
		<h5 className='d-inline'>{title}</h5>
	  </div>
	  <div className='col-split-right'>
		<TableIconicButton
		  type='button'
		  className='btn bg-transparent'
		  iconClass='icon-times'
		  title={I('Close form')}
		  onClick={onClickClose}
		/>
	  </div>
	</div>

	)
}

const DynamicForm = ({
		hidden
		, isNew
		, list
		, title
		, areaList
		, activeId
		, input
		, dataSrc
		, pageId
		, configId
		, view
		, onClickClose
		, onHandleTextInputChange
		, onHandleChangeData
		, onChangeAdminInput
		, onHandleTextInputBlur
		, oauthConstants
		, onSave
		, onCancel
		, onDelete
		, formType
		, hasTabs
		, isWaCloud
		, adminStatus
		// , hasTabOptions
}) => {
	let editView = (formType === FORM_EDIT || formType === FORM_EMPTY);
	let CreateGroupForm = null, ManageGroupForm = null, ListGroupForm = null;

	let baseButtons = <EditFormButtons
		hideDelete={true}
		onCancel={onCancel}
		onDelete={useCallbackMultiValues(onDelete, activeId)}
		onSave={onSave}
	/>

	switch(view) {
		// case M_ACCOUNTS:
		case M_ACC_SLACK:
			ListGroupForm = <GroupListSlackForm list={list}/>
			break;
		case M_ACC_MSTEAMS:
			ListGroupForm = <GroupListMSTeamsForm list={list}/>
			CreateGroupForm = <MSTeamsCreateGroupForm
				list={list}
				input={input}
				onHandleTextInputChange={onHandleTextInputChange}
				onSave={onSave}
				baseButtons={baseButtons}
				onChangeAdminInput={onChangeAdminInput}
			/>
			ManageGroupForm = <MSTeamsManageGroupForm
				list={list}
				input={input}
				onHandleTextInputChange={onHandleTextInputChange}
				onSave={onSave}
				baseButtons={baseButtons}
				onChangeAdminInput={onChangeAdminInput}
			/>
			break;
		case M_ACC_WHATSAPP:
			ListGroupForm = <GroupListWAForm list={list}/>
			CreateGroupForm = <WACreateGroupForm
				list={list}
				input={input}
				onHandleTextInputChange={onHandleTextInputChange}
				onSave={onSave}
				baseButtons={baseButtons}
				onChangeAdminInput={onChangeAdminInput}
			/>
			ManageGroupForm = <WAManageGroupForm
				list={list}
				input={input}
				onHandleTextInputChange={onHandleTextInputChange}
				onSave={onSave}
				baseButtons={baseButtons}
				onChangeAdminInput={onChangeAdminInput}
			/>
		default:
			break
	}
	return (
		<div className='form-container'>
			{editView ?
			<EditorHeader
				hidden={formType === FORM_EMPTY}
				isNew={isNew}
				onClickClose={onClickClose}
				title={title}
			/>
			:
			<OptionHeader
				isNew={isNew}
				onClickClose={onClickClose}
				title={I("Manage Options")}
			/>}
			{/* need add hoc here */}
			{hasTabs &&
			<div className='option-forms'>
				{formType === FORM_CREATEGROUP &&
					CreateGroupForm
				}
				{formType === FORM_MANAGEGROUP &&
					ManageGroupForm
				}
				{formType === FORM_LISTGROUP &&
					ListGroupForm
				}
			</div>}
			{formType === FORM_EDIT &&
			 <EditForm
				areaList={areaList}
				activeId={activeId}
				input={input}
				dataSrc={dataSrc}
				view={view}
				pageId={pageId}
				configId={configId}
				onHandleTextInputChange={onHandleTextInputChange}
				onHandleChangeData={onHandleChangeData}
				onChangeAdminInput={onChangeAdminInput}
				onHandleTextInputBlur={onHandleTextInputBlur}
				oauthConstants={oauthConstants}
				onSave={onSave}
				onCancel={onCancel}
				onDelete={onDelete}
				isNew={isNew}
				isWaCloud={isWaCloud}
				adminStatus={adminStatus}
			/>
			}
		</div>
	)
}

const TableHeader = ({
	tabs: {createGroup, manageGroup, listGroup},
	onToggleAdminFormType,
	onHandleOpenNew,
	showInline,
	title,
	formType,
	optionalComponent
}) => {
	const isCreate = formType == FORM_CREATEGROUP;
	const isManage = formType == FORM_MANAGEGROUP;
	const isList = formType == FORM_LISTGROUP;
	const isEdit = formType == FORM_EDIT;
	return (
		<div className='admin-top-header'>
			<div className='upper-content'>
				<h2 className='title'>{title}</h2>
				<div className='tab-options-wrapper'>
					{createGroup && <TableIconicButton
						type='button'
						// className='btn tab-btn'
						className={classNames("btn tab-btn",{"active": isCreate})}
						iconClass='icon-contact-add'
						title={I('Create groups')}
						onClick={onToggleAdminFormType}
						value={FORM_CREATEGROUP}
						disabled={isCreate}
					/>}
					{manageGroup && <TableIconicButton
						type='button'
						className={classNames("btn tab-btn",{"active": isManage})}
						iconClass='icon-member'
						title={I('Manage teams')}
						onClick={onToggleAdminFormType}
						value={FORM_MANAGEGROUP}
						disabled={isManage}
					/>}
					{listGroup && <TableIconicButton
						type='button'
						className={classNames("btn tab-btn",{"active": isList})}
						iconClass='icon-contact-book'
						title={I('Group list')}
						onClick={onToggleAdminFormType}
						value={FORM_LISTGROUP}
						disabled={isList}
					/>}
					<TableIconicButton
						type='button'
						className={classNames("plus-btn btn tab-btn",{"active": isEdit})}
						iconClass='icon-add'
						title={I('Create New')}
						onClick={onHandleOpenNew}
						// value={2}
						hide={false}
						active={showInline}
					/>
				</div>
			</div>
			{optionalComponent}
			{/* <div className='clear-both' /> */}
		</div>
	)
}

class AccountsV5Base extends PureComponent {
	constructor(props) {
		super(props);
		this.renderPaginationPanel = this.renderPaginationPanel.bind(this);
		this.handleOpenEdit = this.handleOpenEdit.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleRowClick = this.handleRowClick.bind(this);
		this.handleCheckInput = this.handleCheckInput.bind(this);
		this.handleTextInputBlur = this.handleTextInputBlur.bind(this);
		this.handleChangeData = this.handleChangeData.bind(this);
		this.handleTextInputChange = this.handleTextInputChange.bind(this);
		this.handleSave = this.handleSave.bind(this);
	}
	
	componentDidMount() {
		let view = this.props.routerView, freshLoad = false;
		let obj = ADMIN_VIEW_MAP;
		if (obj.hasOwnProperty(view)) {
			view = obj[view];
		}
		if (view === "") {
			// TODO: checkback this part if necessary 
			freshLoad = true;
			let url = window.location.href.split('#')[0];
			let array = url.split('/');
			let lastPath = array[array.length - 1];
			view = lastPath;
			let obj = ADMIN_VIEW_MAP;
			if (obj.hasOwnProperty(view)) {
				view = obj[view];
			}
		}
		if (this.props.onLoad) {
			this.props.onLoad(view, freshLoad);
		}
	}

	handleOpenEdit(id, createNew) {
		//todo: standardize/refactor this part
		if (this.props.view === M_ACC_TWILIO ) {
			this.props.onLoadToEditTwilio(id, this.props.view, false, this.props.list);
		} else {
			this.props.onLoadToEdit(id, this.props.view, false, this.props.list);
		}
	}
	handleOpenNew = (id) => {
		//todo: standardize/refactor this part
		if (this.props.view === M_ACC_TWILIO ) {
			this.props.onOpenNewForm();
		} else {
			// this.props.onOpenNewForm();
			this.props.onLoadToEdit(id, this.props.view, false, this.props.list);
		}
	}
	handleChangeView = (url, label, id) => {
		this.props.onChangeView(id, url);
	}

	handleRowClick(row, rowIndex, columnIndex, event) {
		this.props.onLoadToEdit(row.id, this.props.view, false, this.props.list);
	}

	handleDelete(id, name) {
		let selectedArea = null, twilioAccID;
		const { list, activeId, view } = this.props
		let active = (activeId === id);
		let twilioPage = (view === M_ACC_TWILIO);
		$.each(list, (i, v) => {
			if (id == v.id) {
				selectedArea = v.areaID
			}
			if(twilioPage) {
				twilioAccID = v.twilioAccountId
			}
		})
		let field = { id, name, selectedArea };
		if(twilioPage) {
			field = {id, name , selectedArea , aid: twilioAccID};
		}
		let confirmDelMsg = I('Are you sure you want to delete?');
		if (confirm(confirmDelMsg)) { // TODO: avoid using primitive confirm box
			this.props.onDeleteFromList(field, this.props.view, active);
		}

	}
	handleChangeData(value, field, key) {
		this.props.onChangeAdminInput(field, value, key)
	}
	handleTextInputChange (e) {
		let field = e.target.name, value = e.target.value;
		this.props.onChangeAdminInput(field, value);
	}
	handleCheckInput(field, value) {
		if(field === "name" || field === "wordListname"){
			if(value.length === 0) {
				this.setState({nameWarning: INPUT_EMPTY_WARNING});
				return false;
			}else{
				this.setState({nameWarning: ""});
				return true;
			}
		} else if (field === "companyEmail") {
			if(!addressValidation(Workflow.Errand.SERVICE_EMAIL, value)) {
				this.setState({nameWarning: I("* Please insert correct email address")});
				return false;
			}else{
				this.setState({nameWarning: ""});
				return true;
			}
			return true;
		}
	}
	handleTextInputBlur(e){
		let field = e.target.id, value = e.target.value;
		this.handleCheckInput(field, value);
	}
	handleSave = (data) => {
		let view = this.props.view;
		//email page we take states (data) and pass to redux
		//todo: maybe can standardize this
		if(view == M_ACCOUNTS || view == M_ACC_EMAIL ){
			this.props.onSaveAdminData(view, data);
		}else {
			this.props.onSaveAdmin();
		}
	}
	handleCloseForm = () => {
		this.props.onCloseForm();
	}
	renderPaginationPanel(props) {
		return (
			<div className="wf-pagination">
				<div className="wf-pagination-page">
					{props.totalPages > 1 && props.components.pageList}
				</div>
				<div className="wf-pagination-current">
					{
						(this.props.list && this.props.list.length > 0) &&
						I('Showing {CURRENT_PAGE} of {TOTAL_PAGES}')
							.replace('{CURRENT_PAGE}', props.currPage)
							.replace('{TOTAL_PAGES}', props.totalPages)
					}
				</div>
			</div>
		);
	}
	toggleFormType = (e) => {
		const { value , title } = e.currentTarget;
		this.props.onToggleAdminFormType(+value);
	}

	render() {
		const {
			view
			, list
			, input
			, dataSrc
			, areaList
			, order
			, orgAreas
			, showInline
			, filter
			, pageId
			, configId
			, activeId
			, oauthConstants
			, onChangeAdminInput
			, formType
			, isWaCloud
			, adminStatus
		} = this.props;

		let extraComponent = <AdminAccountSideMenu
								view={view}
								item={this.props.subMenus}
								onClick={this.handleChangeView}
							/>;
		let hasTabs = false, extraTHeaderComponent = null;
		const options = {
			sizePerPage: getListSizeBasedOnHeight(),
			pageStartIndex: 1,
			paginationSize: 3,
			withFirstAndLast: true,
			hideSizePerPage: true,
			onDeleteRow: this.handleDelete,
			noDataText: I("Empty list"),
			paginationPanel: this.renderPaginationPanel,
			// onRowClick: this.handleRowClick,
			defaultSortName: 'id',
			defaultSortOrder: 'asc'
		};
		let tabs = {
			createGroup: false,
			manageGroup: false,
			listGroup: false
		}
		if(view === M_ACC_SLACK) {
			tabs = {
				createGroup: false,
				manageGroup: false,
				listGroup: true
			}
			hasTabs = true;

			let count = 0, msg;
			$.each(list, function(i,v) {
				if(typeof v.enabled !== 'undefined' && v.enabled){
					count++;
				}
			});
			if(count > 1){
				msg = I("* You have more than one slack account enabled for this system group.");
				extraTHeaderComponent = <div className='mandatory p-3'>{msg}</div>
			}
		}

		if(view === M_ACC_MSTEAMS || view === M_ACC_WHATSAPP) {
			hasTabs = true;

			tabs = {
				createGroup: true,
				manageGroup: true,
				listGroup: true
			}
		}

		return (
			<Skeleton
				className={"account-page"}
				lists={
					<Fragment>
						<ListHeaderWrapper>
							<TableHeader
								title={ADMIN_TITLE[view]}
								tabs={tabs}
								type='button'
								className='plus-btn btn bg-transparent'
								iconClass='icon-add'
								onHandleOpenNew={e => this.handleOpenNew(0, true)}
								hide={false}
								onToggleAdminFormType={this.toggleFormType}
								showInline={showInline}
								optionalComponent={extraTHeaderComponent}
								formType={formType}
							/>
						</ListHeaderWrapper>
						<ListContentWrapper>
							{<AdminAccountsTable
								areaList={areaList}
								view={view}
								list={list}
								options={options}
								onHandleOpenEdit={this.handleOpenEdit}
								onHandleDelete={this.handleDelete}
								order={order}
								orgAreas={orgAreas}
								onHandleActivateAgent={this.handleActivateAgent}
								onHandleActivateSipStatus={this.props.onActivateAgentSipStatus}
								onHandleActivateStunTurnStatus={this.props.onActivateStunTurnStatus}
								onHandleUnlockAgent={this.handleUnlockAgent}
								selectedAreas={filter.selectedAreaFilter}
								currentSortField={this.props.currentSortField}
								currentSortOrder={this.props.currentSortOrder}
								onSortFields={this.props.onSortFields}
								onSortOrder={this.props.onSortOrder}
								activeId={activeId}
								onToggleEmailTestPopup={this.props.onToggleEmailTestPopup}
								onActivateAccount={this.props.onActivateAccount}
								id={this.props.id}
							/>}
						</ListContentWrapper>
					</Fragment>
				}
				extraComponent={extraComponent}
			>
				<DynamicForm
					hidden={!showInline}
					isNew={activeId <= 0}
					onClickClose={this.handleCloseForm}
					title={editorTitleByView(activeId <= 0, view)}
					areaList={areaList}
					pageId={pageId}
					configId={configId}
					activeId={activeId}
					input={input}
					dataSrc={dataSrc}
					list={list}
					view={view}
					onHandleTextInputChange={this.handleTextInputChange}
					onHandleChangeData={this.handleChangeData}
					onChangeAdminInput={onChangeAdminInput}
					onHandleTextInputBlur={this.handleTextInputBlur}
					oauthConstants={oauthConstants}
					onSave={this.handleSave}
					onCancel={this.handleCloseForm}
					onDelete={this.handleDelete}
					formType={formType}
					hasTabs={hasTabs}
					isWaCloud={isWaCloud}
					adminStatus={adminStatus}
				/>
			</Skeleton>
		)
	}
}


const AccountsV5 = withAccount(AccountsV5Base);

export default AccountsV5;