import React, {
	Fragment
	, PureComponent
	, memo
	, useCallback
	, useEffect
	, useMemo
} from 'react';
import TagsInput from 'react-tagsinput';
import { I, webRoot } from '../../../common/v5/config';
import { Area } from '../../../components/v5/ManualErrand';
import {
	TextInputRow
	, ReadOnlyTextField
	, SelectInputRow
	, CustomInputRow
} from '../../../reactcomponents/Form';
import {
	LabelToggleSwitch as ToggleSwitch
} from '../../../components/v5/ToggleSwitch';
import { INPUT_EMPTY_WARNING, FORM_SUBMIT_EMPTY_WARNING } from '../../../common/v5/constants';
import update from 'immutability-helper';

export default class AccountSMPPForm extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			showArea: false,
			reqInputs: {
				name: {touched:false},
				hostAddress: {touched:false},
				portNumber: {touched:false},
				loginName: {touched:false},
				password: {touched:false}
			}
		};

	}

	handleSMPPRole = (value, field, key) => {
		this.setState({typeId: value });
		this.props.onHandleChangeData(value, field, key);
	}

	handleFieldWarning = (e) => {
		let field = e.target.name;
		let reqInputs = this.state.reqInputs;
		reqInputs = update(reqInputs,{
			[field]: {
				touched:{$set: true}
			},
		})
		this.setState({reqInputs:reqInputs})
	}

	handleSubmitFrom =(e)=> {
		e.preventDefault();
		let reqInputs = this.state.reqInputs;
		let input = this.props.input;
		let valid = true;
		$.each(reqInputs, (field, v)=>{
			//set required inputs touched
			reqInputs = update(reqInputs,{
				[field]: {
					touched:{$set: true}
				},
			})
			//check if empty
			if(input[field] === "" || input[field] === undefined) {
				valid = false;
			}
		});
		this.setState({reqInputs:reqInputs})
		if(valid){
			this.props.onSave();
		} else {
			alert(FORM_SUBMIT_EMPTY_WARNING);
		}
	}

	render() {
		const {
			input
			, onHandleTextInputChange
			, hidden
			, isNew
			, baseButtons
		} = this.props;

		const field = this.state.reqInputs;
		const warnTxt = INPUT_EMPTY_WARNING;

		let smppRoleTypeSelections = input.smppRoles.map(role => {
			let properties = {
				id: role.id,
				name: role.value
			};
			return properties;
		});

		return (
			<form onSubmit={this.handleSubmitFrom} className="admin-one-form edit-admin-form" hidden={hidden}>
				<TextInputRow
					id={"name"}
					name={"name"}
					className="admin-text-input"
					label={I("Configuration Name")}
					value={input.name || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.name && field.name.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
				/>
				<TextInputRow
					id={"hostAddress"}
					name={"hostAddress"}
					className="admin-text-input"
					label={I("SMPP Host Address")}
					value={input.hostAddress || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.hostAddress && field.hostAddress.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
				/>
				<TextInputRow
					id={"portNumber"}
					name={"portNumber"}
					className="admin-text-input"
					label={I("SMPP Host Port Number")}
					value={input.portNumber || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.portNumber && field.portNumber.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
				/>
				<TextInputRow
					id={"loginName"}
					name={"loginName"}
					className="admin-text-input"
					label={I("SMPP Login Name")}
					value={input.loginName || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.loginName && field.loginName.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
				/>
				<TextInputRow
					id={"password"}
					name={"password"}
					className="admin-text-input"
					label={I("Password")}
					value={input.password || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.password && field.password.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
				/>
				<SelectInputRow
					id={"selectedRole"}
					name={"selectedRole"}
					label={I("SMPP Role")}
					className={"admin-select-option"}
					option={smppRoleTypeSelections}
					value={input.selectedRole || ""}
					onSelect={this.handleSMPPRole}
				/>
				<TextInputRow
					id={"systemType"}
					name={"systemType"}
					className="admin-text-input"
					label={I("SMPP System Type (default is empty)")}
					value={input.systemType || ""}
					onChange={onHandleTextInputChange}
				/>
				{baseButtons}
			</form>
		)
	}
}