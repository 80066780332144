import { connect } from "react-redux";
import AgentAssist from "./AgentAssist";
import {
	changeAdminView
	, toggleAdminEdit
	, setAdminInput
	, adminActionAlert
} from '../../../redux/actions/admin';
import {
	fetchAgentAssistConf,
	saveAgentAssistConf
} from '../../../redux/actions/async/admin';
import { M_AGENT_ASSIST } from "../../../common/v5/constants";

const mapAgentAssistState = (store, props) => {
	const server = store.server
		, app = store.app
		, admin = app.admin
		, ui = admin.ui
		, adminSt = admin.admin
		, wf = app.workflow
		;
	return {
		view: ui.view
		, activeId: adminSt.activeId
		, input: adminSt.input
		, adminAlert: adminSt.adminAlert
		, saveStatus: adminSt.adminStatus,
	};
};

const mapAgentAssistAction = (dispatch, props) => {
	return {
		onLoad: (view) => {
			dispatch(changeAdminView(view));
			dispatch(fetchAgentAssistConf());
		},
		onChangeAdminInput: (field, value) => {
			dispatch(setAdminInput(field, value, M_AGENT_ASSIST));
		},
		onCancel: () => {
			dispatch(toggleAdminEdit(0, false, false, true));
		},
		onDismissAlert: () => {
			const alertMsg = {
				show: false,
				msg: "",
				type: ""
			}
			dispatch(adminActionAlert(alertMsg));
		},
		onSave: (param) => {
			dispatch(saveAgentAssistConf(param));
		},
	}
};

export const AgentAssistCtnr = connect(
	mapAgentAssistState
	, mapAgentAssistAction
)(AgentAssist);