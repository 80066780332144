import React, { PureComponent } from 'react';
import { Button } from 'reactstrap';
import { I, webRoot } from '../../../common/v5/config';
import { Area } from '../../../components/v5/ManualErrand';
import {
	TextInputRow,
	SelectInputRow,
	ReadOnlyTextField,
	CustomInputRow
} from '../../../reactcomponents/Form';
import {
	LabelToggleSwitch as ToggleSwitch
} from '../../../components/v5/ToggleSwitch';
import {
	IdValueSingleSelect
	, UnnestedAreaSingleSelect
 } from '../../../reactcomponents/Dropdown'
import { INPUT_EMPTY_WARNING, FORM_SUBMIT_EMPTY_WARNING } from "../../../common/v5/constants"
import update from 'immutability-helper';
import { TXT_NO_SELECTION } from '../../../common/v5/receiptGreetingConstants';


const helperForUsername = <div>
	<div>{I("JIRA BOT's username with access permission to issues and comments creation.")}</div>
	<div>{I("This username will be treated as a BOT throughout the entire JIRA system.")}</div>
</div>

const helperForArea = <div>
	<div>{I("An area must be chosen because the authenticated Jira account needs to be connected with an area.")}</div>
</div>

const helperForUserAccountID = <div>
	<div>{I("JIRA BOT's user account id with access permission to issues and comments creation.")}</div>
	<div>{I("This user account id will be treated as a BOT throughout the entire JIRA cloud system.")}</div>
</div>

const helperForAccessToken = <div>
	<div>{I("JIRA BOT's password / token.")}</div>
	<br/>
	<div>{I("API Tokens can be generated via JIRA's user profile, provided an API Tokens plugin is installed with a valid license.")}</div>
</div>

const helperForProjectKey = <div>
	<div>{I("JIRA Project Key prefix.")}</div>
	<br/>
	<div>{I("It prefixes each issue in the project.")}</div>
</div>

const helperForProjectName = <div>
	<div>{I("JIRA Project Name.")}</div>
</div>

const helperForType= <div>
	<div>{I("JIRA Issue Types distinguish different types of work.")}</div>
	<br/>
	<div>{I("1. Story")}</div>
	<div>{I("2. Epic")}</div>
	<div>{I("3. Task")}</div>
	<div>{I("4. Bug")}</div>
</div>

const helperForPriority= <div>
	<div>{I("JIRA Priorities will be categorized in order from highest to lowest.")}</div>
	<br/>
	<div>{I("1. Highest")}</div>
	<div>{I("2. High")}</div>
	<div>{I("3. Medium")}</div>
	<div>{I("4. Low")}</div>
	<div>{I("5. Lowest")}</div>
</div>


const JIRA_TYPE = [
	{id: "Story", value: "Story"},
	{id: "Epic", value: "Epic"},
	{id: "Task", value: "Task"},
	{id: "Bug", value: "Bug"}
];

const JIRA_PRIORITY = [
	{id: "Highest", value: "Highest"},
	{id: "High", value: "High"},
	{id: "Medium", value: "Medium"},
	{id: "Low", value: "Low"},
	{id: "Lowest", value: "Lowest"}
];

export default class AccountJiraForm extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			reqInputs: {
				name: {touched:false},
				jiraUrl: {touched:false},
				userName: {touched:false},
				token: {touched:false},
				accountId: {touched:false},
				accountName: {touched:false},
				type: {touched:false},
				priority: {touched:false},
			},
			optionalReqInputs: {
				userAccountID: {touched:false}
			}
		};

	}

	handleSelectArea = (val, field) => {
		this.props.onChangeAdminInput("selectedArea", val, this.props.view);
	}
	
	handleSelect = (val, field) => {
		this.props.onChangeAdminInput(field, val);
	}
	
	handleFieldWarning = (e) => {
		let field = e.target.name;
		let reqInputs = this.state.reqInputs;
		reqInputs = update(reqInputs,{
			[field]: {
				touched:{$set: true}
			},
		})
		this.setState({reqInputs:reqInputs})
	}
	handleOptionalFieldWarning = (e) => {
		let field = e.target.name;
		let optionalReqInputs = this.state.optionalReqInputs;
		optionalReqInputs = update(optionalReqInputs,{
			[field]: {
				touched:{$set: true}
			},
		})
		this.setState({optionalReqInputs:optionalReqInputs})
	}

	handleSubmit = (e) => {
		e.preventDefault();
		let reqInputs = this.state.reqInputs;
		let optionalReqInputs = this.state.optionalReqInputs;
		let input = this.props.input;
		let valid = true;
		if (input.selectedArea === "0") {
			return alert(I("Please select area"));
		}
		$.each(reqInputs, (field, v)=>{
			reqInputs = update(reqInputs,{
				[field]: {
					touched:{$set: true}
				},
			})
			if(input[field] === "" || input[field] === undefined) {
				valid = false;
			}
		});
		//only check "userAccountID" when "cloud" is true
		if(input.cloud){
			optionalReqInputs = update(optionalReqInputs,{
				userAccountID: {
					touched:{$set: true}
				},
			})
			if(!input.userAccountID) {
				valid = false;
			}
		}
		this.setState({reqInputs:reqInputs, optionalReqInputs: optionalReqInputs})
		if(valid){
			this.props.onSave();
		} else {
			alert(FORM_SUBMIT_EMPTY_WARNING);
		}
	}

	render() {
		const {
			areaList
			, input
			, onHandleTextInputChange
			, hidden
			, baseButtons
		} = this.props;

		let selectedArea = parseInt(input.selectedArea, 10);

		const st = this.state, field = st.reqInputs, optionalfield = st.optionalReqInputs;
		const warnTxt = INPUT_EMPTY_WARNING;

		return (
			<form onSubmit={this.handleSubmit} className="admin-one-form edit-admin-form" hidden={hidden}>
				<CustomInputRow
					id={"area"}
					name={"area"}
					label={I('Area')}
					className={"admin-dropdown-select"}
					mandatory={true}
					helper={helperForArea}
				>
					<UnnestedAreaSingleSelect
						data={areaList}
						invalidSelectedText={TXT_NO_SELECTION}
						onSelect={(v)=>{this.handleSelect(v, "selectedArea")}}
						selected={selectedArea}
						nested={{key: 'Areas'}}
						idFields={{id: "Id", name: "Name"}}
					/>
				</CustomInputRow>
				<TextInputRow
					id={"name"}
					name={"name"}
					className="admin-text-input"
					label={I("Name")}
					value={input.name || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.name && field.name.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
				/>
				<ToggleSwitch
					id={"enable"}
					name={"enable"}
					data-qa-id="enableJira"
					label={I("Activated")}
					checked={input.enable}
					onClick={this.handleSelect}
					listWrap={false}
					className="admin-text-input"
				/>
				<TextInputRow
					id={"jiraUrl"}
					name={"jiraUrl"}
					className="admin-text-input"
					label={I("JIRA URL")}
					value={input.jiraUrl || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.jiraUrl && field.jiraUrl.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
				/>
				<TextInputRow
					id={"userName"}
					name={"userName"}
					className="admin-text-input"
					label={I("Username")}
					value={input.userName || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.userName && field.userName.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
					helperTxt={helperForUsername}
				/>
				<ToggleSwitch
					id={"cloud"}
					name={"cloud"}
					data-qa-id="enabledCloudJira"
					label={I("Cloud")}
					checked={input.cloud}
					onClick={this.handleSelect}
					listWrap={false}
					className="admin-text-input"
				/>
				<TextInputRow
					id={"userAccountID"}
					name={"userAccountID"}
					className="admin-text-input"
					label={I("User AccountID")}
					value={input.userAccountID || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					hide={!input.cloud}
					warning={(!input.userAccountID && optionalfield.userAccountID.touched) && warnTxt}
					onBlur={this.handleOptionalFieldWarning}
					helperTxt={helperForUserAccountID}
				/>
				<TextInputRow
					id={"token"}
					name={"token"}
					className="admin-text-input"
					label={I("Access token")}
					value={input.token || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.token && field.token.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
					helperTxt={helperForAccessToken}
				/>
				<TextInputRow
					id={"accountId"}
					name={"accountId"}
					className="admin-text-input"
					label={I("Project Key")}
					value={input.accountId || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.accountId && field.accountId.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
					helperTxt={helperForProjectKey}
				/>
				<TextInputRow
					id={"accountName"}
					name={"accountName"}
					className="admin-text-input"
					label={I("Project Name")}
					value={input.accountName || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.accountName && field.accountName.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
					helperTxt={helperForProjectName}
				/>
				<CustomInputRow
					id={"type"}
					name={"type"}
					label={I('Type')}
					className={"inputs-wrapper"}
					mandatory={true}
					helper={helperForType}
				>
					<IdValueSingleSelect
						data={JIRA_TYPE}
						invalidSelectedText={TXT_NO_SELECTION}
						onSelect={(v)=>{this.handleSelect(v, "type")}}
						selected={input.type || ""}
					/>
				</CustomInputRow>
				<CustomInputRow
					id={"priority"}
					name={"priority"}
					label={I('Priority')}
					className={"admin-select-review-option"}
					mandatory={true}
					helper={helperForPriority}
				>
					<IdValueSingleSelect
						data={JIRA_PRIORITY}
						invalidSelectedText={TXT_NO_SELECTION}
						onSelect={(v)=>{this.handleSelect(v, "priority")}}
						selected={input.priority || ""}
					/>
				</CustomInputRow>
				{baseButtons}
			</form>
		)
	}
}