import React, { Fragment, useState, useEffect, useCallback } from "react";
import styled from 'styled-components';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { BTN_TXT_SAVE, BTN_TXT_SAVING, M_CALL_IVR } from "../../../common/v5/constants";
import { renderFileActionBtns as renderActionButtons } from '../admin';
import {
  ListHeaderWrapper,
  ListContentWrapper,
  TableHeader,
  Delete as ActionDelete,
  Download as ActionDownload,
  Edit as ActionEdit,
  EditorFooter,
  EditorHeader,
  SaveButton,
  CancelButton,
  AdminListAndEditLayout,
  withEditWrap,
  CustomButton
} from '../../../reactcomponents/Admin';
import { ADMIN_TITLE } from '../../../common/v5/constants';
import {
	useCallbackMultiValues,
} from '../../../hooks/callback'
import CallIVREditForm, { IVRPreview } from './callIVREdit';
import {
	centionGreen
 } from '../../../styles/_variables';
 import { AlertWrapper } from "../admin";
 import { DismissableAlert } from '../../../reactcomponents/Error';
 import { TABLE_STANDARD_OPTIONS } from "../../../reactcomponents/Table";

const EditForm = withEditWrap(CallIVREditForm);

const EditFormButtons = ({
	validIVR,
	saveTxt = BTN_TXT_SAVE,
	disableSave,
	onCancel,
	onDelete,
	onRelease,
	onSave,
	onViewStructure
}) => {
	return (
		<EditorFooter
			left={
				<div className="ivr-footer-btn">
					<CustomButton className={"bg-transparent btn-transparent"} icon={"icon-trash"} onClick={onDelete} text={I("Remove")} />
					<CustomButton className={"bg-transparent btn-transparent"} icon={"icon-structure"} onClick={onViewStructure} text={I("View structure")} />
				</div>
			}
		>
			<CancelButton onClick={onCancel} />
			<SaveButton className={"btn-blue"} onClick={onSave} text={saveTxt} title={saveTxt} disabled={disableSave} />
			<CustomButton className={"btn-blue"} disabled={!validIVR} onClick={onRelease} text={I("Release")} />
		</EditorFooter>
	)
}

const StyledActiveStatus = styled.span`
	display: contents;
	&:before {
		display: inline-block;
		width: 8px;
		height: 8px;
		border-radius: 30px;
		background-color: ${props => props.online ? centionGreen : 'transparent'};
		content: '';
		position: relative;
		transition: background-color .2s ease;
		margin: 0px 5px;
	}
`

const CallIVRForm = ({
	view
	, onLoad
	, showInline
	, list
	, onLoadToEdit
	, onChangeAdminInput
	, onChangeCallflow
	, onLoadCallflow
	, onRemoveCallflowKey
	, onSaveAdmin
	, onCancel
	, onRelease
	, onDeleteFromList
	, onCloseForm
	, activeId
	, input
	, mainPrompt
	, ivrTreeData
	, adminAlert
	, onConfirmUpload
	, onUploadPrompt
	, onUploadMOH
	, onPlayAudioError
	, onDismissAlert
	, adminStatus
}) => {

	const [hidePreview, setHidePreview] = useState(true);
	const viewStructure = useCallback(() => setHidePreview(!hidePreview));

	const [previewNodeTree, setPreviewNodeTree] = useState({});
	const [previewTitle, setPreviewTitle] = useState("");
	const [hideListPreview, setHideListPreview] = useState(true);
	const viewListStructure = useCallback(() => setHideListPreview(!hideListPreview));

	const ivrTreeSize = Object.keys(ivrTreeData.drawflow.Home.data).length;
	const validIVR = activeId > 0 && ivrTreeSize > 1;

	useEffect(() => {
		if (onLoad) {
			onLoad(M_CALL_IVR, false);
		}
	}, []);

	const handleEdit = (id) => {
		onLoadToEdit(id, M_CALL_IVR, false, list)
	}

	const handleDelete = (id) => {
		let confirmDelMsg = I('Are you sure you want to delete?');
		if (confirm(confirmDelMsg)) { // TODO: use designed Confirm Box
			onDeleteFromList(id);
		}
	}

	const handlePreview = (id) => {
		const ivr = list.find(cf => cf.id === id);
		const nodeTree = JSON.parse(ivr.nodeTree);
		setPreviewNodeTree(nodeTree);
		setPreviewTitle(ivr.name);
		setHideListPreview(false);
	}

	const actionDatas = {
		onEdit: handleEdit
		, onPreviewStructure: handlePreview
		, onDelete: handleDelete
	}

	const nameFormatter = (cell, row) => {
		let name = <span><StyledActiveStatus online={false}></StyledActiveStatus> {cell} </span>
		if(row.active) {
			name = <span><StyledActiveStatus online={true} title={I("Activated")}></StyledActiveStatus> {cell} </span>
		}
		return name;
	}
	const columns = [
		{
			dataField: 'id',
			text: 'ID',
			sort: true,
			hidden: true
		},
		{
			dataField: 'name',
			text: I('Name'),
			sort: true,
			formatter: nameFormatter
		},
		{
			dataField: 'description',
			text: I('Description'),
			sort: true,
		},
		{
			isDummyField: true,
			dataField: 'action',
			text: I('Action'),
			headerClasses: 'center',
			formatter: (cell, row) => {
				return renderActionButtons(cell, row, actionDatas);
			},
		}
	];

	const defaultSorted = [{
		dataField: 'name',
		order: 'asc'
	}];

	const isNew = activeId <= 0;

	const rowEvents = {
		onClick: (e, row) => {
			return onLoadToEdit(row.id, M_CALL_IVR, false, list);
		}
	};

	const rowClasses = (row) => {
		let classes = null;
		if (row.id === activeId) {
			classes = 'active-row';
		}
		return classes;
	};

	let buttonSaveTxt = BTN_TXT_SAVE, disableSave = false;
	if(adminStatus && adminStatus.status === 1) {
		buttonSaveTxt = BTN_TXT_SAVING;
		disableSave = true;
	}

	const baseButtons = <EditFormButtons
		validIVR={validIVR}
		hideDelete={isNew}
		onCancel={useCallbackMultiValues(onCancel, activeId, mainPrompt.level, mainPrompt.parentid)}
		onDelete={useCallbackMultiValues(handleDelete, activeId)}
		onSave={onSaveAdmin}
		saveTxt={buttonSaveTxt}
		disableSave={disableSave}
		onRelease={useCallbackMultiValues(onRelease, activeId)}
		onViewStructure={viewStructure}
	/>

	const handleTextInputChange = (e) => {
		let field = e.target.name, value = e.target.value;
		onChangeAdminInput(field, value);
	}

	let listSection = <Fragment>
		<ListHeaderWrapper>
			<TableHeader
				title={ADMIN_TITLE[view]}
				type='button'
				// className='plus-btn btn bg-transparent'
				iconClass='icon-add'
				onClickAdd={e => onLoadToEdit(0)}
				hide={false}
				hideAddIcon={false}
				showInline={showInline}
			/>
		</ListHeaderWrapper>
		<ListContentWrapper className={"v5-table-style"}>
			<BootstrapTable
				keyField="id"
				data={list}
				noDataIndication={I("No data to display")}
				columns={columns}
				bordered={false}
				condensed={false}
				defaultSorted={defaultSorted}
				pagination={paginationFactory(TABLE_STANDARD_OPTIONS)}
				rowEvents={ rowEvents }
				rowClasses={ rowClasses }
				hover
			/>
			{
				!hideListPreview &&
				<IVRPreview show={!hideListPreview} title={previewTitle} data={previewNodeTree} onDelete={handleDelete} onClose={viewListStructure} />
			}
		</ListContentWrapper>
	</Fragment>;

	let editSection = <Fragment>
		<AlertWrapper id={"CallIVRAlert"} hidden={adminAlert.show ? false : true} onDismiss={onDismissAlert}>
			<DismissableAlert show={adminAlert.show ? true : false}
				role={adminAlert.type === "error" ? "danger" : "success"}
				text={adminAlert.msg}
				icon={adminAlert.type === "error" ? "icon-caution" : "icon-new-tick-single"}
				onDismiss={onDismissAlert}
			/>
		</AlertWrapper>
		<EditorHeader
			hidden={!showInline}
			isNew={isNew}
			onClickClose={onCloseForm}
			title={I("Edit phone menu")}
		/>
		<EditForm
			activeId={activeId}
			input={input}
			view={view}
			adminStatus={adminStatus}
			mainPrompt={mainPrompt}
			onChangeCallflow={onChangeCallflow}
			onLoadCallflow={onLoadCallflow}
			onRemoveOneKey={onRemoveCallflowKey}
			onTextInputChange={handleTextInputChange}
			onChangeAdminInput={onChangeAdminInput}
			onSave={onSaveAdmin}
			onCancel={onCloseForm}
			onDelete={handleDelete}
			isNew={isNew}
			hidden={!showInline}
			baseButtons={baseButtons}
			ivrTreeData={ivrTreeData}
			showPreview={!hidePreview}
			onHidePreview={viewStructure}
			onConfirmUpload={onConfirmUpload}
			onUploadPrompt={onUploadPrompt}
			onUploadMOH={onUploadMOH}
			onPlayAudioError={onPlayAudioError}
		/>
	</Fragment>;

	return <AdminListAndEditLayout
		className={"call-ivr-page"}
		listSection={listSection}
		editSection={editSection}
	/>
}

export default CallIVRForm;