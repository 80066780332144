import React, { Fragment, useEffect, useState } from "react";
import {
  EditorFooter,
  EditorHeader,
  SaveButton,
  AdminListAndEditLayout,
  withEditWrap
} from '../../../reactcomponents/Admin';
import {
	M_AGENT_ASSIST,
	BTN_TXT_SAVE,
	BTN_TXT_SAVING,
} from '../../../common/v5/constants';
 import { AlertWrapper } from "../admin";
 import { DismissableAlert } from '../../../reactcomponents/Error';
 import {
	TextInputRow,
	FormInputWithLabelRow
} from '../../../reactcomponents/Form';
import { I } from "../../../common/v5/config";

const AgentAssistEditForm = ({
	input
	, baseButtons
	, onChangeAdminInput
	, onHandleTextInputBlur
}) => {

	const handleChangeInput = (e) => {
		let field = e.target.name, value = e.target.value;
		onChangeAdminInput(field, value);
	}

	return (
		<Fragment>
			<form className="admin-one-form edit-admin-form fullwidth-admin-form">
				<FormInputWithLabelRow
					label={I('Custom Instructions')}
					inlineLabel={false}
					labelHelper={I('What would you like the AI to know about you to provide better responses?')}
				>
					<TextInputRow
						name="agentAssistInstruction"
						textArea={true}
						className="admin-text-input with-helper"
						value={input.agentAssistInstruction}
						onChange={handleChangeInput}
						onBlur={onHandleTextInputBlur}
						warning={""}
						inlineLabel={false}
						rows={10}
						withTextCounter={true}
					/>
				</FormInputWithLabelRow>
				<FormInputWithLabelRow
					label={I('How would you like the AI to respond?')}
					inlineLabel={false}
					labelHelper={I('How formal or casual would you like the AI to be? or how do you want to be addressed?')}
				>
					<TextInputRow
						id={"admin-agentAssistResponse"}
						name="agentAssistResponse"
						textArea={true}
						className="admin-text-input with-helper"
						value={input.agentAssistResponse}
						onChange={handleChangeInput}
						onBlur={onHandleTextInputBlur}
						inlineLabel={false}
						rows={10}
						withTextCounter={true}
					/>
				</FormInputWithLabelRow>
				<FormInputWithLabelRow
					label={I('Response Example')}
					inlineLabel={false}
					labelHelper={I('Please provide an example of the response.')}
				>
					<TextInputRow
						id={"admin-agentAssistExample"}
						name="agentAssistExample"
						textArea={true}
						className="admin-text-input with-helper"
						value={input.agentAssistExample}
						onChange={handleChangeInput}
						onBlur={onHandleTextInputBlur}
						inlineLabel={false}
						rows={10}
						withTextCounter={true}
					/>
				</FormInputWithLabelRow>
				<FormInputWithLabelRow
					label={I('Formatting')}
					inlineLabel={false}
					labelHelper={I('How would you like the response to be formatted?')}
				>
					<TextInputRow
						id={"admin-agentAssistFormatting"}
						name="agentAssistFormatting"
						textArea={true}
						className="admin-text-input with-helper"
						value={input.agentAssistFormatting}
						onChange={handleChangeInput}
						onBlur={onHandleTextInputBlur}
						inlineLabel={false}
						rows={10}
						withTextCounter={true}
					/>
				</FormInputWithLabelRow>
				<div style={{ marginTop: '50px' }}>
					{baseButtons}
				</div>
			</form>
		</Fragment>
	)
}

const EditForm = withEditWrap(AgentAssistEditForm);

const EditFormButtons = ({
	onSave,
	saveTxt = BTN_TXT_SAVE,
	disableSave = false
}) => {
	return (
		<EditorFooter>
			<SaveButton className={"btn-blue"} onClick={onSave} disabled={disableSave} text={saveTxt} />
		</EditorFooter>
	)
}

const AgentAssistAdmin = ({
	view
	, onLoad
	, onChangeAdminInput
	, activeId
	, input
	, adminAlert
	, onDismissAlert
	, onSave
	, saveStatus
}) => {
	const [buttonSaveTxt, setbuttonSaveTxt] = useState(BTN_TXT_SAVE);
	useEffect(() => {
		if (onLoad) {
			onLoad(M_AGENT_ASSIST);
		}
		return () => {
			onDismissAlert();
		}
	}, []);

	const handleSave = () => {
		const param = {
			id: activeId
			, instruction: input.agentAssistInstruction
			, responses: input.agentAssistResponse
			, example: input.agentAssistExample
			, formatting: input.agentAssistFormatting
		}
		onSave(param);
	}

	useEffect(() => {
		if(saveStatus && saveStatus.status === 1) {
			setbuttonSaveTxt(BTN_TXT_SAVING);
		} else {
			setbuttonSaveTxt(BTN_TXT_SAVE);
		}
	}, [saveStatus]);

	const baseButtons = <EditFormButtons
		hideDelete={true}
		hidePreview={true}
		disableSave={saveStatus.status === 1}
		saveTxt={buttonSaveTxt}
		onSave={handleSave}
	/>

	const handleTextInputChange = (e) => {
		let field = e.target.name, value = e.target.value;
		onChangeAdminInput(field, value);
	}

	let editSection = <Fragment>
		<AlertWrapper hidden={adminAlert.show ? false : true}>
			<DismissableAlert show={adminAlert.show ? true : false}
				role={adminAlert.type === "error" ? "danger" : "success"}
				text={adminAlert.msg}
				icon={adminAlert.type === "error" ? "icon-caution" : "icon-new-tick-single"}
				onDismiss={onDismissAlert}
			/>
		</AlertWrapper>
		<EditorHeader
			title={I("Customize agent assist")}
		/>
		<EditForm
			activeId={activeId}
			input={input}
			view={view}
			onTextInputChange={handleTextInputChange}
			onChangeAdminInput={onChangeAdminInput}
			onSave={onSave}
			baseButtons={baseButtons}
		/>
	</Fragment>;

	return <AdminListAndEditLayout
		className={"full-width"}
		editSection={editSection}
	/>
}

export default AgentAssistAdmin;