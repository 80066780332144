import React, {
	Fragment
	, PureComponent
	, memo
	, useCallback
	, useEffect
	, useMemo
} from 'react';
import { Button } from 'reactstrap';
import { I, webRoot } from '../../../common/v5/config';
import { Area } from '../../../components/v5/ManualErrand';
import {
	TextInputRow,
	ReadOnlyTextField,
	CustomInputRow
} from '../../../reactcomponents/Form';
import {
	LabelToggleSwitch as ToggleSwitch
} from '../../../components/v5/ToggleSwitch';
import SquareCheckbox from '../../../reactcomponents/SquareCheckbox';
import { INPUT_EMPTY_WARNING, FORM_SUBMIT_EMPTY_WARNING, WEBHOOKAUTHURL } from "../../../common/v5/constants"
import update from 'immutability-helper';
import { windowPopupCenter } from '../../../common/v5/helpers';
import { UnnestedAreaSingleSelect } from '../../../reactcomponents/Dropdown';
import { TXT_NO_SELECTION } from '../../../common/v5/receiptGreetingConstants';


const helperForName = <div>
	<div>{I("This is the name that will be displayed in the list of accounts. This name is only used to help you identify the account.")}</div>
</div>

const helperForArea = <div>
	<div>{I("An area must be chosen because the authenticated Twitter account needs to be connected with an area.")}</div>
</div>

const helperForEnable = <div>
	<div>{I("If the account is enabled the system will fetch posts and comments from your Twitter timeline.")}</div>
	<div>{I("If it is not enabled the system will not do anything with the account.")}</div>
</div>

const helperForHashtags = <div>
	<div>{I("If you want to accept hashtags, then please put the hashtag names (i.e. blah,cention etc) in the field.")}</div>
</div>

const helperForAuthorization = <div>
	<div>{I("For the system to be able to manage your Twitter timeline you need to give the Cention Twitter application permission to do that.")}</div>
	<br/>
	<div>{I("When you click the button a new window will open where you will be asked to login to Twitter. After you have logged in you will be asked to accept the permissions the Cention Twitter application requires to manage your timeline.")}</div>
	<br/>
	<div>{I("Click the Authorize button to start the authorization process.")}</div>
</div>

const TWAuthButton = ({label, onFetchKey}) => {
	const handleClick = (e) => {
		if(onFetchKey) {
			onFetchKey();
		}
		const TWITTER_CLIENT_ID = "aWxrNUZkdDJsWnRaV1N1eDZQVkI6MTpjaQ"
		const msg = I("Twitter authentication failed! Please contact with cention.");
		//var wURL = "https://1b04-2001-f40-92d-9568-6c9e-dd86-3a99-78d1.ngrok-free.app/fbd/";
		let callbackURL = WEBHOOKAUTHURL + "twaccesstoken?source=" + webRoot + "media/twitter/handletoken";
		const rootUrl = "https://twitter.com/i/oauth2/authorize";
		const options = {
			redirect_uri: callbackURL,
			client_id: TWITTER_CLIENT_ID,
			state: "state",
			response_type: "code",
			code_challenge: "y_SfRG4BmOES02uqWeIkIgLQAlTBggyf_G7uKT51ku8",
			code_challenge_method: "S256",
			scope: ["offline.access", "users.read", "tweet.write", "tweet.moderate.write", "tweet.read", "follows.read", "follows.write", "dm.read", "dm.write", "like.write"].join(" ")
		};
		const qs = new URLSearchParams(options).toString();
		const oauthUrl = `${rootUrl}?${qs}`;
		windowPopupCenter(oauthUrl, 800, 600);
	}
	return (
		<Button className="btn-outline blue btn-icon-text"
			outline
			color="primary"
			onClick={handleClick}
			title={label}
		>
			<i className={"icon-member"} />
			<span className="label">{label}</span>
		</Button>
	);
};

export default class AccountTwitterForm extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			authorizationStr: I("Authorize"),
			reqInputs: {
				name: {touched:false},
				tags: {touched:false}
			},
			showAuthBtn: true,
		};

	}

	componentDidMount = () => {
		let ds = this.props.input;
		if(typeof ds.id !== 'undefined' && ds.id > 0){
			this.setState({
				authorizationStr: I("Re-authorize"),
			});
		}
	}

	componentWillUnmount = () => {
	}

	handleSelectArea = (val) => {
		this.props.onChangeAdminInput("selectedArea", val, this.props.view);
	}

	handleToggle = (value, field) => {
		this.props.onChangeAdminInput(field, value)
	}

    	getParameterByName = (name, data) => {
		if (!data) data = window.location.href;
		name = name.replace(/[\[\]]/g, "\\$&");
		var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
			results = regex.exec(data);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, " "));
	}

	handleAuth = (e) => {
		//Mujibur: This is not to be confusing in future.
		//Here the previous model dont work, so, what I did is to create a custom eventListener `storage` for javascript localStorage.
		//It catches the localStorage setItem events for processing temporary tokens.
		//while the localStorage setItem is fired the event from socialMedia.go (/media/:channel/handletoken) 
		//and Based on the setItem events, it triggers the 
		//accessToken, which is generated by backend.
		window.addEventListener('storage', function (event) {
			if (event.key === 'customEvent' && event.newValue !== null) {
				var dataReceived = event.newValue;
				console.log('Data received storage in a.html:', dataReceived);
				const msg = I("We are really sorry, Twitter authentication failed! Please contact Cention Support.");
				let code = this.getParameterByName("token", dataReceived)
				if(code !== ""){
					//var wURL = "https://1b04-2001-f40-92d-9568-6c9e-dd86-3a99-78d1.ngrok-free.app/fbd/";
					let callbackURL = WEBHOOKAUTHURL + "twaccesstoken?source=" + webRoot + "media/twitter/handletoken";
					$.post(webRoot + 'twitter/accesstoken', {code: code, redirectURL: callbackURL})
					.done(function( data ) {
						this.setState({id: data.Id, accountName: data.ScreenName, showAuthBtn: false});
						this.props.onChangeAdminInput("id",data.Id);
						this.props.onChangeAdminInput("accountName",data.ScreenName);
					}.bind(this))
					.fail(function(err){
						alert( msg );
					});
				}else{
					alert(msg);
				}
			}
		}.bind(this));
	}

	handleFieldWarning = (e) => {
		let field = e.target.name;
		let reqInputs = this.state.reqInputs;
		reqInputs = update(reqInputs,{
			[field]: {
				touched:{$set: true}
			},
		})
		this.setState({reqInputs:reqInputs})
	}

	handleSubmit = (e) => {
		e.preventDefault();
		let reqInputs = this.state.reqInputs;
		let input = this.props.input;
		let valid = true;

		if (input.selectedArea === "0") {
			return alert(I("Please select area"));
		}

		$.each(reqInputs, (field, v)=>{
			//set required inputs touched
			reqInputs = update(reqInputs,{
				[field]: {
					touched:{$set: true}
				},
			})
		});
		this.setState({reqInputs:reqInputs})

		if(input.name === "" || input.name === undefined) {
			valid = false;
		} else if(input.accountName === "" || input.accountName === undefined) {
			valid = false;
			return alert(I("Ensure account is authorized. Please try again"));
		}
		if(input.hashTags){
			if(input.tags === "" || input.tags === undefined ){
				valid = false
			}
		}
		if(valid){
			this.props.onSave();
			window.location.reload();
		} else {
			alert(FORM_SUBMIT_EMPTY_WARNING);
		}
	}

	render() {
		const {
			areaList
			, input
			, onHandleTextInputChange
			, hidden
			, baseButtons
		} = this.props;

		let selectedArea = parseInt(input.selectedArea, 10);

		const st = this.state;
		const field = st.reqInputs;
		const warnTxt = INPUT_EMPTY_WARNING;

		return (
			<form onSubmit={this.handleSubmit} className="admin-one-form edit-admin-form" hidden={hidden}>
				<CustomInputRow
					id={"area"}
					name={"area"}
					label={I('Area')}
					className={"admin-dropdown-select"}
					mandatory={true}
					helper={helperForArea}
				>
					<UnnestedAreaSingleSelect
						data={areaList}
						invalidSelectedText={TXT_NO_SELECTION}
						onSelect={this.handleSelectArea}
						selected={selectedArea}
						nested={{key: 'Areas'}}
						idFields={{id: "Id", name: "Name"}}
					/>
				</CustomInputRow>
				<TextInputRow
					id={"name"}
					name={"name"}
					className="admin-text-input"
					label={I("Name")}
					value={input.name || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.name && field.name.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
					helperTxt={helperForName}
				/>
				<ToggleSwitch
					id={"enable"}
					name={"enable"}
					data-qa-id="enableTwitter"
					label={I("Activated")}
					checked={input.enable}
					onClick={this.handleToggle}
					listWrap={false}
					className="admin-text-input"
					helperTxt={helperForEnable}
				/>
			<CustomInputRow
				id={"fetchOption"}
				name={"fetchOption"}
				label={I("Fetch options")}
				className={"admin-fetch-option"}
				mandatory={true}
			>
				<SquareCheckbox
					id={"canFetchWall"}
					data-qa-id={"canFetchWall"}
					checked={input.canFetchWall}
					onClick={this.handleToggle}
					label={"Walls"}
					className={"v5-checkbox"}
				/>
				<SquareCheckbox
					id={"canFetchPM"}
					data-qa-id={"canFetchPM"}
					checked={input.canFetchPM}
					onClick={this.handleToggle}
					label={"PMs"}
					className={"v5-checkbox"}
				/>
				<SquareCheckbox
					id={"hashTags"}
					data-qa-id={"hashTags"}
					checked={input.hashTags}
					onClick={this.handleToggle}
					label={"Hashtags(#)"}
					className={"v5-checkbox"}
				/>
			</CustomInputRow>
			<TextInputRow
				id={"tags"}
				name={"tags"}
				className="admin-text-input"
				label={I("Hashtag names")}
				value={input.tags || ""}
				onChange={onHandleTextInputChange}
				mandatory={true}
				hide={!input.hashTags}
				warning={(!input.tags && field.tags.touched) && warnTxt}
				onBlur={this.handleFieldWarning}
				helperTxt={helperForHashtags}
			/>
			<ReadOnlyTextField
				id={"accountName"}
				name={"accountName"}
				className="admin-text-input"
				label={I("Account ID")}
				value={input.accountName || ""}
				hidden={!input.accountName}
			/>
			{st.showAuthBtn &&
			<CustomInputRow
				id={"fbAuthorization"}
				name={"fbAuthorization"}
				label={I("Authorization")}
				className={"admin-auth-button"}
				helper={helperForAuthorization}
				mandatory={true}
			>
				<TWAuthButton onFetchKey={this.handleAuth} label={st.authorizationStr}/>
			</CustomInputRow>}
			{baseButtons}
		</form>
		)
	}
}