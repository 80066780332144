import React, {
	PureComponent
	, useCallback
	, useEffect
	, useState
	, Fragment
} from 'react';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { TABLE_BASE_CLASSNAME } from '../../../reactcomponents/common';
import { tableHeaderStyle } from './account'
import { I, webRoot } from '../../../common/v5/config';
import { Area } from '../../../components/v5/ManualErrand';
import {
	TextInputRow,
	ReadOnlyTextField,
	CustomInputRow
} from '../../../reactcomponents/Form';
import { TXT_NO_SELECTION } from '../../../common/v5/receiptGreetingConstants';
import { IdValueSingleSelect, CustomSearchableDD, UnnestedAreaSingleSelect } from '../../../reactcomponents/Dropdown';
import {
	LabelToggleSwitch as ToggleSwitch
} from '../../../components/v5/ToggleSwitch';
import { Spinner } from '../../../reactcomponents/Spinner'
import { PlainSwitchCheckbox } from '../../../reactcomponents/SwitchCheckbox'
import { INPUT_EMPTY_WARNING, FORM_SUBMIT_EMPTY_WARNING } from "../../../common/v5/constants"
import update from 'immutability-helper';
import { windowPopupCenter } from '../../../common/v5/helpers';

const helperForName = <div>
	<div>{I("This is the name that will be displayed in the list of accounts. This name is only used to help you identify the account.")}</div>
</div>

const helperForArea = <div>
	<div>{I("An area must be chosen because the authenticated Microsoft account needs to be connected with an area.")}</div>
</div>

const helperForEnable = <div>
	<div>{I("If the account is enabled the system will fetch posts and comments from your Microsoft timeline.")}</div>
	<div>{I("If it is not enabled the system will not do anything with the account.")}</div>
</div>

const helperForAuthorization = <div>
	<div>{I("For the system to be able to manage your Microsoft Teams comments, you need to give the Cention application permission to do that.")}</div>
	<br/>
	<div>{I("When you click the button a new window will open, where you will be asked to login to the gmail account. After you have logged in, you will be asked to accept the permissions the Cention Microsoft application requires to manage comments from videos and channels.")}</div>
	<br/>
	<div>{I("Click the Authorize button to start the authorization process.")}</div>
</div>

const helperForChooseAccount = <div>
	<div>{I("You have to choose an account to be able to create a group.")}</div>
</div>

const MicrosoftAuthButton = ({appId, tenantId, secretId, label, onFetchKey}) => {
	const handleClick = () => {
		if(onFetchKey) {
			onFetchKey();
		}
		const msg = I("Microsoft authentication failed! Please contact Cention support.");
		let callbackURL = webRoot + "media/msteams/handletoken";
		$.post( webRoot + 'msteams/oauthurl', {
			redirectUrl: callbackURL,
			appId: appId,
			tenantId: tenantId,
			secretId: secretId
		})
		.then(function( oauthUrl ) {
			if( oauthUrl != "" ){
				windowPopupCenter(oauthUrl ,800, 600);
			}else{
				alert(msg);
			}
		})
		.fail(function(err){
			alert(msg);
		});
	}
	const handleButtonClick = () =>{
		const msg = I("The mandatory fields such as Application Id, Tenant Id and Secret Id should not be empty!");
		alert(msg);
	}
	if ( appId && tenantId && secretId ){
		return (
			<Button className="btn-outline blue btn-icon-text"
				outline
				color="primary"
				onClick={handleClick}
				title={label}
			>
				<i className={"icon-member"} />
				<span className="label">{label}</span>
			</Button>
		);
	} else {
		return <button type="button" className="btn-outline btn-grey" onClick={handleButtonClick}>
			<i className={"icon-member"} />
			<span className="label">{label}</span>
		</button>
	}
};

const MsTeamsChannelChecker = ({enabled, id}) => {

	const[check, setCheck] = useState(enabled);

	const handleCheckBox = () => {
		let action = webRoot + "admin/msteams/group/"+ id +"/deactivate";
		$.ajax({
			url: action
			, type: 'DELETE'
			, success: function(d) {
				setCheck(!check);
			}.bind(this)
			, error: function(xhr) {
				if(xhr.responseJSON) {
					console.debug("deactivate account error:",xhr.responseJSON);
				} else {
					console.debug("deactivate account error:", xhr);
				}
			}
		});
	}

	return (
		<PlainSwitchCheckbox
			active={check}
			onClick={handleCheckBox}
		/>
	)
}

export const GroupListMSTeamsForm = ({list}) => {

	const[channelList, setChannelList] = useState([])
	const[loading, setLoading] = useState(false)
	const[sync,setSync] = useState(false)

	const fetchChannel = () => {
		setLoading(true);
		let ids = list.map((ac, i) =>{return ac.id})
		$.post(webRoot + 'admin/msteams/getgroups',{sync: sync, ids: ids.join(",")})
		.then((rs) =>{
			if(typeof rs.result != 'undefined' && rs.result != null){
				setLoading(false);
				setChannelList(rs.result)
			}
		});
	}

	const renderCheckBox = (cell, row, formatExtraData, rowIndex) => {
		return (
			<MsTeamsChannelChecker
				enabled={cell}
				id={row.id}
			/>
		)
	}

	return (
		<div>
			<div className="label-row">
				<div className="label-block align-items-center">
					<div className="label-admin-text-input">
						<div htmlFor="admin-text-input">{I("Do you want to sync the channels with Microsoft Teams?")}</div>
					</div>
					<PlainSwitchCheckbox
						active={sync}
						onClick={()=> setSync(!sync)}
					/>
					<button type="button" className="btn-blue ms-team-test-btn" title={I("Fetch Group")} data-qa-id="btn-fetchSlackGroup" onClick={fetchChannel}>{I("Fetch Group")}</button>
				</div>
			</div>
			<br/>
			{loading ? <div>Loading...</div>
			:
			<BootstrapTable data={channelList} hover
				bordered={false}
				tableBodyClass={classNames(TABLE_BASE_CLASSNAME, "c3-admin-table-v5")}
				tableHeaderClass={TABLE_BASE_CLASSNAME}
				version='4'
			>
				<TableHeaderColumn isKey dataField='id'
					hidden={true}
					thStyle={tableHeaderStyle}
				>{I("ID")}</TableHeaderColumn>
				<TableHeaderColumn
					dataField='msaccount'
					dataSort={true}
					thStyle={tableHeaderStyle}
					columnTitle
					headerTitle
				>{I("Teams account ID")}</TableHeaderColumn>
				<TableHeaderColumn
					dataField='name'
					dataSort={true}
					thStyle={tableHeaderStyle}
					columnTitle
					headerTitle
				>{I("Name")}</TableHeaderColumn>
				<TableHeaderColumn
					dataField='enabled'
					formatExtraData={list}
					thStyle={tableHeaderStyle}
					dataFormat={renderCheckBox}
					dataSort={true}
					columnTitle
					headerTitle
					columnClassName={"text-center"}
				>{I("Activate for internal chat?")}</TableHeaderColumn>
			</BootstrapTable>
			}
		</div>
	)
}

export const MSTeamsCreateGroupForm = ({
	input
	, list
	, onHandleTextInputChange
	, onChangeAdminInput
	, baseButtons
	, onSave
}) => {

	const [touched, setTouched] = useState({mstId:false,gname:false,nickname:false});
	const warnTxt = INPUT_EMPTY_WARNING;

	const handleFieldWarning = (e) => {
		let field = e.target.name;
		setTouched((previousState => {
			return {...previousState,[field]:true}
		}))
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		let valid = true;
		setTouched({mstId:true,gname:true,nickname:true})

		let reqInputs = ["mstId", "gname", "nickname"]
		reqInputs.forEach((field, idx) => {
			if(input[field] === "" || input[field] === undefined) {
				valid = false;
			}
		});

		if(valid) {
			onSave();
		} else {
			alert(FORM_SUBMIT_EMPTY_WARNING);
		}
	}
	return (
		<div className='form-wrapper clearfix '>
			<form onSubmit={handleSubmit} className="admin-one-form edit-admin-form" hidden={false}>
				<CustomInputRow
					id={"mstId"}
					name={"mstId"}
					label={I('Microsoft Teams account')}
					className={"inputs-wrapper"}
					mandatory={true}
					helper={helperForChooseAccount}
				>
					<IdValueSingleSelect
						data={list.map((v, i) =>{return {id: v.id, value: `${v.accountID} - (${v.name})`}})}
						invalidSelectedText={TXT_NO_SELECTION}
						onSelect={(v)=>{onChangeAdminInput("mstId", v)}}
						selected={input.mstId || ""}
					/>
				</CustomInputRow>
				<TextInputRow
					id={"gname"}
					name={"gname"}
					className="admin-text-input"
					label={I("Name")}
					value={input.gname}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.gname && touched.gname) && warnTxt}
					onBlur={handleFieldWarning}
				/>
				<TextInputRow
					id={"desc"}
					name={"desc"}
					className="admin-text-input"
					label={I("Description")}
					value={input.desc || ""}
					onChange={onHandleTextInputChange}
				/>
				<TextInputRow
					id={"nickname"}
					name={"nickname"}
					className="admin-text-input"
					label={I("Name of person responsible for the group")}
					value={input.nickname || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.nickname && touched.nickname) && warnTxt}
					onBlur={handleFieldWarning}
				/>
				{baseButtons}
			</form>
		</div>
	)
}

export const MSTeamsManageGroupForm = ({
	input
	, list
	, onChangeAdminInput
	, baseButtons
	, onSave
}) => {

	const [groups, setGroups] = useState([]);
	const [groupContacts, setGroupContacts] = useState([]);
	const [channelList, setChannelList] = useState([]);

	const handleBAClick = (aid) => {
		if(aid !== 0){
			onChangeAdminInput("mstId", aid)
			$.get(webRoot + 'admin/msteams/getGroups/' + aid)
			.then((rs)=>{
				console.log("rs =>", rs);
				if(typeof rs !== 'undefined' && rs.groups !== null){
					setGroups(rs.groups);
				}
			})
		}
	}
	const handleGroupClick = (gid) => {
		if(gid !== 0){
			onChangeAdminInput("selectedGroup", gid)
			let accountId =input.mstId;
			let gStrValue = groups.find(el => el.id == gid).value;
			$.get(webRoot + 'admin/msteams/getChannelByGroup/'+ accountId +'/group/' + gid + '/gs/' + gStrValue)
			.then((rs)=>{
				console.log("rs =>", rs);
				if(typeof rs !== 'undefined' && rs !== null){
					setChannelList(rs)
				}
			});
		}
	}
	const handleChannelClick = (cid) => {
		if(cid !== 0){
			onChangeAdminInput("selectedChannel", cid)
			let accountId =input.mstId;
			let groupId = groups.find(el => el.id == input.selectedGroup).value;
			$.get(webRoot + 'admin/msteams/getContactsByGroups/'+ accountId +'/group/'  + groupId)
			.then((rs)=>{
				if(typeof rs !== 'undefined' && rs !== null){
					let opts = [];
					if (rs.seletedContacts != ""){
						let s = rs.seletedContacts.split(',');
						if(s.length > 0){
							s.forEach(element => {
								opts.push({label: `mst-${element}`, value: element});
							});
						}
					};
					setGroupContacts(rs.contacts);
					onChangeAdminInput("groupContacts", rs.contacts);
					onChangeAdminInput("selectedOptions", opts);

					// setGroupContacts([
					// 	{id: 1, name: "test1", email: "test1@email.com", value: "value 1"},
					// 	{id: 2, name: "test2", email: "test2@email.com", value: "value 2"},
					// 	{id: 3, name: "test3", email: "test3@email.com", value: "value 3"},
					// ]);
				}
			});
		}
	}
	const copyText = () => {
		if (!navigator.clipboard) {
			return;
		}
		let text = input.selectedChannel;
		navigator.clipboard.writeText(text)
		.then(() =>{
			console.log('Copying to clipboard was successful!', text);
		});
	}
	const onSelectContacts = (v) =>{
		let sv = v.map(x => x.value);
		let contacts = sv.join()
		onChangeAdminInput("selectedContacts", contacts);
	}
	const handleSubmit = (e) => {
		e.preventDefault();
		let valid = true;

		let reqInputs = ["mstId", "selectedGroup", "selectedChannel"]
		reqInputs.forEach((field, idx) => {
			if(input[field] === "" || input[field] === undefined) {
				valid = false;
			}
		});

		if(valid) {
			onSave();
		} else {
			alert(FORM_SUBMIT_EMPTY_WARNING);
		}
	}
	return (
		<div className='form-wrapper clearfix ms-team-manage'>
			<form onSubmit={handleSubmit} className="admin-one-form edit-admin-form ms-team-manage" hidden={false}>
				<CustomInputRow
					id={"mstId"}
					name={"mstId"}
					label={I('Microsoft Teams account')}
					className={"inputs-wrapper"}
					mandatory={true}
					helper={helperForChooseAccount}
				>
					<IdValueSingleSelect
						data={list.map((v, i) =>{return {id: v.id, value: `${v.accountID} - (${v.name})`}})}
						invalidSelectedText={TXT_NO_SELECTION}
						// onSelect={(v)=>{onChangeAdminInput("mstId", v)}}
						onSelect={handleBAClick}
						selected={input.mstId || ""}
					/>
				</CustomInputRow>
				{/* GROUPS */}
				{groups.length > 0 && <CustomInputRow
					id={"selectedGroup"}
					name={"selectedGroup"}
					label={I('Groups')}
					className={"inputs-wrapper"}
					mandatory={true}
				>
					<IdValueSingleSelect
						data={groups.map((v, i) =>{return {id: v.id, value: `${v.name} - (${v.value})`}})}
						invalidSelectedText={TXT_NO_SELECTION}
						// onSelect={(v)=>{onChangeAdminInput("selectedGroup", v)}}
						onSelect={handleGroupClick}
						selected={input.selectedGroup || ""}
					/>
				</CustomInputRow>}
				{/* CHANNEL LIST */}
				{channelList.length > 0 && <CustomInputRow
					id={"channelList"}
					name={"channelList"}
					label={I('Channels')}
					className={"inputs-wrapper"}
					mandatory={true}
				>
					<IdValueSingleSelect
						data={channelList.map((v, i) =>{return {id: v.id, value: `${v.channelName}`}})}
						invalidSelectedText={TXT_NO_SELECTION}
						onSelect={handleChannelClick}
						selected={input.selectedChannel || ""}
					/>
				</CustomInputRow>}
				{/* SELECTED CHANNEL */}
				{input.selectedChannel !== "" && <ReadOnlyTextField
					id={"selectedChannel"}
					name={"selectedChannel"}
					className="admin-text-input"
					label={I("Selected channel")}
					value={input.selectedChannel || ""}
				><button type="button"
					className="btn-blue ms-team-test-btn"
					title={I("Copy")}
					data-qa-id="btn-fetchSlackGroup"
					onClick={copyText}>{I("Copy")}
				</button>
				</ReadOnlyTextField>
				}
				{/* MEMBERS */}
				{groups.length > 0 && channelList.length > 0 &&
				<CustomInputRow
					id={"members"}
					name={"members"}
					label={I('Members')}
					className={"inputs-wrapper custom-search-dd"}
					mandatory={true}
				>
					<CustomSearchableDD
						options={groupContacts.map((v, i) =>{return {value: v.id, label: `${v.name} (${v.email})`}})}
						onChange={onSelectContacts}
						optSelected={input.selectedContacts || []}
					/>
				</CustomInputRow>
				}
				{baseButtons}
			</form>
		</div>
	)
}


export default class AccountMSTeamsForm extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			id: 0,
			name: "",
			connectedArea: "",
			authenticated: false,
			authLoading: false,
			showAuthBtn: true,
			reqInputs: {
				name: {touched:false},
				appId: {touched:false},
				tenantId: {touched:false},
				secretId: {touched:false}
			},
			accountName: "",
			appId: "",
			tenantId: "",
			secretId: ""
		};

	}

	componentDidMount = () => {
		let ds = this.props.input;
		if(typeof ds.id !== 'undefined' && ds.id > 0){
			this.setState({
				id: ds.id,
				name: ds.name,
				connectedArea: ds.connectedArea,
				authenticated: true,
				accountName: ds.ownerName + " - " + ds.ownerEmail,
				appId: ds.appId,
				tenantId: ds.tenantId,
				secretId: ds.secretId
			});
		}
	}

	componentWillUnmount = () =>{
		$('body').off('microsoft.authorization.accounts');
	}


	handleSelectArea = (val) => {
		this.props.onChangeAdminInput("selectedArea", val, this.props.view);
	}

	handleToggle = (value, field) => {
		this.props.onChangeAdminInput(field, value)
	}

	getParameterByName = (name, url) => {
		if (!url) url = window.location.href;
		name = name.replace(/[\[\]]/g, "\\$&");
		var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, " "));
	}

	handleAuth = (e) => {
		const {
			appId,
			tenantId,
			secretId
		} = this.props.input;
		$("body").on('microsoft.authorization.accounts', function(e, rd) {
			const msg = I("We are really sorry, Microsoft authentication failed! Please contact Cention Support.");
			let code = this.getParameterByName("code", rd);
			let callbackURL = webserverRoot + "media/msteams/handletoken";
			if(code != ""){
				$.post( webRoot + 'msteams/accesstoken', {
					code: code,
					redirectUrl: callbackURL,
					appId: appId,
					tenantId: tenantId,
					secretId: secretId
				})
				.done(function( data ) {
					let accountName = data.displayName + " - " + data.email;
					this.setState({
						id: data.id, //table unique ID
						accountName: accountName,
						authenticated: true
					});
					this.props.onChangeAdminInput("id",data.id);
					this.props.onChangeAdminInput("accountName",accountName);
				}.bind(this))
				.fail(function(err){
					alert( msg );
				});
			}else{
				alert(msg);
			}
		}.bind(this));
	}

	handleFieldWarning = (e) => {
		let field = e.target.name;
		let reqInputs = this.state.reqInputs;
		reqInputs = update(reqInputs,{
			[field]: {
				touched:{$set: true}
			},
		})
		this.setState({reqInputs:reqInputs})
	}

	handleSubmit = (e) => {
		e.preventDefault();
		let reqInputs = this.state.reqInputs;
		let input = this.props.input;
		let valid = true;
		if (input.selectedArea === "0") {
			return alert(I("Please select area"));
		}
		$.each(reqInputs, (field, v)=>{
			reqInputs = update(reqInputs,{
				[field]: {
					touched:{$set: true}
				},
			})
			if(input[field] === "" || input[field] === undefined) {
				valid = false;
			}
		});
		this.setState({reqInputs:reqInputs})
		if(!this.state.authenticated) {
			return alert(I("Account not authorized. Please try again"))
		}
		if(valid){
			this.props.onSave();
		} else {
			alert(FORM_SUBMIT_EMPTY_WARNING);
		}
	}

	render() {
		const {
			areaList
			, activeId
			, input
			, onHandleTextInputChange
			, hidden
			, baseButtons
		} = this.props;

		let selectedArea = parseInt(input.selectedArea, 10);
		const st = this.state;
		const field = st.reqInputs;
		const warnTxt = INPUT_EMPTY_WARNING;

		let authorizationStr = st.authenticated ? I("Re-authorize") : I("Authorize") ;
		
		return (
			<form onSubmit={this.handleSubmit} className="admin-one-form edit-admin-form" hidden={hidden}>
				<CustomInputRow
					id={"area"}
					name={"area"}
					label={I('Area')}
					className={"admin-dropdown-select"}
					mandatory={true}
					helper={helperForArea}
				>
					<UnnestedAreaSingleSelect
						data={areaList}
						invalidSelectedText={TXT_NO_SELECTION}
						onSelect={this.handleSelectArea}
						selected={selectedArea}
						nested={{key: 'Areas'}}
						idFields={{id: "Id", name: "Name"}}
					/>
				</CustomInputRow>
				<TextInputRow
					id={"name"}
					name={"name"}
					className="admin-text-input"
					label={I("Name")}
					value={input.name || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.name && field.name.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
					helperTxt={helperForName}
				/>
				<ToggleSwitch
					id={"enable"}
					name={"enable"}
					data-qa-id="enableMSTeam"
					label={I("Activated")}
					checked={input.enable}
					onClick={this.handleToggle}
					listWrap={false}
					className="admin-text-input"
					helperTxt={helperForEnable}
				/>
				<TextInputRow
					id={"appId"}
					name={"appId"}
					className="admin-text-input"
					label={I("Application ID")}
					value={input.appId || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.appId && field.appId.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
				/>
				<TextInputRow
					id={"tenantId"}
					name={"tenantId"}
					className="admin-text-input"
					label={I("Tenant ID")}
					value={input.tenantId || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.tenantId && field.tenantId.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
				/>
				<TextInputRow
					id={"secretId"}
					name={"secretId"}
					className="admin-text-input"
					label={I("Secret ID")}
					value={input.secretId || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.secretId && field.secretId.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
				/>
				{st.pageLoading ? <div className='center p-3'><Spinner/></div>
				: 
				<ReadOnlyTextField
					id={"ownerID"}
					name={"ownerID"}
					className="admin-text-input"
					label={I("Microsoft Teams account")}
					value={`@${st.accountName}` || ""}
					hidden={!st.accountName}
				/>}
				{st.showAuthBtn &&
				<CustomInputRow
					id={"authorization"}
					name={"authorization"}
					label={I("Authorization")}
					className={"admin-auth-button"}
					helper={helperForAuthorization}
					mandatory={true}
					hide={activeId === 0 && st.authenticated}
				>
				<MicrosoftAuthButton
					appId={input.appId}
					tenantId={input.tenantId}
					secretId ={input.secretId}
					onFetchKey={this.handleAuth}
					label={authorizationStr}/>
				</CustomInputRow>}
				{st.authLoading 
				? <div className='center p-3'><Spinner/></div>
				: <ReadOnlyTextField
					id={"authentication"}
					name={"authentication"}
					className="admin-text-input"
					label={I("Microsoft account")}
					value={I("@Authenticated")}
					hidden={!st.authenticated}
					mandatory={true}
				/>}
				{baseButtons}
			</form>
		)
	}
}