import React, {
	Fragment
	, PureComponent
	, memo
	, useCallback
	, useEffect
	, useState
	, useMemo
} from 'react';
import classNames from 'classnames';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { TABLE_BASE_CLASSNAME } from '../../../reactcomponents/common';
import { tableHeaderStyle } from './account'
import { I, webRoot } from '../../../common/v5/config';
import { Area } from '../../../components/v5/ManualErrand';
import {
	TextInputRow,
	CustomInputRow
} from '../../../reactcomponents/Form';
import {
	LabelToggleSwitch as ToggleSwitch
} from '../../../components/v5/ToggleSwitch';
import { PlainSwitchCheckbox } from '../../../reactcomponents/SwitchCheckbox'
import { INPUT_EMPTY_WARNING, FORM_SUBMIT_EMPTY_WARNING } from '../../../common/v5/constants';
import update from 'immutability-helper';
import { UnnestedAreaSingleSelect } from '../../../reactcomponents/Dropdown';
import { TXT_NO_SELECTION } from '../../../common/v5/receiptGreetingConstants';

const helperForName = <div>
	<div>{I("This is the name that will be displayed in the list of accounts. This name is only used to help you identify the account.")}</div>
</div>

const helperForArea = <div>
	<div>{I("An area must be chosen because the authenticated Slack account needs to be connected with an area.")}</div>
</div>

const helperForEnable = <div>
	<div>{I("If the account is enabled the system will fetch posts and comments from your Slack account")}</div>
	<div>{I("If it is not enabled the system will not do anything with the account.")}</div>
</div>

const SlackChannelChecker = ({enabled, id}) => {

	const[check, setCheck] = useState(enabled);

	const handleCheckBox = () => {
		let action = webRoot + "admin/slackaccount/channel/"+ id +"/deactivate";
		$.ajax({
			url: action
			, type: 'DELETE'
			, success: function(d) {
				setCheck(!check);
			}.bind(this)
			, error: function(xhr) {
				if(xhr.responseJSON) {
					console.debug("deactivate account error:",xhr.responseJSON);
				} else {
					console.debug("deactivate account error:", xhr);
				}
			}
		});
	}

	return (
		<PlainSwitchCheckbox
			active={check}
			onClick={handleCheckBox}
		/>
	)
}


export const GroupListSlackForm = ({list}) => {

	const[channelList, setChannelList] = useState([])
	const[loading, setLoading] = useState(false)
	const[sync,setSync] = useState(false)

	const fetchChannel = () => {
		setLoading(true);
		let ids = list.map((ac, i) =>{return ac.id})
		$.post(webRoot + 'admin/slack/account/getAllChannel',{sync: sync, ids: ids.join(",")})
		.then((rs) =>{
			if(typeof rs.result != 'undefined' && rs.result != null){
				setLoading(false);
				setChannelList(rs.result)
			}
		});
	}

	const renderCheckBox = (cell, row, formatExtraData, rowIndex) => {
		return (
			<SlackChannelChecker
				enabled={cell}
				id={row.id}
			/>
		)
	}

	return (
		<div>
			<div className="label-row">
				<div className="label-block align-items-center">
					<div className="label-admin-text-input">
						<div htmlFor="admin-text-input">{I("Do you want to sync the channels with Slack?")}</div>
					</div>
					<PlainSwitchCheckbox
						active={sync}
						onClick={()=> setSync(!sync)}
					/>
					<button type="button" className="btn-blue viber-test-btn" title={I("Fetch Group")} data-qa-id="btn-fetchSlackGroup" onClick={fetchChannel}>{I("Fetch Group")}</button>
				</div>
			</div>
			<br/>
			{loading ? <div>Loading...</div>
			:
			<BootstrapTable data={channelList} hover
				bordered={false}
				tableBodyClass={classNames(TABLE_BASE_CLASSNAME, "c3-admin-table-v5")}
				tableHeaderClass={TABLE_BASE_CLASSNAME}
				version='4'
			>
				<TableHeaderColumn isKey dataField='id'
					hidden={true}
					thStyle={tableHeaderStyle}
				>{I("ID")}</TableHeaderColumn>
				<TableHeaderColumn
					dataField='account'
					dataSort={true}
					thStyle={tableHeaderStyle}
					columnTitle
					headerTitle
				>{I("Account name")}</TableHeaderColumn>
				<TableHeaderColumn
					dataField='name'
					dataSort={true}
					thStyle={tableHeaderStyle}
					columnTitle
					headerTitle
				>{I("Name")}</TableHeaderColumn>
				<TableHeaderColumn
					dataField='enabled'
					formatExtraData={list}
					thStyle={tableHeaderStyle}
					dataFormat={renderCheckBox}
					dataSort={true}
					columnTitle
					headerTitle
					columnClassName={"text-center"}
				>{I("Activate for internal chat?")}</TableHeaderColumn>
			</BootstrapTable>
			}
		</div>
	)
}

export default class AccountSlackForm extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			myState: "default",
			testingToken: false,
			reqInputs: {
				name: {touched:false},
				appId: {touched:false},
				appToken: {touched:false}
			}
		};

	}

	componentDidMount = () => {
		if(!this.props.wfId) {
			this.setState({myState: "user"});
		}
		let ds = this.props.input;
		if(typeof ds.id !== 'undefined' && ds.id > 0){
			this.setState({
				id: ds.id,
				appId: ds.appId,
                name: ds.name,
				appToken: ds.appToken,
				webhookUrl: ds.webhookUrl,
				connectedArea: ds.connectedArea,
				oldAreaId: ds.connectedArea
			});
		}
	}

	handleSelectArea = (val) => {
		this.props.onChangeAdminInput("selectedArea", val, this.props.view);
	}
	

	handleToggle = (value, field) => {
		this.props.onChangeAdminInput(field, value)
	}

	handleFieldWarning = (e) => {
		let field = e.target.name;
		let reqInputs = this.state.reqInputs;
		reqInputs = update(reqInputs,{
			[field]: {
				touched:{$set: true}
			},
		})
		this.setState({reqInputs:reqInputs})
	}

	handleSubmitFrom =(e)=> {
		e.preventDefault();
		let reqInputs = this.state.reqInputs;
		let input = this.props.input;
		let valid = true;
		//check area validity
		if (input.selectedArea === "0" || input.selectedArea.length < 1) {
			return alert(I("Please select area"));
		}
		$.each(reqInputs, (field, v)=>{
			//set required inputs touched
			reqInputs = update(reqInputs,{
				[field]: {
					touched:{$set: true}
				},
			})
			//check if empty
			if(input[field] === "" || input[field] === undefined) {
				valid = false;
			}
		});
		this.setState({reqInputs:reqInputs})
		if(valid){
			this.props.onSave();
		} else {
			alert(FORM_SUBMIT_EMPTY_WARNING);
		}
	}

	render() {
		const {
			areaList
			, input
			, onHandleTextInputChange
			, hidden
			, isNew
			, baseButtons
		} = this.props;

		let selectedArea = parseInt(input.selectedArea, 10);
		const st = this.state;
		const field = this.state.reqInputs;
		const warnTxt = INPUT_EMPTY_WARNING;

		return (
			<form onSubmit={this.handleSubmitFrom} className="admin-one-form edit-admin-form" hidden={hidden}>
				<CustomInputRow
					id={"area"}
					name={"area"}
					label={I('Area')}
					className={"admin-dropdown-select"}
					mandatory={true}
					helper={helperForArea}
				>
					<UnnestedAreaSingleSelect
						data={areaList}
						invalidSelectedText={TXT_NO_SELECTION}
						onSelect={this.handleSelectArea}
						selected={selectedArea}
						nested={{key: 'Areas'}}
						idFields={{id: "Id", name: "Name"}}
					/>
				</CustomInputRow>
				<TextInputRow
					id={"name"}
					name={"name"}
					className="admin-text-input"
					label={I("Name")}
					value={input.name || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.name && field.name.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
					helperTxt={helperForName}
				/>
				<ToggleSwitch
					id={"enabled"}
					name={"enabled"}
					data-qa-id="enableLine"
					label={I("Activated")}
					checked={input.enabled}
					onClick={this.handleToggle}
					listWrap={false}
					className="admin-text-input"
					helperTxt={helperForEnable}
				/>
				<TextInputRow
					id={"appId"}
					name={"appId"}
					className="admin-text-input"
					label={I("Application ID")}
					value={input.appId || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.appId && field.appId.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
				/>
				<TextInputRow
					id={"appToken"}
					name={"appToken"}
					className="admin-text-input"
					label={I("Application Token")}
					value={input.appToken || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.appToken && field.appToken.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
				/>
				<TextInputRow
					id={"webhookUrl"}
					name={"webhookUrl"}
					className="admin-text-input"
					label={I("Webhook URL")}
					value={input.webhookUrl || ""}
					onChange={onHandleTextInputChange}
				/>
				{baseButtons}
			</form>
		)
	}
}