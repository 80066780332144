import React, { PureComponent } from 'react';
import { Button } from 'reactstrap';
import { I, webRoot } from '../../../common/v5/config';
import { Area } from '../../../components/v5/ManualErrand';
import {
	TextInputRow,
	ReadOnlyTextField,
	CustomInputRow
} from '../../../reactcomponents/Form';
import {
	LabelToggleSwitch as ToggleSwitch
} from '../../../components/v5/ToggleSwitch';
import { Spinner } from '../../../reactcomponents/Spinner'
import { INPUT_EMPTY_WARNING, FORM_SUBMIT_EMPTY_WARNING } from "../../../common/v5/constants"
import update from 'immutability-helper';
import FileUploader from '../../../components/v5/FileUploader';
import { UnnestedAreaSingleSelect } from '../../../reactcomponents/Dropdown';
import { TXT_NO_SELECTION } from '../../../common/v5/receiptGreetingConstants';

const helperForName = <div>
	<div>{I("This is the name that will be displayed in the list of accounts. This name is only used to help you identify the account.")}</div>
</div>

const helperForArea = <div>
	<div>{I("An area must be chosen because the authenticated Google Chat account needs to be connected with an area.")}</div>
</div>

const helperForEnable = <div>
	<div>{I("If the account is enabled the system will fetch posts and comments from your Google Chat timeline.")}</div>
	<div>{I("If it is not enabled the system will not do anything with the account.")}</div>
</div>

const helperForFileUpload = <div>
	<div>{I("The file should contain the company's Google Service account, which you need to download from Google Cloud <https://console.cloud.google.com>")}</div>
</div>

const helperForAuthorization = <div>
	<div>{I("For the system to be able to manage your Google Chat comments, you need to give the Cention application permission to do that.")}</div>
	<br/>
	<div>{I("When you click the button a new window will open where you will be asked to login to gmail account. After you have logged in you will be asked to accept the permissions the Cention Google Chat application requires to manage comments from the videos and channels")}</div>
	<br/>
	<div>{I("Click the Authorize button to start the authorization process.")}</div>
</div>



const GoogleChatAuthButton = ({label, onFetchKey}) => {
	const handleClick = () => {
		if(onFetchKey) {
			onFetchKey();
		}
	}
	return (
		<Button className="btn-outline blue btn-icon-text"
			outline
			color="primary"
			onClick={handleClick}
			title={label}
		>
			<i className={"icon-member"} />
			<span className="label">{label}</span>
		</Button>
	);
};

export default class AccountGoogleChatForm extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			id: 0,
			name: "",
			connectedArea: "",
			showAuthorise: true,
			path: "",
			isNewFile: false,
			fileUploadForm: "",
			authenticated: false,
			authLoading: false,
			showAuthBtn: true,
			reqInputs: {
				name: {touched:false},
			}
		};

	}

	componentDidMount = () => {
		let ds = this.props.input;
		if(typeof ds.id !== 'undefined' && ds.id > 0){
			this.setState({
				id: ds.id,
				name: ds.name,
				connectedArea: ds.connectedArea,
				path: ds.path,
				authenticated: true,
			});
		}
	}

	componentWillUnmount = () =>{
		$('body').off('googlechat.authorization.accounts');
	}

	handleSelectArea = (val) => {
		this.props.onChangeAdminInput("selectedArea", val, this.props.view);
	}
	

	handleToggle = (value, field) => {
		this.props.onChangeAdminInput(field, value)
	}

	handleUploadFile = (datas, uploadTo) => {
		let formData = datas[0]; //only one data passed in this case, so take the 1st index
		let xhr = [];

		xhr[i] = new XMLHttpRequest();
		xhr[i].open("POST", webRoot + uploadTo);
		xhr[i].onreadystatechange = function(event){
			var msg, ro;
			if (event.target.readyState != 4) return;
			if (event.target.status != 200) {
				try {
					ro = JSON.parse(event.target.responseText);
					if (ro.status) {
						msg = ro.status;
					}
					if (ro.error) {
						msg += ' ' + ro.error;
					}
					msg = I("Error: {ERROR}").replace('{ERROR}', msg);
				} catch(e) {
					msg = I("We're sorry, an error occurred while uploading the file");
				}
				alert(msg);
				return;
			}
			ro = JSON.parse(event.target.responseText);
			if(ro !== "") {
				this.setState({fileUploadForm: I("File uploaded."), path: ro, isNewFile: true, authenticated: false})
			} else {
				this.setState({fileUploadForm: I("File uploading failed! Please contact support.")})
			}
		}.bind(this);
		xhr[i].send(formData);
	}

	handleAuth = (e) => {
		const { input, onChangeAdminInput } = this.props;

		if (input.selectedArea === "0") {
			return alert(I("Please select area"));
		}
		if (input.name == "" || input.name === undefined) {
			let reqInputs = this.state.reqInputs;
			reqInputs = update(reqInputs,{
				name: {
					touched:{$set: true}
				}
			})
			this.setState({reqInputs:reqInputs})
			return alert(I("Please select name"));	
		}
		this.setState({authLoading: true});
		const msg = I("We are really sorry, the Google Chat authentication failed. Please contact Cention Support.");
		$.post(webRoot + 'googlechat/accesstoken', {areaId: this.state.connectedArea, name: this.state.name, filename: this.state.path, accId: this.state.id, isNewFile: this.state.isNewFile})
		.done(function( data ) {
				this.setState({
					id: data.id,
					accountName: data.accName,
					showAuthorise: false,
					path: data.filepath,
					authLoading:false,
					authenticated: true
				});
				onChangeAdminInput("id",data.id);
				onChangeAdminInput("path",data.filepath);
		}.bind(this))
		.fail(function(err){
			this.setState({authLoading: false, authenticated:false});
			setTimeout(() => alert( msg ),100);
		}.bind(this));
	}

	handleFieldWarning = (e) => {
		let field = e.target.name;
		let reqInputs = this.state.reqInputs;
		reqInputs = update(reqInputs,{
			[field]: {
				touched:{$set: true}
			},
		})
		this.setState({reqInputs:reqInputs})
	}

	handleSubmit = (e) => {
		e.preventDefault();
		let reqInputs = this.state.reqInputs;
		let input = this.props.input;
		let valid = true;
		if (input.selectedArea === "0") {
			return alert(I("Please select area"));
		}
		$.each(reqInputs, (field, v)=>{
			//set required inputs touched
			reqInputs = update(reqInputs,{
				[field]: {
					touched:{$set: true}
				},
			})
			//check if empty
			if(input[field] === "" || input[field] === undefined) {
				valid = false;
			}
		});
		this.setState({reqInputs:reqInputs})
		if(input.path === "" || input.path === undefined || !this.state.authenticated) {
			return alert(I("File is not authorized. Please check again."))
		}
		if(valid){
			this.props.onSave();
		} else {
			alert(FORM_SUBMIT_EMPTY_WARNING);
		}
	}

	render() {
		const {
			areaList
			, activeId
			, input
			, onHandleTextInputChange
			, hidden
			, baseButtons
		} = this.props;

		let selectedArea = parseInt(input.selectedArea, 10);
		const st = this.state;
		const field = st.reqInputs;
		const warnTxt = INPUT_EMPTY_WARNING;

		let authorizationStr = st.authenticated ? I("Re-check service key") : I("Check service key") ;
		
		return (
			<form onSubmit={this.handleSubmit} className="admin-one-form edit-admin-form" hidden={hidden}>
				<CustomInputRow
					id={"area"}
					name={"area"}
					label={I('Area')}
					className={"admin-dropdown-select"}
					mandatory={true}
					helper={helperForArea}
				>
					<UnnestedAreaSingleSelect
						data={areaList}
						invalidSelectedText={TXT_NO_SELECTION}
						onSelect={this.handleSelectArea}
						selected={selectedArea}
						nested={{key: 'Areas'}}
						idFields={{id: "Id", name: "Name"}}
					/>
				</CustomInputRow>
				<TextInputRow
					id={"name"}
					name={"name"}
					className="admin-text-input"
					label={I("Name")}
					value={input.name || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.name && field.name.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
					helperTxt={helperForName}
				/>
				<ToggleSwitch
					id={"enable"}
					name={"enable"}
					data-qa-id="enableGoogleChat"
					label={I("Activated")}
					checked={input.enable}
					onClick={this.handleToggle}
					listWrap={false}
					className="admin-text-input"
					helperTxt={helperForEnable}
				/>
				<CustomInputRow
					id={"file"}
					name={"file"}
					label={I("Upload service account file")}
					helper={helperForFileUpload}
					mandatory={true}
				>
					<FileUploader
						cssClass={"btn btn-file upload-btn"}
						uploadAttachment={this.handleUploadFile}
						uploadTo="googlechat/serviceAccount/upload"
						attachSingle={true}
						title={I("Browse")}
					/>
				</CustomInputRow>
				{st.showAuthBtn &&
				<CustomInputRow
					id={"authorization"}
					name={"authorization"}
					label={I("Authorization")}
					className={"admin-auth-button"}
					helper={helperForAuthorization}
					mandatory={true}
					hide={activeId === 0 && st.authenticated}
				>
					<GoogleChatAuthButton onFetchKey={this.handleAuth} label={authorizationStr}/>
				</CustomInputRow>}
				{st.authLoading 
				? <div className='center p-3'><Spinner/></div>
				: <ReadOnlyTextField
					id={"authentication"}
					name={"authentication"}
					className="admin-text-input"
					label={I("Google Chat account")}
					value={I("@Authenticated")}
					hidden={!st.authenticated}
					mandatory={true}
				/>}
				{baseButtons}
			</form>
		)
	}
}