import React, {
	Fragment
	, PureComponent
	, memo
	, useCallback
	, useState
	, useEffect
	, useMemo
} from 'react';
import classNames from 'classnames';
import { Button } from 'reactstrap';
import { AUTHSERVERURL } from '../../../common/v5/constants';
import { I, webRoot } from '../../../common/v5/config';
import {
	TextInputRow,
	CustomInputRow
} from '../../../reactcomponents/Form';
import { TABLE_BASE_CLASSNAME } from '../../../reactcomponents/common';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { tableHeaderStyle } from './account'
import { TXT_NO_SELECTION } from '../../../common/v5/receiptGreetingConstants';
import { IdValueSingleSelect, UnnestedAreaSingleSelect } from '../../../reactcomponents/Dropdown';
import {
	LabelToggleSwitch as ToggleSwitch
} from '../../../components/v5/ToggleSwitch';
import { PlainSwitchCheckbox } from '../../../reactcomponents/SwitchCheckbox'
import { INPUT_EMPTY_WARNING, FORM_SUBMIT_EMPTY_WARNING, OPT_WHATSAPP_CLOUD_PREMISE } from '../../../common/v5/constants';
import update from 'immutability-helper';
import { windowPopupCenter } from '../../../common/v5/helpers';

const helperForArea = <div>
	<div>{I("An area must be chosen because the authenticated WhatsApp account needs to be connected with an area.")}</div>
</div>

const helperForServerUrl = <div>
	<div>{I("Default whatsapp server url: https://whatsapp.cention.com")}</div>
</div>
const helperForWabaId = <div>
	<div>{I("The WhatsApp Cloud API WABA ID. This is available at Facebook business account administration.")}<br/>
	{I("Go to Business Manager > Business Settings > Accounts > WhatsApp Business Accounts to find the ID of a WhatsApp Business Account. Select the account you want to use. A panel with account information, including the ID will appear.")}
	</div>
</div>
const helperForTime = <div>
	<div>{I("According to Whatsapp rules you can't send a message to a customer when 24 h passed from when the last message was sent by the customer. This setting decides how many hours after the customer's last message to notify the agent.")}</div>
</div>

const helperForChooseAccount = <div>
	<div>{I("Choose the account you want to create a group with.")}</div>
</div>


export const GroupListWAForm = ({list}) => {

	const[channelList, setChannelList] = useState([])
	const[loading, setLoading] = useState(false)
	const[sync,setSync] = useState(false)

	const fetchChannel = () => {
		setLoading(true);
		let ids = list.map((ac, i) =>{return ac.id})
		$.post(webRoot + 'admin/whatsapp/getgroups',{sync: sync, ids: ids.join(",")})
		.then((rs) =>{
			if(typeof rs.result != 'undefined' && rs.result != null){
				setLoading(false);
				setChannelList(rs.result)
			}
		});
	}

	return (
		<div>
			<div className="label-row">
				<div className="label-block align-items-center">
					<div className="label-admin-text-input">
						<div htmlFor="admin-text-input">{I("Do you want to sync the channels with Whatsapp?")}</div>
					</div>
					<PlainSwitchCheckbox
						active={sync}
						onClick={()=> setSync(!sync)}
					/>
					<button type="button" className="btn-blue ms-team-test-btn" title={I("Fetch Group")} data-qa-id="btn-fetchSlackGroup" onClick={fetchChannel}>{I("Fetch Group")}</button>
				</div>
			</div>
			<br/>
			{loading ? <div>Loading...</div>
			:
			<BootstrapTable data={channelList} hover
				bordered={false}
				tableBodyClass={classNames(TABLE_BASE_CLASSNAME, "c3-admin-table-v5")}
				tableHeaderClass={TABLE_BASE_CLASSNAME}
				version='4'
			>
				<TableHeaderColumn isKey dataField='id'
					hidden={true}
					thStyle={tableHeaderStyle}
				>{I("Business account")}</TableHeaderColumn>
				<TableHeaderColumn
					dataField='msaccount'
					dataSort={true}
					thStyle={tableHeaderStyle}
					columnTitle
					headerTitle
				>{I("Name")}</TableHeaderColumn>
				<TableHeaderColumn
					dataField='name'
					dataSort={true}
					thStyle={tableHeaderStyle}
					columnTitle
					headerTitle
				>{I("Id")}</TableHeaderColumn>
			</BootstrapTable>
			}
		</div>
	)
}

export const WACreateGroupForm = ({
	input
	, list
	, onHandleTextInputChange
	, onChangeAdminInput
	, baseButtons
	, onSave
}) => {

	const [touched, setTouched] = useState({waId:false,gname:false,nickname:false});
	const warnTxt = INPUT_EMPTY_WARNING;

	const handleFieldWarning = (e) => {
		let field = e.target.name;
		setTouched((previousState => {
			return {...previousState,[field]:true}
		}))
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		let valid = true;
		setTouched({waId:true,gname:true})

		let reqInputs = ["waId", "gname"]
		reqInputs.forEach((field, idx) => {
			if(input[field] === "" || input[field] === undefined) {
				valid = false;
			}
		});

		if(valid) {
			onSave();
		} else {
			alert(FORM_SUBMIT_EMPTY_WARNING);
		}
	}
	return (
		<div className='form-wrapper clearfix '>
			<form onSubmit={handleSubmit} className="admin-one-form edit-admin-form" hidden={false}>
				<CustomInputRow
					id={"waId"}
					name={"waId"}
					label={I('Whatsapp business account')}
					className={"inputs-wrapper"}
					mandatory={true}
					helper={helperForChooseAccount}
				>
					<IdValueSingleSelect
						data={list.map((v, i) =>{return {id: v.id, value: `${v.accountID} - (${v.name})`}})}
						invalidSelectedText={TXT_NO_SELECTION}
						onSelect={(v)=>{onChangeAdminInput("waId", v)}}
						selected={input.waId || ""}
					/>
				</CustomInputRow>
				<TextInputRow
					id={"gname"}
					name={"gname"}
					className="admin-text-input"
					label={I("Name")}
					value={input.gname}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.gname && touched.gname) && warnTxt}
					onBlur={handleFieldWarning}
				/>
				{baseButtons}
			</form>
		</div>
	)
}

export const WAManageGroupForm = ({
	input
	, list
	, onChangeAdminInput
	, baseButtons
	, onSave
}) => {

	const [groups, setGroups] = useState([]);
	const [contacts, setContacts] = useState([]);
	const [selectedOptions, setSelectedOptions] = useState([]);
	// const [groupContacts, setGroupContacts] = useState([]);
	const [channelList, setChannelList] = useState([]);

	const handleBAClick = (aid) => {
		if(aid !== 0){
			onChangeAdminInput("waId", aid)
			$.get(webRoot + 'admin/whatsapp/getGroups/' + aid)
			.then((rs)=>{
				console.log("rs =>", rs);
				if(typeof rs !== 'undefined' && rs.groups !== null){
					setGroups(rs.groups);
				}
			})
		}
	}
	const handleGroupClick = (gid) => {
		if(gid !== 0){
			onChangeAdminInput("selectedGroup", gid)
			let accountId =input.waId;
			let wa = list.find(({ id }) => id === parseInt(accountId, 10));
			$.get(webRoot + 'admin/whatsapp/getContactByGroup/' + wa.accountID + "/group/" + gid)
			.then((rs)=>{
				// console.log("rs =>", rs);
				if(typeof rs !== 'undefined' && rs.contacts !== null){
					let opts = [];
					if (rs.groupContacts != ""){
						let s = rs.groupContacts.split(',');
						if(s.length > 0){
							s.forEach(element => {
								opts.push({label: `wa-${element}`, value: element});
							});
						}
					}
					setContacts(rs.contacts);
					onChangeAdminInput("selectedContacts",rs.groupContacts)
					setSelectedOptions(opts);
				}
			});
		}
	}

	const onSelectContacts = (v) =>{
		let sv = v.map(x => x.value);
		let contacts = sv.join()
		onChangeAdminInput("selectedContacts", contacts);
	}
	const handleSubmit = (e) => {
		e.preventDefault();
		let valid = true;

		let reqInputs = ["waId", "selectedGroup", "selectedContacts"]
		reqInputs.forEach((field, idx) => {
			if(input[field] === "" || input[field] === undefined) {
				valid = false;
			}
		});

		if(valid) {
			onSave();
		} else {
			alert(FORM_SUBMIT_EMPTY_WARNING);
		}
	}
	return (
		<div className='form-wrapper clearfix ms-team-manage'>
			<form onSubmit={handleSubmit} className="admin-one-form edit-admin-form ms-team-manage" hidden={false}>
				<CustomInputRow
					id={"waId"}
					name={"waId"}
					label={I('Whatsapp business account')}
					className={"inputs-wrapper"}
					mandatory={true}
					helper={helperForChooseAccount}
				>
					<IdValueSingleSelect
						data={list.map((v, i) =>{return {id: v.id, value: `${v.accountID} - (${v.name})`}})}
						invalidSelectedText={TXT_NO_SELECTION}
						onSelect={handleBAClick}
						selected={input.waId || ""}
					/>
				</CustomInputRow>
				{/* GROUPS */}
				{groups.length > 0 && <CustomInputRow
					id={"selectedGroup"}
					name={"selectedGroup"}
					label={I('Groups')}
					className={"inputs-wrapper"}
					mandatory={true}
				>
					<IdValueSingleSelect
						data={groups.map((v, i) =>{return {id: v.id, value: `${v.name} - (${v.value})`}})}
						invalidSelectedText={TXT_NO_SELECTION}
						// onSelect={(v)=>{onChangeAdminInput("selectedGroup", v)}}
						onSelect={handleGroupClick}
						selected={input.selectedGroup || ""}
					/>
				</CustomInputRow>}
				{/* contact */}
				<CustomInputRow
					id={"members"}
					name={"members"}
					label={I('Members')}
					className={"inputs-wrapper custom-search-dd"}
					mandatory={true}
				>
					{groups.length > 0 && contacts.length > 0 ?
					<CustomSearchableDD
						options={groupContacts.map((v, i) =>{return {value: v.id, label: `${v.name} (${v.email})`}})}
						onChange={onSelectContacts}
						optSelected={input.selectedContacts || []}
					/>
					:<div>{I("{not found}")}</div>
					}
				</CustomInputRow>
				{baseButtons}
			</form>
		</div>
	)
}

export default class AccountWhatsAppForm extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			myState: "default",
			testingToken: false,
			decryptedPageId: "",
			reqInputs: {
				name: {touched:false},
				phone: {touched:false},
				url: {touched:false},
				username: {touched:false},
				password: {touched:false},
				token: {touched:false},
				expireAt: {touched:false}
			},
			reqInputsAPI: {
				name: {touched:false},
				phone: {touched:false},
				username: {touched:false},
				token: {touched:false},
				waBusinessId: {touched:false},
			},
			authorizationStr: I("Authorize"),
			showAuthButton: true,
			isEmbeddedSignup: false, 
		};

	}

 async decryptData (encryptedBase64, keyString) {
		// Convert Base64 URL to standard Base64
		const standardBase64 = encryptedBase64.replace(/-/g, '+').replace(/_/g, '/');
	
		try {
			// Convert the base64 string to an ArrayBuffer
			const encryptedData = Uint8Array.from(atob(standardBase64), c => c.charCodeAt(0));
			
			// Assuming the IV is 12 bytes long and is prefixed to the encrypted data
			const iv = encryptedData.slice(0, 12);
			const dataToDecrypt = encryptedData.slice(12);
		
			// Convert the key string to an ArrayBuffer
			const keyBuffer = new TextEncoder().encode(keyString);
		
			// Import the key
			const cryptoKey = await window.crypto.subtle.importKey(
				"raw",
				keyBuffer,
				{ name: "AES-GCM" },
				false, // extractable
				["decrypt"] // possible actions
			);
		
			// Decrypt the data
			const decrypted = await window.crypto.subtle.decrypt(
				{ name: "AES-GCM", iv: iv },
				cryptoKey,
				dataToDecrypt
			);
		
			// Convert the decrypted data to a string
			return new TextDecoder().decode(decrypted);
		} catch (error) {
			console.error("Decryption failed", error);
			return null;
		}
	}
	  
	componentDidMount = () => {
		const encryptedPageId =this.props.pageId
		const decryptionKey = "12345678901234567890123456789012"; 

		this.decryptData(encryptedPageId, decryptionKey)
		.then(decryptedPageId => {
			this.setState({ decryptedPageId }, () => {
				this.setState({ decryptedPageId });
				this.loadFacebookSDK(decryptedPageId);
			});			
		})
		.catch(error => {
		  console.error("Error during decryption:", error);
		});
		const p = this.props;
		if(p.activeId > 0){
			this.setState({authorizationStr: I("Re-authorize")});
		}
		this.props.onChangeAdminInput('api', true, OPT_WHATSAPP_CLOUD_PREMISE);
	   // Add sessionInfoListener for Embedded Signup Flow
	   const sessionInfoListener = (event) => {
		console.log('Non-JSON response received:');

        if (event.origin !== "https://www.facebook.com") return;
        try {
            const data = JSON.parse(event.data);
            if (data.type === 'WA_EMBEDDED_SIGNUP') {
                if (data.event === 'FINISH') {
                    const {  waba_id } = data.data;
					this.props.onChangeAdminInput("waBusinessId", waba_id);

					this.setState(prevState => ({
						input: {
						  ...prevState.input,
						  waBusinessId: waba_id, 
						}
					  }));
                    // Handle successful signup here, e.g., save phone number ID and WABA ID to your state or perform an action
                } else if (data.event === 'CANCEL') {
                    const { current_step } = data.data;
                    console.log('User canceled at step:', current_step);
                    // Handle cancellation here, e.g., notify the user or log the step at which they canceled
                }
            }
        } catch (error) {
            console.log('Non-JSON response received:', event.data);

			

        }
    };

    window.addEventListener('message', sessionInfoListener);

    // Clean up the event listener when the component unmounts
    this.cleanup = () => {
        window.removeEventListener('message', sessionInfoListener);
    };
	}

	handleSelectArea = (val) => {
		this.props.onChangeAdminInput("selectedArea", val, this.props.view);
	}
	

	handleToggle = (value, field) => {
		this.props.onChangeAdminInput(field, value)
	}

	handleFieldWarning = (e) => {
		let field = e.target.name;
		let reqInputs = this.state.reqInputs;
		reqInputs = update(reqInputs,{
			[field]: {
				touched:{$set: true}
			},
		})
		this.setState({reqInputs:reqInputs})
	}

	handleTest = () =>{
		const { input } = this.props;
		let errToken = I("* You can not leave the token field empty.");
		let errServerEmpty = I("* You can not leave the url field empty.");
		let hasToken = typeof input.token !== 'undefined' && input.token !== "";
		let hasServerUrl = typeof input.url !== 'undefined' && input.url !== "";
		
		if(!hasToken) {
			return alert(errToken);
		}

		if(!hasServerUrl){
			return alert(errServerEmpty)
		}
		$.ajax({
			url: webRoot + 'admin/whatsapp/getToken',
			data: {
				token: input.token,
				serverUrl: input.url,
			},
			cache: false,
			type: "GET",
			success: function(data) {
				//console.log("Success: "+data.token_ok);
				if(data.status !== null) {
					alert("Token status is " + data.status);
				} else {
					alert("No token found for the phone number!");
				}
			},
			error: function(xhr) {
				if(xhr.responseJSON) {
					alert("! FAILED/invalid token: "+xhr.responseJSON.status);
				} else {
					console.log("Error loading JSON: ", xhr);
				}
			}
		});
		
	};

	handleSubmitFrom =(e)=> {
		e.preventDefault();
		let reqInputs = this.state.reqInputs;
		let reqInputsAPI = this.state.reqInputsAPI;
		let input = this.props.input;
		let valid = true;
		//check area validity
		if (input.selectedArea === "0" || input.selectedArea.length < 1) {
			return alert(I("Please select area"));
		}
		
		console.log("input.api",input.api)

			$.each(reqInputsAPI, (field, v)=>{
				//set required inputs touched
				reqInputsAPI = update(reqInputsAPI,{
					[field]: {
						touched:{$set: true}
					},
				})
				//check if empty
				if(input[field] === "" || input[field] === undefined) {
					if(field == "url" || field == "password" || field == "expireAt"){
						valid = true;
					}else{
						valid = false;
					}
				}
			});
			this.setState({reqInputsAPI:reqInputsAPI})
		if(valid){
			this.props.onSave();
		} else {
			alert(FORM_SUBMIT_EMPTY_WARNING);
		}
	}
	handleFetchKey = (e) =>{
		$("body").on('facebook.authorization.accounts', function(e, d) {
			console.log('d => ', d);
			var accessTokenRegexp = /access_token=([^]+)&data_access_expiration_time=([0-9]*)/g
			var accessTokenMatch = accessTokenRegexp.exec(d);
			var shortLiveToken = accessTokenMatch[1];
			$.post( webRoot + 'whatsapp/getLongLivedAccessToken', {"accessToken": shortLiveToken})
			.done(function(results){
				let username = "@noname";
				console.log('results = ', results);
				if(results.account != null){
					username = "@" + results.account.name
				}
				
				this.props.onChangeAdminInput("username", username);
				//intentionally token and useraccessToken same
				//no token receive
				this.props.onChangeAdminInput("token",results.userAccessToken);
				this.props.onChangeAdminInput("userAccessToken",results.userAccessToken);
				if(username != "@noname" ){
					this.setState({authorizationStr: I("Re-authorize")});
				}
			}.bind(this))
			.fail(function(error){
				console.log('Error loading data from: whatsapp/getLongLivedAccessToken', error);
			});
		}.bind(this));
	}
	renderAuthorizationButton = () => {
        if (this.state.isEmbeddedSignup) {
            return (
				<Button className="btn-outline blue btn-icon-text"
				outline
				color="primary"
				onClick={this.launchWhatsAppSignup}
				title={this.state.authorizationStr}
			>
				<i className={"icon-v5-facebook"} />
				<span className="label">{this.state.authorizationStr}</span>
			</Button>
            );
        } else {
            return (
				<Button className="btn-outline blue btn-icon-text"
				outline
				color="primary"
				onClick={this.handleAuth}
				title={this.state.authorizationStr}
			>
				<i className={"icon-v5-facebook"} />
				<span className="label">{this.state.authorizationStr}</span>
			</Button>
            );
        }
    };
	  loadFacebookSDK = (decryptedPageId) => {
		window.fbAsyncInit = () => {
		  window.FB.init({
			appId      : decryptedPageId,
			cookie     : true,
			xfbml      : true,
			version    : 'v19.0'
		  });
		};
	
		(function(d, s, id){
		   var js, fjs = d.getElementsByTagName(s)[0];
		   if (d.getElementById(id)) return;
		   js = d.createElement(s); js.id = id;
		   js.src = "https://connect.facebook.net/en_US/sdk.js";
		   fjs.parentNode.insertBefore(js, fjs);
		 }(document, 'script', 'facebook-jssdk'));
		 console.log('==========done=========')
	  }
	  handleTokenExchange = async (shortLiveToken) => {
		try {
		  const response = await fetch(`${webRoot}whatsapp/getLongLivedAccessToken`, {
			method: 'POST',
			credentials: "include",
			headers: {
			  "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
			},
            body: `accessToken=${shortLiveToken}&isEmbeddedSignup=${this.state.isEmbeddedSignup}`, 
		  });
		  let username = "@Embedded";
		  const results = await response.json();
		  this.props.onChangeAdminInput("username", username);
		  this.props.onChangeAdminInput("token",results.userAccessToken);
		  this.props.onChangeAdminInput("userAccessToken",results.userAccessToken);
		  if(username != "@noname" ){
			  this.setState({authorizationStr: I("Re-authorize")});
		  }

		} catch (error) {
		  console.error('Error during token exchange', error);
		}
	  }
	 launchWhatsAppSignup = () => {
		const configId = this.props.configId
		window.fbq && window.fbq('trackCustom', 'WhatsAppOnboardingStart', {
		  appId: decryptedPageId, 
		  feature: 'whatsapp_embedded_signup',
		});
	
		window.FB.login(response => {
		  if (response.authResponse) {
			const code = response.authResponse.code;
			this.handleTokenExchange(code)
		  } else {
			console.log('User cancelled login or did not fully authorize.');
		  }
		}, {
		  config_id: configId,
		  scope: "whatsapp_business_management, whatsapp_business_messaging",
		  response_type: 'code',
		  override_default_response_type: true,
		  extras: {
			sessionInfoVersion: 2, 
			setup: {
			}
		  }
		});
	  };
	handleAuth = () => {
		var appID = this.state.decryptedPageId;
		var path = "https://www.facebook.com/dialog/oauth?";
		var AccessTokenHandlerPageURL = webRoot + "media/facebook/handletoken";
		var queryParameters = [
				"client_id=" + appID,
				"redirect_uri=" + AUTHSERVERURL + "fbaccesstoken",
				"response_type=token",
				"display=popup",
				"state=" + AccessTokenHandlerPageURL,
				"scope=public_profile,whatsapp_business_messaging,whatsapp_business_management"
			];
		var query = queryParameters.join("&");
		var url = path + query;
		if(this.handleFetchKey) {
			this.handleFetchKey();
		}
		windowPopupCenter(url, 400, 580);
	}

	render() {
		const {
			areaList
			, input
			, onHandleTextInputChange
			, hidden
			, isNew
			, baseButtons
			, onChangeAdminInput
		} = this.props;
		let selectedArea = parseInt(input.selectedArea, 10);
		const st = this.state;
		const field = this.state.reqInputs;
		const warnTxt = INPUT_EMPTY_WARNING;
		const helperForAuthorization = <div>
			<div>{I("For the system to be able to manage your Whatsapp account you need to give the Cention Facebook application permission to do that.")}</div>
			<br/>
			<div>{I("When you click the button a new window will open where you will be asked to login to Facebook. After you have logged in you will be asked to accept the permissions the Cention Facebook application requires to manage your phone number.")}</div>
			<div>{I("Click the Authorize Application button to start the authorization process.")}</div>
			<br/>
			<div>{I("Click the Authorize button to start the authorization process.")}</div>
		</div>

		let timeIntervals = [];
		for(let i = 10; i < 24; i++){
			timeIntervals.push({id: i, value: i});
		}

		return (
			<form onSubmit={this.handleSubmitFrom} className="admin-one-form edit-admin-form" hidden={hidden}>
				<CustomInputRow
					id={"area"}
					name={"area"}
					label={I('Area')}
					className={"admin-dropdown-select"}
					mandatory={true}
					helper={helperForArea}
				>
					<UnnestedAreaSingleSelect
						data={areaList}
						invalidSelectedText={TXT_NO_SELECTION}
						onSelect={this.handleSelectArea}
						selected={selectedArea}
						nested={{key: 'Areas'}}
						idFields={{id: "Id", name: "Name"}}
					/>
				</CustomInputRow>
				<TextInputRow
					id={"name"}
					name={"name"}
					className="admin-text-input"
					label={I("Name")}
					value={input.name || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.name && field.name.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
				/>
				<ToggleSwitch
					id={"enable"}
					name={"enable"}
					data-qa-id="enableLine"
					label={I("Activated")}
					checked={input.enable}
					onClick={this.handleToggle}
					listWrap={false}
					className="admin-text-input"
				/>
				<ToggleSwitch
					id={"Embededsignin"}
					name={"Embededsignin"}
					label={I("Embeded sign-in")}
					checked={this.state.isEmbeddedSignup} // Use the new state variable
					onClick={() => this.setState({ isEmbeddedSignup: !this.state.isEmbeddedSignup })} // Toggle the new state variable
					listWrap={false}
					className="admin-text-input"
				/>				
				<TextInputRow
					id={"phone"}
					name={"phone"}
					className="admin-text-input"
					label={I("Business phone number#")}
					value={input.phone || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.phone && field.phone.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
				/>
				<TextInputRow
					id={"waBusinessId"}
					name={"waBusinessId"}
					className="admin-text-input"
					label={I("WABA ID")}
					value={input.waBusinessId || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					helperTxt={helperForWabaId}
				/>
				{/* {!input.api && <span>
					<TextInputRow
						id={"url"}
						name={"url"}
						className="admin-text-input"
						label={I("Server url")}
						value={input.url || ""}
						onChange={onHandleTextInputChange}
						mandatory={true}
						warning={(!input.url && field.url.touched) && warnTxt}
						onBlur={this.handleFieldWarning}
						helperTxt={helperForServerUrl}
					/>
					<TextInputRow
						id={"username"}
						name={"username"}
						className="admin-text-input"
						label={I("Username")}
						value={input.username || ""}
						onChange={onHandleTextInputChange}
						mandatory={true}
						warning={(!input.username && field.username.touched) && warnTxt}
						onBlur={this.handleFieldWarning}
					/>
					<TextInputRow
						id={"password"}
						name={"password"}
						className="admin-text-input"
						label={I("Password")}
						value={input.password || ""}
						onChange={onHandleTextInputChange}
						mandatory={true}
						warning={(!input.password && field.password.touched) && warnTxt}
						onBlur={this.handleFieldWarning}
					/>
					<TextInputRow
						id={"token"}
						name={"token"}
						className="admin-text-input"
						label={I("Bearer token")}
						value={input.token || ""}
						onChange={onHandleTextInputChange}
						mandatory={true}
						warning={(!input.token && field.token.touched) && warnTxt}
						onBlur={this.handleFieldWarning}
					/>
					<TextInputRow
						id={"expireAt"}
						name={"expireAt"}
						className="admin-text-input"
						label={I("Expire at")}
						value={input.expireAt || ""}
						onChange={onHandleTextInputChange}
						mandatory={true}
						warning={(!input.expireAt && field.expireAt.touched) && warnTxt}
						onBlur={this.handleFieldWarning}
					/>
					<div className="label-row">
						<div className="label-block">
							<div className="label-admin-text-input">
								<label htmlFor="admin-text-input">{I("Test Token")}</label>
							</div>
							{st.testingToken 
							? <div>{I("Testing ...")}</div>
							: <button type="button" className="btn-blue viber-test-btn" title="Test" data-qa-id="btn-Test" onClick={this.handleTest}>{I("Test")}</button>
							}
						</div>
					</div>
				</span>}  */}
				{/* {input.api && this.state.showAuthButton && */}
				<CustomInputRow
					id={"fbAuthorization"}
					name={"fbAuthorization"}
					label={I("Authorization")}
					className={"admin-auth-button"}
					helper={helperForAuthorization}
					mandatory={true}
				>
					{this.renderAuthorizationButton()}
					{/* <Button className="btn-outline blue btn-icon-text"
						outline
						color="primary"
						onClick={this.launchWhatsAppSignup}
						title={this.state.authorizationStr}
					></Button>
					<Button className="btn-outline blue btn-icon-text"
						outline
						color="primary"
						onClick={this.handleAuth}
						title={this.state.authorizationStr}
					>
						<i className={"icon-v5-facebook"} />
						<span className="label">{this.state.authorizationStr}</span>
					</Button> */}
				</CustomInputRow>
				{/* } */}
				{input.username && 
					<CustomInputRow
						id={"Accountname"}
						name={"Accountname"}
						label={I("Connected account")}
						className={"admin-fetch-option"}
						mandatory={true}>
							{input.username}
					</CustomInputRow>
				}
				<CustomInputRow
						id={"notificationInterval"}
						name={"notificationInterval"}
						label={I('Notification interval')}
						className={"inputs-wrapper"}
						mandatory={true}
						helper={helperForTime}
					>
					<IdValueSingleSelect
						data={timeIntervals}
						invalidSelectedText={TXT_NO_SELECTION}
						onSelect={(v)=>{onChangeAdminInput("notificationInterval", v)}}
						selected={+input.notificationInterval || 10}
					/>
				</CustomInputRow>
				{baseButtons}
			</form>
		)
	}
}