import React, { Fragment, PureComponent, useState, useCallback, useEffect } from 'react';
import { Button } from 'reactstrap';
import { I, webRoot } from '../../../common/v5/config';
import { Area } from '../../../components/v5/ManualErrand';
import {
	TextInputRow,
	ReadOnlyTextField,
	CustomInputRow
} from '../../../reactcomponents/Form';
import SquareCheckbox from '../../../reactcomponents/SquareCheckbox';
import { Spinner } from '../../../reactcomponents/Spinner'
import { INPUT_EMPTY_WARNING, FORM_SUBMIT_EMPTY_WARNING } from "../../../common/v5/constants"
import update from 'immutability-helper';
import { windowPopupCenter } from '../../../common/v5/helpers';

const helperForName = <div>
	<div>{I("This is the name that will be displayed in the list of accounts. This name is only used to help you identify the account.")}</div>
</div>
const helperForMasterToken = <div>
	<div>{I("Provide your Twilio master token to allow Cention's Twilio Connect App to retrieve and port over your master phone numbers to our sub-account. Cention never store this token in the database.")}</div>
</div>


const UNAUTHORIZED = 0, AUTHORIZING = 1, REAUTHORIZING = 2, RETRIEVING = 3,
	AUTHORIZED = 4, noOp = function(){}, tpPfx = 'tp#', subPfx = 'sub#',
	twilioREST = webRoot + 'twilio/accounts',
	noNeedToken = 0, needToken = 1;

//functions
function singlePhone(d) {
	return {
		friendlyName: d.friendlyName,
		number: d.number,
		sid: d.sid,
		area: "",
		name: "",
		enable: true,
		warm: false,
		outbound: false,
		inbound: true,
		record: false,
		include: false
	}
}

function phoneList(phones) {
	var list = [];
	$.each(phones, function(i,v) {
		list.push(singlePhone(v));
	});
	return list;
}

function othersPhoneList(others) {
	$.each(others, function(i,v) {
		v.phones = phoneList(v.phones);
	});
	return others;
}

const TwilioAuthButton = ({label, onClick}) => {
	const handleClick = () => {
		var url = "https://www.twilio.com/authorize/"
				+ callService.constants.centionTwilioSID
			// TODO: support cloud.cention.com/s/<space>? Likely no need because
			// the moment user clicking the Twilio connect button, he / she must
			// have already login but popup page may lost the cookies
			// information but user should be able to login again and still
			// redirect to the correct token page.
			, redirect_uri = 'https://'
				+ window.location.hostname
				+ '/ng/twilio/account/twilioconnecttoken'
			;
		url += '?state=' + encodeURIComponent(redirect_uri);
		if(onClick) {
			onClick();
		}
		windowPopupCenter(url, 990, 800);
	}
	return (
		<Button className="btn-outline blue btn-icon-text"
			outline
			color="primary"
			onClick={handleClick}
			title={label}
		>
			<i className={"icon-member"} />
			<span className="label">{label}</span>
		</Button>
	);
};

const PhoneForm = ({
	areaList
	, id
	, input
	, data
	, showArea
	, selectedArea
	, onToggleArea
	, onHandleTextInputChange
	, field
	, warnTxt
	, helperForName
	, onToggleCheckbox
	, onHandleFieldWarning
	, onIncludePhone
	, phones
	, phoneType
	, onUpdatePhoneFields
}) => {
	//useState
	const [check, setCheck] = useState(false);
	const [phoneState, setPhoneState] = useState(data);
	const [reqInputs, setReqInputs] = useState({
		name: {touched:false},
		area: {touched:false}
	});

	useEffect(() => {
		onUpdatePhoneFields(phoneType, data.sid, phoneState);
	},[phoneState]);


	const handleToggleInclude = () => {
		setCheck(!check);
		onIncludePhone(phoneType, data.sid, phoneState);
	};

	let phoneIncluded = false;

	$.each(phones, function(i,v) {
		if(v.sid === data.sid) {
			phoneIncluded = true;
		}
	});

	const handleTextInputBlur = (e) => {
		let field = e.target.name;
		let req = reqInputs;
		req = update(req,{
			[field]: {
				touched:{$set: true}
			},
		})
		setReqInputs(req);
	}

	const handleTextInputChange = (e) => {
		let field = e.target.name, value = e.target.value;
		setPhoneState((prevState)=>({
			...prevState,
			[field] : value
		}));
	};

	const handleToggleCheckbox = (value, field) => {
		setPhoneState((prevState)=>({
			...prevState,
			[field] : value
		}));
	}

	const handleSelectArea = (val) => {
		setPhoneState((prevState)=>({
			...prevState,
			area : val
		}));

		let req = reqInputs;
		req = update(req,{
			area: {
				touched:{$set: true}
			},
		})
		setReqInputs(req);
	}

	return (
		<div className='phone-form-container'>
			<CustomInputRow
				id={"include"}
				name={"include"}
				label={I("Include")}
				className={"admin-checkbox-option flex-column twilio-include"}
				mandatory={true}
			>
				<SquareCheckbox
					id={phoneType}
					data-qa-id={phoneType} //type "phones" or "otherPhones"
					checked={phoneIncluded}
					onClick={handleToggleInclude}
					label={"Add this phone to database"}
					className={"v5-checkbox"}
				/>
			</CustomInputRow>
			<BasicPhoneInputs
				areaList={areaList}
				input={phoneState}
				showArea={showArea}
				selectedArea={phoneState.area}
				onToggleArea={onToggleArea}
				onSelectArea={handleSelectArea}
				onHandleTextInputChange={handleTextInputChange}
				onHandleFieldWarning={handleTextInputBlur}
				field={reqInputs}
				warnTxt={warnTxt}
				helperForName={helperForName}
				onToggleCheckbox={handleToggleCheckbox}
			/>
		</div>
	)
}

const BasicPhoneInputs = ({
	areaList
	, id
	, sid
	, input
	, keyId
	, data
	, showArea
	, selectedArea
	, onToggleArea
	, onSelectArea
	, onHandleTextInputChange
	, field
	, warnTxt
	, helperForName
	, onToggleCheckbox
	, onHandleFieldWarning
}) => {

	return (
		<div className='base-phone-form'>
			<Area
				show={showArea}
				data={areaList}
				selected={selectedArea}
				notReady={!areaList}
				onToggle={onToggleArea}
				readOnly={false}
				onSelect={onSelectArea}
			/>
			<ReadOnlyTextField
				id={"number"}
				name={"number"}
				className="admin-text-input"
				label={I("Phone")}
				value={input.number || ""}
			/>
			<ReadOnlyTextField
				id={"accountName"}
				name={"accountName"}
				className="admin-text-input"
				label={I("Account name")}
				value={input.friendlyName || ""}
			/>
			<TextInputRow
				id={"name"}
				name={"name"}
				className="admin-text-input"
				label={I("Name")}
				value={input.name || ""}
				onChange={onHandleTextInputChange}
				mandatory={true}
				warning={(!input.name && field.name.touched) && warnTxt}
				onBlur={onHandleFieldWarning}
				helperTxt={helperForName}
			/>
			<CustomInputRow
				id={"Options"}
				name={"Options"}
				label={I("Others")}
				className={"admin-checkbox-option flex-column"}
				mandatory={true}
			>
				<SquareCheckbox
					id={"enable"}
					data-qa-id={"enable"}
					checked={input.enable || false}
					onClick={onToggleCheckbox}
					label={"Enable"}
					className={"v5-checkbox"}
				/>
				<SquareCheckbox
					id={"warm"}
					data-qa-id={"warm"}
					checked={input.warm || false}
					onClick={onToggleCheckbox}
					label={"Attended transfer"}
					className={"v5-checkbox"}
				/>
			</CustomInputRow>
		</div>
	)
}

const PhonesPerSubAcc = ({
	type
	, appName
	, index
	, appSid
	, data
	, input
	, showArea
	, areas
	, selectedArea
	, onToggleArea
	, onSelectArea
	, onHandleTextInputChange
	, onToggleCheckbox
	, field
	, warnTxt
	, helperForName
	, onHandleFieldWarning
	, onIncludePhone
	, phones:phoneObj
	, phoneType
	, onUpdatePhoneFields
}) => {
	var phones = [];

	let helperForAccountName;

	if(type == noNeedToken) {
		helperForAccountName = I("Phones under this account ({SID}) don't require master token to port over.")
		.replace('{SID}', appSid);
	} else {
		helperForAccountName = I("Phones under this account ({SID}) requires master token to port over. Cention Contact Center currently don't support buying Twilio phone over API. User requires to move these phone numbers to sub-account with Sid: {APP_SID}")
		.replace('{SID}', appSid)
		.replace('{APP_SID}', appSid);
	}

	$.each(data, function(i,v) {
		let unique = tpPfx+i;
		phones.push(<PhoneForm key={unique} id={unique}
				data={v}
				selectedArea={selectedArea}
				areaList={areas}
				input={v}
				showArea={showArea}
				onToggleArea={onToggleArea}
				onSelectArea={onSelectArea}
				onHandleTextInputChange={onHandleTextInputChange}
				field={field}
				warnTxt={warnTxt}
				helperForName={helperForName}
				onToggleCheckbox={onToggleCheckbox}
				onHandleFieldWarning={onHandleFieldWarning}
				onIncludePhone={onIncludePhone}
				phones={phoneObj}
				phoneType={phoneType}
				onUpdatePhoneFields={onUpdatePhoneFields}
			/>);
	}.bind(this));
	if(phones.length <= 0) {
		phones = <div className='text-danger'>{I('No available phone number under this sub-account.')}</div>
	}
	return (
		<div className='phone-container' key={appSid}>
			<ReadOnlyTextField
				id={"subAccountName"}
				name={"subAccountName"}
				className="admin-text-input"
				label={I("Twilio sub-account name")}
				value={`${appName} (${appSid})` || ""}
				helperTxt={helperForAccountName}
			/>
			{phones}
		</div>
	)
}

//multi
const MultiPhoneForm = ({
	others
	, appSid
	, appName
	, areas
	// , areaList
	, input
	, showArea
	, selectedArea
	, onToggleArea
	, onSelectArea
	, onHandleTextInputChange
	, onChangeAdminInput
	, field
	, warnTxt
	, helperForName
	, onToggleCheckbox
	, onHandleFieldWarning
	, st
	, list
	, onIncludePhone
	, onUpdatePhoneFields
	, hide
}) => {
	let other = null;
	if(others && others.length){
		other = [];
		$.each(others, function(i,v) {
			other.push(<PhonesPerSubAcc
				type={needToken}
				key={v.sid}
				index={subPfx+(i+1)} appName={v.friendlyName}
				appSid={v.sid}
				input={input}
				data={v.phones}
				areas={areas}
				showArea={showArea}
				selectedArea={selectedArea}
				onToggleArea={onToggleArea}
				onSelectArea={onSelectArea}
				onHandleTextInputChange={onHandleTextInputChange}
				field={field}
				warnTxt={warnTxt}
				helperForName={helperForName}
				onToggleCheckbox={onToggleCheckbox}
				onHandleFieldWarning={onHandleFieldWarning}
				onIncludePhone={onIncludePhone}
				phones={st.otherPhones}
				phoneType={"others"}
				onUpdatePhoneFields={onUpdatePhoneFields}
				/>);
			});
	}
	// onChangeAdminInput("phones", list);
	return (
		<div className='multiphone-wrapper' hidden={hide}>
			<PhonesPerSubAcc
				name={appName}
				type={noNeedToken}
				key={appSid}
				index={subPfx+0}
				appSid={appSid}
				appName={appName} data={list}
				areas={areas}
				input={input}
				showArea={showArea}
				selectedArea={selectedArea}
				onToggleArea={onToggleArea}
				onSelectArea={onSelectArea}
				onHandleTextInputChange={onHandleTextInputChange}
				field={field}
				warnTxt={warnTxt}
				helperForName={helperForName}
				onToggleCheckbox={onToggleCheckbox}
				onHandleFieldWarning={onHandleFieldWarning}
				onIncludePhone={onIncludePhone}
				phones={st.phones}
				phoneType={"phones"}
				onUpdatePhoneFields={onUpdatePhoneFields}
			/>
			{other}
		</div>
	)
}

export default class AccountTwilioForm extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			id: 0,
			name: "",
			showArea: false,
			authenticated: false,
			authorizationStr: I("Authorize"),
			showAuthBtn: true,
			stage: UNAUTHORIZED,
			list: [],
			phones: [],
			otherPhones:[],
			reqInputs: {
				name: {touched:false},
			}
		};

	}

	componentDidMount = () => {
		let ds = this.props.input;
		if(typeof ds.id !== 'undefined' && ds.id > 0){
			this.setState({
				authenticated: true,
				authorizationStr: I("Re-authorize"),
			});
		}
	}

	componentWillUnmount = () =>{
		$('body').off('twilio.authorization.accountsid');
	}

	toggleArea = (t, v) => {
		this.setState({showArea: !v});
	}

	handleSelectArea = (val) => {
		this.props.onChangeAdminInput("selectedArea", val, this.props.view);
	}
	

	handleToggle = (value, field) => {
		this.props.onChangeAdminInput(field, value)
	}

	handleChannel = ( id ) =>{
		this.props.onChangeAdminInput("channelId", id);
		let cl = this.state.accountList;
		let chFound = false;
		if (cl.length > 0){
			cl.forEach((v, i) => {
				if(v.id === id){
					chFound = true;
					this.setState({channelName: v.value, errPageEmpty: ""});
					this.props.onChangeAdminInput("channelName", v.value);
				}
			})
		}
		if(!chFound){
			this.setState({channelId: "", channelName: ""});
		}
	}

	getParameterByName = (name, url) => {
		if (!url) url = window.location.href;
		name = name.replace(/[\[\]]/g, "\\$&");
		var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, " "));
	}

	handleAuth = (e) => {
		$('body').on('twilio.authorization.accountsid', function(e, d) {
			var q = {sid: d};
			if(this.state.token) {
				q.token = this.state.token;
			}
			$.get(twilioREST + "/info", q)
			.done(function(data) {
				var s = {
					stage: AUTHORIZED,
					name: data.friendlyName,
					friendlyName: data.friendlyName,
					authToken: data.authToken,
					list: phoneList(data.phones),
					authenticated: true
				};
				if(data.others && data.others.length) {
					s.others = othersPhoneList(data.others);
				}
				if(data.ownerSid) {
					s.owner = data.ownerSid;
				}
				this.setState(s);
			}.bind(this))
			.fail(function(xhr) {
				if(xhr.responseJSON && xhr.responseJSON.error) {
					console.debug("get phone info error:",
						xhr.responseJSON.error);
				} else {
					console.debug("get phone info error:", xhr);
				}
				this.setState({
					stage: UNAUTHORIZED,
					list: [],
					name: ""
				});
			}.bind(this));
			this.setState({sid: d, stage: RETRIEVING});
		}.bind(this));
		this.setState({stage: AUTHORIZING});
	}

	handleFieldWarning = (e) => {
		let field = e.target.name;
		let reqInputs = this.state.reqInputs;
		reqInputs = update(reqInputs,{
			[field]: {
				touched:{$set: true}
			},
		})
		this.setState({reqInputs:reqInputs})
	}

	handleToken = (e) => {
		let val = e.target.value, field = e.target.name;
		this.props.onChangeAdminInput(field, val);
		this.setState({token: val});
	}

	includePhone = (type, idx, phoneObj) => {
		let arrPhones = this.state.phones;
		let isOtherphone = (type === "others");
		if(isOtherphone) {
			arrPhones = this.state.otherPhones;
		}
		let index = arrPhones.findIndex(x => x.sid === idx);
		if(index === -1) {
			arrPhones = update(arrPhones, {$push: [phoneObj]})
		} else {
			arrPhones = update(arrPhones, {$splice: [[index, 1]]})
		}
		isOtherphone ? this.setState({otherPhones:arrPhones}) : this.setState({phones:arrPhones});
	}

	updatePhone = (type, idx, obj) => {
		let arrPhones = this.state.phones;
		let isOtherphone = (type === "others");
		if(isOtherphone) {
			arrPhones = this.state.otherPhones;
		}
		let index = arrPhones.findIndex(x => x.sid === idx);
		if(index !== -1){
			arrPhones = update(arrPhones, {
				// [index]:{$merge: {name: val}},
				[index]:{$merge: obj},
			});
			this.setState({phones:arrPhones});
		}
	};

	handleSubmit = (e) => {
		e.preventDefault();
		let reqInputs = this.state.reqInputs;
		let input = this.props.input;
		let valid = true;

		if (this.props.isNew) {
			const st = this.state;
			if (st.phones.length < 1) {
				return alert(I("No phone account added. Please try again"));
			}
			$.each(st.phones, function(i,v) {
				if(v.name === "" || v.area === "") {
					return valid = false;
				}
			});
			if (!valid) return alert(I("Ensure all required fields are filled, please try again"));

			let newRecords = {
				sid: st.sid,
				authToken: st.authToken,
				ownerSid: st.owner,
				ownerToken: st.token,
				friendlyName: st.friendlyName,
				phones: st.phones,
				others: st.otherPhones,
			}
			this.props.onChangeAdminInput("newAccountData", newRecords);
			this.props.onSave();
		} else {
			if (input.selectedArea === "0") {
				return alert(I("Please select area"));
			}
			$.each(reqInputs, (field, v)=>{
				//set required inputs touched
				reqInputs = update(reqInputs,{
					[field]: {
						touched:{$set: true}
					},
				})
				//check if empty
				if(input[field] === "" || input[field] === undefined) {
					valid = false;
				}
			});
			this.setState({reqInputs:reqInputs});
			if(valid){
				this.props.onSave();
			} else {
				alert(FORM_SUBMIT_EMPTY_WARNING);
			}
		}
	}

	render() {
		const {
			areaList
			, activeId
			, input
			, onHandleTextInputChange
			, hidden
			, baseButtons
			, onChangeAdminInput
			, isNew
		} = this.props;

		let selectedArea = parseInt(input.selectedArea, 10);
		const st = this.state;
		const field = st.reqInputs;
		const warnTxt = INPUT_EMPTY_WARNING;
		let authorized = st.stage === AUTHORIZED;
		let authorizationStr = authorized ? I("Re-authorize") : I("Authorize") ;

		return (
			<form onSubmit={this.handleSubmit} className="admin-one-form edit-admin-form" hidden={hidden}>
				{isNew 
				? <div className='create-new'>
					<CustomInputRow
						id={"authorization"}
						name={"authorization"}
						label={I("Authorization")}
						className={"admin-auth-button"}
						// helper={helperForAuthorization}
						mandatory={true}
						// hide={!isNew}
						hide={activeId === 0 && authorized}
					>
						<TwilioAuthButton onClick={this.handleAuth} label={authorizationStr}/>
					</CustomInputRow>
					<TextInputRow
						id={"token"}
						name={"token"}
						className="admin-text-input"
						label={I("Master token")}
						value={input.token || ""}
						onChange={this.handleToken}
						mandatory={false}
						helperTxt={helperForMasterToken}
						hide={activeId === 0 && authorized}
					/>
					{authorized && <MultiPhoneForm
						st={st}
						list={st.list}
						others={st.others || []}
						appSid={st.sid}
						appName={st.name || ""}
						areas={areaList}
						input={input}
						showArea={st.showArea}
						selectedArea={selectedArea}
						onToggleArea={this.toggleArea}
						onSelectArea={this.handleSelectArea}
						onHandleTextInputChange={onHandleTextInputChange}
						onChangeAdminInput={onChangeAdminInput}
						field={field}
						warnTxt={warnTxt}
						helperForName={helperForName}
						onToggleCheckbox={this.handleToggle}
						onHandleFieldWarning={this.handleFieldWarning}
						onIncludePhone={this.includePhone}
						onUpdatePhoneFields={this.updatePhone}
					/>}
				</div>
				: <Fragment>
					<ReadOnlyTextField
						id={"friendlyName"}
						name={"friendlyName"}
						className="admin-text-input"
						label={I("Belong to")}
						value={input.twilioAccountFriendlyName || ""}
					/>
					<BasicPhoneInputs
						areaList={areaList}
						input={input}
						showArea={st.showArea}
						selectedArea={selectedArea}
						onToggleArea={this.toggleArea}
						onSelectArea={this.handleSelectArea}
						onHandleTextInputChange={onHandleTextInputChange}
						field={field}
						warnTxt={warnTxt}
						helperForName={helperForName}
						onToggleCheckbox={this.handleToggle}
						onHandleFieldWarning={this.handleFieldWarning}
					/>
				</Fragment>
				}
				{baseButtons}
			</form>
		)
	}
}