import React, {
	Fragment
	, PureComponent
	, memo
	, useCallback
	, useEffect
	, useMemo
} from 'react';
import { Button } from 'reactstrap';
import { I, webRoot } from '../../../common/v5/config';
import { Area } from '../../../components/v5/ManualErrand';
import {
	TextInputRow,
	SelectInputRow,
	ReadOnlyTextField,
	CustomInputRow
} from '../../../reactcomponents/Form';
import {
	LabelToggleSwitch as ToggleSwitch
} from '../../../components/v5/ToggleSwitch';
import { Spinner } from '../../../reactcomponents/Spinner'
import { AUTHSERVERURL, INPUT_EMPTY_WARNING, FORM_SUBMIT_EMPTY_WARNING } from "../../../common/v5/constants"
import update from 'immutability-helper';
import { IdValueSingleSelect } from '../../../reactcomponents/Dropdown'
import { TXT_NO_SELECTION } from '../../../common/v5/receiptGreetingConstants'
import { windowPopupCenter } from '../../../common/v5/helpers';
import { UnnestedAreaSingleSelect } from '../../../reactcomponents/Dropdown';

const helperForName = <div>
	<div>{I("This is the name that will be displayed in the list of accounts. This name is only used to help you identify the account.")}</div>
</div>

const helperForArea = <div>
	<div>{I("An area must be chosen because the authenticated Instagram account needs to be connected with an area.")}</div>
</div>

const helperForEnable = <div>
	<div>{I("If the account is enabled the system will fetch posts and comments from the Instagram page.")}</div>
	<div>{I("If it is not enabled the system will not do anything with the account.")}</div>
</div>

const helperForAuthorization = <div>
	<div>{I("For the system to be able to manage your Instagram page you need to give the Cention Instagram application permission to do that.")}</div>
	<br/>
	<div>{I("When you click the button a new window will open where you will be asked to login to Instagram. After you have logged in you will be asked to accept the permissions the Cention Instagram application requires to manage your timeline.")}</div>
	<br/>
	<div>{I("Click the Authorize button to start the authorization process.")}</div>
</div>


const INSAuthButton = ({label, onFetchKey, decryptedPageId}) => {
	const handleClick = () => {
		var appID = decryptedPageId;
		var path = "https://www.facebook.com/dialog/oauth?";
		var AccessTokenHandlerPageURL = webRoot + "media/facebook/handletoken";
		var queryParameters = [
				"client_id=" + appID,
				"redirect_uri=" + AUTHSERVERURL + "fbaccesstoken",
				"response_type=token",
				"display=popup",
				"state=" + AccessTokenHandlerPageURL,
				"scope=pages_messaging,instagram_basic,instagram_manage_comments,instagram_manage_messages,pages_manage_metadata,pages_read_engagement,pages_manage_engagement"
			];
		var query = queryParameters.join("&");
		var url = path + query;
		if(onFetchKey) {
			onFetchKey();
		}
		windowPopupCenter(url,400,580);
	}
	return (
		<Button className="btn-outline blue btn-icon-text"
			outline
			color="primary"
			onClick={handleClick}
			title={label}
		>
			<i className={"icon-v5-facebook"} />
			<span className="label">{label}</span>
		</Button>
	);
};

export default class AccountInstagramForm extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			pageId: "",
			pageName: "",
			pages: [],
			decryptedPageId: "",
			rawAccountList: [],
			pageLoading: false,
			authorizationStr: I("Authorize"),
			showInvalidPage: false,
			showAuthBtn: true,
			reqInputs: {
				name: {touched:false},
			}
		};

	}
	loadFacebookSDK = (decryptedPageId) => {
		window.fbAsyncInit = () => {
		  window.FB.init({
			appId      : decryptedPageId,
			cookie     : true,
			xfbml      : true,
			version    : 'v19.0'
		  });
		};
	
		(function(d, s, id){
		   var js, fjs = d.getElementsByTagName(s)[0];
		   if (d.getElementById(id)) return;
		   js = d.createElement(s); js.id = id;
		   js.src = "https://connect.facebook.net/en_US/sdk.js";
		   fjs.parentNode.insertBefore(js, fjs);
		 }(document, 'script', 'facebook-jssdk'));
		 console.log('==========done=========')
	  }
	  async decryptData (encryptedBase64, keyString) {
		// Convert Base64 URL to standard Base64
		const standardBase64 = encryptedBase64.replace(/-/g, '+').replace(/_/g, '/');
	
		try {
			// Convert the base64 string to an ArrayBuffer
			const encryptedData = Uint8Array.from(atob(standardBase64), c => c.charCodeAt(0));
			
			// Assuming the IV is 12 bytes long and is prefixed to the encrypted data
			const iv = encryptedData.slice(0, 12);
			const dataToDecrypt = encryptedData.slice(12);
		
			// Convert the key string to an ArrayBuffer
			const keyBuffer = new TextEncoder().encode(keyString);
		
			// Import the key
			const cryptoKey = await window.crypto.subtle.importKey(
				"raw",
				keyBuffer,
				{ name: "AES-GCM" },
				false, // extractable
				["decrypt"] // possible actions
			);
		
			// Decrypt the data
			const decrypted = await window.crypto.subtle.decrypt(
				{ name: "AES-GCM", iv: iv },
				cryptoKey,
				dataToDecrypt
			);
		
			// Convert the decrypted data to a string
			return new TextDecoder().decode(decrypted);
		} catch (error) {
			console.error("Decryption failed", error);
			return null;
		}
	}
	  
	componentDidMount = () => {
		const encryptedPageId =this.props.pageId
		const decryptionKey = "12345678901234567890123456789012"; 

		this.decryptData(encryptedPageId, decryptionKey)
		.then(decryptedPageId => {
			// this.setState({dec ryptedPageId :decryptedPageId})
			this.setState({ decryptedPageId }, () => {
				this.loadFacebookSDK(decryptedPageId);
			});	
			// Initialize Facebook SDK with decrypted page ID
		this.loadFacebookSDK(decryptedPageId);			
		})
		.catch(error => {
		  console.error("Error during decryption:", error);
		});
		let ds = this.props.input;
		if(typeof ds.id !== 'undefined' && ds.id > 0){
			this.setState({
				pageId: ds.pageId,
				pageName: ds.pageName,
				authorizationStr: I("Re-authorize"),
			});
		}
	}

	componentWillUnmount = () =>{
		$('body').off('facebook.authorization.accounts');
	}

	handleSelectArea = (val) => {
		this.props.onChangeAdminInput("selectedArea", val, this.props.view);
	}
	

	handleToggle = (value, field) => {
		this.props.onChangeAdminInput(field, value)
	}

	handlePage = ( value ) =>{
		this.setState({pageId: value, pageLoading: true });
		let els = [value];
		if(els.length > 0 && els[0] != 0){
			let spa = this.state.rawAccountList[els[0]]
			$.post( webRoot + 'instagram/getBusinessAccount', {"token": spa.token, "pageId": els[0]})
			.done(result =>{
				let insBusinessAccount = result.instagram_business_account;
				if(insBusinessAccount != null){
					let accName = spa.page+" - "+result.id;
					this.setState({pageId: els[0], showInvalidPage: false});
					this.props.onChangeAdminInput("accountID",insBusinessAccount.id);
					this.props.onChangeAdminInput("accountName",accName);
					this.props.onChangeAdminInput("pageId", els[0]);
					this.props.onChangeAdminInput("accessToken",spa.token);
				} else {
					this.props.onChangeAdminInput("accountID","");
					this.props.onChangeAdminInput("accountName","");
					this.props.onChangeAdminInput("accessToken",null);
					this.setState({showInvalidPage:true})
				}
				this.setState({pageLoading: false})
			});
		}else{
			this.setState({
				pageId: els[0],
				pageLoading: false,
				// accountAccessToken: "",
				accountName: ""
			});
		}
	}
	// handleAuth = () => {
	// 	if (window.FB) {
	// 	  window.FB.login((response) => {
	// 		if (response.authResponse) {
	// 		  console.log('Welcome! Fetching your information.... ');
	// 		  const accessToken = response.authResponse.accessToken;
	// 		  // Proceed with fetching long-lived token, user data etc.
	// 		  this.exchangeShortLivedToken(accessToken);
	// 		} else {
	// 		  console.log('User cancelled login or did not fully authorize.');
	// 		}
	// 	  }, {scope: 'pages_messaging,instagram_basic,instagram_manage_comments,instagram_manage_messages,pages_manage_metadata,pages_read_engagement,pages_manage_engagement'});
	// 	}
	//   };
	
	//   exchangeShortLivedToken = (shortLiveToken) => {
	// 	// Implement your logic to exchange short-lived token for long-lived token
	// 	// Similar to what you have in your original method
	// 	// Example POST request to your backend
	// 	this.setState({ pageLoading: true });
	// 	fetch(`${webRoot}instagram/getLongLivedAccessToken`, {
	// 		method: 'POST',
	// 		credentials: "include",
	// 		headers: {
	// 		  "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
	// 	  },
	// 		body:  `accessToken=${shortLiveToken}`,
	// 	})
	// 	.then(response =>
	// 		 response.json())
	// 	.then(results => {
	// 		// Assuming results.pages contains the data you need
	// 		let plist = [];
	// 		let adId = "";
	// 		let pages = results.pages;
	// 		let pageName = pages.name;
	// 		let accounts = {};
	// 		$.each(pages.accounts.data, (i, account) => {
	// 			accounts[account.id] = {token: account.access_token, page: account.name};
	// 			plist.push({id: account.id, value: account.category + ' - ' + account.name});
	// 		});
	// 		// If the response structure allows for multiple pages, you'd loop through them here.
	// 		// For a single page based on your structure:
	// 		// accounts[pages.id] = { token: userAccessToken, page: pages.name };
	// 		// plist.push({ id: pages.id, value: pages.name });
	
	// 		// Update component state with the new data
	// 		this.setState({
	// 			pages: plist,
	// 			rawAccountList: accounts,
	// 			showAuthBtn: false,
	// 			pageLoading: false
	// 		});
	// 	})
	// 	.catch(error => {
	// 	  console.error('Error during token exchange', error);
	// 	});
	//   };

	handleAuth = (e) => {
		$("body").on('facebook.authorization.accounts', function(e, d) {
			var accessTokenRegexp = /access_token=([^]+)&data_access_expiration_time=([0-9]*)/g
			var accessTokenMatch = accessTokenRegexp.exec(d);
			var shortLiveToken = accessTokenMatch[1];
			this.setState({pageLoading:true})
			$.post( webRoot + 'instagram/getLongLivedAccessToken', {"accessToken": shortLiveToken})
			.done(function(results){
				let plist = [];
				let adId = "";
				let pages = results.pages;
				let pageName = pages.name;
				let accounts = {};
				$.each(pages.accounts.data, (i, account) => {
					accounts[account.id] = {token: account.access_token, page: account.name};
					plist.push({id: account.id, value: account.category + ' - ' + account.name});
				});
				this.setState({
					pages: plist,
					rawAccountList: accounts,
					showAuthBtn: false,
					pageLoading: false
				});
				this.props.onChangeAdminInput("userAccessToken",results.userAccessToken);
			}.bind(this))
			.fail(function(error){
				console.log('Error loading data from: instagram/getLongLivedAccessToken', error);
			});
		}.bind(this));
	}

	handleFieldWarning = (e) => {
		let field = e.target.name;
		let reqInputs = this.state.reqInputs;
		reqInputs = update(reqInputs,{
			[field]: {
				touched:{$set: true}
			},
		})
		this.setState({reqInputs:reqInputs})
	}

	handleSubmit = (e) => {
		e.preventDefault();
		let reqInputs = this.state.reqInputs;
		let input = this.props.input;
		let valid = true;
		$.each(reqInputs, (field, v)=>{
			//set required inputs touched
			reqInputs = update(reqInputs,{
				[field]: {
					touched:{$set: true}
				},
			})
			//check if empty
			if(input[field] === "" || input[field] === undefined || input.selectedArea === "0") {
				valid = false;
			}
		});
		this.setState({reqInputs:reqInputs})
		if(input.accountName === "" || input.accountName === undefined) {
			return alert(I("Page is not valid. Ensure authorized Instagram business account is selected."))
		}
		if(valid){
			this.props.onSave();
		} else {
			alert(FORM_SUBMIT_EMPTY_WARNING);
		}
	}

	render() {
		const {
			areaList
			, input
			, onHandleTextInputChange
			, hidden
			, baseButtons
		} = this.props;

		let selectedArea = parseInt(input.selectedArea, 10);

		const st = this.state;
		const field = st.reqInputs;
		const warnTxt = INPUT_EMPTY_WARNING;

		return (
			<form onSubmit={this.handleSubmit} className="admin-one-form edit-admin-form" hidden={hidden}>
				<CustomInputRow
					id={"area"}
					name={"area"}
					label={I('Area')}
					className={"admin-dropdown-select"}
					mandatory={true}
					helper={helperForArea}
				>
					<UnnestedAreaSingleSelect
						data={areaList}
						invalidSelectedText={TXT_NO_SELECTION}
						onSelect={this.handleSelectArea}
						selected={selectedArea}
						nested={{key: 'Areas'}}
						idFields={{id: "Id", name: "Name"}}
					/>
				</CustomInputRow>
				<TextInputRow
					id={"name"}
					name={"name"}
					className="admin-text-input"
					label={I("Name")}
					value={input.name || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.name && field.name.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
					helperTxt={helperForName}
				/>
				<ToggleSwitch
					id={"enable"}
					name={"enable"}
					data-qa-id="enableInstagram"
					label={I("Activated")}
					checked={input.enable}
					onClick={this.handleToggle}
					listWrap={false}
					className="admin-text-input"
					helperTxt={helperForEnable}
				/>
				{st.showAuthBtn &&
				<CustomInputRow
					id={"fbAuthorization"}
					name={"fbAuthorization"}
					label={I("Authorization")}
					className={"admin-auth-button"}
					helper={helperForAuthorization}
					mandatory={true}
				>
					<INSAuthButton onFetchKey={this.handleAuth} label={st.authorizationStr}  decryptedPageId={this.state.decryptedPageId} />
				</CustomInputRow>}
				<CustomInputRow
					id={"pageId"}
					name={"pageId"}
					label={I('Facebook page')}
					className={"admin-select-review-option"}
					mandatory={true}
					hide={st.pages.length < 1}
				>
					<IdValueSingleSelect
						data={st.pages}
						invalidSelectedText={TXT_NO_SELECTION}
						onSelect={this.handlePage}
						selected={st.pageId || ""}
					/>
				</CustomInputRow>
				{st.pageLoading ? <div className='center p-3'><Spinner/></div>
				: <Fragment>
					<ReadOnlyTextField
						id={"accountID"}
						name={"accountID"}
						className="admin-text-input"
						label={I("Instagram business account")}
						value={input.accountID || ""}
						hidden={!input.accountID}
					/>
					{st.showInvalidPage &&
						<p className='text-danger center p-2'>
							{I("Selected page is not associated with Instagram business")}
						</p>
					}
					<ReadOnlyTextField
						id={"accountName"}
						name={"accountName"}
						className="admin-text-input"
						label={I("Page account ID")}
						value={input.accountName || ""}
						hidden={!input.accountName}
					/>
				</Fragment>}
				{baseButtons}
			</form>
		)
	}
}