import React, {
	Fragment
	, PureComponent
	, memo
	, useCallback
	, useEffect
	, useMemo
} from 'react';
import TagsInput from 'react-tagsinput';
import { Button } from 'reactstrap';
import { I, webRoot } from '../../../common/v5/config';
import { Area } from '../../../components/v5/ManualErrand';
import {
	Label,
	TableIconicButton,
	FormInputWithLabelRow,
	TextInputRow,
	SelectInputRow,
	AvatarInputRow,
	HelperTooltip,
	withRow,
	CheckboxInputRow,
	ReadOnlyTextField,
	CustomInputRow
} from '../../../reactcomponents/Form';
import {
	LabelToggleSwitch as ToggleSwitch
} from '../../../components/v5/ToggleSwitch';
import { AUTHSERVERURL } from '../../../common/v5/constants';
import SquareCheckbox from '../../../reactcomponents/SquareCheckbox';
import { INPUT_EMPTY_WARNING, FORM_SUBMIT_EMPTY_WARNING } from '../../../common/v5/constants';
import update from 'immutability-helper';
import { windowPopupCenter } from '../../../common/v5/helpers';
import { UnnestedAreaSingleSelect } from '../../../reactcomponents/Dropdown';
import { TXT_NO_SELECTION } from '../../../common/v5/receiptGreetingConstants';

export class AccountFacebookForm extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			accountAccessToken: "",
			userAccessToken: "",
			pages: [],
			pageName: "",
			pageID: "",
			rawAccountList: [],
			authorizationStr: I("Authorize"),
			showAuthButton: true,
			reqInputs: {
				name: {touched:false},
				pageID: {touched:false}
			},
		};
	}
	
	async decryptData (encryptedBase64, keyString) {
		// Convert Base64 URL to standard Base64
		const standardBase64 = encryptedBase64.replace(/-/g, '+').replace(/_/g, '/');
	
		try {
			// Convert the base64 string to an ArrayBuffer
			const encryptedData = Uint8Array.from(atob(standardBase64), c => c.charCodeAt(0));
			
			// Assuming the IV is 12 bytes long and is prefixed to the encrypted data
			const iv = encryptedData.slice(0, 12);
			const dataToDecrypt = encryptedData.slice(12);
		
			// Convert the key string to an ArrayBuffer
			const keyBuffer = new TextEncoder().encode(keyString);
		
			// Import the key
			const cryptoKey = await window.crypto.subtle.importKey(
				"raw",
				keyBuffer,
				{ name: "AES-GCM" },
				false, // extractable
				["decrypt"] // possible actions
			);
		
			// Decrypt the data
			const decrypted = await window.crypto.subtle.decrypt(
				{ name: "AES-GCM", iv: iv },
				cryptoKey,
				dataToDecrypt
			);
		
			// Convert the decrypted data to a string
			return new TextDecoder().decode(decrypted);
		} catch (error) {
			console.error("Decryption failed", error);
			return null;
		}
	}
	  
	componentDidMount = () => {
		const encryptedPageId =this.props.pageId
		const decryptionKey = "12345678901234567890123456789012"; 

		this.decryptData(encryptedPageId, decryptionKey)
		.then(decryptedPageId => {
			// Initialize Facebook SDK with decrypted page ID
		this.loadFacebookSDK(decryptedPageId);			
		})
		.catch(error => {
		  console.error("Error during decryption:", error);
		});
		let ds = this.props.input;
		if(typeof ds.id !== 'undefined' && ds.id > 0){
			this.setState({
				id: ds.id,
				name: ds.name,
				enable: ds.enable,
				wall: ds.canFetchWall,
				pm: ds.canFetchPM,
				advert: ds.canFetchAd,
				events: ds.canFetchEvent,
				connectedArea: ds.connectedArea,
				oldAreaId: ds.connectedArea,
				adAccountId: ds.adAccountId,
				pageID: ds.pageID,
				pageName: ds.pageName,
				pages: [{id: ds.pageID, name: ds.pageName }],
				authorizationStr: I("Re-authorize")
			});
		}
	}

	componentWillUnmount = () => {
		$('body').off('facebook.authorization.accounts');
	}

	handleSelectArea = (val) => {
		this.props.onChangeAdminInput("selectedArea", val, this.props.view);
	}
	
	handleToggle = (value, field) => {
		this.props.onChangeAdminInput(field, value)
	}
	loadFacebookSDK = (decryptedPageId) => {
		window.fbAsyncInit = () => {
		  window.FB.init({
			appId      : decryptedPageId,
			cookie     : true, 
			xfbml      : true,
			version    : 'v19.0'
		  });
		}; 
	
		(function(d, s, id){
		   var js, fjs = d.getElementsByTagName(s)[0];
		   if (d.getElementById(id)) return;
		   js = d.createElement(s); js.id = id;
		   js.src = "https://connect.facebook.net/en_US/sdk.js";
		   fjs.parentNode.insertBefore(js, fjs);
		 }(document, 'script', 'facebook-jssdk'));
		 console.log('==========done=========')
	  }

	handleFetchKey = async (e) => {
		// Attaching an event listener to the body for the custom event 'facebook.authorization.accounts'
		$("body").on('facebook.authorization.accounts', async (e, d) => {

		  // Regular expression to extract the access token and its expiration time from the event data
		  const accessTokenRegexp = /access_token=([^]+)&data_access_expiration_time=([0-9]*)/g;
		  // Executing the regular expression on the event data to find matches
		  const accessTokenMatch = accessTokenRegexp.exec(d);

		  // Early return if no match is found, preventing further execution
		  if (!accessTokenMatch) {
			console.error('No access token match found');
			return;
		  }
	
		  // Extracting the short-lived access token from the match
		  const shortLiveToken = accessTokenMatch[1];
		  
		  try {
			// Sending a POST request to exchange the short-lived token for a long-lived one
			const response = await fetch(`${webRoot}facebook/getLongLivedAccessToken`, {
			  method: 'POST',
			  credentials: "include",
			  headers: {
				"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
			},
			  body:  `accessToken=${shortLiveToken}`,
			});
			// Throw an error if the response from the server is not ok
			if (!response.ok) {
			  throw new Error('Network response was not ok');
			}
	
			// Parsing the JSON response from the server
			const results = await response.json();
			// Initializing the list of pages with a default 'Select Facebook page' option
			const plist = [{id: 0, value: "Select Facebook page"}];
			const pages = results.pages;
			const accounts = {};
			
			// Iterating over each account in the pages object to populate plist and accounts
			pages.accounts.data.forEach((account) => {
			  accounts[account.id] = {token: account.access_token, page: account.name};
			  plist.push({id: account.id, name: `${account.category} - ${account.name}`});
			});
	
			// Updating the component's state with the fetched data
			this.setState({
			  pages: plist,
			  pageName: "",
			  pageID: "",
			  userAccessToken: results.userAccessToken,
			  userEmail: results.pages.email,
			  showAuthButton: false,
			  rawAccountList: accounts,
			  showAuthorise: false
			});
	
			// Updating parent component's state with the new data
			this.props.onChangeAdminInput("accountUserEmail", results.pages.email);
			this.props.onChangeAdminInput("userAccessToken", results.userAccessToken);
		  } catch (error) {
			// Logging an error message if there was an issue with the fetch request
			console.error('Error loading data from: facebook/getLongLivedAccessToken', error);
		  }
		});
	  }


	handleAuth = () => {
		window.FB.login((response) => {
			if (response.authResponse) {
			  console.log("Welcome! Fetching your information.... ", response.authResponse.accessToken);
			  this.handleTokenExchange(response.authResponse.accessToken);
			} else {
			  console.log("User cancelled login or did not fully authorize.");
			}
		  }, {scope: 'email,public_profile,pages_messaging,pages_manage_metadata,pages_read_engagement,pages_manage_engagement,pages_read_user_content,business_management'});
		// var appID = "726842812894114";
		// var path = "https://www.facebook.com/dialog/oauth?";
		// var AccessTokenHandlerPageURL = webRoot + "media/facebook/handletoken";
		// var queryParameters = [
		// 		"client_id=" + appID,
		// 		"redirect_uri=" + AUTHSERVERURL + "fbaccesstoken",
		// 		"response_type=token",
		// 		"display=popup",
		// 		"state=" + AccessTokenHandlerPageURL,
		// 		"scope=manage_pages,pages_messaging,email,pages_manage_metadata,pages_read_engagement,pages_manage_engagement,pages_read_user_content,publish_pages"
		// 	];
		// var query = queryParameters.join("&");
		// var url = path + query;
		// if(this.handleFetchKey) {
		// 	this.handleFetchKey();
		// }
		// windowPopupCenter(url, 400, 580);
	}
	handleTokenExchange = async (shortLiveToken) => {
		try {
		  const response = await fetch(`${webRoot}facebook/getLongLivedAccessToken`, {
			method: 'POST',
			credentials: "include",
			headers: {
			  "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
			},
			body: `accessToken=${shortLiveToken}`,
		  });
	
		  if (!response.ok) {
			throw new Error('Network response was not ok');
		  }
			// Parsing the JSON response from the server
			const results = await response.json();
			// Initializing the list of pages with a default 'Select Facebook page' option
			const plist = [{id: 0, value: "Select Facebook page"}];
			const pages = results.pages;
			const accounts = {};
			
			// Iterating over each account in the pages object to populate plist and accounts
			pages.accounts.data.forEach((account) => {
			accounts[account.id] = {token: account.access_token, page: account.name};
			plist.push({id: account.id, name: `${account.category} - ${account.name}`});
			});

			// Updating the component's state with the fetched data
			this.setState({
			pages: plist,
			pageName: "",
			pageID: "",
			userAccessToken: results.userAccessToken,
			userEmail: results.pages.email,
			showAuthButton: false,
			rawAccountList: accounts,
			showAuthorise: false
			});

			// Updating parent component's state with the new data
			this.props.onChangeAdminInput("accountUserEmail", results.pages.email);
			this.props.onChangeAdminInput("userAccessToken", results.userAccessToken);
		
		} catch (error) {
		  console.error('Error during token exchange', error);
		}
	  }
	handlePage = (value, field, key) => {
		this.setState({pageID: value });
		let els = [value];

		if(els.length > 0 && els[0] != 0){
			let spa = this.state.rawAccountList[els[0]]
			this.setState({pageID: els[0], accountAccessToken: spa.token, pageName: spa.page, errPageEmpty: ""});
			this.props.onChangeAdminInput("accessToken",spa.token);
			this.props.onChangeAdminInput("pageID",els[0]);
			this.props.onChangeAdminInput("pageName",spa.page,);
			
		}else{
			this.setState({pageID: els[0], accountAccessToken: "", pageName: ""});
			this.props.onChangeAdminInput("accessToken","");
			this.props.onChangeAdminInput("pageID",els[0]);
			this.props.onChangeAdminInput("pageName", "");
		}
	}

	handleFieldWarning = (e) => {
		let field = e.target.name;
		let reqInputs = this.state.reqInputs;
		reqInputs = update(reqInputs,{
			[field]: {
				touched:{$set: true}
			},
		})
		this.setState({reqInputs:reqInputs})
	}

	handleSubmit = (e) => {
		e.preventDefault();
		let reqInputs = this.state.reqInputs;
		let input = this.props.input;
		let valid = true;
		//check area validity
		if (input.selectedArea === "0") {
			return alert(I("Please select area"));
		}
		$.each(reqInputs, (field, v)=>{
			//set required inputs touched
			reqInputs = update(reqInputs,{
				[field]: {
					touched:{$set: true}
				},
			})
		});
		this.setState({reqInputs:reqInputs})
		//check if empty
		if(input.name === "" || input.name === undefined) {
			valid = false;
		} else if (input.pageID === "" || input.pageID === undefined) {
			return alert(I("Page is not valid. Ensure authorized Facebook page is selected."))
		}
		if(valid){
			this.props.onSave();
		} else {
			alert(FORM_SUBMIT_EMPTY_WARNING);
		}
	}

	render() {
		const {
			areaList
			, input
			, onHandleTextInputChange
			, hidden
			, onHandleTextInputBlur
			, isNew
			, baseButtons
		} = this.props;

		let selectedArea = parseInt(input.selectedArea, 10);
		const field = this.state.reqInputs;
		const warnTxt = INPUT_EMPTY_WARNING;


		const helperForName = <div>
			<div>{I("This is the name that will be displayed in the list of accounts. This name is only used to help you identify the account.")}</div>
		</div>

		const helperForEnable = <div>
			<div>{I("If the account is enabled the system will fetch posts and comments from your Facebook page wall.")}</div>
			<div>{I("If it is not enabled the system will not do anything with the account.")}</div>
		</div>

		const helperForAuthorization = <div>
			<div>{I("For the system to be able to manage your Facebook page you need to give the Cention Facebook application permission to do that.")}</div>
			<br/>
			<div>{I("When you click the button a new window will open where you will be asked to login to Facebook. After you have logged in you will be asked to accept the permissions the Cention Facebook application requires to manage your page.")}</div>
			<div>{I("Click the Authorize Application button to start the authorization process.")}</div>
			<br/>
			<div>{I("Click the Authorize button to start the authorization process.")}</div>
		</div>

		const hasPages = this.state.pages.length > 0;

		return (
			<form onSubmit={this.handleSubmit} className="admin-one-form edit-admin-form" hidden={hidden}>
				<ToggleSwitch
					id={"enable"}
					name={"enable"}
					data-qa-id="enableFacebook"
					label={I("Activated")}
					checked={input.enable}
					onClick={this.handleToggle}
					listWrap={false}
					className="admin-text-input"
					helperTxt={helperForEnable}
				/>
				<CustomInputRow
					id={"area"}
					name={"area"}
					label={I('Area')}
					className={"admin-dropdown-select"}
					mandatory={true}
				>
					<UnnestedAreaSingleSelect
						data={areaList}
						invalidSelectedText={TXT_NO_SELECTION}
						onSelect={this.handleSelectArea}
						selected={selectedArea}
						nested={{key: 'Areas'}}
						idFields={{id: "Id", name: "Name"}}
					/>
				</CustomInputRow>
				<TextInputRow
					id={"name"}
					name={"name"}
					className="admin-text-input"
					label={I("Name")}
					value={input.name || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.name && field.name.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
					helperTxt={helperForName}
				/>
				{this.state.showAuthButton &&
				<CustomInputRow
					id={"fbAuthorization"}
					name={"fbAuthorization"}
					label={I("Authorization")}
					className={"admin-auth-button"}
					helper={helperForAuthorization}
					mandatory={true}
				>
					<Button className="btn-outline blue btn-icon-text"
						outline
						color="primary"
						onClick={this.handleAuth}
						title={this.state.authorizationStr}
					>
						<i className={"icon-v5-facebook"} />
						<span className="label">{this.state.authorizationStr}</span>
					</Button>
				</CustomInputRow>}
				{hasPages &&
					<SelectInputRow
						id={"pageID"}
						name={"pageID"}
						label={I('Facebook page')}
						className={"admin-select-review-option"}
						option={this.state.pages}
						value={this.state.pageID}
						onSelect={this.handlePage}
						mandatory={true}
						warning={(!input.pageID && field.pageID.touched) && warnTxt}
					/>
				}
				<CustomInputRow
					id={"fetchOption"}
					name={"fetchOption"}
					label={I("Fetch options")}
					className={"admin-fetch-option"}
					mandatory={true}
				>
					<SquareCheckbox
						id={"canFetchWall"}
						data-qa-id={"canFetchWall"}
						checked={input.canFetchWall}
						onClick={this.handleToggle}
						label={"Walls"}
						className={"v5-checkbox"}
					/>
					<SquareCheckbox
						id={"canFetchPM"}
						data-qa-id={"canFetchPM"}
						checked={input.canFetchPM}
						onClick={this.handleToggle}
						label={"PMs"}
						className={"v5-checkbox"}
					/>
					<SquareCheckbox
						id={"canFetchAd"}
						data-qa-id={"canFetchAd"}
						checked={input.canFetchAd}
						onClick={this.handleToggle}
						label={"Adverts"}
						className={"v5-checkbox"}
					/>
					<SquareCheckbox
						id={"canFetchEvent"}
						data-qa-id={"canFetchEvent"}
						checked={input.canFetchEvent}
						onClick={this.handleToggle}
						label={"Events"}
						className={"v5-checkbox"}
					/>
				</CustomInputRow>
				{baseButtons}
			</form>
		)
	}
}
