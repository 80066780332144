import React, { PureComponent } from 'react';
import { Button } from 'reactstrap';
import { I, webRoot } from '../../../common/v5/config';
import { Area } from '../../../components/v5/ManualErrand';
import {
	TextInputRow,
	SelectInputRow,
	ReadOnlyTextField,
	CustomInputRow
} from '../../../reactcomponents/Form';
import {
	LabelToggleSwitch as ToggleSwitch
} from '../../../components/v5/ToggleSwitch';
import { Spinner } from '../../../reactcomponents/Spinner'
import { INPUT_EMPTY_WARNING, FORM_SUBMIT_EMPTY_WARNING } from "../../../common/v5/constants"
import update from 'immutability-helper';
import { windowPopupCenter } from '../../../common/v5/helpers';
import { UnnestedAreaSingleSelect } from '../../../reactcomponents/Dropdown';
import { TXT_NO_SELECTION } from '../../../common/v5/receiptGreetingConstants';

const helperForName = <div>
	<div>{I("This is the name that will be displayed in the list of accounts. This name is only used to help you identify the account.")}</div>
</div>

const helperForArea = <div>
	<div>{I("An area must be chosen because the authenticated LinkedIn account needs to be connected with an area.")}</div>
</div>

const helperForEnable = <div>
	<div>{I("If the account is enabled the system will fetch posts and comments from the LinkedIn page.")}</div>
	<div>{I("If it is not enabled the system will not do anything with the account.")}</div>
</div>

const helperForAuthorization = <div>
	<div>{I("For the system to be able to manage your LinkedIn Timeline you need to give the Cention LinkedIn application permission to do that.")}</div>
	<br/>
	<div>{I("When you click the button a new window will open where you will be asked to login to LinkedIn. After you have logged in you will be asked to accept the permissions the Cention LinkedIn application requires to manage your timeline.")}</div>
	<br/>
	<div>{I("Click the Authorize button to start the authorization process.")}</div>
</div>

const helperForCompany = <div>
	<div>{I("Here you choose which LinkedIn company you should manage within Cention Workflow.")}</div>
	<br/>
	<div>{I("Cention Workflow will automatically fetch all posts and comments made after you have activated your account so that you can immediately start answering questions.")}</div>
	<br/>
	<div>{I("Please Note")}</div>
	<div>{I("If no LinkedIn pages were found with the LinkedIn account you used to authenticate with, it either means your LinkedIn account does not have any pages it can manage, or that an error occurred.")}</div>
	<br/>
	<div>{I("If the problem persists and you are confident that your LinkedIn account has pages it can manage, please contact Cention Support.")}</div>
</div>


const LnAuthButton = ({label, onFetchKey}) => {
	const handleClick = () => {
		const msg = I("LinkedIn authentication failed! Please contact Cention Support.");
		let callbackURL = webRoot + "media/linkedin/handletoken";
		$.get(webRoot + 'linkedin/accesstoken', {redURL: callbackURL})
		.done(function( oauthUrl ) {
			console.log("url=" + oauthUrl);
			if( oauthUrl != "" ){
				windowPopupCenter(oauthUrl, 890, 600);
			}else{
				alert(msg);
			}
		})
		.fail(function(err){
			alert(msg);
		});
		if(onFetchKey) {
			onFetchKey();
		}
	}
	return (
		<Button className="btn-outline blue btn-icon-text"
			outline
			color="primary"
			onClick={handleClick}
			title={label}
		>
			<i className={"icon-member"} />
			<span className="label">{label}</span>
		</Button>
	);
};

export default class AccountLinkedInForm extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			companyId: "",
			companyName: "",
			company: [],
			pageLoading: false,
			authorizationStr: I("Authorize"),
			showAuthButton: true,
			reqInputs: {
				name: {touched:false},
			}
		};

	}

	componentDidMount = () => {
		let ds = this.props.input;
		if(typeof ds.id !== 'undefined' && ds.id > 0){
			this.setState({
				companyId: ds.companyId,
				company: [{id:ds.companyId, name: ds.companyName}],
				companyName: ds.companyName,
				authorizationStr: I("Re-authorize"),
			});
		}
	}

	componentWillUnmount = () => {
		$("body").off('lnins.authorization.accounts');
	}


	handleSelectArea = (val) => {
		this.props.onChangeAdminInput("selectedArea", val, this.props.view);
	}
	

	handleToggle = (value, field) => {
		this.props.onChangeAdminInput(field, value)
	}

	handleCompany = ( value ) => {
		let els = [value];
		if(els.length > 0 && els[0] != 0){
			let cm = this.companyList[els[0]]
			this.setState({companyId: els[0], companyName: cm, errCompanyEmpty: ""});
			this.props.onChangeAdminInput("companyId", els[0].toString());
			this.props.onChangeAdminInput("companyName",cm);
		}else{
			this.setState({companyId: els[0], companyName: cm});
		}
	}

	getParameterByName = (name, url) => {
		if (!url) url = window.location.href;
		name = name.replace(/[\[\]]/g, "\\$&");
		var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, " "));
	}

	companyList = {}

	handleAuth = (e) => {
		$("body").on('lnins.authorization.accounts', function(e,rd) {
			const msg = I("We are really sorry, LinkedIn authentication failed! Please contact Cention Support.");
			let url = rd.split("?")[0];
			let authCode = this.getParameterByName("code", rd);
			this.setState({pageLoading:true})
			if(authCode != null){
				$.get(webRoot + 'linkedin/callback',{code: authCode, redirectURL: url })
				.done(function( data ) {
					console.log("data=", data);
					let clist = [{id: 0, value: I("Select LinkedIn company")}];
					$.each(data.company, (i, comp) => {
						this.companyList[comp.id] = comp.name;
						clist.push({id: comp.id, name: comp.name});
					});
					this.setState({
						id: data.id,
						company: clist,
						pageLoading: false,
						accountName: data.info,
						showAuthButton: false});
					this.props.onChangeAdminInput("id",data.id);
					this.props.onChangeAdminInput("accountName",data.info);
				}.bind(this))
				.fail(function(err){
					alert( msg );
				});
			}else{
				alert(msg);
			}
		}.bind(this));
	}

	handleFieldWarning = (e) => {
		let field = e.target.name;
		let reqInputs = this.state.reqInputs;
		reqInputs = update(reqInputs,{
			[field]: {
				touched:{$set: true}
			},
		})
		this.setState({reqInputs:reqInputs})
	}

	handleSubmit = (e) => {
		e.preventDefault();
		let reqInputs = this.state.reqInputs;
		let input = this.props.input;
		let valid = true;
		if (input.selectedArea === "0" || input.selectedArea.length < 1) {
			return alert(I("Please select area"));
		}
		$.each(reqInputs, (field, v)=>{
			//set required inputs touched
			reqInputs = update(reqInputs,{
				[field]: {
					touched:{$set: true}
				},
			})
			//check if empty
			if(input[field] === "" || input[field] === undefined) {
				valid = false;
			}
		});
		this.setState({reqInputs:reqInputs})
		if(input.companyName === "" || input.companyName === undefined) {
			return alert(I("Page is not valid. Ensure authorized LinkedIn account is selected."))
		}
		if(valid){
			this.props.onSave();
		} else {
			alert(FORM_SUBMIT_EMPTY_WARNING);
		}
	}

	render() {
		const {
			areaList
			, input
			, onHandleTextInputChange
			, hidden
			, baseButtons
		} = this.props;

		let selectedArea = parseInt(input.selectedArea, 10);

		const st = this.state;
		const field = st.reqInputs;
		const warnTxt = INPUT_EMPTY_WARNING;
		const hasCompany = this.state.company.length > 0;

		return (
			<form onSubmit={this.handleSubmit} className="admin-one-form edit-admin-form" hidden={hidden}>
				<CustomInputRow
					id={"area"}
					name={"area"}
					label={I('Area')}
					className={"admin-dropdown-select"}
					mandatory={true}
					helper={helperForArea}
				>
					<UnnestedAreaSingleSelect
						data={areaList}
						invalidSelectedText={TXT_NO_SELECTION}
						onSelect={this.handleSelectArea}
						selected={selectedArea}
						nested={{key: 'Areas'}}
						idFields={{id: "Id", name: "Name"}}
					/>
				</CustomInputRow>
				<TextInputRow
					id={"name"}
					name={"name"}
					className="admin-text-input"
					label={I("Name")}
					value={input.name || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.name && field.name.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
					helperTxt={helperForName}
				/>
				<ToggleSwitch
					id={"enable"}
					name={"enable"}
					data-qa-id="enableLinkedIn"
					label={I("Activated")}
					checked={input.enable}
					onClick={this.handleToggle}
					listWrap={false}
					className="admin-text-input"
					helperTxt={helperForEnable}
				/>
				{st.showAuthButton &&
				<CustomInputRow
					id={"linkedInAuthorization"}
					name={"linkedInAuthorization"}
					label={I("Authorization")}
					className={"admin-auth-button"}
					helper={helperForAuthorization}
					mandatory={true}
				>
					<LnAuthButton onFetchKey={this.handleAuth} label={st.authorizationStr}/>
				</CustomInputRow>}
				{hasCompany &&
				<SelectInputRow
					id={"companyId"}
					name={"companyId"}
					label={I('LinkedIn company')}
					className={"admin-select-review-option d-flex"}
					option={st.company}
					value={st.companyId}
					onSelect={this.handleCompany}
					helper={helperForCompany}
				/>}
				{st.pageLoading
				? <div className='center p-3'><Spinner/></div>
				: <ReadOnlyTextField
					id={"accountName"}
					name={"accountName"}
					className="admin-text-input"
					label={I("LinkedIn account")}
					value={input.accountName || ""}
					hidden={!input.accountName}
				/>}
				{baseButtons}
			</form>
		)
	}
}