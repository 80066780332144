import React, {
	Fragment
	, PureComponent
	, memo
	, useCallback
	, useEffect
	, useMemo
} from 'react';
import TagsInput from 'react-tagsinput';
import { I, webRoot } from '../../../common/v5/config';
import { Area } from '../../../components/v5/ManualErrand';
import {
	TextInputRow,
	CustomInputRow
} from '../../../reactcomponents/Form';
import {
	LabelToggleSwitch as ToggleSwitch
} from '../../../components/v5/ToggleSwitch';
import { INPUT_EMPTY_WARNING, FORM_SUBMIT_EMPTY_WARNING } from '../../../common/v5/constants';
import update from 'immutability-helper';
import { UnnestedAreaSingleSelect } from '../../../reactcomponents/Dropdown';
import { TXT_NO_SELECTION } from '../../../common/v5/receiptGreetingConstants';

const helperForArea = <div>
	<div>{I("An area must be chosen because the authenticated Viber account needs to be connected with an area.")}</div>
</div>
export default class AccountViberForm extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			myState: "default",
			testingToken: false,
			reqInputs: {
				name: {touched:false},
				accountId: {touched:false},
				accountName: {touched:false},
				token: {touched:false},
				webhookUrl: {touched:false}
			}
		};

	}

	componentDidMount = () => {
		if(!this.props.wfId) {
			this.setState({myState: "user"});
		}
		let ds = this.props.input;
		if(typeof ds.id !== 'undefined' && ds.id > 0){
			this.setState({
				id: ds.id,
				myState: "user",
				enable: ds.enable,
				vbC3Id: ds.id, //db uniqueID
                name: ds.name,
				accountId: ds.accountId, //vb bot uniqueID
				accountName: ds.accountName, //vb bot username
				token: ds.token,
				webhookUrl: ds.webhookUrl,
				connectedArea: ds.connectedArea,
				oldAreaId: ds.connectedArea
			});
		}
	}

	handleSelectArea = (val) => {
		this.props.onChangeAdminInput("selectedArea", val, this.props.view);
	}
	

	handleToggle = (value, field) => {
		this.props.onChangeAdminInput(field, value)
	}

	handleFieldWarning = (e) => {
		let field = e.target.name;
		let reqInputs = this.state.reqInputs;
		reqInputs = update(reqInputs,{
			[field]: {
				touched:{$set: true}
			},
		})
		this.setState({reqInputs:reqInputs})
	}

	noEmpty = () => {
		let reqInputs = this.state.reqInputs;
		let valid = true;
		$.each(reqInputs, (field, v)=>{
			if(input[field] === "" || input[field] === undefined) {
				valid = false;
			}
		});
		return valid;
	}

	noEmptyInput = () => {
		// e.preventDefault();
		let reqInputs = this.state.reqInputs;
		let input = this.props.input;
		let valid = true;
		$.each(reqInputs, (field, v)=>{
			//set required inputs touched
			reqInputs = update(reqInputs,{
				[field]: {
					touched:{$set: true}
				},
			})
			//check if empty
			if(input[field] === "" || input[field] === undefined) {
				valid = false;
			}
		});
		this.setState({reqInputs:reqInputs})
		return valid;
	}

	handleTest = () =>{
		if(this.noEmptyInput()) {
			this.setState({testingToken:true});
			const { input } = this.props;
			$.ajax({
				url: webRoot + 'viber/account/test',
				data: {
					accountId: input.accountId,
					accountName: input.accountName,
					token: input.token,
					webhookUrl: input.webhookUrl
				},
				cache: false,
				type: "GET",
				success: function(data) {
					this.setState({testingToken:false});
					if(data.name) {
						alert("Success token: "+data.token_ok+" name: "+data.name);
					} else {
						alert("! FAILED/invalid token: "+data.token_ok);
					}
				}.bind(this),
				error: function(xhr) {
					this.setState({testingToken:false});
					if(xhr.responseJSON) {
						alert("! FAILED/invalid token: "+xhr.responseJSON.status);
					} else {
						console.log("Error loading JSON: ", xhr);
					}
				}.bind(this)
			});
		} else {
			alert(FORM_SUBMIT_EMPTY_WARNING);
		}
	};

	handleSubmitFrom =(e)=> {
		e.preventDefault();
		let reqInputs = this.state.reqInputs;
		let input = this.props.input;
		let valid = true;
		//check area validity
		if (input.selectedArea === "0" || input.selectedArea.length < 1) {
			return alert(I("Please select area"));
		}
		$.each(reqInputs, (field, v)=>{
			//set required inputs touched
			reqInputs = update(reqInputs,{
				[field]: {
					touched:{$set: true}
				},
			})
			//check if empty
			if(input[field] === "" || input[field] === undefined) {
				valid = false;
			}
		});
		this.setState({reqInputs:reqInputs})
		if(valid){
			this.props.onSave();
		} else {
			alert(FORM_SUBMIT_EMPTY_WARNING);
		}
	}

	render() {
		const {
			areaList
			, input
			, onHandleTextInputChange
			, hidden
			, isNew
			, baseButtons
		} = this.props;

		let selectedArea = parseInt(input.selectedArea, 10);
		const st = this.state;
		const field = this.state.reqInputs;
		const warnTxt = INPUT_EMPTY_WARNING;

		return (
			<form onSubmit={this.handleSubmitFrom} className="admin-one-form edit-admin-form" hidden={hidden}>
				<CustomInputRow
					id={"area"}
					name={"area"}
					label={I('Area')}
					className={"admin-dropdown-select"}
					mandatory={true}
					helper={helperForArea}
				>
					<UnnestedAreaSingleSelect
						data={areaList}
						invalidSelectedText={TXT_NO_SELECTION}
						onSelect={this.handleSelectArea}
						selected={selectedArea}
						nested={{key: 'Areas'}}
						idFields={{id: "Id", name: "Name"}}
					/>
				</CustomInputRow>
				<TextInputRow
					id={"name"}
					name={"name"}
					className="admin-text-input"
					label={I("Name")}
					value={input.name || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.name && field.name.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
				/>
				<ToggleSwitch
					id={"enable"}
					name={"enable"}
					data-qa-id="enableLine"
					label={I("Activated")}
					checked={input.enable}
					onClick={this.handleToggle}
					listWrap={false}
					className="admin-text-input"
				/>
					<TextInputRow
					id={"accountId"}
					name={"accountId"}
					className="admin-text-input"
					label={I("BOT ID")}
					value={input.accountId || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.accountId && field.accountId.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
				/>
				<TextInputRow
					id={"accountName"}
					name={"accountName"}
					className="admin-text-input"
					label={I("BOT username")}
					value={input.accountName || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.accountName && field.accountName.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
				/>
				<TextInputRow
					id={"token"}
					name={"token"}
					className="admin-text-input"
					label={I("Access token")}
					value={input.token || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.token && field.token.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
				/>
				<TextInputRow
					id={"webhookUrl"}
					name={"webhookUrl"}
					className="admin-text-input"
					label={I("Webhook URL")}
					value={input.webhookUrl || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.webhookUrl && field.webhookUrl.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
				/>
				<div className="label-row">
					<div className="label-block">
						<div className="label-admin-text-input">
							<label htmlFor="admin-text-input">{I("Test Token")}</label>
						</div>
						{st.testingToken 
						? <div>{I("Testing ...")}</div>
						: <button type="button" className="btn-blue viber-test-btn" title="Test" data-qa-id="btn-Test" onClick={this.handleTest}>{I("Test")}</button>
						}
					</div>
				</div>
				{baseButtons}
			</form>
		)
	}
}