import React, {
	Fragment
	, PureComponent
	, memo
	, useCallback
	, useEffect
	, useMemo
} from 'react';
import {
	Modal
	, ModalHeader
	, ModalBody
	, Collapse
	, Card
	, CardBody
} from 'reactstrap';
import classNames from 'classnames';
import TagsInput from 'react-tagsinput';
import { I, webRoot } from '../../../common/v5/config';
import withAccount from "./accountsCtnr"
import { Authorization } from '../../accounts/email/edit'
import {
	AuthorizedToken,
	ControlAuthorizationSelect,
	justAuthorizedTokenProvider
} from '../../../reactcomponents/Authorization';
import { Area } from '../../../components/v5/ManualErrand';
import { isNumberOrNumberStringEqual } from  '../../../common/helpers';
import {
	Label,
	TableIconicButton,
	FormInputWithLabelRow,
	TextInputRow,
	SelectInputRow,
	CustomInputRow,
	AvatarInputRow,
	HelperTooltip,
	withRow,
	CheckboxInputRow,
	ReadOnlyTextField
} from '../../../reactcomponents/Form';
import { UnnestedAreaSingleSelect } from '../../../reactcomponents/Dropdown';
import {
	LabelToggleSwitch as ToggleSwitch
} from '../../../components/v5/ToggleSwitch';
import { INPUT_EMPTY_WARNING, FORM_SUBMIT_EMPTY_WARNING } from '../../../common/v5/constants'
import update from 'immutability-helper';
import { TXT_NO_SELECTION } from '../../../common/v5/receiptGreetingConstants';


const oAuth2ProtocolMap = {
	ews: true,
	imap: true,
	msgraphapi: true,
}

const getProtocolTypeDatabaseId = ( dataSrc ) => {
	if (dataSrc) {
		const { accountTypes, typeId } = dataSrc
		if (typeof typeId !== 'undefined') {
			return typeId
		} else if (accountTypes && accountTypes.length) {
			return accountTypes[0].id
		}
	}
	console.log('this is considered error because we can not tell confidently if databse-id 2 is always IMAP!')
	return 2
}

const protocolSupportsOAuth2 = protocol => oAuth2ProtocolMap[protocol]

//Tofix: Optimize this component. Currently this "Email Test" component is just an exact copy from old page.
class EmailTest extends React.Component {
	state = {
		hColor: "",
		mColor: "",
		cColor: "",
		hnameMsg: I("Checking if mail server exists..."),
		connectMsg: I("Checking if it is possible to connect to mail server..."),
		mailboxMsg: I("Verifying if username and password are correct...")
	};
	MailAccountID = 0;
	goCheckIfDomainExists() {
		$.get(webRoot + 'admin/areas/mailaccounts/chkdomain/' + this.MailAccountID).done(function(jdata) {
			this.setState({hnameMsg: jdata.message, hColor: jdata.colour});
			this.goCheckIfServerExists();
		}.bind(this));
	}
	goCheckIfServerExists() {
		$.get(webRoot + 'admin/areas/mailaccounts/chkserver/' + this.MailAccountID).done(function(jdata) {
			this.setState({connectMsg: jdata.message,cColor: jdata.colour});
			this.goCheckIfCanConnect();
		}.bind(this));
	}
	goCheckIfCanConnect() {
		$.get(webRoot + 'admin/areas/mailaccounts/chkconnect/' + this.MailAccountID).done(function(jdata) {
			this.setState({mailboxMsg: jdata.message, mColor: jdata.colour});
		}.bind(this));
	}
	componentDidMount(){
		this.MailAccountID = this.props.mId;
		window.setTimeout(function(){
			$("#HeaderContainer").hide();
			this.goCheckIfDomainExists();
		}.bind(this), 100)
	}
	render = () =>{
		return (<div>
					<div>
						<div id="HostnameInformationBox" style={{fontWeight: 'bold',padding: '5px',fontSize:'12px',background:'#fffc96'}}>
						<span id="HostnameInformation" style={{backgroundColor: this.state.hColor}}>{this.state.hnameMsg}</span>
					</div>

					<div id="ConnectInformationBox" style={{fontWeight: 'bold',fontSize:'12px',padding: '5px',background:'#fffc96'}} >
						<span id="ConnectInformation" style={{backgroundColor: this.state.cColor}}>{this.state.connectMsg}</span>
					</div>

					<div id="MailboxInformationBox" style={{fontWeight: 'bold',fontSize:'12px',padding: '5px',background:'#fffc96'}}>
						<span id="MailboxInformation" style={{backgroundColor: this.state.mColor}}>{this.state.mailboxMsg}</span>
					</div>
				</div>
			</div>
		)
	}
}

class EmailTestPopup extends React.Component {
	constructor(props) {
		super(props);
	}

	handleToggle = () => {
		// this.props.onToggleEmailTestPopup/(false);
		this.props.onClosePopUp();
	}

	render(){
		const {
			showEmailTestPopup,
			input,
		} = this.props
		return (
			//<div>
				<Modal
					isOpen={showEmailTestPopup}
					className="mail-test"
					// toggle={this.handleToggle}
				>
					<ModalHeader toggle={this.handleToggle}>
					{I("Mail account testing")}
					</ModalHeader>
					<ModalBody>
						<EmailTest mId={input.id}/>
					</ModalBody>
				</Modal>
			//</div>
		)
	}
}

const AdvanceTab = ({label, show, icon, onClick, ...p}) => {
	return (
		<div className="show-advance-wrapper">
			<div className={classNames("show-advance-label-row",{"active": show})}
				data-qa-id={"advance-settings"}
				onClick={onClick}
			>
				<span>{label}</span>
				<i className={icon}/>
			</div>
			<Collapse isOpen={show}>
				<Card>
					<CardBody>
						{p.children}
					</CardBody>
				</Card>
			</Collapse>
		</div>
	)
}

export const EmailTestPopupCtnr = withAccount(EmailTestPopup);
//todo:rename this fn
const getEmailSearchTags = (p) => {
	let tags = p.emailAddressSearchTerm;
	if (tags && tags.length){
		//remove any default empty string from backend
		if(tags[0] === "" && tags.length <= 1) {
			return []
		}else {
			return tags
		}
	}
	return [];
}

const nameUpdater = name => ({name, errNameEmpty: ""});
export class AccountEmailForm extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			autoReply: 0,
			autoReplyActiveFrom: "",
			autoReplyActiveTo: "",
			autoReplyAnswerNote: "",
			autoReplyForwardAddress: "",
			autoReplyMaxAnswersPerReply: 0,
			autoReplyUnanswered: 0,
			contentTransformation: "",
			contentTransformationSubject: "",
			emailAddressSearchTerm: [],
			enabled: false,
			host: "",
			id: 0,
			name: "",
			// null = no active token
			// empty string = remove previous token
			// non-empty-string = fresh authorized token
			oauthAccessToken: null,
			oauthExpiry: "",
			oauthProtocolType: null,
			oauthProvider: null,
			oauthRefreshToken: "",
			oauthTokenType: "",
			receipt: 0,
			receiptActiveFrom: "00:00",
			receiptActiveTo: "23:59",
			receiptBody: "",
			receiptEmailAddress: "",
			receiptSubject: "",
			receiptOutOfActiveHourSelected: 0,
			receiptSubjectOutOfActiveHour: "",
			receiptBodyOutOfActiveHour: "",
			reoccuringReceipt: 0,
			reoccuringReceiptsBody: "",
			reoccurringReceiptSubject: "",
			reoccurringReceiptInterval: 0,
			typeId: getProtocolTypeDatabaseId(props.input),
			useSecureMessage: false,
			username: "",
			wantSSL: false,
			retainFetchedEmail: false,
			allowImapDelete: false,
			fetchSeenMessages: false,
			allowDelEmptyEmail: false,
			centionDelete: false,
			readTimeout: 0,
			connectedArea: "",
			oldAreaId: 0,
			receiptShowHide: false,
			receiptTime: false,
			receiptOutShowHide: false,
			reocShowHide: false,
			haveReceipt: false,
			saving: false,
			errAreaEmpty: "",
			errNameEmpty: "",
			errServerEmpty: "",
			errUserEmpty: "",
			reqInputs: {
				name: {touched:false},
				host: {touched:false},
				username: {touched:false},
				typeId: {touched:false}
			},
			passwordNeeded:false,
			tags: getEmailSearchTags(props.input),
			showAdvance: false
		};
		this.protocolHasAccessToken = this.protocolHasAccessToken.bind(this);
		this.handleRemoveToken = this.handleRemoveToken.bind(this);
	}
	componentDidMount() {
		const ds = this.props.dataSrc
		const { oauthProvider } = ds
		if (typeof ds.id !== 'undefined' && ds.id > 0) {
			this.setState({
				autoReply: ds.autoReply,
				autoReplyActiveFrom: ds.autoReplyActiveFrom,
				autoReplyActiveTo: ds.autoReplyActiveTo,
				autoReplyAnswerNote: ds.autoReplyAnswerNote,
				autoReplyForwardAddress: ds.autoReplyForwardAddress,
				autoReplyMaxAnswersPerReply: ds.autoReplyMaxAnswersPerReply,
				autoReplyUnanswered: ds.autoReplyUnanswered,
				contentTransformation: ds.contentTransformation,
				contentTransformationSubject: ds.contentTransformationSubject,
				emailAddressSearchTerm: getEmailSearchTags(ds),
				enabled: ds.enabled,
				host: ds.host,
				id: ds.id,
				name: ds.name,
				oauthProvider: typeof oauthProvider === 'number' ? oauthProvider : null,
				receipt: ds.receipt,
				receiptActiveFrom: ds.receiptActiveFrom,
				receiptActiveTo: ds.receiptActiveTo,
				receiptBody: ds.receiptBody,
				receiptEmailAddress: ds.receiptEmailAddress,
				receiptSubject: ds.receiptSubject,
				receiptOutOfActiveHourSelected: ds.receiptOutOfActiveHourSelected,
				receiptSubjectOutOfActiveHour: ds.receiptSubjectOutOfActiveHour,
				receiptBodyOutOfActiveHour: ds.receiptBodyOutOfActiveHour,
				reoccuringReceipt: ds.reoccuringReceipt,
				reoccuringReceiptsBody: ds.reoccuringReceiptsBody,
				reoccurringReceiptSubject: ds.reoccurringReceiptSubject,
				reoccurringReceiptInterval: ds.reoccurringReceiptInterval,
				typeId: ds.typeId,
				useSecureMessage: ds.useSecureMessage,
				username: ds.username,
				wantSSL: ds.wantSSL,
				retainFetchedEmail: ds.retainFetchedEmail,
				allowImapDelete: ds.allowImapDelete,
				fetchSeenMessages: ds.fetchSeenMessages,
				allowDelEmptyEmail: ds.allowDelEmptyEmail,
				centionDelete: ds.centionDelete,
				readTimeout: ds.readTimeout,
				connectedArea: ds.connectedArea ? ds.connectedArea.toString() : "",
				oldAreaId: ds.connectedArea,
				receiptShowHide: ds.receipt > 0 ? true : false,
				receiptTime: ds.receipt > 1 ? true : false,
				receiptOutShowHide: ds.receiptOutOfActiveHourSelected > 0 ? true : false,
				reocShowHide: ds.reoccuringReceipt > 0 ? true : false,
				haveReceipt: ds.receipt > 0 ? true : false
			});
		}
	}

	serializeSearchTerm = ( st ) => {
		if(st != null && st.length > 0){
			return st.join(',');
		}
		return "";
	}

	toggleAdvanceSettings = () => {
		this.setState({showAdvance:!this.state.showAdvance})
	}

	handleSelectArea = (val) => {
		this.setState({ connectedArea:[val]})
		this.props.onChangeAdminInput("selectedArea", val, this.props.view);
	}
	//authorization
	updateNameIfEmpty =(nameFromToken)=> {
		// nameFromToken.persist();
		this.setState(({ name }) => {
			if (!name) {
				return nameUpdater(nameFromToken);
			}
		})
		if(!this.props.input.name) {
			this.props.onChangeAdminInput("name", nameFromToken);
		}
	}
	handleSecure =(wantSSL)=> {
		// wantSSL.persist();
		if (typeof wantSSL === 'function') {
			this.setState(
				state => update(
					state,
					{ wantSSL: { $set: wantSSL(state.wantSSL) } }
				)
			)
		} else {
			this.setState({ wantSSL })
		}
	}
	handleHost =(host)=> {
		// host.persist();
		if (typeof host === "function") {
			host = host(this.state.host);
			if (!host) {
				return;
			}
		}
		this.setState({host, errServerEmpty: ""});
		this.props.onChangeAdminInput("host", host);
	}
	updateToken =({ access_token, refresh_token, expiry, provider, token_type })=> {
		this.setState({
			oauthAccessToken: access_token,
			oauthExpiry: expiry,
			oauthProtocolType: this.state.typeId,
			oauthProvider: provider,
			oauthRefreshToken: refresh_token,
			oauthTokenType: token_type
		})
	}
	updateUsername =(username)=> {
		// username.persist();
		this.setState({username, errUserEmpty: ""});
		this.props.onChangeAdminInput("username", username);
	}
	justAuthorizedTokenSameProtocol() {
		return isNumberOrNumberStringEqual(
			this.state.oauthProtocolType,
			this.state.typeId
		)
	}
	justAuthorizedTokenProvider() {
		const provider = justAuthorizedTokenProvider(
			this.state.oauthAccessToken,
			this.state.oauthProvider
		)
		if (typeof provider === 'number' &&
			this.justAuthorizedTokenSameProtocol()) {
			return provider
		}
	}

	handleAccountType = (value, field, key) => {
		this.setState({[field]: value });
		this.props.onHandleChangeData(value, field, key);
	}

	protocolHasAccessToken(token, provider) {
		if (token) {
			return this.justAuthorizedTokenSameProtocol()
		} else if (typeof provider === 'number') {
			return isNumberOrNumberStringEqual(
				this.props.dataSrc.typeId,
				this.state.typeId
			)
		}
		return false
	}
	handleRemoveToken() {
		let emptyAccessToken;
		const ds = this.props.dataSrc;
		if (typeof ds !== 'undefined' && typeof ds.oauthProvider === 'number') {
			emptyAccessToken = ''
		} else {
			emptyAccessToken = null
		}
		this.setState({
			oauthAccessToken: emptyAccessToken,
			oauthExpiry: '',
			oauthProtocolType: null,
			oauthProvider: null,
			oauthRefreshToken: '',
			oauthTokenType: ''
		})
	}
	handleTags = (tags) => {
		this.setState({emailAddressSearchTerm: tags})
		this.props.onChangeAdminInput("emailAddressSearchTerm", tags)
	}
	handleToggle = (value, field) => {

		this.setState({
			[field]: value
		})
		this.props.onChangeAdminInput(field, value)
	}

	handleFieldWarning = (e) => {
		let field = e.target.name;
		let reqInputs = this.state.reqInputs;
		reqInputs = update(reqInputs,{
			[field]: {
				touched:{$set: true}
			},
		})
		this.setState({reqInputs:reqInputs})
	}

	handlePasswordWarning = () => {
		const { isNew } = this.props;
		if(isNew){
			this.setState({passwordNeeded:true})
		}
	}

	handleInputChange = (e) => {
		let field = e.target.name;
		let value = e.target.value;
		this.setState({
			[field]: value
		})
	}

	handleSubmit = (e) => {
		e.preventDefault();
		let reqInputs = this.state.reqInputs;
		const st = this.state;
		const { input, isNew } = this.props;
		let valid = true;
		//check area validity
		if (st.connectedArea === "0" || st.connectedArea.length < 1) {
			return alert(I("Please select area"));
		}
		$.each(reqInputs, (field, v)=>{
			//set required inputs to "touched"
			reqInputs = update(reqInputs,{
				[field]: {
					touched:{$set: true}
				},
			})
			//check if empty
			if(st[field] === "" || st[field] === undefined) {
				valid = false;
			}
		});
		this.setState({reqInputs:reqInputs})
		if(isNew){
			//when "create new", password field is required else invalid
			if(st.password === "" || st.password === undefined) {
				valid = false;
				this.setState({passwordNeeded:true})
			}
		}
		if(valid){
			const saveData = {
				autoReply: this.state.autoReply,
				autoReplyActiveFrom: this.state.autoReplyActiveFrom,
				autoReplyActiveTo: this.state.autoReplyActiveTo,
				autoReplyAnswerNote: this.state.autoReplyAnswerNote,
				autoReplyForwardAddress: this.state.autoReplyForwardAddress,
				autoReplyMaxAnswersPerReply: this.state.autoReplyMaxAnswersPerReply,
				autoReplyUnanswered: this.state.autoReplyUnanswered,
				contentTransformation: this.state.contentTransformation,
				contentTransformationSubject: this.state.contentTransformationSubject,
				emailAddressSearchTerm: this.state.emailAddressSearchTerm.toString(),
				enabled: this.state.enabled,
				host: this.state.host,
				id: this.state.id,
				name: this.state.name,
				receipt: this.state.receipt,
				receiptActiveFrom: this.state.receiptActiveFrom,
				receiptActiveTo: this.state.receiptActiveTo,
				receiptBody: this.state.receiptBody,
				receiptEmailAddress: this.state.receiptEmailAddress,
				receiptSubject: this.state.receiptSubject,
				receiptOutOfActiveHourSelected: this.state.receiptOutOfActiveHourSelected,
				receiptSubjectOutOfActiveHour: this.state.receiptSubjectOutOfActiveHour,
				receiptBodyOutOfActiveHour: this.state.receiptBodyOutOfActiveHour,
				reoccuringReceipt: this.state.reoccuringReceipt,
				reoccuringReceiptsBody: this.state.reoccuringReceiptsBody,
				reoccurringReceiptSubject: this.state.reoccurringReceiptSubject,
				reoccurringReceiptInterval: this.state.reoccurringReceiptInterval,
				typeId: this.state.typeId,
				useSecureMessage: this.state.useSecureMessage,
				username: this.state.username,
				password: this.state.password,
				wantSSL: this.state.wantSSL,
				retainFetchedEmail: this.state.retainFetchedEmail,
				allowImapDelete: this.state.allowImapDelete,
				fetchSeenMessages: this.state.fetchSeenMessages,
				allowDelEmptyEmail: this.state.allowDelEmptyEmail,
				centionDelete: this.state.centionDelete,
				readTimeout: this.state.readTimeout,
				connectedArea: this.state.connectedArea.toString(),
				oldAreaId: this.state.oldAreaId
			};
			if (typeof this.state.oauthAccessToken === 'string') {
				saveData.oauthAccessToken = this.state.oauthAccessToken;
				if (this.state.oauthAccessToken) {
					saveData.oauthExpiry = this.state.oauthExpiry;
					saveData.oauthProvider = this.state.oauthProvider;
					saveData.oauthRefreshToken = this.state.oauthRefreshToken;
					saveData.oauthTokenType = this.state.oauthTokenType;
				}
			}
			this.props.onSave(saveData);
		} else {
			alert(FORM_SUBMIT_EMPTY_WARNING);
		}
	}

	render() {
		const {
			areaList
			, onHandleChangeData
			, input
			, onHandleTextInputChange
			, hidden
			, onHandleTextInputBlur
			, warning
			, routeTags
			, onChangeAdminInput
			, view
			, children
			, oauthConstants
			, isNew
			, baseButtons
			, dataSrc
		} = this.props;

		// const { normalizedAccountType } = input;
		// const typeId = input.id;

		const { normalizedAccountType } = dataSrc;
		const { oauthAccessToken, oauthProvider, typeId, showAdvance } = this.state;
		const st = this.state;
		const protocol = normalizedAccountType[typeId];
		let selectedArea = parseInt(st.connectedArea, 10);

		let accountTypeSelections = dataSrc.accountType.map(account => {
			let properties = {
				id: account.id,
				name: account.value
			};
			return properties;
		});

		const helperSearchTerm = <div>
			<div>{I("By entering a value here the system will automatically search for an email address in the text and make that the errands from address.")}</div>
			<div>{I("Enter the string the system should search for an then put [email] at place were the email address will be.")}</div>
			<div>{I("Example:")}</div>
			<br/>
			<strong>{I("From: [email]")}</strong>
		</div>

		let arrowIcon = showAdvance ? "icon-chevron-up" : "icon-chevron-down";

		const field = st.reqInputs;
		const warnTxt = INPUT_EMPTY_WARNING;
		return (
			<form onSubmit={this.handleSubmit} className="admin-one-form edit-admin-form" hidden={hidden}>
				<CustomInputRow
					id={"area"}
					name={"area"}
					label={I('Area')}
					className={"admin-dropdown-select"}
					mandatory={true}
				>
					<UnnestedAreaSingleSelect
						data={areaList}
						invalidSelectedText={TXT_NO_SELECTION}
						onSelect={this.handleSelectArea}
						selected={selectedArea}
						nested={{key: 'Areas'}}
						idFields={{id: "Id", name: "Name"}}
					/>
				</CustomInputRow>
				<TextInputRow
					id={"name"}
					name={"name"}
					className="admin-text-input"
					label={I("Name")}
					// defaultValue={""}
					value={st.name}
					onChange={this.handleInputChange}
					mandatory={true}
					warning={(!st.name && field.name.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
				/>
				<TextInputRow
					id={"emailServer"}
					name={"host"}
					className="admin-text-input"
					label={I("Server")}
					value={st.host}
					onChange={this.handleInputChange}
					mandatory={true}
					warning={(!st.host && field.host.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
				/>
				<TextInputRow
					id={"emailUsername"}
					name={"username"}
					className="admin-text-input"
					label={I("Username")}
					value={st.username || ""}
					onChange={this.handleInputChange}
					mandatory={true}
					warning={(!st.username && field.username.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
				/>
				<TextInputRow
					id={"emailPassword"}
					name={"password"}
					className="admin-text-input"
					label={I("Password")}
					type={"password"}
					value={st.password || ""}
					onChange={this.handleInputChange}
					mandatory={isNew}
					warning={(!st.password && this.state.passwordNeeded) && warnTxt}
					onBlur={this.handlePasswordWarning}
				/>
				<CustomInputRow
					id={"accountType"}
					name={"accountType"}
					label={I('Account type')}
					className={"inputs-wrapper"}
					mandatory={isNew}
				>
					<SelectInputRow
						id={"typeId"}
						name={"typeId"}
						className={"admin-select-option"}
						option={accountTypeSelections}
						value={st.typeId}
						onSelect={this.handleAccountType}
						warning={(!st.typeId && field.typeId.touched) && warnTxt}
					/>
					<AuthorizedToken
						hasAccessToken={this.protocolHasAccessToken(
							oauthAccessToken,
							oauthProvider
						)}
						hidden={!protocolSupportsOAuth2(protocol)}
						onClear={this.handleRemoveToken}
						provider={oauthProvider}
					/>
				</CustomInputRow>
				<div> 
					<Authorization
						onChangeName={this.updateNameIfEmpty}
						onChangeSecure={this.handleSecure}
						onChangeServer={this.handleHost}
						onChangeToken={this.updateToken}
						onChangeUsername={this.updateUsername}
						protocol={protocol}
						provider={this.justAuthorizedTokenProvider()}
						constants={oauthConstants}
					/>
				</div>
				<ToggleSwitch
					id={"enabled"}
					name={"enabled"}
					data-qa-id="enableEmail"
					label={I("Enabled")}
					checked={st.enabled}
					onClick={this.handleToggle}
					listWrap={false}
					className="admin-text-input"
				/>
				<AdvanceTab
					icon={arrowIcon}
					label={I("Advanced settings")}
					show={showAdvance}
					onClick={this.toggleAdvanceSettings}>
					<ToggleSwitch
						id={"wantSSL"}
						name={"wantSSL"}
						data-qa-id="useSSLEmail"
						label={I("Use SSL")}
						checked={st.wantSSL}
						onClick={this.handleToggle}
						listWrap={false}
						className="admin-text-input"
					/>
					<Fragment>
						<ToggleSwitch
							id={"retainFetchedEmail"}
							name={"retainFetchedEmail"}
							data-qa-id="retainFetchedEmail"
							label={I("Retain fetched email")}
							checked={st.retainFetchedEmail}
							onClick={this.handleToggle}
							listWrap={false}
							className="admin-text-input"
							helperTxt={I("Retain fetched emails")}
						/>
						<ToggleSwitch
							id={"allowImapDelete"}
							name={"allowImapDelete"}
							data-qa-id="allowImapDelete"
							label={I("Allow IMAP delete")}
							checked={st.allowImapDelete}
							onClick={this.handleToggle}
							listWrap={false}
							className="admin-text-input"
							helperTxt={I("Allow IMAP protocol to delete fetched emails")}
						/>
						<ToggleSwitch
							id={"fetchSeenMessages"}
							name={"fetchSeenMessages"}
							data-qa-id="fetchSeenMessages"
							label={I("Fetch seen messages")}
							checked={st.fetchSeenMessages}
							onClick={this.handleToggle}
							listWrap={false}
							className="admin-text-input"
							helperTxt={I("Fetch all emails include those unseen")}
						/>
						<ToggleSwitch
							id={"allowDelEmptyEmail"}
							name={"allowDelEmptyEmail"}
							data-qa-id="allowDelEmptyEmail"
							label={I("Delete empty body email")}
							checked={st.allowDelEmptyEmail}
							onClick={this.handleToggle}
							listWrap={false}
							className="admin-text-input"
							helperTxt={I("Delete all fetched emails that are empty")}
						/>
						<ToggleSwitch
							id={"centionDelete"}
							name={"centionDelete"}
							data-qa-id="centionDelete"
							label={I("Cention delete")}
							checked={st.centionDelete}
							onClick={this.handleToggle}
							listWrap={false}
							className="admin-text-input"
							helperTxt={I("Delete fetched emails on Cention mail servers")}
						/>
						<TextInputRow
							id={"readTimeout"}
							name={"readTimeout"}
							type={"number"}
							className="admin-text-input with-helper"
							label={I("Read timeout")}
							value={(st.readTimeout)}
							onChange={this.handleInputChange}
							onBlur={onHandleTextInputBlur}
							warning={warning}
							helperTxt={I("Low level read timeout (seconds) before unblock")}
						/>
					</Fragment>
					<CustomInputRow
						id={"emailSearchTerm"}
						name={"emailSearchTerm"}
						label={I("Email address search term")}
						className={"admin-tags-input"}
						helper={helperSearchTerm}
					>
						<TagsInput
							className="tags-input-wrapper"
							placeholder="myPlaceholder"
							name="emailAddressSearchTerm"
							addOnBlur={true}
							value={st.emailAddressSearchTerm}
							onChange={this.handleTags}
							onlyUnique={true}
							inputProps={{
								className:"tags-input-field",
								placeholder: I("Add a term")
							}}
						/>
					</CustomInputRow>
					<TextInputRow
						id={"contentTransformationSubject"}
						name="contentTransformationSubject"
						className="admin-text-input with-helper"
						label={I("Content transformation subject")}
						value={st.contentTransformationSubject}
						onChange={this.handleInputChange}
						onBlur={onHandleTextInputBlur}
						warning={warning}
						helperTxt={I("Content transformation subject")}
						// position="right"
					/>
					<TextInputRow
						id={"contentTransformation"}
						name="contentTransformation"
						className="admin-text-input with-helper"
						label={I("Content transformation")}
						value={st.contentTransformation || ""}
						onChange={this.handleInputChange}
						onBlur={onHandleTextInputBlur}
						warning={warning}
						helperTxt={I("Content transformation")}
						// position="right"
					/>
				</AdvanceTab>
				{baseButtons}
			</form>
		)
	}
}
