import React,
{
	PureComponent
} from 'react';
import { I, webRoot } from '../../../common/v5/config';
import { AreaDropdown } from '../../../reactcomponents/Dropdown';
import { BTN_TXT_SAVE, BTN_TXT_SAVING} from '../../../common/v5/constants';
import {
	simplePasswordCheck
} from '../../../common/v5/helpers';
import {
	TextInputRow,
	CustomInputRow,
	ReadOnlyTextField,
	BooleanSelect,
	TableIconicButton,
	SelectInputRow
} from '../../../reactcomponents/Form';
import {
	CancelButton
	, SaveButton
	, EditorFooter
} from '../../../reactcomponents/Admin';
import SearchFilter from '../../../reactcomponents/SearchFilter';
import { centionTitleGrey } from '../../../styles/_variables';

export default class AgentSipLoginForm extends PureComponent {

	constructor(props) {
		super(props);
		this.state = {
			pwdWarnMsg: ""
			, firstFilteredName: ""
		}
		this.handleChangeData = this.handleChangeData.bind(this);
		this.handleSaveAgent = this.handleSaveAgent.bind(this);
		this.handleCheckPassword = this.handleCheckPassword.bind(this);
		this.checkPassword = this.checkPassword.bind(this);
		this.checkFields = this.checkFields.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleFilteredList = this.handleFilteredList.bind(this);
	}

	handleChangeData = (e) => {
		const { value, name } = e.target
		this.props.onChangeAdminInput(name, value);
	}

	handleDelete = (id) => {
		this.props.onDelete(id);
	}

	handleFilteredList = (agent) => {
		let loginName = "";
		if(typeof agent !== "undefined" && agent != null &&
			agent.length > 0){
			loginName = agent[0].loginname;
		}
		this.setState({firstFilteredName: loginName})
	}

	checkFields = () => {
		this.checkPassword(this.props.input.password);
		if(this.state.pwdWarnMsg.length > 0){
			return false;
		}
		if(this.state.firstFilteredName == "" &&
			(typeof this.props.input.loginname == "undefined" ||
			this.props.input.loginname.length == 0)){
			console.info("no login name");
			return false;
		}
		if(typeof this.props.input.linkedextension == "undefined" ||
			this.props.input.linkedextension.length == 0){
			console.info("no linked extension");
			return false;
		}

		return true;
	}

	checkPassword = (password) => {
		let newMsg = "";
		if(password == null || password.length == 0){
			newMsg = "invalid password length";
		} else if(simplePasswordCheck(password) == false){
			newMsg = "failed password check";
		}
		this.setState({pwdWarnMsg: newMsg});
		return;
	}

	handleCheckPassword = (p) => {
		let password = p.target.value;
		this.checkPassword(password);
	}

	handleSaveAgent = (e) => {
		if(this.checkFields() == false) {
			return;
		}
		let loginName = "";
		if(typeof this.props.input.loginname !== "undefined" &&
			this.props.input.loginname != ""){
			loginName = this.props.input.loginname;
		} else if(this.state.firstFilteredName != ""){
			loginName = this.state.firstFilteredName;
		}
		let params = {
			id: this.props.activeId,
			loginname: loginName,
			linkedextension: this.props.input.linkedextension,
			password: this.props.input.password,
			description: this.props.input.description,
		};
		this.props.onSaveAdmin(params);
	}

	render() {
		const {
		hidden
		, input
		, onCancel
		, isNew
		, noSipList
		, adminStatus
		} = this.props;

		let loginName = input.loginname;
		if(isNew && this.state.firstFilteredName != "") {
			loginName = this.state.firstFilteredName;
		}

		let buttonSaveTxt = BTN_TXT_SAVE , disableSave = false;
		if(adminStatus && adminStatus.status === 1){
			buttonSaveTxt = BTN_TXT_SAVING;
			disableSave = true;
		}

		return (
			<form id="agentSipLoginEdit" className="admin-one-form edit-admin-form" hidden={hidden}>
				<div className='input-section'>
					<ReadOnlyTextField
						id={"userid"}
						name={"userid"}
						className="admin-text-input"
						label={I("User ID")}
						value={input.id || ""}
						hidden={true}
					/>
					{isNew &&
					<CustomInputRow
							id={"searchname"}
							name={"searchname"}
                            label={I("Login name")}
							className="admin-text-input"
						>
						<div className="admin-text-input">
							<SearchFilter
							name={"searchnamefilter"}
							data={noSipList}
							altClass={"text-input"}
							fields={{id: "id", value: "loginname"}}
							onSelectFilter={this.handleFilteredList} />
						</div>
					</CustomInputRow>
					}
					{!isNew &&
					<TextInputRow
						id={"loginname"}
						name={"loginname"}
						className="admin-text-input"
						label={I("Login name")}
						value={loginName || ""}
						hidden={false}
						onChange={this.handleChangeData}
						mandatory={isNew}
						readonly={!isNew}
					/>
					}
					<TextInputRow
						id={"linkedextension"}
						name={"linkedextension"}
						className="admin-text-input"
						label={I("Primary linked extension")}
						value={input.linkedextension || ""}
						hidden={false}
						onChange={this.handleChangeData}
						mandatory={isNew}
					/>
					<TextInputRow
						id={"password"}
						name={"password"}
						type="password"
						className="admin-text-input"
						label={I("Password")}
						value={input.password || ""}
						hidden={false}
						onChange={this.handleChangeData}
						onBlur={this.handleCheckPassword}
						mandatory={isNew}
						warning={this.state.pwdWarnMsg}
					/>
					<TextInputRow
						id={"description"}
						name={"description"}
						className="admin-text-input"
						label={I("Description")}
						value={input.description || ""}
						hidden={false}
						onChange={this.handleChangeData}
					/>
					<EditorFooter>
						{!isNew &&
							<TableIconicButton
								type="button"
								className="btn bg-transparent action delete"
								iconClass={"icon-trash"}
								iconPosition={"left"}
								iconSize={"16px"}
								buttonTxtColor={centionTitleGrey}
								label={I("Delete")} title={I("Delete")}
								onClick={()=>{this.handleDelete(input.id)}}
							/>

						}
						<CancelButton onClick={onCancel} />
						<SaveButton onClick={this.handleSaveAgent} text={buttonSaveTxt} title={buttonSaveTxt} disabled={disableSave} type="submit"/>
					</EditorFooter>
				</div>
			</form>
		)
	}
}
