import React, { useEffect, useState, useRef } from 'react';
import { I } from '../../../common/v5/config';
import { ReadOnlyTextField } from '../../../reactcomponents/Form';


const playBtnWidth = 6; //6% of viewport width
const playBtnStyle = {
	top: `calc(50% - ${playBtnWidth/2}rem)`,
    left: `calc(50% - ${playBtnWidth/2}rem)`,
	padding:0,
	margin:0
};

const RewindIcon = ({disabled, onClick}) => {
	const color = disabled ? 'grey' : 'black';
	return (
		<div className="audio-control-icon rewind" title={I("Rewind")} onClick={() => onClick()}>
			{
				[1, 2].map((i) => <i key={i} className='icon-play-filled' style={{color: `${color}`, cursor: (disabled ? 'default' : 'pointer')}}></i>)
			}
		</div>
	)
}

const FastForwardIcon = ({onClick}) => {
	return (
		<div className="audio-control-icon forward" title={I("Forward")} onClick={() => onClick()}>
			{
				[1, 2].map((i) => <i key={i} className='icon-play-filled' style={{color: 'black'}}></i>)
			}
		</div>
	)
}

const PlayIcon = ({playing, onClick}) => {
	const icon = playing ? 'icon-pause-filled' : 'icon-play-filled';
	const title = playing ? I("Pause") : I("Play");
	return (
		<div className="audio-control-icon play" title={title} onClick={() => onClick()}>
			<i className={icon}></i>
		</div>
	)

}

const CallRecordingForm = ({
	hidden
	, input
}) => {

	const [control, setControl] = useState(false);
	const [hidePlay, setHidePlay] = useState(false);
	const [audioPlaying, setAudioPlaying] = useState(false);
	const [audioPlayed, setAudioPlayed] = useState(false);

	const vidRef = useRef(null);
	const audioRef = useRef(null);

	const handlePlayVideo = (e) => {
		vidRef.current.play();
		setHidePlay(true);
		setControl(true);
	}

	const date = new Date(input.createtime * 1000);
	const createDate = date.toLocaleDateString("default");
	const createTime = date.toLocaleTimeString("default");

	let duration = "";
	if(input.duration !== undefined){
		duration = new Date(input.duration * 1000).toISOString().slice(11, 19);
	}
	const allowRewindAndFastForward = true;
	useEffect(() => {
		if(audioRef && audioRef.current) {
			audioRef.current.addEventListener('play', () => {
				setAudioPlayed(true);
				setAudioPlaying(true);
			});
		}
		if(audioRef && audioRef.current) {
			audioRef.current.addEventListener('pause', () => {
				setAudioPlaying(false);
			});
		}
	}
	, [audioRef]);

	const rewindAudio = () => {
		if(audioPlayed) {
			if(audioRef && audioRef.current) {
				audioRef.current.currentTime -= 10;
				if(audioRef.current.paused) {
					audioRef.current.play();
				}
			}
		}
	}
	const fastForwardAudio = () => {
		if(audioRef && audioRef.current) {
			audioRef.current.currentTime += 10;
			if(audioRef.current.paused) {
				audioRef.current.play();
			}
		}
	}
	const playOrPauseAudio = () => {
		if(audioRef && audioRef.current) {
			if(audioRef.current.paused) {
				audioRef.current.play();
			} else {
				audioRef.current.pause();
			}
		}
	}
	return (
		<form id="callRecordingsEdit" className="admin-one-form edit-admin-form" hidden={hidden}>
			{input.type == "voice" ? 
			<div className='audio-frame'>
				<audio controls style={{ width: "100%" }} ref={audioRef}>
					<source src={input.download} type="audio/mpeg"/>
				</audio>
				{
					allowRewindAndFastForward &&
					<div className="audio-control-wrapper">
						<RewindIcon onClick={rewindAudio} disabled={!audioPlayed}></RewindIcon>
						<PlayIcon playing={audioPlaying} onClick={playOrPauseAudio}></PlayIcon>
						<FastForwardIcon onClick={fastForwardAudio}></FastForwardIcon>
					</div>
				}
			</div> 
			:
			<div className='video-frame'>
				<video ref={vidRef} controls={control} style={{ width: "100%" }}>
					<source src={input.download} type="video/mp4" />
				</video>
				<button type="button" className="btn-play-thumbnail" hidden={hidePlay} onClick={handlePlayVideo} style={playBtnStyle}>
					<i className='icon-play-filled' style={{fontSize:`${playBtnWidth}vw`, outline:"none"}}></i>
				</button>
			</div>
			}
			<br/>
			<div className='input-section'>
				<ReadOnlyTextField
					id={"fileName"}
					name={"fileName"}
					className="admin-text-input"
					label={I("File name")}
					value={input.name || ""}
					hidden={false}
				/>
				<ReadOnlyTextField
					id={"errandid"}
					name={"errandid"}
					className="admin-text-input"
					label={I("Errand")}
					value={input.errandid || ""}
					hidden={false}
				/>
				<ReadOnlyTextField
					id={"agentName"}
					name={"agentName"}
					className="admin-text-input"
					label={I("Agent")}
					value={input.agentName || ""}
					hidden={false}
				/>
				<ReadOnlyTextField
					id={"date"}
					name={"date"}
					className="admin-text-input"
					label={I("Date created")}
					value={createDate}
					hidden={false}
				/>
				<ReadOnlyTextField
					id={"createtime"}
					name={"createtime"}
					className="admin-text-input"
					label={"Time created"}
					value={createTime}
					hidden={false}
				/>
				<ReadOnlyTextField
					id={"duration"}
					name={"duration"}
					className="admin-text-input"
					label={I("Duration")}
					value={duration}
					hidden={false}
				/>
			</div>
			<br/>
			<a href={input.download} target="_blank">
				<i className='icon-download'></i>
				<span>&nbsp;{I("Download")}</span>
			</a>
		</form>
	)
}

export default CallRecordingForm;