import React, {
	Fragment
	, PureComponent
	, memo
	, useCallback
	, useEffect
	, useMemo
	, useState
	, useRef
} from 'react';
import each from 'lodash/each';
import update from 'immutability-helper';
import classNames from 'classnames';
import styled from 'styled-components';
import { withProps } from 'recompose';
import EllipsisText from "../../reactcomponents/EllipsisText"
import { BootstrapTable as OldBootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import OrganisationCtnr from "../organisations/v5/ctnr";
import memoizeOne from 'memoize-one';
import { emptyArray } from '../../common/constants';
import {
	ActionsWrapper,
	Delete as ActionDelete,
	Download as ActionDownload,
	Edit as ActionEdit,
	EditorBox,
	EditorFooter,
	EditorHeader,
	SaveButton,
	CancelButton,
	CopyButton,
	SingleListSekeleton,
	TableHeader,
	editorTitleByView,
	editorTitleByViewFA,
	PreviewStructure as ActionPreviewStructure
} from '../../reactcomponents/Admin';
import Breadcrumb from '../../reactcomponents/Breadcrumb';
import KnowledgeBaseCtnr from './knowledgebase/knowledgeBaseCtnr';
import {
	BreadcrumbItem,
	Tooltip as BSTooltip,
	Input as BSInput,
	Button
} from 'reactstrap';
import {
	addressValidation
	, formatValueToNameObj
	, getListSizeBasedOnHeight
	, isExternalExpert
	, isSST
	, isTemplate
	, isFileArchive
	, isInternalState
	, isRoutingView
	, isAdminList
	, isAccount
	, isTelavoxEnabled
	, sanitizeHtml,
	isQuickReply,
	filePathPrefix
} from '../../common/v5/helpers';
import { ordinal_suffix_of } from '../../common/helpers';
import { SidebarSettingsCtnr, WorkflowInnerContainerCtnr, IconizeSideBarMenuCtnr } from './workflowCtnr';
import { AdminListCtnr } from './adminCtnr';
import { MenuItem } from '../../common/v5/menu';
import {
	A_CHATBOTS,
	A_CLOSE_RECEIPTS,
	A_FEEDBACKS,
	A_FORM_DATA,
	A_GREETINGS,
	A_KNOWLEDGE_BASES,
	A_ROUTING_GROUPS,
	A_RECEIVE_RECEIPTS,
	A_SMTP,
	A_TAG,
	A_ACCOUNTS,
	A_ORGANISATIONS_V5,
	A_CALL_RECORDINGS,
	A_AGENT_SIP_LOGINS,
	A_SIP_PRIORITY_NUMBERS,
	A_CORS_WHITELIST,
	A_CHAT_IP_BLACKLIST,
	A_CONTACT_CARDS,
	ADMIN_CKE_DEF_HEIGHT,
	ADMIN_TITLE,
	ADMINISTRATION,
	ADMIN_VIEW_MAP,
	INPUT_EMPTY_WARNING,
	INPUT_SPACE_WARNING,
	DUPLICATE_SIP_STATUS_NAME,
	FILTER_BY_SELECT,
	FILTER_BY_TEXT,
	FILTER_BY_AREA,
	FILTER_BY_AGENT_STATUS,
	FILTER_BY_AGENTS,
	FILTER_BY_AGENT_SIP_STATUS,
	FILTER_BY_DATE_BETWEEN,
	M_EXTERNALEXPERT,
	M_KNOWLEDGEBASE,
	M_MY_EXTERNALEXPERT,
	M_REVIEW_KEYWORDS,
	M_REVIEW_AGENTS,
	M_ERRANDINTERNALSTATE,
	M_STUN_TURN,
	OBJ_ID_NAME,
	UPLOAD_AGENT_INPROGRESS,
	TXT_AREA_AVAILABLE,
	MM_ADMINISTRATION,
	M_COMPANIES,
	M_CONFIGCHANGELOG,
	UNSELECT,
	REVIEW_KEYWORDS_OPTION,
	M_ROUTING_KEYWORDS,
	ROUTE_KEYWORDS_OPTION,
	M_ROUTING_AUTOTAGS,
	M_ROUTING_SIP,
	M_CHATWIDGETDL,
	M_FAQWIDGETDL,
	M_VOICEWIDGETDL,
	M_AGENTSIPSTATUS,
	M_CLASSIFIER,
	M_GENERATIVE_AI_DOCUMENT,
	USE_XHR_CREATE_AGENT_ACCESS_KEY,
	USER_STATUS_CLASSNAME_OPTION,
	WIDGET_POSITION_OPTION,
	FAQ_WIDGET_POSITION_OPTION,
	INTERACTIVE_MSG_OPTION,
	INTERACTIVE_NUMBER_OF_OPTION,
	INTERACTIVE_LIST_OPTION,
	INTERACTIVE_BUTTON_OPTION,
	M_QUICK_REPLY,
	QUICK_REPLY_MSG_OPTION,
	QUICK_REPLY_MSG_TYPES,
	QUICK_REPLY_MESSAGE_OPTION,
	QUICK_REPLY_TEMPLATE_OPTION,
	QUICK_REPLY_MESSAGE_TEXT,
	QUICK_REPLY_MSG_PHONE_TYPE,
	QUICK_REPLY_MSG_EMAIL_TYPE,
	QUICK_REPLY_MESSAGE,
	QUICK_REPLY_MSG_TEXT_TYPE,
	QUICK_REPLY_MESSAGE_DATA,
	M_AGENTS,
	M_TAG,
	M_API_CALLBACK,
	M_API_ACCESSTOKENS,
	A_GROUPS,
	A_CALL_IVR,
	A_CALL_SIP_TRUNK,
	M_ADDRESSBOOK,
	A_SKILLS,
	M_AGENT_ASSIST,
	A_AGENT_ASSIST,
	BTN_TXT_SAVE,
	BTN_TXT_SAVING,
	M_CALL_RECORDINGS
} from '../../common/v5/constants';
import { pathPrefix } from '../../common/v5/config';
import {
	useCallbackWithValue
	, useCheckValidValueOnBlur
} from '../../hooks/callback';
import {
	TABLE_BASE_CLASSNAME,
	TABLE_HEADER_STYLE
} from '../../reactcomponents/common';
import {
	composeWithDisplayName
	, createWithPropAttachedAction
	, withIdAttachedOnClick
	, withShowToggle
	, withUnmountWhenHidden
} from '../../reactcomponents/hocs';
import Assist, {
	AssistBarLabel
	, AssistBarItems
	, AssistBarOneItem
	, AssistColumn
	, ProtectAssist
} from '../../reactcomponents/Assist';
import { NoFlipMultiSelect, SingleSelectDD } from '../../reactcomponents/Dropdown';
import UploadButton, {
	DownloadButton
} from '../../reactcomponents/UploadButton';
import { DismissableAlert } from '../../reactcomponents/Error';
import AppHeader from '../../components/v5/AppHeader';
import { AdminEditInlineCtnr, AgentFormCtnr, AdminEditLeftViewCtnr, AdminEditRightViewCtnr } from './adminCtnr';
import ToggleSideBar from '../../components/v5/ToggleSideBar';
import Ckeditor from '../../components/v5/Ckeditor';
import {
	Label,
	TableIconicButton,
	FormInputWithLabelRow,
	TextInputRow,
	SelectInputRow,
	AvatarInputRow,
	HelperTooltip,
	withRow,
	CheckboxInputRow,
	ReadOnlyTextField
} from '../../reactcomponents/Form';
import { FileUploaderInput } from '../../components/v5/FileUploader';
import { I, webRoot } from '../../common/v5/config';
import { AdminFileArchivePreview, AdminGenerativeAIDocumentPreview } from '../../redux/constants/endpoints';
import ScrollContainer from 'react-indiana-drag-scroll';
import ChatbotCtnr from '../../containers/chatbot';
import ReceiptGreetingCtnr from '../receiptgreetings/list';
import SMTPCtnr from '../smtp/smtp';
import { Area } from '../../components/v5/ManualErrand';
import ColorPicker from '../../reactcomponents/ColorPicker';
import {
	LabelToggleSwitch as ToggleSwitch
} from '../../components/v5/ToggleSwitch';
import { WidgetConfigForm, ChatWidgetCodePreviewForm, FaqWidgetCodePreviewForm, VoiceWidgetCodePreviewForm } from '../../components/v5/Widget';
import MenuCtnr from '../../common/v5/menuCtnr';
import AccountsV5Ctnr from "./accounts/account"
import AgentSipLoginCtnr from './agentsiplogins/agentSipLoginCtnr';
import SipPriorityNumberCtnr from './sipprioritynumbers/sippriorityCtnr';
import CallSIPTrunkCtnr from './siptrunk/sipTrunkCtnr';
import CallRecordingCtnr, { CallIVRCtnr } from './calls/callCtnr';
import CORSwhiteList from './chat/cors';
import ChatIPBlacklist from './chat/blacklist';
import ContactCardsCtnr from './contactcards/contactCardsCtnr';
import RoutingGroupsCtnr from "./routinggroups/routinggroupsCtnr";
import useWindowDimensions from '../../reactcomponents/Dimension';
import { SingleCalenderDD } from '../../reactcomponents/DatePicker';
import { centionTitleGrey } from '../../styles/_variables';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
	PaginationProvider
  } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import AdminGroups from './groups/groups';
import Helper from '../../reactcomponents/Helper';
import { customPageTotal } from '../../reactcomponents/Table';
import { SkillsCtnr } from './skills/SkillsCtnr';
import { AgentAssistCtnr } from './agentassist/AgentAssistCtnr';
import { CheckEndpoint } from '../../redux/actions/async/restful';
import SwitchCheckbox from '../../reactcomponents/SwitchCheckbox';
import { FilePreviewV5 } from './../../components/v5/FilePreview';
import PopupPage from '../../reactcomponents/PopupPage';

let CLICK_ROW_TO_EDIT = true; //If false, edit icon appear before delete icon.

const FormOrDiv = USE_XHR_CREATE_AGENT_ACCESS_KEY ? 'form' : 'div';

class AdminTitle extends PureComponent {
	handleClick = (e) => {
		let p = this.props;
		p.onBackToMain(p.mainView,e);
	}
	render() {
		const {view, title, level} = this.props;
		return (
			<Breadcrumb>
				<BreadcrumbItem tag="span" href="#">
					<MenuCtnr visible={true} hoverable={true}/>
				</BreadcrumbItem>
				<BreadcrumbItem tag="a" href="#" active={level === 2 ? true : false}>{title}</BreadcrumbItem>
				{
					level > 2 &&
						<BreadcrumbItem active tag="span">{title}</BreadcrumbItem>
				}
			</Breadcrumb>
		);
	}
}

class AdminSubMenus extends PureComponent {
	constructor(props){
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick(url, label, id) {
		this.props.onChangeView(id, url);
	}
	render() {
		const { list, collapseSideBar, onChangeView, showSideBar, handleSideBarCollapse, handleSideBarToggle } = this.props;
		let flexMenusStyle = {paddingLeft: "10px", paddingRight: "10px"};
		if(collapseSideBar) {
			flexMenusStyle = {padding: "0px"};
		}
		return (
			<div className="flex-menus-wrap" style={flexMenusStyle}>
				<div className="side-bar-main-menu" hidden={features['hide-cention-logo']}>
					{
						!collapseSideBar &&
							<div style={{marginRight: "20px"}}><MenuCtnr visible={true} /></div>
					}
					<ToggleSideBar visible={showSideBar}
						showSideBar={showSideBar}
						collapsed={collapseSideBar}
						mobileMode={false} //todo, admin mobile mode?
						id={"QA_toggle_sidebar_top"}
						onCollapse={handleSideBarCollapse}
						onClick={handleSideBarToggle} />
				</div>
				{
					collapseSideBar &&
						<IconizeSideBarMenuCtnr currentPage={MM_ADMINISTRATION} onChangePage={onChangeView} showWorkflowMenu={true} />
				}
				{
					!collapseSideBar &&
						<ul className="sidebar-menu">
						{
							list.map((menuItem, i) => (
								<MenuItem
									key={i}
									item={menuItem}
									active={false}
									onClick={this.handleClick}
								/>
							))
						}
						</ul>
				}
			</div>
		)
	}
}

const AssistLabel = () => <Label><AssistBarLabel textCase="capitalize"/></Label>;

const AssistRow = withRow(AssistLabel);

const TemplateAssist = ({ disabled, onClick, view }) => (
	<Assist hidden={!isTemplate(view)}>
		<AssistColumn>
			<AssistRow>
				<AssistBarItems>
					<AssistBarOneItem>
						<ProtectAssist disabled={disabled} onClick={onClick} />
					</AssistBarOneItem>
				</AssistBarItems>
			</AssistRow>
		</AssistColumn>
	</Assist>
);

class QuickReplyForm extends PureComponent {
	constructor(props) {
		super(props);
		this.handleToggleArea = this.handleToggleArea.bind(this);
		this.handleSelectArea = this.handleSelectArea.bind(this);
		this.state = {
			showArea: false,
			isFBTemplate: false
		};
	}
	handleSelectArea(val) {
		this.props.onHandleChangeData(val, "selectedArea");
	}
	handleToggleArea() {
		this.setState({showArea: !this.state.showArea});
	}
	onHandleChangeQR = (input, val) =>{
		this.props.onHandleChangeData(input, val);	
	}
	onTypeChange = ( val ) =>{
		this.props.onChangeQuickReply("types", val);
	}
	render() {
		const {
			view
			, input
			, quickReply
			, modal
			, areaList
			, warning
			, onHandleTextInputBlur
			, onHandleTextInputChange
			, onChangeQuickReply
		} = this.props
		, nameField = "name"
		, constantData = A_FORM_DATA[view]
		;
		let helperTxt
			, contentLabel = I("Quick Reply")
			, areaWarning
			;
		if (constantData) {
			contentLabel = constantData.label;
			helperTxt = constantData.helperTxt;
		}
		let qrMsgData = quickReply.msgData;
		let opts = QUICK_REPLY_MESSAGE_OPTION;
		if(quickReply.id !== 0){
			if(quickReply.types === QUICK_REPLY_MESSAGE_OPTION){
				opts = QUICK_REPLY_MESSAGE_OPTION;
			}
			//TODO(Mujibur): Template needs another task to do. seems lot more work
			//else if (quickReply.types === QUICK_REPLY_TEMPLATE_OPTION){
			//	opts = QUICK_REPLY_TEMPLATE_OPTION;
			//}
		}
		return (
			<div className="admin-one-form">
				<FormInputWithLabelRow
					label={I('Area')}
					mandatory={true}
					warning={areaWarning}
				>
					<NoFlipMultiSelect
						id={"area"}
						title={I("Select Area")}
						className="popup-multi-select admin-text-input-qrm"
						overrideTitle={true}
						groupSelect={true}
						nested={{key: 'Areas'}}
						data={areaList}
						idFields={OBJ_ID_NAME}
						selected={input.selectedArea}
						onSelect={this.handleSelectArea}
						onToggle={this.handleToggleArea}
						show={this.state.showArea}
						selectAll={true}
						selectNone={true}
					/>
				</FormInputWithLabelRow>
				<TextInputRow
					id={modal ? nameField+"Popup" : nameField}
					name={nameField}
					className="admin-text-input-qrm"
					label={I("Name")}
					value={input[nameField] ? input[nameField] : ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={warning}
					onBlur={onHandleTextInputBlur}
				/>
				<SelectInputRow
					hidden={false}
					id={"qr-option"}
					name={"type"}
					label={I('Type')}
					className={"admin-select-review-option admin-text-input-qrm"}
					option={opts}
					value={quickReply.types}
					onSelect={this.onTypeChange}
				/>
				<QuickReplyMessageBox
					archiveImgs={quickReply.archiveImages}
					quickReplyMsg={qrMsgData}
					onHandleQuickReplyMsg={onChangeQuickReply}
				/>
				{/* {qrMsgData.isFBTemplate && <QuickReplyTemplateBox
					quickReplyMsg={qrMsgData}
				/>} */}
			</div>
		);
	}
}

class QuickReplyTemplateBox extends PureComponent {
	constructor(props) {
		super(props);
	}
	render(){
		return null;
	}
}

class QuickReplyMessageBox extends PureComponent {
	constructor(props) {
		super(props);
	}
	onChangeContentType =( val )=> {
		this.props.onHandleQuickReplyMsg("contentType", val, QUICK_REPLY_MESSAGE);
	}
	handleTextInput = ( e ) =>{
		this.props.onHandleQuickReplyMsg(e.target.name, e.target.value, QUICK_REPLY_MESSAGE);
	}
	onHandleChangeOption =( val )=>{
		this.props.onHandleQuickReplyMsg("numberOption", val, QUICK_REPLY_MESSAGE);
	}
	listChange =( val )=>{
		this.props.onHandleQuickReplyMsg("messageList", val, QUICK_REPLY_MESSAGE);
	}
	isContentText = (ct) =>{
		return ct === QUICK_REPLY_MESSAGE_TEXT
	}
	render() {
		const {hide, quickReplyMsg, archiveImgs} = this.props;
		let opts = QUICK_REPLY_MSG_TYPES, qrOption = INTERACTIVE_NUMBER_OF_OPTION;
		if(quickReplyMsg.id != 0){
			if(quickReplyMsg.contentType === QUICK_REPLY_MESSAGE_TEXT){
				opts = QUICK_REPLY_MSG_TEXT_TYPE;
			}else if (quickReplyMsg.contentType === QUICK_REPLY_MSG_PHONE_TYPE){
				opts = QUICK_REPLY_MSG_PHONE_TYPE;
			}else if (quickReplyMsg.contentType === QUICK_REPLY_MSG_EMAIL_TYPE){
				opts = QUICK_REPLY_MSG_EMAIL_TYPE;
			}
		}
		return (
			<div className="admin-one-form">
				<SelectInputRow
					hidden={false}
					id={"qrm-option"}
					name={"content-type"}
					label={I('Content type')}
					className={"admin-select-review-option admin-text-input-qrm"}
					option={opts}
					value={quickReplyMsg.contentType}
					onSelect={this.onChangeContentType}
				/>			
				<TextInputRow
					mandatory={true}
					className="admin-text-input-qrm"
					id={"id-internal-state-header"}
					name={"title"}
					label={I("Title")}
					value={quickReplyMsg.title}
					onChange={this.handleTextInput}
				/>
				{this.isContentText(quickReplyMsg.contentType) &&
					<Fragment>
						<SelectInputRow
							className="admin-text-input-qrm"
							id={"admin-text-input-qrm"}
							name={"numberOption"}
							label={I('Options')}
							option={qrOption}
							value={quickReplyMsg.numberOption}
							onSelect={this.onHandleChangeOption}
						/>
						<ExtendedListQRText
							archiveImgs={archiveImgs}
							id={quickReplyMsg.id}
							type={quickReplyMsg.contentType}
							value={quickReplyMsg.numberOption}
							sectionsVal={quickReplyMsg.messageList}
							listChangeData={this.listChange}
						/>
					</Fragment>
				}
			</div>
		)
	}
}

const StyledFullDiv = styled.div`
  div& {
    display: block;
    .left {
      width: 100%;
      border: 1px dashed #ccceee;
      margin-left: 2px;
    }
    & + & {
      padding-top: 5px;
    }
  }
`
export const DivFull = composeWithDisplayName(
  'DivFull',
  withUnmountWhenHidden,
  memo
)(StyledFullDiv)

class ListQRText extends PureComponent {
	constructor(props) {
		super(props);
		this.state ={
			"image-1": ""
		};
	}
	handleTextInputChange=( idx, e )=>{
		let lv ={
			id: idx,
			title:e.target.name,
			name: e.target.name,
			value: e.target.value
		}
		this.props.listChangeData(lv);
	}
	onHandleChangeOption = (idx, val) =>{
		let lv ={
			id: idx,
			title:"value-" + idx,
			name: "value-" + idx,
			value: val
		}
		this.props.listChangeData(lv);
	}
	onHandleChangeImage = (idx, val) =>{
		const {archiveImgs} = this.props;
		let ai = archiveImgs && archiveImgs.find( v => v.download === val);
		let lv ={
			id: idx,
			title:"description-" + idx,
			name: "description-" + idx,
			origName: (ai ? ai.name : ""),
			value: val
		}
		this.props.listChangeData(lv);
		this.setState({["image-" + idx]: val});
	}
	getDimension =( width )=> {
		let imgDim = 40;
		if(width < 1500){
			imgDim = imgDim + 20;
		} else if (width >= 1500 && width < 1700){
			imgDim = imgDim + 10;
		}else if (width >= 1700 && width <= 1880){
			imgDim = imgDim + 20;
		}else{
			imgDim = imgDim + 40;
		}
		return imgDim;
	}
	render() {
		const { id, value, hide, sectionsVal, archiveImgs, type, width } = this.props;
		let state = this.state;
		let section = [];
		let sectionContent = [];
		let imgDim = this.getDimension( width );
		let imgStyle = {
			float: "right",
			position: "absolute",
			top: 0,
			right: 0,
			width: `${imgDim}px`,
			height: `${imgDim}px`,
		};
		if(type !== QUICK_REPLY_MESSAGE_TEXT){
			return null;
		}
		let NUMBER_OF_IMAGE = [];
		
		archiveImgs && archiveImgs.forEach( v =>{
			NUMBER_OF_IMAGE.push({id: v.download, name: v.name});
		});
		for(let i = 1; i <= value; i++){
			let sv = sectionsVal[i-1];
			let position = 'left';
			if(width >= 1500){
				if( i % 2 == 0 ){
					position = 'right';
				}
			}
			let imgSrc = "", imgSelectedValue = sv.description;
			if(id != 0){
				imgSrc = sv.description;
				let origName = sv.origName;
				let iv = NUMBER_OF_IMAGE.find((v,i) =>{
					if(v.name == origName){
						return v;
					}
				});
				if (typeof iv === 'object'){
					imgSelectedValue = iv.id;
				}
			}else{
				imgSrc = state["image-"+i];
			}
			sectionContent.push(<div className={position} key={i}>
					<div>{I("Text quick reply:")} {i}</div>
					<SelectInputRow
						className="admin-text-input"
						id={"quick-reply-number-image"}
						name={"image-"+i}
						label={I('Image')}
						imgSrc={imgSrc}
						imgStyle={imgStyle}
						imgId={"image-"+i}
						option={NUMBER_OF_IMAGE}
						value={imgSelectedValue}
						onSelect={this.onHandleChangeImage.bind(this, i)}
					/>
					<SelectInputRow
						readonly={true}
						className="admin-text-input"
						id={"interactive-number-of-option"}
						name={"value-"+i}
						label={I('Value')}
						option={INTERACTIVE_NUMBER_OF_OPTION}
						value={sv.id}
						onSelect={this.onHandleChangeOption.bind(this, i)}
					/>
					<TextInputRow
						className="admin-text-input-section"
						mandatory={true}
						id={"id-internal-state-title"}
						name={"title-"+ i}
						label={I("Title")}
						value={sv.title}
						onChange={this.handleTextInputChange.bind(this, i)}
					/>
					
					
				</div>);
			if( position == 'right' ){
				section.push(<Div key={i}>{sectionContent}</Div>);
				sectionContent = [];
			}else{
				if( i >= value ){ 
					if(width >= 1500){
						section.push(<Div key={i}>{sectionContent}</Div>);
					}else{
						section.push(<DivFull key={i}>{sectionContent}</DivFull>);
					}
				}
			}
			
		}
		return (
			<div className="admin-one-form" hidden={hide}>
				{section}
			</div>
		)
	}
}

const listQRHook = (Component)=> {
	return props =>{
		const { height, width } = useWindowDimensions();
		return <Component {...props} width={width} height={height} />;
	}
}

export const ExtendedListQRText = listQRHook(ListQRText);

class SignatureSalutationForm extends PureComponent {
	constructor(props) {
		super(props);
		this.handleToggleArea = this.handleToggleArea.bind(this);
		this.handleSelectArea = this.handleSelectArea.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.handleCkeditorChange = this.handleCkeditorChange.bind(this);
		this.isInteractiveChange = this.isInteractiveChange.bind(this);
		this.state = {
			showArea: false,
			isInteractive: false
		};
	}
	handleSelectArea(val) {
		this.props.onHandleChangeData(val, "selectedArea");
	}
	handleToggleArea() {
		this.setState({showArea: !this.state.showArea});
	}
	handleKeyDown() {

	}
	handleCkeditorChange(e) {
		const cke = e.editor;
		this.props.onHandleCKEditorChange(cke.getData(), "htmlContent");
		this.props.onHandleCKEditorChange(cke.document.getBody().getText(), "plainContent");
	}
	isInteractiveChange( val, field ){
		this.props.onChangeInteractive(field, val);
	}
	onChangeInteractiveTemplates = (input, val) =>{
		this.props.onChangeInteractive(input, val);
	}
	render() {
		//TODO: Check if all area should be the same,
		//if yes, then using what we already have from workflow settings
		const {
				view
				, agent
				, input
				, interactive
				, insertionText
				, modal
				, langSrc
				, areaList
				, ckeditorSettings
				, warning
				, archiveImgs
				, onHandleChangeData
				, onHandleTextInputBlur
				, onHandleTextInputChange
				, onHandleCKEditorChange
				, onOpenInsertProtectedContentReply
				, onResetInsertionText
				, onChangeInteractiveTemplates
			} = this.props
			, nameField = "name"
			, constantData = A_FORM_DATA[view]
			;
		let helperTxt
			, contentLabel = I("Salutation")
			, areaWarning
			, ckeditorId = "ckeditorAdmin_"+view;
			;
		if (constantData) {
			contentLabel = constantData.label;
			helperTxt = constantData.helperTxt;
		}
		return (
			<div className="admin-one-form">
				<FormInputWithLabelRow
					label={I('Area')}
					mandatory={true}
					warning={areaWarning}
				>
					<NoFlipMultiSelect
						id={"area"}
						title={I("Select Area")}
						className="popup-multi-select"
						overrideTitle={true}
						groupSelect={true}
						nested={{key: 'Areas'}}
						data={areaList}
						idFields={OBJ_ID_NAME}
						selected={input.selectedArea}
						onSelect={this.handleSelectArea}
						onToggle={this.handleToggleArea}
						show={this.state.showArea}
						selectAll={true}
						selectNone={true}
					/>
				</FormInputWithLabelRow>
				<TextInputRow
					id={modal ? nameField+"Popup" : nameField}
					name={nameField}
					className="admin-text-input"
					label={I("Name")}
					value={input[nameField] ? input[nameField] : ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={warning}
					onBlur={onHandleTextInputBlur}
				/>
				<TemplateAssist
					onClick={onOpenInsertProtectedContentReply}
					view={view}
				/>
				<FormInputWithLabelRow label={contentLabel} mandatory={true}>
					{helperTxt &&
						<HelperTooltip txt={helperTxt} key="input-form-helper" id={"input-form-helper-admin-sig"} position={"left"} />
					}
					<Ckeditor
						id={ckeditorId}
						data-qa-id={'QA_adminEditor'}
						defaultFontFamily={ckeditorSettings.fontFamily}
						height={ADMIN_CKE_DEF_HEIGHT}
						insertionText={insertionText}
						toolbarID="ckeditorAdminTop"
						defaultFontSize={ckeditorSettings.fontSize}
						defaultContent={input.htmlContent ? input.htmlContent : "<div> </div>"}
						spellLanguages={langSrc}
						fileArchiveImages={archiveImgs}
						onKeydown={this.handleKeyDown}
						onChange={this.handleCkeditorChange}
						onResetInsertionText={onResetInsertionText}
						avatarTemplate={true}
						control={true}
						showResize={true}
					/>
				</FormInputWithLabelRow>
				<CheckboxInputRow
					hide={!isTemplate(view)}
					label={I("Is interactive?")}
					id={"adminChat-customize"}
					name={"isInteractive"}
					data-qa-id="isInteractive"
					checked={interactive.isInteractive}
					onChange={this.isInteractiveChange}
				/>
				{interactive.isInteractive && <InteractiveMessageBox
					hide={!isTemplate(view)}
					interactive={interactive}
					onHandleInteractiveMsg={this.onChangeInteractiveTemplates}
				/>}
			</div>
		);
	}
}

const StyledHeadDiv = styled.div`
  div& {
    display: flex;
    .left, .right{
	width: 50%;
	margin-left: 10px;
    }
    & + & {
      padding-top: 5px;
    }
  }
`
export const DivH = composeWithDisplayName(
  'Div',
  withUnmountWhenHidden,
  memo
)(StyledHeadDiv)

class InteractiveMessageBox extends PureComponent {
	constructor(props) {
		super(props);
	}
	onHandleChangeTypes =( val )=> {
		this.props.onHandleInteractiveMsg("types", val);
	}
	handleTextInput = ( e ) =>{
		this.props.onHandleInteractiveMsg(e.target.name, e.target.value);
	}
	onHandleChangeOption =( val )=>{
		this.props.onHandleInteractiveMsg("numberOption", val);
	}
	listChange =( val )=>{
		this.props.onHandleInteractiveMsg("list", val);
	}
	buttonChange =( val )=>{
		this.props.onHandleInteractiveMsg("button", val);
	}
	render() {
		const {hide, interactive, onHandleInteractiveMsg} = this.props;
		let opts = INTERACTIVE_MSG_OPTION;
		if(interactive.id != 0){
			if(interactive.types === 0){
				opts = INTERACTIVE_LIST_OPTION;
			}else if (interactive.types === 1){
				opts = INTERACTIVE_BUTTON_OPTION;
			}
		}
		return (
			<div className="admin-one-form" hidden={hide}>
				<SelectInputRow
					hidden={false}
					id={"interactive-option"}
					name={"type"}
					label={I('Type')}
					className={"admin-select-review-option"}
					option={opts}
					value={interactive.types}
					onSelect={this.onHandleChangeTypes}
				/>
				<DivH>
					<div className='left'>
						<TextInputRow
							mandatory={true}
							className="admin-text-input-interactive"
							id={"id-internal-state-header"}
							name={"header"}
							label={I("Header")}
							maxLength={"60"}
							value={interactive.header}
							onChange={this.handleTextInput}
						/>
					</div>
					<div className='right'>
						<TextInputRow
							className="admin-text-input-interactive"
							mandatory={true}
							id={"id-internal-state-body"}
							name={"body"}
							label={I("Body")}
							maxLength={"1024"}
							value={interactive.body}
							onChange={this.handleTextInput}
						/>
					</div>
				</DivH>
				<DivH>
					<div className='left'>
						<TextInputRow
							className="admin-text-input-interactive"
							mandatory={true}
							id={"id-internal-state-footer"}
							name={"footer"}
							label={I("Footer")}
							maxLength={"60"}
							value={interactive.footer}
							onChange={this.handleTextInput}
						/>
					</div>
					<div className='right'>
						<SelectInputRow
							hidden={false}
							className="admin-text-input"
							id={"admin-text-input-interactive"}
							name={"numberOption"}
							label={I('Options')}
							option={INTERACTIVE_NUMBER_OF_OPTION}
							value={interactive.numberOption}
							onSelect={this.onHandleChangeOption}
						/>
					</div>
				</DivH>
				{interactive.types === 0 && <ListSection
					buttonText={interactive.buttonText}
					buttonChangeText={this.handleTextInput}
					sectionTitle={interactive.sectionTitle}
					sectionTitleChangeText={this.handleTextInput}
					value={interactive.numberOption}
					sectionsVal={interactive.sections}
					listChangeData={this.listChange}
				/>}
				{interactive.types === 1 && <ButtonSection
					value={interactive.numberOption}
					buttonsVal={interactive.buttons}
					buttonChangeData={this.buttonChange}
				/>}
			</div>
		)
	}
}

const StyledIntDiv = styled.div`
  div& {
    display: flex;
    .left, .right {
      width: 50%;
      border: 1px dashed #ccceee;
      margin-left: 2px;
    }
    & + & {
      padding-top: 5px;
    }
  }
`
export const Div = composeWithDisplayName(
  'Div',
  withUnmountWhenHidden,
  memo
)(StyledIntDiv)

class ListSection extends PureComponent {
	constructor(props) {
		super(props);
	}
	handleTextInputChange=( idx, e )=>{
		let lv ={
			id: idx,
			name: e.target.name,
			value: e.target.value
		}
		this.props.listChangeData(lv);
	}
	onHandleChangeOption = (idx, val) =>{
		let lv ={
			id: idx,
			name: "value-" + idx,
			value: val
		}
		this.props.listChangeData(lv);
	}
	handleButtonTextChange=( field, value )=>{
		this.props.buttonChangeText(field, value);
	}
	handlesSectionTitleChange=( field, value )=>{
		this.props.sectionTitleChangeText(field, value);
	}
	render() {
		const { value, hide, sectionsVal, buttonText, sectionTitle } = this.props;
		let section = [];
		let sectionContent = [];
		for(let i = 1; i <= value; i++){
			let sv = sectionsVal[i-1];
			let position = 'left';
			if( i % 2 == 0 ){
				position = 'right';
			}
			sectionContent.push(<div className={position} key={i}>
					<div>{I("Section#")} {i}</div>
					<TextInputRow
						className="admin-text-input-section"
						mandatory={true}
						id={"id-internal-state-title"}
						name={"title-"+ i}
						label={I("Title")}
						maxLength={"24"}
						value={sv.title}
						onChange={this.handleTextInputChange.bind(this, i)}
					/>
					<SelectInputRow
						readonly={true}
						className="admin-text-input"
						id={"interactive-number-of-option"}
						name={"value-"+i}
						label={I('Value')}
						option={INTERACTIVE_NUMBER_OF_OPTION}
						value={sv.value}
						onSelect={this.onHandleChangeOption.bind(this, i)}
					/>
					<TextInputRow
						className="admin-text-input-section"
						mandatory={true}
						id={"id-internal-state-description"}
						name={"description-"+ i}
						label={I("Description")}
						value={sv.description}
						maxLength={"72"}
						onChange={this.handleTextInputChange.bind(this, i)}
					/>
				</div>);
			if( position == 'right' ){
				section.push(<Div key={i}>{sectionContent}</Div>);
				sectionContent = [];
			}else{
				if( i >= value ){
					section.push(<Div key={i}>{sectionContent}</Div>);
				}
			}

		}
		return (
			<div className="admin-one-form" hidden={hide}>
				<TextInputRow
					className="admin-text-input"
					mandatory={true}
					id={"id-internal-state-button"}
					name={"buttonText"}
					label={I("buttonText")}
					value={buttonText}
					maxLength={"24"}
					onChange={this.handleButtonTextChange}
				/>
				<TextInputRow
					mandatory={true}
					className="admin-text-input"
					id={"id-internal-state-sectionTitle"}
					name={"sectionTitle"}
					label={I("sectionTitle")}
					value={sectionTitle}
					maxLength={"24"}
					onChange={this.handlesSectionTitleChange}
				/>
				{section}
			</div>
		)
	}
}

class ButtonSection extends PureComponent {
	constructor(props) {
		super(props);
	}
	handleTextInputChange=( idx, e )=>{
		let lv ={
			id: idx,
			name: e.target.name,
			value: e.target.value
		}
		this.props.buttonChangeData(lv);
	}
	render() {
		const { value, hide, buttonsVal } = this.props;
		let section = [];
		let sectionContent = [];
		for(let i = 1; i <= value; i++){
			let sv = buttonsVal[i-1];
			let position = 'left';
			if( i % 2 == 0 ){
				position = 'right';
			}
			sectionContent.push(<div className={position} key={i}>
					<div>{I("Button#")} {i}</div>
					{/* <TextInputRow
						id={"id-internal-state-title"}
						name={"types-"+ i}
						label={I("Types")}
						value={sv.type}
						onChange={this.handleTextInputChange.bind(this, i)}
					/> */}
					<TextInputRow
						mandatory={true}
						className="admin-text-input-section"
						id={"id-internal-state-title"}
						name={"title-"+ i}
						label={I("Title")}
						value={sv.title}
						maxLength={"20"}
						onChange={this.handleTextInputChange.bind(this, i)}
					/>
					<SelectInputRow
						readonly={true}
						className="admin-text-input"
						id={"interactive-number-of-option"}
						name={"value"}
						label={I('Value')}
						option={INTERACTIVE_NUMBER_OF_OPTION}
						value={sv.value}
						onSelect={this.onHandleChangeOption}
					/>
				</div>);
			if( position == 'right' ){
				section.push(<Div key={i}>{sectionContent}</Div>);
				sectionContent = [];
			}else{
				if( i >= value ){
					section.push(<Div key={i}>{sectionContent}</Div>);
				}
			}

		}
		return (
			<div className="admin-one-form" hidden={hide}>
				{section}
			</div>
		)
	}
}

class WordListForm extends PureComponent {
	constructor(props) {
		super(props);
	}
	render() {
		const {input, modal, langSrc, warning, onHandleChangeData, onHandleTextInputBlur, onHandleTextInputChange} = this.props;
		return (
			<div className="admin-one-form">
				<SelectInputRow
					id={modal ? "languagePopup" : "language"}
					name="language"
					className={"admin-edit-language"}
					label={I('Language')}
					option={formatValueToNameObj(langSrc)}
					value={input.language}
					onSelect={onHandleChangeData}
				/>
				<TextInputRow
					id={modal ? "wordListnamePopup" : "wordListname"}
					name="wordListname"
					className="admin-text-input"
					label={I("Word")}
					value={input.wordListname ? input.wordListname : ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={warning}
					onBlur={onHandleTextInputBlur}
				/>
			</div>
		)
	}
}

const getLevelLabel = level => {
	if (level == 2) {
		return I('2nd level tags')
	} else if (level == 3) {
		return I('3rd level tags')
	}
	return I('{ORDINAL} level tags').replace(
		'{ORDINAL}',
		ordinal_suffix_of(level)
	)
}

class TagNewEditForm extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			showArea: false
			, showSubTag: false
			, tagLevel: 1
			, connectedTags: []
		};
		this.handleTagColorPicker = this.handleTagColorPicker.bind(this);
		this.handleToggle = this.handleToggle.bind(this);
		this.handleToggleArea = this.handleToggleArea.bind(this);
		this.handleSelectArea = this.handleSelectArea.bind(this);
		this.handleSubTagsChange = this.handleSubTagsChange.bind(this);
		this.handleSubToggleSubTag = this.handleSubToggleSubTag.bind(this);
		this.handleAddSubTag = this.handleAddSubTag.bind(this);
		this.handleCreateNewSubTag = this.handleCreateNewSubTag.bind(this);
		this.handleOpenEdit = this.handleOpenEdit.bind(this);
	}
	handleTagColorPicker(c) {
		this.props.onHandleChangeData(c, "tagColor");
	}
	handleToggle(val, field) {
		this.props.onHandleChangeData(val, field);
	}
	handleToggleArea() {
		this.setState({showArea: !this.state.showArea});
	}
	handleSelectArea(val) {
		this.props.onHandleChangeData(val, "selectedArea");
	}
	isEndLevel(maxLevel, level) {
		if (maxLevel < 0) {
			return false
		}
		return level >= maxLevel
	}
	handleSubTagsChange(value) {
		if(value) {
			const vals = value.split(',').map(function(item) {
				return parseInt(item, 10);
			});
			this.setState({ connectedTags: vals });
		} else {
			this.setState({ connectedTags: [] });
		}
	}
	handleSubToggleSubTag() {
		this.setState({ showSubTag: !this.state.showSubTag });
	}
	handleAddSubTag() {
		if(this.props.activeId === 0) {
			if(this.state.connectedTags.length === 0) {
				this.props.onEmptySubTagsWarning();
			} else {
				this.props.onHandleChangeData(this.state.connectedTags, "newSubTags");
				this.props.onSaveAdmin();
				this.setState({ connectedTags: [] });
			}
		} else {
			if(this.state.connectedTags.length > 0) {
				const params = {
					"id": this.props.activeId
					, "tags": this.state.connectedTags
					, "addToArea": true
				};
				const param = { params: JSON.stringify(params) };
				this.props.onAppendSubTags(param, this.props.view);
				this.setState({ connectedTags: [] });
			} else {
				this.props.onEmptySubTagsWarning();
			}
		}
	}
	handleCreateNewSubTag() {
		const newLevel = this.props.input.level + 1;
		this.props.onHandleChangeData(true, "creatingNewSubTagMode");
		const params = {
			id: this.props.input.id
			, newLevel
		}
		this.props.onCreateNew(params, this.props.view, this.props.tagsList)
	}
	renderAreas = (cell, row) => {
		let areaLen = 1, areaList = "";;
		if(typeof cell != "undefined") {
			if(cell && cell.length > 0) {
				areaLen = cell.length-1;
				if(areaLen > 0) {
					areaList = cell.join("\n");
				}
			}
		}
		return (
			<div title={areaList}>{areaLen}</div>
		);
	}
	renderActions = (cell, row) => {
		return renderEditButton(cell, row, this.props);
	}
	handleOpenEdit(id) {
		this.props.onLoadToEditRow(id, M_TAG, this.props.tagsList);
	}
	render() {
		const {activeId, input, modal, areaList, warning, tagsList, subTagsList, onHandleTextInputBlur, onHandleTextInputChange} = this.props;
		const areaWarning = "";
		const maxLevel = -1, tagLevel = input.level ? parseInt(input.level, 10) : 1;

		const columns = [{
			dataField: 'id',
			text: I("ID"),
			hidden: true
		}, {
			dataField: 'name',
			text: I("Name"),
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: '45%' };
			}
		}, {
			dataField: 'levelsUnderneath',
			text: getLevelLabel(input.level ? input.level + 2 : 3),
			sort: true
		}, {
			isDummyField: true,
			dataField: 'actions',
			text: "",
			formatter: (cell, row) => {
				return this.renderActions(cell, row);
			},
		}];
		const rowEvents = {
			onClick: (e, row, rowIndex) => {
				return this.handleOpenEdit(row.id);
			}
		};
		return (
			<div className="admin-one-form">
				<h5 style={{margin: "20px 0px"}}>
					{input.id == 0
						? I('Create New Tag: Level {LEVEL}').replace('{LEVEL}', (input.level == 0 ? 1 : input.level))
						: I('Edit Tag: {NAME} (Level {LEVEL})')
							.replace('{NAME}', input.tagName)
							.replace('{LEVEL}', (input.level == 0 ? 1 : input.level))
					}
				</h5>
				<TextInputRow
					id={modal ? "tagNamePopup" : "tagNameInline"}
					name="tagName"
					className="admin-text-input"
					label={I("Name")}
					value={input.tagName ? input.tagName : ""}
					readOnly={input.id == 0 ? false : true}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={warning}
					onBlur={onHandleTextInputBlur}
				/>
				<ToggleSwitch
					id={"tagDelete"}
					name={"tagDelete"}
					data-qa-id="admin-delete-tag"
					label={I('Delete tag?')}
					checked={input.tagDelete}
					onClick={this.handleToggle}
					listWrap={false}
					className="admin-text-input"
				/>
				<ToggleSwitch
					id={"tagVIP"}
					name={"tagVIP"}
					data-qa-id="admin-tag-vip"
					label={I('VIP tag?')}
					checked={input.tagVIP}
					onClick={this.handleToggle}
					listWrap={false}
					className="admin-text-input"
				/>
				{ input.tagVIP &&
					<ReadOnlyTextField
						id={"adminTag-colorPicker"}
						name="tagColor"
						className="admin-text-input"
						label={I("Select flag color")}
					>
					<ColorPicker id="tagColorPicker"
						onChangeColor={this.handleTagColorPicker}
						selected={input.tagColor}
					/>
				</ReadOnlyTextField>
				}
				<FormInputWithLabelRow
					label={I('Area')}
					mandatory={true}
					warning={areaWarning}
				>
					<NoFlipMultiSelect
						id={"area"}
						title={I("Select Area")}
						className="popup-multi-select"
						overrideTitle={true}
						groupSelect={true}
						nested={{key: 'Areas'}}
						data={areaList}
						idFields={OBJ_ID_NAME}
						selected={input.selectedArea}
						onSelect={this.handleSelectArea}
						onToggle={this.handleToggleArea}
						show={this.state.showArea}
						selectAll={true}
						selectNone={true}
					/>
				</FormInputWithLabelRow>
				{ !this.isEndLevel(maxLevel, tagLevel) && tagsList.length > 0 ?
					<Fragment>
						<FormInputWithLabelRow
							label={I('Add sub-tag')}
							mandatory={false}
							className={"add-new-sub-tag-wrapper"}
						>
							<NoFlipMultiSelect
								id={"add-from-list-tag-dropdown"}
								title={I("Sub tag")}
								className="popup-multi-select"
								overrideTitle={true}
								groupSelect={true}
								data={subTagsList}
								idFields={{ id: 'id', name: 'name' }}
								selected={this.state.connectedTags}
								onToggle={this.handleSubToggleSubTag}
								show={this.state.showSubTag}
								selectAll={true}
								multiSelect={false}
								selectNone={true}
								onSelect={this.handleSubTagsChange}
							/>
							<SaveButton
								key="btn-add-sub-tag-admin"
								text={activeId === 0 ? I("Save and Add") : I("Add")}
								disabled={false}
								onClick={this.handleAddSubTag}
							/>
							<label style={{width: "auto"}}>{I("or")}</label>
							<SaveButton
								key="btn-add-new-sub-tag-admin"
								text={activeId === 0 ? I("Save and Create new") : I("Create new")}
								disabled={false}
								onClick={this.handleCreateNewSubTag}
							/>
						</FormInputWithLabelRow>
					</Fragment>
					: ""
				}
				<div>
					<FormInputWithLabelRow
						label={getLevelLabel(input.level ? input.level + 1 : 2)}
					></FormInputWithLabelRow>
					<BootstrapTable
						classes={TABLE_BASE_CLASSNAME+" sub-tag-table"}
						keyField='id'
						bordered={false}
						data={ input.connectedTagsList }
						columns={ columns }
						rowEvents={ rowEvents }
					/>
				</div>
			</div>
		)
	}
}

class ErrandInternalStateForm extends PureComponent {
	constructor(props) {
		super(props);
		this.handleDataChange = this.handleDataChange.bind(this);
		this.handleTextInputChange = this.handleTextInputChange.bind(this);
	}
	handleDataChange(value, field) {
		this.props.onHandleChangeData(value, field, M_ERRANDINTERNALSTATE);
	}
	handleTextInputChange (e) {
		let field = e.target.name, value = e.target.value;
		this.props.onHandleChangeData(value, field, M_ERRANDINTERNALSTATE);
	}
	render() {
		const {hidden, input, onHandleTextInputChange,
			onHandleChangeData} = this.props;
		let iState = input.selectedErrandInternalState;
		return (
			<div className="admin-one-form">
				<Label>{iState.name}</Label>
				<CheckboxInputRow
					label={I("Hide state from agent")}
					id={"id-internal-state-hide"}
					data-qa-id="hide"
					name={"internal-state-hide"}
					checked={iState.hide}
					onChange={this.handleDataChange}
				/>
				<CheckboxInputRow
					label={I("Send update to sender")}
					id={"id-internal-state-sendupdate"}
					name={"internal-state-sendupdate"}
					data-qa-id="sendupdate"
					checked={iState.sendupdate}
					onChange={this.handleDataChange}
				/>
				<TextInputRow
					id={"id-internal-state-subject"}
					name={"subject"}
					label={I("Subject")}
					value={iState.subject ? iState.subject: ""}
					onChange={this.handleTextInputChange}
				/>
				<TextInputRow
					id={"id-internal-state-body"}
					name={"htmlbody"}
					label={I("Body")}
					value={iState.htmlbody ? iState.htmlbody: ""}
					onChange={this.handleTextInputChange}
					textArea={true}
				/>
			</div>
		)
	}
}

class AddressBookForm extends PureComponent {
	constructor(props) {
		super(props);
		this.handleTextInputBlur = this.handleTextInputBlur.bind(this);
		this.handleCheckInput = this.handleCheckInput.bind(this);
		this.state = {
			nameWarning: "",
			entryWarning: ""
		}
	}
	handleCheckInput(field, value) {
		if(field === "addressBookName"){
			if(value.length === 0) {
				this.setState({nameWarning: INPUT_EMPTY_WARNING});
				return false;
			}else{
				this.setState({nameWarning: ""});
				return true;
			}
		}else {
			let supportMultiAddress = false, channel = this.props.input.selectedChannel;
			if(channel === Workflow.Errand.SERVICE_EMAIL) {
					supportMultiAddress = true;
				}
			if(!addressValidation(channel, value, supportMultiAddress)) {
				this.setState({entryWarning: I("* Please insert correct contact address")});
				return false;
			}else{
				this.setState({entryWarning: ""});
				return true;
			}
		}
		return true;
	}
	handleTextInputBlur(e){
		let field = e.target.name, value = e.target.value;
		this.handleCheckInput(field, value);
	}

	getPlaceholderByChannelId = (channelId) => {
		switch (channelId) {
			case 1:
				return "xyz@mnb.com";
			case 3:
				return "Enter chat username...";
			case 4:
				return "facebook-username";
			case 5:
				return "twitter-username";
			case 6:
				return "linkedin-username";
			case 7:
				return "Enter phone number for SMS...";
			case 12:
				return "centionline-userid";
			case 14:
				return "VKontakte";
			case 16:
				return "Enter phone number or SIP number...";
			case 17:
				return "instagram-username";
			case 19:
				return "Enter form information...";
			case 21:
				return "youtube-channelid";
			case 23:
				return "wa-phonenumber";
			case 27:
				return "Enter chat bot information...";
			case 29:
				return "slack-username";
			case 30:
				return "telegram-phonenumber";
			case 31:
				return "viber-phonenumber";
			case 32:
				return "trustpilot-username";
			case 33:
				return "jira-userid";
			case 34:
				return "teams-username";
			case 35:
				return "google-review-username";
			case 36:
				return "google-chat-username";
			case 37:
				return "linkedin-username";
			case 38:
				return "sip-voice-phonenumber";
			case 39:
				return "sip-video-phonenumber";
			default:
				return "Enter address...";
		}
	};
	
	
	render() {
		const {input, warning, modal, channels, onHandleChangeData, onHandleTextInputBlur, onHandleTextInputChange} = this.props;
		let useTextArea = false, channel = this.props.input.selectedChannel;
		console.log("...................", channels)
		if(channel === Workflow.Errand.SERVICE_EMAIL) {
			useTextArea = true;
		}
		return (
			<div className="admin-one-form">
				<TextInputRow
					id={modal ? "addressBookNamePopup" : "addressBookName"}
					name="addressBookName"
					className="admin-text-input"
					label={I("Name")}
					value={input.addressBookName ? input.addressBookName : ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={this.state.nameWarning}
					onBlur={this.handleTextInputBlur}
				/>
				<FormInputWithLabelRow label={I('Address')} mandatory={true} warning={""} className="admin-addressbook-row">
					<SelectInputRow
						id={modal ? "channelPopup" : "selectedChannel"}
						name="selectedChannel"
						className={"admin-select-channel"}
						option={channels}
						value={(input.selectedChannel === 0 ? Workflow.Errand.SERVICE_EMAIL : input.selectedChannel)}
						onSelect={onHandleChangeData}
					/>
					<TextInputRow
    					id={modal ? "addressBookEntryPopup" : "addressBookEntry"}
    					name="addressBookEntry"
    					className="admin-text-input addressbook"
    					value={input.addressBookEntry ? input.addressBookEntry : ""}
    					placeholder={this.getPlaceholderByChannelId(input.selectedChannel)}
    					textArea={useTextArea}
						cols={50}
						rows={10}
						onChange={onHandleTextInputChange}
						helperTxt={useTextArea ? I("To insert multiple addresses, use comma to separate each email"): ""}
						warning={this.state.entryWarning}
						mandatory={true}
    					onBlur={this.handleTextInputBlur}
					/>
				</FormInputWithLabelRow>
			</div>
		)
	}
}

class ReviewByAgent extends PureComponent {
	constructor(props) {
		super(props);
		this.handleToggleArea = this.handleToggleArea.bind(this);
		this.handleSelectArea = this.handleSelectArea.bind(this);
		this.state = {
			showArea: false,
		}
	}
	handleToggleArea() {
		this.setState({showArea: !this.state.showArea});
	}
	handleSelectArea(val) {
		this.props.onChangeAdminInput("selectedAgents", val, this.props.view);
	}
	render() {
		const {agentList, onHandleChangeData, input} = this.props;
		return (
			<div className="admin-one-form">
				<FormInputWithLabelRow label={I('Agents')}
					mandatory={true}
					warning={""}
					className="admin-review-by-agent">
						<NoFlipMultiSelect
							id={"area"}
							title={I("Select Agent")}
							className="popup-multi-select"
							overrideTitle={true}
							groupSelect={false}
							data={agentList}
							idFields={OBJ_ID_NAME}
							selected={input.selectedAgents}
							onSelect={this.handleSelectArea}
							onToggle={this.handleToggleArea}
							show={this.state.showArea}
							selectAll={true}
							selectNone={true}
						/>
				</FormInputWithLabelRow>
			</div>
		)
	}
}

class FileArchiveForm extends React.PureComponent {
	constructor(props){
		super(props);

	}
	handleUploadedFile = (file) => {
		this.props.onChangeAdminInput("file", file);
		this.props.onChangeAdminInput("fileArchiveName", file.name);
	}

	render() {
		const {
			input
			, areaList
			, activeId
			, onHandleChangeData
			, onHandleTextInputBlur
			, onHandleTextInputChange
		} = this.props;

		const urlPreview = filePathPrefix() + AdminFileArchivePreview;
		let fileName = input.fileArchiveName, getFileExtension, isImage;
		if(fileName){
			getFileExtension =fileName.substring(fileName.lastIndexOf(".")+1);
			isImage = ['jpeg','jpg','jpe','gif','tif','tiff','png','bmp'].includes(getFileExtension.toLowerCase());
		}
		return (
			<div className="admin-one-form custom">
				{/* thumbnail frame */}
				{isImage &&
				<div className="preview-container" hidden={!activeId}>
					<div className="label-block">
						<ScrollContainer className="scroll-container admin" hideScrollbars={false}>
							<img src={urlPreview+`?archive=${activeId}`} alt="image"/>
						</ScrollContainer>
					</div>
				</div>}
				{/* form */}
				<div className="form-fields-container">
				<ReadOnlyTextField
					id={"fileArchiveFileName"}
					name="fileArchiveFileName"
					className="admin-text-input"
					label={I("File name")}
					onChange={onHandleTextInputChange}
					value={input.fileArchiveName}>
					<FileUploaderInput
						hide={activeId}
						className={"btn admin-browse bg-transparent"}
						text={I("Browse")}
						// iconClass={"fas fa-file-upload"}
						onUploadFile={this.handleUploadedFile}
					/>
				</ReadOnlyTextField>
				<TextInputRow
					id={"fileArchiveDescription"}
					name="fileArchiveDescription"
					className="admin-text-input"
					label={I("Description")}
					value={input.fileArchiveDescription ? input.fileArchiveDescription : ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
				/>
				<AreaSelection
					data={areaList}
					onSelect={onHandleChangeData}
					selected={input.selectedArea}
					warning=""
				/>
				</div>
			</div>
		);
	}
}

const FilePreviewV5Popup = (props) => {
	const {show, onClose, file, type, download} = props;

	return <PopupPage
	data-qa-id="doc-preview-backdrop-popup"
	extraClass="doc-preview-backdrop"
	innerClass="doc-preview-option"
	show={show}
	onClose={onClose}
	>	<div className="popup-title">
	</div>
	<div>
		<FilePreviewV5 src={file} type={type} downloadSrc={download} downloadPosition="top" />
	</div>
	</PopupPage>
}

const GenAIDocumentForm = (props) => {
	const {
		input
		, areaList
		, langSrc
		, activeId
		, onHandleChangeData
		, onHandleTextInputBlur
		, onHandleTextInputChange
	} = props;

	const [isFile, setIsFile] = useState(true);
	const handleUploadedFile = (file) => {
		props.onChangeAdminInput("file", file);
		props.onChangeAdminInput("docName", file.name);
	}
	const handleSwitchBoxCheck = (checked, state) => {
		const name = state;
		const value = !checked;
		setIsFile(value);
		if(name === "isFile") {
			if(value){
				props.onChangeAdminInput("docUrl", "");
			} else {
				props.onChangeAdminInput("docName", "");
			}
		}
	}

	const [openPreview, setOpenPreview] = useState(false);
	const handleOpenFilePreview  = () => {
		setOpenPreview(true);
	}
	const handleCloseFilePreview = () => {
		setOpenPreview(false);
	}

	useEffect(() => {
		if(input.docType === "URL"){
			setIsFile(false);
		} else {
			setIsFile(true);
		}
	}, [input.docType]);

	const urlPreview = filePathPrefix() + AdminGenerativeAIDocumentPreview;
	let fileName = input.docName, getFileExtension, isDoc, fileLink;
	if(fileName){
		getFileExtension = input.docName.substring(fileName.lastIndexOf(".")+1);
		isDoc = ['pdf','txt','doc','docx','xls','xlsx','pptx','json','md','csv'].includes(getFileExtension.toLowerCase());
		fileLink = urlPreview+`?doc=${input.id}`;
	}

	return (
		<div className="admin-one-form custom">
			{isDoc &&
				<div className="preview-doc preview-container" style={{height: '100px'}} hidden={!activeId}>
					<div className="label-block" style={{height: '100px'}}>
						<div className="preview-doc-text">
							<span onClick={handleOpenFilePreview}> {I("Click to Preview")} </span>
							<FilePreviewV5Popup
								show={openPreview}
								file={fileLink}
								type={getFileExtension.toLowerCase()}
								download={fileLink}
								onClose={handleCloseFilePreview}/>
							</div>
					</div>
				</div>}
			<div className="form-fields-container">
				<div className="label-block">
					<div className="label-isFile">
						<label className="label-row">{I("Upload file")}</label>
					</div>
					<div className="input-container">
						<SwitchCheckbox
							active={isFile}
							disabled={activeId ? true : false}
							buttonClassName={""}
							id={"isFile"}
							name={"isFile"}
							type="checkbox"
							onClick={() => { handleSwitchBoxCheck(isFile, "isFile") }}
						/>
					</div>
				</div>
				{isFile &&
					<ReadOnlyTextField
						id={"docName"}
						name="docName"
						className="admin-text-input"
						label={I("File name")}
						onChange={onHandleTextInputChange}
						value={input.docName}>
						<FileUploaderInput
							//hide={activeId}
							className={"btn admin-browse bg-transparent"}
							text={I("Browse")}
							// iconClass={"fas fa-file-upload"}
							onUploadFile={handleUploadedFile}
						/>
					</ReadOnlyTextField>
				}
				{!isFile &&
					<TextInputRow
						id={"docUrl"}
						name="docUrl"
						className="admin-text-input"
						label={I("URL")}
						value={input.docUrl ? input.docUrl : ""}
						onChange={onHandleTextInputChange}
						onBlur={onHandleTextInputBlur}
					/>
				}
				<TextInputRow
					id={"docDescription"}
					name="docDescription"
					className="admin-text-input"
					label={I("Description")}
					value={input.docDescription ? input.docDescription : ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
				/>
				<SelectInputRow
					id="selectedLanguage"
					name="selectedLanguage"
					label={I('Language')}
					option={formatValueToNameObj(langSrc)}
					value={input.selectedLanguage}
					onSelect={onHandleChangeData}
				/>
				<AreaSelection
					data={areaList}
					onSelect={onHandleChangeData}
					selected={input.selectedArea}
					warning=""
				/>
			</div>
		</div>
	);
}

class AutoTagFormInputs extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			colorPick: this.props.choosenTagColor
		}
	}

	componentDidUpdate(prevProps){
		if(prevProps.choosenTagColor !== this.props.choosenTagColor) {
			this.setState({
			  colorPick: this.props.choosenTagColor
			});
		}
	}

	handleTagColorPicker = (c) => {
		this.props.onChangeAdminInput("tagVIPColor", c, this.props.view);
		this.setState({
			colorPick: c
		})
	}

	handleTag = (val, name) => {
		const p = this.props
		p.onChangeAdminInput(name, val, p.view);
		let colorUpdate = "";
		if(val){
			$.each(p.routeTags, (i, v) => {
				if(p.routeTags[i].id === val){
					colorUpdate = p.routeTags[i].tagColor;
					p.onChangeAdminInput("tagVIPColor", colorUpdate, p.view);
				}
			})
		}
		this.setState({
			colorPick: colorUpdate
		})
	}

	render() {
		const { routeTags
			, tagId
			, tagColor
			, colorOption
			, choosenTagColor
		} = this.props

		return (
			<Fragment>
				<SelectInputRow
					className='admin-select-tags-option'
					hidden={false}
					id='autoTags-option'
					label={I('Tag')}
					manualHeight='auto'
					name='tagLevel'
					onSelect={this.handleTag}
					option={routeTags}
					value={tagId}
				/>
				{choosenTagColor &&
				<ReadOnlyTextField
					id={"autotags-color"}
					name="tagColor"
					className="admin-text-input"
					label={I("Color")}
					value={choosenTagColor}/>
				}
				{colorOption &&
				<ReadOnlyTextField
					id={"autotags-colorPicker"}
					name="tagFlagColor"
					className="admin-text-input"
					label={I("Select flag color")}
				>
					<ColorPicker id="tagColorPicker"
						onChangeColor={this.handleTagColorPicker}
						selected={this.state.colorPick}
					/>
				</ReadOnlyTextField>
				}
			</Fragment>
		)
	}
}

class RouteForm extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			showArea: false
		};
	}

	toggleArea = (t, v) => {
		this.setState({showArea: !v});
	}

	handleSelectArea = (val) => {
		this.props.onChangeAdminInput("selectedArea", val, this.props.view);
	}

	render() {
		const {
			areaList
			, onHandleChangeData
			, input
			, onHandleTextInputChange
			, onHandleTextInputBlur
			, warning
			, routeTags
			, onChangeAdminInput
			, view
			, children
		} = this.props;

		let selectedArea = parseInt(input.selectedArea, 10);
		let tagId, colorOption = false, tagColor = "";
		if(view === M_ROUTING_AUTOTAGS){
			tagId = input.tagLevel;
			if(routeTags && routeTags.length == 1) {
				//if only one item in tag list (routeTags), then there is no other selections
				//so we select it by default and set that value on store
				tagId = routeTags[0].id;
				// let selTagC = routeTags[0].tagColor
				onChangeAdminInput("tagLevel", tagId, view);
				onChangeAdminInput("tagVIPColor", routeTags[0].tagColor, view);

			}else if (routeTags && routeTags.length == 0) {
				// reset the tags if no value
				tagId = UNSELECT;
				onChangeAdminInput("tagLevel", "", view);
			}
			if(tagId){
				$.each(routeTags, (i, v) => {
					if(routeTags[i].id === tagId){
						tagColor = routeTags[i].tagColor;
						// NOTE: as of this commit, only parent TAG seems to support VIP tag
						if(routeTags[i].isVIP){
							colorOption = true
						}
					}
				})
			}
		}

		let isSipView = false;
		if(view === M_ROUTING_SIP){
			isSipView = true
		}
		return (
			<div className="admin-one-form">
				<Area
					show={this.state.showArea}
					data={areaList}
					selected={selectedArea}
					notReady={!areaList}
					onToggle={this.toggleArea}
					readOnly={false}
					onSelect={this.handleSelectArea}
				/>
				<SelectInputRow
					hide={isSipView}
					id={"review-option"}
					name={"type"}
					label={I('Type')}
					className={"admin-select-review-option"}
					option={ROUTE_KEYWORDS_OPTION}
					value={input.type}
					onSelect={onHandleChangeData}
				/>
				<TextInputRow
					hide={isSipView}
					id={"routeKeywordsContent"}
					name={"content"}
					className="admin-text-input"
					label={I("Keywords")}
					value={input.content ? input.content : ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={warning}
					onBlur={onHandleTextInputBlur}
				/>
				{view === M_ROUTING_SIP &&
				<TextInputRow
					id={"routeSipId"}
					name={"routeSipId"}
					className="admin-text-input"
					label={I("SIP id")}
					value={input.routeSipId ? input.routeSipId : ""}
					onChange={onHandleTextInputChange}
					mandatory={false}
					warning={warning}
					onBlur={onHandleTextInputBlur}
				/>}
				{view === M_ROUTING_AUTOTAGS &&
				<AutoTagFormInputs
					routeTags={routeTags}
					view={view}
					tagId={tagId}
					tagColor={tagColor}
					choosenTagColor={this.props.selectedTagColor}
					colorOption={colorOption}
					onChangeAdminInput={onChangeAdminInput}
				/>}
			</div>
		)
	}
}

class CompanyForm extends React.PureComponent {
	constructor(props){
		super(props);
	}
	render() {
		const {
			input
			, activeId
			, onHandleChangeData
			, onHandleTextInputBlur
			, onHandleTextInputChange
		} = this.props;

		return (
			<div className="admin-one-form custom">
				<div className="form-fields-container">
				<TextInputRow
					id={"companyName"}
					name="companyName"
					className="admin-text-input"
					label={I("Name")}
					mandatory={true}
					value={input.companyName ? input.companyName : ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
				/>
				<TextInputRow
					id={"companyAddress"}
					name="companyAddress"
					className="admin-text-input"
					label={I("Address")}
					value={input.companyAddress ? input.companyAddress : ""}
					textArea={true}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
				/>
				<TextInputRow
					id={"companyZipcode"}
					name="companyZipcode"
					className="admin-text-input"
					label={I("Zip code")}
					value={input.companyZipcode ? input.companyZipcode : ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
				/>
				<TextInputRow
					id={"companyCity"}
					name="companyCity"
					className="admin-text-input"
					label={I("City")}
					value={input.companyCity ? input.companyCity : ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
				/>
				<TextInputRow
					id={"companyEmail"}
					name="companyEmail"
					className="admin-text-input"
					label={I("Email")}
					value={input.companyEmail ? input.companyEmail : ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					warning={this.props.warning}
				/>
				<TextInputRow
					id={"companyFax"}
					name="companyFax"
					className="admin-text-input"
					label={I("Fax")}
					value={input.companyFax ? input.companyFax : ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
				/>
				<TextInputRow
					id={"companyPhone"}
					name="companyPhone"
					className="admin-text-input"
					label={I("Phone")}
					value={input.companyPhone ? input.companyPhone : ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
				/>
				<TextInputRow
					id={"companyMobile"}
					name="companyMobile"
					className="admin-text-input"
					label={I("Mobile")}
					value={input.companyMobile ? input.companyMobile : ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
				/>
				</div>
			</div>
		);
	}
}

class StunTurnForm extends React.PureComponent {
	constructor(props){
		super(props);
		this.handleSelectedService = this.handleSelectedService.bind(this);
		this.handleServiceToggle = this.handleServiceToggle.bind(this);
		this.state = {
			showService: false
		};
	}
	handleSelectedService = (data) => {
		this.props.onHandleChangeData(data, "selectedWebRtcService");
	}
	handleServiceToggle = (data) => {
		this.setState({showService: !this.state.showService});
	}
	render() {
		const {
			input
			, onHandleTextInputChange
			, onHandleTextInputBlur
			, onHandleChangeData
			, stunTurnServices
			, hide
		} = this.props;
		const serverTypes = [
			{id : "stun", name: "stun"},
			{id : "turn", name: "turn"}
		];
		return(
			<div className="admin-one-form custom">
				<div className="form-fields-container">
				<TextInputRow
					id={"stunturnname"}
					name="stunturnname"
					className="admin-text-input"
					label={I("Name")}
					mandatory={true}
					value={input.stunturnname ? input.stunturnname : ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
				/>
				<TextInputRow
					id={"stunturnurl"}
					name="stunturnurl"
					className="admin-text-input"
					label={I("URL")}
					mandatory={true}
					value={input.stunturnurl ? input.stunturnurl : ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
				/>
				<TextInputRow
					id={"stunturnusername"}
					name="stunturnusername"
					className="admin-text-input"
					label={I("User name")}
					mandatory={false}
					value={input.stunturnusername ? input.stunturnusername : ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
				/>
				<TextInputRow
					id={"stunturnpassword"}
					name="stunturnpassword"
					className="admin-text-input"
					label={I("Password")}
					mandatory={false}
					value={input.stunturnpassword ? input.stunturnpassword : ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
				/>
				<FormInputWithLabelRow
					label={I('Server type')}
					mandatory={true}
					warning={""}
					className={"admin-servertype-row"}
				>
					<SelectInputRow
						hidden={false}
						id={"stunturnservertype"}
						name={"stunturnservertype"}
						className={"admin-select-channel"}
						option={serverTypes}
						mandatory={true}
						value={input.stunturnservertype}
						onSelect={onHandleChangeData}
					/>
				</FormInputWithLabelRow>
				<FormInputWithLabelRow label={I('WebRtc services')}>
					<NoFlipMultiSelect
						flip={true}
						id={"webrtcservices"}
						title={I("Select services")}
						mandatory={true}
						className={"popup-multi-select"}
						overrideTitle={true}
						groupSelect={true}
						data={stunTurnServices}
						idFields={{id: "id", name: "name"}}
						selected={input.selectedWebRtcService}
						onSelect={this.handleSelectedService}
						onToggle={this.handleServiceToggle}
						show={this.state.showService}
						selectAll={true}
						selectNone={true}
					/>
				</FormInputWithLabelRow>
				</div>
			</div>
		);
	}
}

class JWTAPIForm extends React.PureComponent {
	constructor(props){
		super(props);
		this.state = {
			chSelect: "",
			accSelect: "",
			areaSelect: "",
			calender: "",
			warnChannel: true,
			warnAccount: false,
			keypass: "",
			name: "",
			allowAttachment: false,
			apiv: "",
			automatedConversation: {
				user: "",
				webhook: ""
			},
			copying: false
		};
		this.handleItemsChange = this.handleItemsChange.bind(this);
		this.handleToggleAccess = this.handleToggleAccess.bind(this);
		this.handleSelectDate = this.handleSelectDate.bind(this);
		this.handleCopyToken = this.handleCopyToken.bind(this);
	}
	componentDidMount() {
		//TODO: Sue , these below should no longer needed
		let s = this.state;
		const data = this.props.data;
		if(data) {
			this.pre = {};
			if(data.createErrand.channel > 0) {
				s.chSelect = data.createErrand.channel.toString();
				if(data.createErrand.account) {
					s.accSelect = data.createErrand.account.toString();
				} else if (data.createErrand.area) {
					s.areaSelect = data.createErrand.area.toString();
				}
			}
			if(data.expireDate == noExpireStr) {
				s.calender = "";
			} else {
				s.calender = data.expireDate;
			}
			s.name = data.name;
			this.pre.name = s.name;
			s.allowAttachment = data.allowAttachment;
			s.apiv = data.apiVersion;
			if(data.others) {
				s.others = data.others;
			}
			if(data.automatedConversation) {
				s.automatedConversation.user = data.automatedConversation.user.toString();
				s.automatedConversation.webhook = data.automatedConversation.webhook;
			}
		} else if(this.props.versions && this.props.versions.length &&
			this.props.versions.length > 0) {
			// just force choose the first one
			s.apiv = this.props.versions[0].id.toString();
		}
		this.setState(s);
	}
	handleItemsChange(master, slave, thrall) {
		var newstate = {chSelect: master, accSelect: slave};
		if(typeof thrall !== 'undefined') {
			newstate.areaSelect = thrall;
			this.props.onHandleChangeData(thrall, "jwtCreateErrandArea");
		}
		if(master != "" || !this.props.require) {
			newstate.warnChannel = false;
			this.props.onHandleChangeData(master, "jwtCreateErrandChannel");
		}
		if(slave != "" || thrall != "" || !this.props.require) {
			newstate.warnAccount = false;
			this.props.onHandleChangeData(slave, "jwtCreateErrandAcc");
		}
		this.setState(newstate);
	}
	handleToggleAccess(toggle, id) {
		this.props.onChangeAdminInput(id, toggle, this.props.view);
		if(this.props.activeId == 0) {
			this.setState({warnChannel: false});
		}
	}
	handleSelectDate(date) {
		this.props.onHandleChangeData(date, "jwtExpiryDate");
	}
	handleCopyToken() {
		const textToCopy = document.getElementById("jwtToken").value;
		if (!textToCopy) {
			return;
		}
		if(navigator.clipboard) {
			navigator.clipboard.writeText(textToCopy).then(() => {
				this.setState({ copying: true });
				setTimeout(() => {
					this.setState({ copying: false });
				}, 500);
			})
		} else {
			console.log('Browser Not compatible');
		}
	}
	render() {
		const {
			activeId
			, input
			, onHandleTextInputChange
			, onHandleTextInputBlur
			, onHandleChangeData
			, versions
			, services
			, accounts
			, users
			, ...props
		} = this.props;
		//from old form
		var canNotSave = false;
		var canNotEdit = false;
		var keyPlaceholder = "";
		if(this.state.keypass == "" && this.pre) {
			// existing token editing
			canNotEdit = true;
			keyPlaceholder = I("Can't leave empty if token is changed.");
		}
		if(this.state.name == "") {
			canNotSave = true;
		} else if(this.state.keypass == "") {
			// no token provided so this is create new token which must always
			// has token info
			if(!this.pre || this.pre.name == this.state.name) {
				canNotSave = true;
				if(!this.pre) {
					keyPlaceholder = I("Can't leave field empty");
				}
			}
		}
		let jwtNameWarning = "";
		if(activeId > 0) {
			jwtNameWarning = I("If you change any setting below the Key field, a new token string will be generated. The old token can no longer be used and will be replaced with the new one.")
		}
		let makeReadOnly = false;
		if(activeId > 0) {
			if(input.jwtKey === "") {
				makeReadOnly = true;
			}
		}
		return <div className="admin-one-form custom admin-api-form">
			<div className="form-fields-container">
				<TextInputRow
					id={"jwtName"}
					name="jwtName"
					className="admin-text-input"
					inlineLabel={false}
					label={I("Name")}
					mandatory={true}
					value={input.jwtName ? input.jwtName : ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					helperTxt={I("Name to identify this API token.")}
					warning={jwtNameWarning}
				/>
				<TextInputRow
					id={"jwtKey"}
					name="jwtKey"
					className="admin-text-input"
					inlineLabel={false}
					label={I("Key")}
					mandatory={true}
					value={input.jwtKey ? input.jwtKey : ""}
					onChange={onHandleTextInputChange}
					onBlur={onHandleTextInputBlur}
					placeholder={activeId > 0 ? I("Can't leave empty if token is changed.") : ""}
					helperTxt={I("Secret key that is used to encrypt API token to avoid unauthorized user to generate similar token.")}
				/>

				<div className={classNames({'admin-readonly-wrapper': makeReadOnly})}>
					<div style={{ marginTop: "30px" }}>
						<FormInputWithLabelRow
							label={I('Create errand')}
							mandatory={this.state.warnChannel}
							warning={""}
							className={""}
							inlineLabel={false}
						>
							<TwinDropDown
								items={services}
								slaveItems={accounts}
								thrallItems={props.areaList}
								masterName={I("Channel")}
								slaveName={I("Account")}
								thrallName={I("Area")}
								masterSelect={input.jwtCreateErrandChannel}
								slaveSelect={this.state.accSelect}
								thrallSelect={input.jwtCreateErrandArea}
								onItemsChange={this.handleItemsChange}
								warnMaster={this.state.warnChannel}
								disabled={makeReadOnly}
								require={!(input.jwtAllowAttachment || input.jwtLogioAPI || input.jwtAccessExportAPI || input.jwtStatsAPI
									|| input.jwtAllowAccessLib || input.jwtProgressStatus || input.jwtErrandData || input.jwtCreateCustContact
									|| input.jwtIntegrateAgentLogin)
								}
							/>
						</FormInputWithLabelRow>
					</div>
					<div style={{ marginTop: "50px" }}>
						<FormInputWithLabelRow
							label={I('Automated Conversation')}
							warning={""}
							className={""}
							inlineLabel={false}
						>
							<SelectInputRow
								id={"jwtAutomatedConversationUser"}
								name={"jwtAutomatedConversationUser"}
								className={"admin-select-cb-event"}
								option={users}
								value={input.jwtAutomatedConversationUser}
								textNoItemSelected={I("Choose Agent")}
								onSelect={onHandleChangeData}
								readonly={makeReadOnly}
							/>
						</FormInputWithLabelRow>
					</div>
					<TextInputRow
						id={"jwtACWebhookURL"}
						name="jwtACWebhookURL"
						className="admin-text-input full-width"
						inlineLabel={false}
						label={I("Automated Conversation Webhook URL")}
						mandatory={true}
						value={input.jwtACWebhookURL ? input.jwtACWebhookURL : ""}
						onChange={onHandleTextInputChange}
						onBlur={onHandleTextInputBlur}
						readonly={makeReadOnly}
					/>
					<div className='admin-input-wrapper-half'>
						<ToggleSwitch
							id={"jwtAllowAttachment"}
							name={"jwtAllowAttachment"}
							data-qa-id="admin-enable-callback"
							label={I('Allow to get attachment')}
							checked={input.jwtAllowAttachment}
							onClick={this.handleToggleAccess}
							listWrap={false}
							className="admin-text-input"
							inlineLabel={false}
							disabled={makeReadOnly}
						/>
						<ToggleSwitch
							id={"jwtAllowAccessLib"}
							name={"jwtAllowAccessLib"}
							data-qa-id="admin-enable-callback"
							label={I('Access to library')}
							checked={input.jwtAllowAccessLib}
							onClick={this.handleToggleAccess}
							listWrap={false}
							className="admin-text-input"
							inlineLabel={false}
							disabled={makeReadOnly}
						/>
					</div>
					<div className='admin-input-wrapper-half'>
						<ToggleSwitch
							id={"jwtProgressStatus"}
							name={"jwtProgressStatus"}
							data-qa-id="admin-enable-callback"
							label={I('Access to errand progress status')}
							checked={input.jwtProgressStatus}
							onClick={this.handleToggleAccess}
							listWrap={false}
							className="admin-text-input"
							inlineLabel={false}
							disabled={makeReadOnly}
						/>
						<ToggleSwitch
							id={"jwtErrandData"}
							name={"jwtErrandData"}
							data-qa-id="admin-enable-callback"
							label={I('Access to errand data')}
							checked={input.jwtErrandData}
							onClick={this.handleToggleAccess}
							listWrap={false}
							className="admin-text-input"
							inlineLabel={false}
							disabled={makeReadOnly}
						/>
					</div>
					<div className='admin-input-wrapper-half'>
						<ToggleSwitch
							id={"jwtCreateCustContact"}
							name={"jwtCreateCustContact"}
							data-qa-id="admin-enable-callback"
							label={I('Access and create customer contacts')}
							checked={input.jwtCreateCustContact}
							onClick={this.handleToggleAccess}
							listWrap={false}
							className="admin-text-input"
							inlineLabel={false}
							disabled={makeReadOnly}
						/>
						<ToggleSwitch
							id={"jwtIntegrateAgentLogin"}
							name={"jwtIntegrateAgentLogin"}
							data-qa-id="admin-enable-callback"
							label={I('Access to integration agent login')}
							checked={input.jwtIntegrateAgentLogin}
							onClick={this.handleToggleAccess}
							listWrap={false}
							className="admin-text-input"
							inlineLabel={false}
							disabled={makeReadOnly}
						/>
					</div>
					<div className='admin-input-wrapper-half'>
						<ToggleSwitch
							id={"jwtAccessExportAPI"}
							name={"jwtAccessExportAPI"}
							data-qa-id="admin-enable-callback"
							label={I('Access to export API')}
							checked={input.jwtAccessExportAPI}
							onClick={this.handleToggleAccess}
							listWrap={false}
							className="admin-text-input"
							inlineLabel={false}
							disabled={makeReadOnly}
						/>
						<ToggleSwitch
							id={"jwtLogioAPI"}
							name={"jwtLogioAPI"}
							data-qa-id="admin-enable-callback"
							label={I('Access to logio API')}
							checked={input.jwtLogioAPI}
							onClick={this.handleToggleAccess}
							listWrap={false}
							className="admin-text-input"
							inlineLabel={false}
							disabled={makeReadOnly}
						/>
					</div>
					<div className='admin-input-wrapper-half'>
						<ToggleSwitch
							id={"jwtStatsAPI"}
							name={"jwtStatsAPI"}
							data-qa-id="admin-enable-callback"
							label={I('Access to statistics API')}
							checked={input.jwtStatsAPI}
							onClick={this.handleToggleAccess}
							listWrap={false}
							className="admin-text-input"
							inlineLabel={false}
							disabled={makeReadOnly}
						/>
						<ToggleSwitch
							id={"jwtChatMessages"}
							name={"jwtChatMessages"}
							data-qa-id="admin-enable-callback"
							label={I('Access to chat messages')}
							checked={input.jwtChatMessages}
							onClick={this.handleToggleAccess}
							listWrap={false}
							className="admin-text-input"
							inlineLabel={false}
							disabled={makeReadOnly}
						/>
					</div>
					<div className='admin-input-wrapper-half' style={{ display: "inline-flex", width: '100%' }}>
						<FormInputWithLabelRow
							label={I('Expiration date')}
							warning={""}
							className={""}
							inlineLabel={false}
						>
							<SingleCalenderDD
								id="jwtExpiryDate"
								title=""
								selected={input.jwtExpiryDate === "None" ? null : input.jwtExpiryDate}
								style={{ display: "block" }}
								readOnly={makeReadOnly}
								onSelect={this.handleSelectDate}
							/>
						</FormInputWithLabelRow>

						<FormInputWithLabelRow
							label={I('API version')}
							mandatory={true}
							warning={""}
							className={""}
							inlineLabel={false}
						>
							<SelectInputRow
								id={"jwtAPIVersion"}
								name={"jwtAPIVersion"}
								className={"admin-select-cb-event"}
								option={versions}
								mandatory={true}
								value={input.jwtAPIVersion}
								textNoItemSelected={I("Choose API version")}
								onSelect={onHandleChangeData}
								readonly={makeReadOnly}
							/>
						</FormInputWithLabelRow>
					</div>
				</div>
				<div hidden={activeId > 0 ? false : true}>
					<TextInputRow
						id={"jwtToken"}
						name="jwtToken"
						className={classNames('admin-text-input jwt-token full-width', {'copied': this.state.copying})}
						inlineLabel={false}
						label={I("Token")}
						value={input.jwtToken ? input.jwtToken : ""}
						textIcon="icon-copy"
						textIconTitle={I("Copy")}
						onChange={onHandleTextInputChange}
						onBlur={onHandleTextInputBlur}
						onClickTextIcon={this.handleCopyToken}
						readonly={true}
					/>
				</div>
			</div>
		</div>
	}

}

class TwinDropDown extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleMasterChange = this.handleMasterChange.bind(this);
		this.handleSlaveChange = this.handleSlaveChange.bind(this);
		this.handleThrallChange = this.handleThrallChange.bind(this);
		this.handleToggleArea = this.handleToggleArea.bind(this);

		this.state = {
			slaveItems: [],
			showArea: false,
			areaAccMandatory: false
		}
	}
	handleMasterChange(v) {
		if (!v) {
			this.props.onItemsChange(v, "", "");
		} else {
			if (v != this.props.masterSelect) {
				this.props.onItemsChange(v, "");
				this.setState({areaAccMandatory: true});
			}
		}
	}
	handleSlaveChange(v) {
		if (v != "") {
			this.props.onItemsChange(this.props.masterSelect, v, "");
		} else {
			this.props.onItemsChange(this.props.masterSelect, "");
		}
	}
	handleThrallChange(v) {
		let slave;
		if (v == "") {
			slave = this.props.slaveSelect;
		} else {
			slave = "";
		}
		this.props.onItemsChange(this.props.masterSelect, slave, v);
	}
	handleToggleArea() {
		this.setState({ showArea: !this.state.showArea });
	}
	render() {
		const { items, masterSelect, slaveSelect, thrallSelect, masterName, slaveName, thrallName, slaveItems, thrallItems, onItemsChange, disabled, warnMaster, require, ...props } = this.props;

		const warnAccTxt = I("* Choose either an {ACCOUNT} or an {AREA}")
				.replace('{ACCOUNT}', this.props.slaveName)
				.replace('{AREA}', this.props.thrallName);
		return (
			<div>
				<div className='admin-twin-label'>
					<FormInputWithLabelRow
						label={masterName}
						mandatory={warnMaster}
						warning={""}
						className={""}
						inlineLabel={false}
					>
						<SelectInputRow
							id={masterName}
							name={masterName}
							className={"admin-select-cb-event"}
							option={items}
							mandatory={warnMaster}
							value={masterSelect}
							textNoItemSelected={I("Select")}
							onSelect={this.handleMasterChange}
							readonly={disabled}
						/>
					</FormInputWithLabelRow>
					<FormInputWithLabelRow
						label={slaveName}
						mandatory={this.state.areaAccMandatory}
						warning={this.state.areaAccMandatory ? warnAccTxt : ""}
						className={""}
						inlineLabel={false}
					>
						<SelectInputRow
							id={slaveName}
							name={slaveName}
							className={"admin-select-cb-event"}
							option={slaveItems}
							mandatory={true}
							value={slaveSelect}
							textNoItemSelected={I("No account")}
							onSelect={this.handleSlaveChange}
							readonly={disabled}
						/>
					</FormInputWithLabelRow>
				</div>
				<FormInputWithLabelRow
					label={thrallName}
					mandatory={this.state.areaAccMandatory}
					warning={this.state.areaAccMandatory ? warnAccTxt : ""}
					className={""}
					inlineLabel={false}
				>
					<SingleSelectDD
						id={"jwt-createerrand-area"}
						textNoItemSelected={I("Select area")}
						className={"popup-multi-select"}
						nested={{ key: 'Areas' }}
						data={thrallItems}
						idFields={OBJ_ID_NAME}
						selected={thrallSelect}
						onSelect={this.handleThrallChange}
						isHeader={true}
						onToggle={this.handleToggleArea}
						show={this.state.showArea}
						selectNone={true}
						selectAll={false}
						readOnly={disabled}
					/>
				</FormInputWithLabelRow>
			</div>
		)
	}
}
class CallBackAPIForm extends React.PureComponent {
	constructor(props){
		super(props);
		this.handleToggleArea = this.handleToggleArea.bind(this);
		this.handleSelectArea = this.handleSelectArea.bind(this);
		this.handleToggleEnable = this.handleToggleEnable.bind(this);
		this.state = {
			showArea: false
		}
	}
	handleToggleArea() {
		this.setState({showArea: !this.state.showArea});
	}
	handleSelectArea(val) {
		this.props.onChangeAdminInput("selectedArea", val, this.props.view);
	}
	handleToggleEnable(toggle) {
		this.props.onChangeAdminInput("cbEnabled", toggle, this.props.view);
	}
	render() {
		const {
			activeId
			, input
			, onHandleTextInputChange
			, onHandleTextInputBlur
			, onHandleChangeData
			, areaList
		} = this.props;
		const cbEventTypes = [
			{id: Workflow.CallbackEvents.OFFLINE_CHAT, name: "Answer offline chat" },
			{id: Workflow.CallbackEvents.CLOSE_ERD_WO_ANSWER, name: "Close errand"}
		];
		return (
			<div className="admin-one-form custom admin-api-form">
				<div className="form-fields-container">
					<TextInputRow
						id={"cbName"}
						name="cbName"
						className="admin-text-input"
						inlineLabel={false}
						label={I("Name")}
						mandatory={true}
						value={input.cbName ? input.cbName : ""}
						onChange={onHandleTextInputChange}
						onBlur={onHandleTextInputBlur}
					/>
					<TextInputRow
						id={"cbEndpoint"}
						name="cbEndpoint"
						className="admin-text-input"
						inlineLabel={false}
						label={I("Endpoint")}
						mandatory={true}
						value={input.cbEndpoint ? input.cbEndpoint : ""}
						onChange={onHandleTextInputChange}
						onBlur={onHandleTextInputBlur}
						helperTxt={I("URL that the Cention Contact Center server will call when a callback event is triggered. Scheme must be included (http:// or https://).")}
					/>
					<TextInputRow
						id={"cbSecretKey"}
						name="cbSecretKey"
						className="admin-text-input"
						inlineLabel={false}
						label={I("Secret key")}
						mandatory={true}
						value={input.cbSecretKey ? input.cbSecretKey : ""}
						readonly={activeId ? true : false}
						onChange={onHandleTextInputChange}
						onBlur={onHandleTextInputBlur}
						helperTxt={I("Secret key that is used to encrypt API token to avoid unauthorized user to generate similar token.")}
					/>
					<div style={{display: "inline-block", width: "100%"}}>
						<div style={{float:"left", width: "50%", paddingRight: '2%'}}>
							<FormInputWithLabelRow
								label={I('Event')}
								mandatory={true}
								warning={""}
								className={""}
								inlineLabel={false}
							>
								<SelectInputRow
									id={"cbEvent"}
									name={"cbEvent"}
									className={"admin-select-cb-event"}
									option={cbEventTypes}
									mandatory={true}
									value={input.cbEvent}
									textNoItemSelected={I("Select")}
									onSelect={onHandleChangeData}
								/>
							</FormInputWithLabelRow>
						</div>
						<div style={{float:"right", width: "50%"}}>
							<FormInputWithLabelRow
								label={I('Area')}
								mandatory={true}
								inlineLabel={false}
							>
								<NoFlipMultiSelect
									id={"area"}
									title={I("Select Area")}
									className="popup-multi-select admin-text-input-qrm"
									overrideTitle={true}
									groupSelect={true}
									nested={{key: 'Areas'}}
									data={areaList}
									idFields={OBJ_ID_NAME}
									selected={input.selectedArea}
									onSelect={this.handleSelectArea}
									onToggle={this.handleToggleArea}
									show={this.state.showArea}
									selectAll={true}
									selectNone={true}
								/>
							</FormInputWithLabelRow>
						</div>
					</div>
					<ToggleSwitch
						id={"cbEnabled"}
						name={"cbEnabled"}
						data-qa-id="admin-enable-callback"
						label={I('Enabled')}
						checked={input.cbEnabled}
						onClick={this.handleToggleEnable}
						listWrap={false}
						className="admin-text-input"
						inlineLabel={false}
					/>
				</div>
			</div>
		)
	}
}

class AgentSipStatusForm extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			nameWarning: "",
			internalNameWarning: ""
		}
	}
	// Note: calling onBlur and mutating state can cause onClick to not fire
	// If the nameWarning state changed and clicked Save button, it will not
	// fire OnClick(), in this case need to click for 2nd time to actually fire onClick()
	handleValidateName = (e) => {
		let value = e.target.value;
		if(value.length === 0) {
			this.setState({nameWarning: INPUT_EMPTY_WARNING});
			return;
		}
		if(this.props.onValidateAgentSipStatus){
			return this.props.onValidateAgentSipStatus(this.props.activeId, value)
			.then((result) => {
				if (result.valid) {
					this.setState({nameWarning: ""});
					return;
				}
				this.setState({nameWarning: DUPLICATE_SIP_STATUS_NAME});
				return;
			})
		}
		this.setState({nameWarning: ""});
		return;
	}
	handleValidateInternalName = (e) => {
		let value = e.target.value;
		if(value.length === 0) {
			this.setState({internalNameWarning: INPUT_EMPTY_WARNING});
			return;
		}
		if (value.includes(" ")) {
			this.setState({internalNameWarning: INPUT_SPACE_WARNING});
			return;
		}
		this.setState({internalNameWarning: ""});
		return;
	}
	render() {
		const {
			input
			, onHandleTextInputChange
			, onHandleChangeData
		} = this.props;
		return (
			<div className="admin-one-form">
				<TextInputRow
					id={"sipstatusname"}
					name="name"
					className="admin-text-input"
					label={I("Name")}
					mandatory={true}
					value={input.name ? input.name : ""}
					warning={this.state.nameWarning}
					onChange={onHandleTextInputChange}
					onBlur={this.handleValidateName}
				/>
				<TextInputRow
					id={"internalName"}
					name="internalName"
					className="admin-text-input"
					label={I("Internal Name")}
					mandatory={true}
					value={input.internalName ? input.internalName : ""}
					warning={this.state.internalNameWarning}
					onChange={onHandleTextInputChange}
					onBlur={this.handleValidateInternalName}
				/>
				<SelectInputRow
					hidden={false}
					id={"className"}
					name={"className"}
					label={I('Class')}
					className={"admin-select-review-option"}
					option={USER_STATUS_CLASSNAME_OPTION}
					value={input.className}
					onSelect={onHandleChangeData}
				/>
			</div>
		)
	}
}

class ClassifierForm extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			nameWarning: "",
			modelWarning: "",
		}
	}
	// Note: calling onBlur and mutating state can cause onClick to not fire
	// If the nameWarning state changed and clicked Save button, it will not
	// fire OnClick(), in this case need to click for 2nd time to actually fire onClick()
	handleValidateName = (e) => {
		let value = e.target.value;
		if(value.length === 0) {
			this.setState({nameWarning: INPUT_EMPTY_WARNING});
			return;
		}
		this.setState({nameWarning: ""});
		return;
	}
	handleValidateModel = (e) => {
		let value = e.target.value;
		if(value.length === 0) {
			this.setState({modelWarning: INPUT_EMPTY_WARNING});
			return;
		}
		if (value.includes(" ")) {
			this.setState({modelWarning: INPUT_SPACE_WARNING});
			return;
		}
		this.setState({modelWarning: ""});
		return;
	}
	render() {
		const {
			input
			, areaList
			, onHandleTextInputChange
			, onHandleChangeData
		} = this.props;
		return (
			<div className="admin-one-form">
				<AreaSelection
					data={areaList}
					onSelect={onHandleChangeData}
					selected={input.selectedArea}
					warning=""
				/>
				<TextInputRow
					id={"name"}
					name="name"
					className="admin-text-input"
					label={I("Name")}
					mandatory={true}
					value={input.name ? input.name : ""}
					warning={this.state.nameWarning}
					onChange={onHandleTextInputChange}
					onBlur={this.handleValidateName}
				/>
				<TextInputRow
					id={"model"}
					name="model"
					className="admin-text-input"
					label={I("Model")}
					mandatory={true}
					value={input.model ? input.model : ""}
					warning={this.state.modelWarning}
					onChange={onHandleTextInputChange}
					onBlur={this.handleValidateModel}
				/>
			</div>
		)
	}
}

const SortAdminTableSelectBox = ({
	currentSortAttribute
	, dataSortAttributes
	// , dataSortDirections
	, onSortFields
}) => {
	const handleSortAttribute = useCallback(
		e => onSortFields(e.target.value)
		, [onSortFields]
	)
	,sortAttributes = dataSortAttributes.map(({ id, name }) => {
		switch (id, name) {
		  case 'id':
			return <option key={id} value={name} index={name}>{"Default"}</option>;
		  case 'fileName':
			return <option key={id} value={name} index={name}>{"File name"}</option>;
		  case 'description':
			return <option key={id} value={name} index={name}>{"Description"}</option>;
		  case 'timestampUploaded':
			return <option key={id} value={name} index={name}>{"Date"}</option>;
		  case 'userName':
			return <option key={id} value={name} index={name}>{"User name"}</option>;
		}
	  });
	return (
		<div className="admin-form-select-wrapper">
			<BSInput
				className="admin-form-select"
				type="select"
				name="select"
				id="admin-tableSort"
				value={currentSortAttribute}
				onChange={handleSortAttribute}
			>
				{sortAttributes}
			</BSInput>
		</div>
	);
};

const keyAreas = {key: 'Areas'}
	, idName = {id: "Id", name: "Name"}
	;
const SelectAreaBase = ({ data, onSelect, onToggle, selected, show }) => {
	return (
		<NoFlipMultiSelect
			id="area"
			title={I("Select Area")}
			className="popup-multi-select"
			overrideTitle={true}
			groupSelect={true}
			nested={keyAreas}
			data={data}
			idFields={idName}
			selected={selected}
			onSelect={onSelect}
			onToggle={onToggle}
			show={show}
			selectAll={true}
			selectNone={true}
		/>
	);
};

const SelectArea = withShowToggle(SelectAreaBase);

const SelectAgentBase = ({ data, onSelect, onToggle, selected, show }) => {
	return (
		<NoFlipMultiSelect
			id="agent"
			title={I("Select Agent")}
			className="popup-multi-select"
			overrideTitle={true}
			groupSelect={false}
			data={data}
			idFields={idName}
			selected={selected}
			onSelect={onSelect}
			onToggle={onToggle}
			show={show}
			selectAll={true}
			selectNone={true}
		/>
	);
};

const SelectAgent = withShowToggle(SelectAgentBase);

const FormInputWithLabelAgent = styled(FormInputWithLabelRow)`
  &.search-field {
	padding-right: 10px;
  }
`

const SelectAgentFilterBase = ({ data, onSelect, onToggle, selected, textNoItemSelected, className, id, name, show }) => {
	return (
		<FormInputWithLabelAgent
			label={I('Filter')}
			className={className}
		>
			<SingleSelectDD
				id={id+"_agentFilter"}
				name={name+"_agentFilter"}
				className="popup-multi-select"
				textNoItemSelected={textNoItemSelected}
				groupSelect={false}
				nested={keyAreas}
				data={data}
				idFields={idName}
				selected={selected}
				onSelect={onSelect}
				onToggle={onToggle}
				show={show}
			/>
		</FormInputWithLabelAgent>
	);
};

const SelectAgentFilter = withShowToggle(SelectAgentFilterBase);

const AreaSelection = ({
	data
	, onSelect
	, onToggle
	, selected
	, show
	, warning
}) => (
	<FormInputWithLabelRow
		label={I('Area')}
		mandatory={true}
		warning={warning}
	>
		<SelectArea
			data={data}
			selected={selected}
			onSelect={useCallbackWithValue("selectedArea", onSelect, true)}
		/>
	</FormInputWithLabelRow>
);

const ChannelSelection = ({
	view
	, showSelection
	, collabChannels
	, selectedChannel
	, onHandleChangeData
}) => (
	<FormInputWithLabelRow
		label={I('Channel')}
		mandatory={true}
		warning={""}
		className={"admin-addressbook-row"}
	>
		<SelectInputRow
			hidden={!showSelection}
			id={"selectedChannel"}
			name={"selectedChannel"}
			className={"admin-select-channel"}
			option={collabChannels}
			value={selectedChannel}
			onSelect={onHandleChangeData}
		/>
	</FormInputWithLabelRow>
);

const MandatoryKeywordsInput = ({ modal, onBlur, onChange, value, warning }) => (
	<TextInputRow
		id={modal ? "keywordsPopup" : "keywords"}
		name="keywords"
		label={I("Keywords")}
		className="admin-text-input email-address"
		value={value}
		onChange={onChange}
		mandatory={true}
		warning={warning}
		onBlur={onBlur}
	/>
);

const MandatoryInput= ({ label, modal, onBlur, onChange, value, warning }) => (
    <TextInputRow
        id={modal ? "emailAddressPopup" : "emailAddress"}
        name="emailAddress"
        label={label}
        className="admin-text-input text-address"
        value={value}
        onChange={onChange}
        mandatory={true}
        warning={warning}
        onBlur={onBlur}
    />
);

const QuestionTypeInput = ({ label, modal, onBlur, onChange, value, warning }) => (
    <div className="question-input-container">
        <label>{label}</label>
        <textarea
            id="questionType"
            name="questionType"
            className="admin-text-input text-question-type"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            rows="10"
            style={{
                width: '100%',
                padding: '8px',
                fontSize: '14px',
                resize: 'vertical',
                marginBottom: '10px',
                border: '1px solid #ccc',
                borderRadius: '4px',
            }}
        />
        {warning && <span style={{ color: 'red', fontSize: '12px' }}>{warning}</span>}
    </div>
);


const ChannelSelectBase = ({
	className
	, id
	, name
	, onChange
	, option
	, textNoItemSelected
	, value
	, label
}) => (
	<SelectInputRow
		id={id}
		name={name}
		className={className}
		label={label}
		textNoItemSelected={textNoItemSelected}
		option={option}
		value={value}
		onSelect={useCallbackWithValue("selectedLangFilter", onChange)}
	/>
);

const ChannelSelect = composeWithDisplayName(
	"ChannelSelect"
	, memo
	, withUnmountWhenHidden
)(ChannelSelectBase);

const doNothing = () => {};

const AvatarBase = ({ width = 120, height = 100, data, onCrop, onRemove }) => {
	const handleCrop = useCallbackWithValue("avatarPreview", onCrop, true)
		, { className, label, preview } = data
		;
	return (
		<AvatarInputRow
			label={label}
			name={className}
			width={width}
			height={height}
			onClose={doNothing}
			onCrop={handleCrop}
			mimeTypes="image/jpeg,image/png,image/gif"
			preview={preview}
			src=""
			className="upload-avatar"
			inlineLabel={false}
			handleRemoveAvatar={onRemove}
		/>
	);
};

export const Avatar = withUnmountWhenHidden(AvatarBase);

const EditFormBase = ({
    avatar,
    view,
    modal,
    warning,
    input,
    activeId,
    collabSlackEnabled,
    collabJiraEnabled,
    collabMSTeamEnabled,
    collabGoogleChatEnabled,
    collabChannels,
    areaList,
    onHandleChangeData,
    onHandleTextInputBlur,
    onHandleTextInputChange,
    onRemoveAvatar,
}) => {
    const [emailWarning, handleBlur] = useCheckValidValueOnBlur(),
        handleRemoveAvatar = useCallbackWithValue(activeId, onRemoveAvatar);

    let showSlackEE =
        (view === ADMIN_VIEW_MAP["externalexpert-personal"] ||
            view === ADMIN_VIEW_MAP["externalexpert"]) &&
        collabSlackEnabled;
    let showJiraEE =
        (view === ADMIN_VIEW_MAP["externalexpert-personal"] ||
            view === ADMIN_VIEW_MAP["externalexpert"]) &&
        collabJiraEnabled;
    let showMSTeamEE =
        (view === ADMIN_VIEW_MAP["externalexpert-personal"] ||
            view === ADMIN_VIEW_MAP["externalexpert"]) &&
        collabMSTeamEnabled;
    let showGoogleChatEE =
        (view === ADMIN_VIEW_MAP["externalexpert-personal"] ||
            view === ADMIN_VIEW_MAP["externalexpert"]) &&
        collabGoogleChatEnabled;
    let slackEmailWarning = emailWarning;
    let showEE =
        view === ADMIN_VIEW_MAP["externalexpert-personal"] ||
        view === ADMIN_VIEW_MAP["externalexpert"];

    if (
        (showSlackEE && input.selectedChannel === Workflow.Errand.SERVICE_SLACK) ||
        (showJiraEE && input.selectedChannel === Workflow.Errand.SERVICE_JIRA) ||
        (showMSTeamEE && input.selectedChannel === Workflow.Errand.SERVICE_MICROSOFT_TEAMS) ||
        (showGoogleChatEE && input.selectedChannel === Workflow.Errand.SERVICE_GOOGLECHAT)
    ) {
        slackEmailWarning = "";
    }

    return (
        <div className="admin-one-form">
            <AreaSelection
                data={areaList}
                onSelect={onHandleChangeData}
                selected={input.selectedArea}
                warning=""
            />
            {!showEE && (
                <SelectInputRow
                    hidden={false}
                    id={"review-option"}
                    name={"reviewType"}
                    label={I("Type")}
                    className={"admin-select-review-option"}
                    option={REVIEW_KEYWORDS_OPTION}
                    value={input.reviewType}
                    onSelect={onHandleChangeData}
                />
            )}
            {!showEE && (
                <MandatoryKeywordsInput
                    modal={modal}
                    onBlur={handleBlur}
                    onChange={onHandleTextInputChange}
                    value={input.keywords}
                    warning={emailWarning}
                />
            )}
            {showEE && (
                <ChannelSelection
                    view={view}
                    showSelection={showSlackEE}
                    collabChannels={collabChannels}
                    selectedChannel={
                        input.selectedChannel === 0
                            ? Workflow.Errand.SERVICE_EMAIL
                            : input.selectedChannel
                    }
                    onHandleChangeData={onHandleChangeData}
                />
            )}
            {showEE && (
                <MandatoryInput
                    label={I("Address")}
                    modal={modal}
                    onBlur={handleBlur}
                    onChange={onHandleTextInputChange}
                    value={input.emailAddress}
                    warning={slackEmailWarning}
                />
            )}
            <Avatar
                data={avatar}
                hidden={!avatar}
                onCrop={onHandleChangeData}
                handleRemoveAvatar={handleRemoveAvatar}
            />
			{showEE &&  <QuestionTypeInput
                label="Suggested questions:"
                modal={modal}
                onBlur={onHandleTextInputBlur}
                onChange={onHandleTextInputChange}
                value={input.questionType}
                warning={warning}
            />}
        </div>
    );
};

const RawEditForm = withUnmountWhenHidden(EditFormBase);

const genericEditForm = {
	[M_EXTERNALEXPERT]: true
	, [M_MY_EXTERNALEXPERT]: true
	, [M_REVIEW_KEYWORDS]: true
};

function isGenericEditForm(view) {
	if (isExternalExpert(view)) {
		return true;
	} else if (genericEditForm[view]) {
		return true;
	}
	return false;
}

const useAvatar = (view, preview) => useMemo(
	() => {
		if (isExternalExpert(view)) {
			return {
				className: "ee-avatar"
				, label: I("Expert's avatar")
				, preview
			};
		}
		return false;
	}
	, [view, preview]
);

const EditForm = ({ avatar, view, ...props }) => (
	<RawEditForm
		avatar={useAvatar(view, props.input.avatarPreview)}
		view={view}
		{...props}
	/>
);
let tableOrder = 'desc';

const LOADING_STATE = 0;
const LOADED_STATE = 1;
const CREATENEW_STATE = 2;
const DETAIL_STATE = 3;
const RECREATE_STATE = 4;

class AdminOneForm extends PureComponent {
	constructor(props){
		super(props);
		this.handleCheckInput = this.handleCheckInput.bind(this);
		this.handleTextInputBlur = this.handleTextInputBlur.bind(this);
		this.state = {
			nameWarning: "",
			myState: LOADING_STATE, // loading, loaded, createNew, detail
			list: {},
			token: {},
		}
	}
	handleCheckInput(field, value) {
		if(field === "name" || field === "wordListname"){
			if(value.length === 0) {
				this.setState({nameWarning: INPUT_EMPTY_WARNING});
				return false;
			}else{
				this.setState({nameWarning: ""});
				return true;
			}
		} else if (field === "companyEmail") {
			if(!addressValidation(Workflow.Errand.SERVICE_EMAIL, value)) {
				this.setState({nameWarning: I("* Please insert correct email address")});
				return false;
			}else{
				this.setState({nameWarning: ""});
				return true;
			}
			return true;
		}
	}
	handleTextInputBlur(e){
		let field = e.target.id, value = e.target.value;
		this.handleCheckInput(field, value);
	}
	handleDelete = (id) => {
		let field = { id };
		let confirmDelMsg = I('Are you sure you want to delete?');
		if (confirm(confirmDelMsg)) { // TODO: avoid using primitive confirm box
			this.props.onDeleteFromList(field, this.props.view);
		}

	}
	render() {
		const {
			view,
			agent,
			activeId,
			input,
			interactive,
			quickReply,
			insertionText,
			modal,
			langSrc,
			areaList,
			connectedAreaList,
			knowledgeBaseList,
			channels,
			agentList,
			children,
			archiveImgs,
			ckeditorSettings,
			onRemoveAvatar,
			onHandleTextInputChange,
			onHandleChangeData,
			onHandleCKEditorChange,
			onOpenInsertProtectedContentReply,
			onResetInsertionText,
			selectedFileArchive,
			agentUploadInProgress,
			collabSlackEnabled,
			collabJiraEnabled,
			collabMSTeamEnabled,
			collabGoogleChatEnabled,
			collabChannels,
			routeTags,
			onValidateAgentSipStatus,
			allChannels,
			onChangeInteractive,
			onChangeQuickReply,
			stunTurnServices,
			selectedStunTurnServices,
			tagsList,
			subTagsList,
			onAppendSubTags,
			onEmptySubTagsWarning,
			onCreateNew,
			onSaveAdmin,
			onLoadToEditRow,
			jwtChannels,
			jwtAccList,
			jwtUsers,
			jwtAPIVersion,
			...props
		} = this.props;
		if(input) {
			return <div className={"admin-form-"+view}>
				{view === ADMIN_VIEW_MAP["wordlist"] &&
					<WordListForm
						modal={modal}
						warning={this.state.nameWarning}
						input={input}
						langSrc={langSrc}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleTextInputBlur={this.handleTextInputBlur}
						onHandleChangeData={onHandleChangeData}
					/>
				}
				{/* SignatureSalutationForm is used to salutation, signature and templates */
					isSST(view) &&
						<SignatureSalutationForm
							agent={agent}
							modal={modal}
							view={view}
							ckeditorSettings={ckeditorSettings}
							input={input} warning={this.state.nameWarning}
							insertionText={insertionText}
							langSrc={langSrc}
							areaList={areaList}
							archiveImgs={archiveImgs}
							interactive={interactive}
							onHandleTextInputChange={onHandleTextInputChange}
							onHandleTextInputBlur={this.handleTextInputBlur}
							onHandleChangeData={onHandleChangeData}
							onHandleCKEditorChange={onHandleCKEditorChange}
							onOpenInsertProtectedContentReply={onOpenInsertProtectedContentReply}
							onResetInsertionText={onResetInsertionText}
							onChangeInteractive={onChangeInteractive}
						/>
				}
				{(view === ADMIN_VIEW_MAP["addressbook-personal"] || view === ADMIN_VIEW_MAP["addressbook"] )&&
					<AddressBookForm
						modal={modal}
						input={input}
						channels={channels}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleTextInputBlur={this.handleTextInputBlur}
						onHandleChangeData={onHandleChangeData}
					/>
				}
				{isGenericEditForm(view) &&
					<EditForm
						modal={modal}
						activeId={activeId}
						input={input}
						collabSlackEnabled={collabSlackEnabled}
						collabJiraEnabled={collabJiraEnabled}
						collabMSTeamEnabled={collabMSTeamEnabled}
						collabGoogleChatEnabled={collabGoogleChatEnabled}
						collabChannels={collabChannels}
						view={view}
						areaList={areaList}
						onRemoveAvatar={onRemoveAvatar}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleChangeData={onHandleChangeData}
					/>
				}
				{isInternalState(view) &&
					<ErrandInternalStateForm
						input={input}
						activeId={activeId}
						onHandleChangeData={onHandleChangeData}
					/>
				}
				{isRoutingView(view) &&
					<RouteForm
						areaList={areaList}
						modal={modal}
						activeId={activeId}
						input={input}
						view={view}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleChangeData={onHandleChangeData}
						onHandleTextInputBlur={this.handleTextInputBlur}
						onChangeAdminInput={this.props.onChangeAdminInput}
						warning={this.state.nameWarning}
						routeTags={routeTags}
						selectedTagColor={input.tagVIPColor}
					/>
				}
				{view === ADMIN_VIEW_MAP["filearchive"] &&
					<FileArchiveForm
						areaList={features["isAdmin"] ? areaList : connectedAreaList}
						activeId={activeId}
						input={input}
						onHandleChangeData={onHandleChangeData}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleTextInputBlur={this.handleTextInputBlur}
						onChangeAdminInput={this.props.onChangeAdminInput}
					/>
				}
				{view === M_GENERATIVE_AI_DOCUMENT &&
					<GenAIDocumentForm
						langSrc={langSrc}
						areaList={features["isAdmin"] ? areaList : connectedAreaList}
						activeId={activeId}
						input={input}
						onHandleChangeData={onHandleChangeData}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleTextInputBlur={this.handleTextInputBlur}
						onChangeAdminInput={this.props.onChangeAdminInput}
					/>
				}
				{view === ADMIN_VIEW_MAP["agents"] &&
					<AgentFormCtnr
						key={"agentFormForList_"+activeId}
						agentUploadInProgress={agentUploadInProgress}
						modal={modal}
						prefMode={false}
						activeId={activeId}
					/>
				}
				{view === M_AGENTSIPSTATUS &&
					<AgentSipStatusForm
						key={"agentSipStatusForm_"+activeId}
						input={input}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleChangeData={onHandleChangeData}
						onValidateAgentSipStatus={onValidateAgentSipStatus}
						activeId={activeId}
					>
					</AgentSipStatusForm>
				}
				{view === M_CLASSIFIER &&
					<ClassifierForm
						key={"classifierForm"+activeId}
						input={input}
						areaList={areaList}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleChangeData={onHandleChangeData}
						activeId={activeId}
					>
					</ClassifierForm>
				}
				{view === ADMIN_VIEW_MAP["companies"] &&
					<CompanyForm
						modal={modal}
						warning={this.state.nameWarning}
						input={input}
						langSrc={langSrc}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleTextInputBlur={this.handleTextInputBlur}
						onHandleChangeData={onHandleChangeData}
					/>
				}
				{view === ADMIN_VIEW_MAP["stunturn"] &&
					<StunTurnForm
						input={input}
						stunTurnServices={stunTurnServices}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleTextInputBlur={this.handleTextInputBlur}
						onHandleChangeData={onHandleChangeData}
					/>
				}
				{view === M_API_ACCESSTOKENS &&
					<JWTAPIForm
						activeId={activeId}
						input={input}
						areaList={areaList}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleTextInputBlur={this.handleTextInputBlur}
						onHandleChangeData={onHandleChangeData}
						onChangeAdminInput={this.props.onChangeAdminInput}
						token={"token"} //data for edit
						pageTitle={"title"}
						versions={jwtAPIVersion}
						services={jwtChannels}
						accounts={jwtAccList}
						users={jwtUsers}
					/>
				}
				{view === M_API_CALLBACK &&
					<CallBackAPIForm
						activeId={activeId}
						input={input}
						areaList={areaList}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleTextInputBlur={this.handleTextInputBlur}
						onHandleChangeData={onHandleChangeData}
						onChangeAdminInput={this.props.onChangeAdminInput}
					/>
				}
				{view === M_REVIEW_AGENTS &&
					<ReviewByAgent
						view={view}
						agentList={agentList}
						input={input}
						onChangeAdminInput={this.props.onChangeAdminInput}
					/>
				}
				{(view === M_FAQWIDGETDL || view === M_CHATWIDGETDL || view === M_VOICEWIDGETDL) &&
					<WidgetConfigForm
						activeId={activeId}
						modal={modal}
						view={view}
						warning={this.state.nameWarning}
						input={input}
						langSrc={langSrc}
						areaList={areaList}
						knowledgeBaseList={knowledgeBaseList}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleTextInputBlur={this.handleTextInputBlur}
						onHandleChangeData={onHandleChangeData}
						onHandleRemoveAvatar={onRemoveAvatar}
						onChangeAdminInput={this.props.onChangeAdminInput}
						channels={channels}
						allChannels={allChannels}
					/>
				}
				{isQuickReply(view) &&
					<QuickReplyForm
						modal={modal}
						view={view}
						input={input}
						warning={this.state.nameWarning}
						insertionText={insertionText}
						areaList={areaList}
						quickReply={quickReply}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleTextInputBlur={this.handleTextInputBlur}
						onHandleChangeData={onHandleChangeData}
						onHandleCKEditorChange={onHandleCKEditorChange}
						onOpenInsertProtectedContentReply={onOpenInsertProtectedContentReply}
						onResetInsertionText={onResetInsertionText}
						onChangeQuickReply={onChangeQuickReply}
					/>
				}
				{view === M_TAG &&
					<TagNewEditForm
						activeId={activeId}
						view={view}
						modal={modal}
						warning={this.state.nameWarning}
						input={input}
						areaList={areaList}
						tagsList={tagsList}
						subTagsList={subTagsList}
						onHandleTextInputChange={onHandleTextInputChange}
						onHandleTextInputBlur={this.handleTextInputBlur}
						onHandleChangeData={onHandleChangeData}
						onAppendSubTags={onAppendSubTags}
						onEmptySubTagsWarning={onEmptySubTagsWarning}
						onCreateNew={onCreateNew}
						onSaveAdmin={onSaveAdmin}
						onLoadToEditRow={onLoadToEditRow}
						onDelete={this.handleDelete}
					/>
				}
				{children}
				</div>
		}else{
			return null;
		}
	}
}

class AdminPreviewForm extends PureComponent {
	constructor(props){
		super(props);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleChangeTab = this.handleChangeTab.bind(this);
		this.state = { showCopy: false };
	}
	handleCopy() {
		let previewId = "";
		switch (this.props.view) {
			case M_CHATWIDGETDL:
				previewId = "cell-preview-body-chat-widget";
				break;
			case M_FAQWIDGETDL:
				previewId = "cell-preview-body-faq-widget";
				break;
			case M_VOICEWIDGETDL:
				previewId = "cell-preview-body-voice-widget";
				break;
		}

		var elm = document.getElementById(previewId);
		if (!elm) {
			return;
		}
		// for Internet Explorer
		if(document.body.createTextRange) {
		  var range = document.body.createTextRange();
		  range.moveToElementText(elm);
		  range.select();
		  document.execCommand("Copy");
		}
		else if(window.getSelection) {
		  // other browsers
		  var selection = window.getSelection();
		  var range = document.createRange();
		  range.selectNodeContents(elm);
		  selection.removeAllRanges();
		  selection.addRange(range);
		  document.execCommand("Copy");
		}
	}
	handleChangeTab(tab) {
		if(tab === "Code") {
			this.setState({ showCopy: true });
		} else {
			this.setState({ showCopy: false });
		}
	}
	render() {
		const {
			view,
			input,
			modal,
			langSrc,
			channels,
			agent,
			children,
			areaList,
			onHandleTextInputChange,
			...props
		} = this.props;
		const { showCopy } = this.state;
		if(input) {
			let buttons = [];
			if(showCopy) {
				buttons.push(
					<CopyButton
						key="btn-copy-admin"
						onClick={this.handleCopy}
					/>
				);
			}
			return <div className={"admin-form-"+view}>
				<EditorFooter>
				{buttons}
				</EditorFooter>
				<ChatWidgetCodePreviewForm
					modal={modal}
					agent={agent}
					hide={!(view === M_CHATWIDGETDL)}
					input={input} langSrc={langSrc}
					areaList={areaList}
					onHandleTextInputChange={onHandleTextInputChange}
					onChangeTab={this.handleChangeTab}
				/>
				<FaqWidgetCodePreviewForm
					modal={modal}
					agent={agent}
					hide={!(view === M_FAQWIDGETDL)}
					input={input} langSrc={langSrc}
					areaList={areaList}
					onHandleTextInputChange={onHandleTextInputChange}
					onChangeTab={this.handleChangeTab}
				/>
				<VoiceWidgetCodePreviewForm
					modal={modal}
					hide={!(view === M_VOICEWIDGETDL)}
					input={input} langSrc={langSrc}
					areaList={areaList}
					onHandleTextInputChange={onHandleTextInputChange}
					onChangeTab={this.handleChangeTab}
				/>
				{children}
				</div>
		}else{
			return null;
		}
	}
}

const getTag = (ancestors, tag) => {
	ancestors = ancestors.slice()
	ancestors.push(tag)
	const { isVIP, tagColor } = ancestors[0]
	const ids = []
	const names = []
	each(ancestors, ({ id, value }) => {
		ids.push(id)
		names.push(value)
	})
	return {
		id: ids.join(','),
		name: names.join(' / '),
		level: ancestors.length,
		isVIP,
		tagColor
	}
}

const getRouteTagsBase = (tags, tagList, ancestors) => {
	each(tagList, tag => {
		// NOTE: tag.children can be null or array with empty content which BOTH
		// indicate different condition. Former indicates no children while later
		// indicates no children's area meet area (this is tag list of an area).
		if (tag.children && tag.children.length) {
			const _ancestors = ancestors.slice()
			_ancestors.push(tag)
			getRouteTagsBase(tags, tag.children, _ancestors)
		} else {
			tags.push(getTag(ancestors, tag))
		}
	})
	return tags
}

const getRouteTags = (view, selectedArea, tagsList) => {
	if (
		view !== M_ROUTING_AUTOTAGS ||
		!selectedArea.length ||
		!tagsList[selectedArea]) {
		return emptyArray
	}
	return getRouteTagsBase([], tagsList[selectedArea].normal_tags, [])
}

class AdminEditFormBase extends PureComponent {
	constructor(props) {
		super(props);
		this.handleChangeData = this.handleChangeData.bind(this);
		this.handleTextInputChange = this.handleTextInputChange.bind(this);
		this.handleCKEditorChange = this.handleCKEditorChange.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.handleBack = this.handleBack.bind(this);
	}
	handleChangeData(value, field, key) {
		this.props.onChangeAdminInput(field, value, key)
	}
	handleTextInputChange (e) {
		let field = e.target.name, value = e.target.value;
		this.props.onChangeAdminInput(field, value);
	}
	handleCKEditorChange (value, field) {
		this.props.onChangeAdminInput(field, value)
	}
	handleSave() {
		if(this.props.view === M_TAG) {
			this.props.onChangeAdminInput("creatingNewSubTagMode", false);
		}
		this.props.onSaveAdmin();
	}
	handleCancel() {
		this.props.onCancelEdit();
	}
	handleBack() {
		const { input, tagsList, onLoadToEditRow } = this.props;
		if(input.parentId && input.parentId > 0) {
			onLoadToEditRow(input.parentId, M_TAG, tagsList)
		}
	}
	render() {
		const {
			view,
			input,
			insertionText,
			langSrc,
			channels,
			activeId,
			areaList,
			knowledgeBaseList,
			archiveImgs,
			onOpenInsertProtectedContentReply,
			onRemoveAvatar,
			onResetInsertionText,
			modal,
			isValidLoginName,
			agentDataReady,
			onHandleDelete,
			onDownloadFileArchive,
			tagsList,
			routeTagsList,
			onValidateAgentSipStatus,
			connectedAreaList,
			stunTurnServices,
			allChannels,
			adminStatus,
			...props
		} = this.props;
		let buttons = [], disableSave = false;
		let buttonSaveTxt = BTN_TXT_SAVE;
		if( BTN_TXT_SAVE === "Save" ){
			buttonSaveTxt = I("Save")
		}
		if(adminStatus) {
			if(adminStatus.status === 1) {
				if( BTN_TXT_SAVING=== "Saving...'" ){
					buttonSaveTxt = I("Saving...'")
				}
				disableSave = true;
			}
		}
		if(view === ADMIN_VIEW_MAP["agents"] && (typeof isValidLoginName == "boolean" && !isValidLoginName)){
			disableSave = true;
		} else if(view === M_API_ACCESSTOKENS) {
			disableSave = false;
			if(!input.jwtAllowAttachment && !input.jwtAllowAccessLib && !input.jwtProgressStatus && !input.jwtErrandData
				&& !input.jwtCreateCustContact && !input.jwtIntegrateAgentLogin && !input.jwtAccessExportAPI &&
				!input.jwtLogioAPI && !input.jwtStatsAPI && !input.jwtChatMessages) {
					if(input.jwtCreateErrandChannel > 0) {
						disableSave = false;
					} else {
						disableSave = true;
					}
			}
		}
		if((view === M_TAG && input.level > 1) || view === M_API_CALLBACK || view === M_API_ACCESSTOKENS) {
			buttons.push(
				<CancelButton
					key="btn-back-admin"
					text={view === M_TAG ? I("Back") : I("Cancel")}
					disabled={false}
					onClick={view === M_TAG ? this.handleBack : this.handleCancel}
				/>
			);
		}
		buttons.push(
			<SaveButton 
				key="btn-save-admin"
				disabled={disableSave}
				onClick={this.handleSave}
				text={buttonSaveTxt}
			/>
		);
		let hideBtns = false;
		if(view === ADMIN_VIEW_MAP["agents"] && !agentDataReady){
			hideBtns = true;
		}
		if(view === M_CONFIGCHANGELOG ){
			hideBtns = true;
		}
		let hideRemoveBtns = true, removeBtnLabel = I("Delete");
		if(((view === M_API_CALLBACK || view === M_API_ACCESSTOKENS) && activeId > 0) ||
			(view === M_CHATWIDGETDL || view === M_FAQWIDGETDL || view === M_VOICEWIDGETDL)) {
			hideRemoveBtns = false;
			removeBtnLabel = I("Remove");
		}
		return <div className="edit-admin-form">
			<AdminOneForm
				activeId={activeId}
				modal={modal}
				view={view}
				input={input}
				insertionText={insertionText}
				langSrc={langSrc}
				channels={channels}
				allChannels={allChannels}
				areaList={areaList}
				connectedAreaList={connectedAreaList}
				knowledgeBaseList={knowledgeBaseList}
				archiveImgs={archiveImgs}
				stunTurnServices={stunTurnServices}
				onRemoveAvatar={onRemoveAvatar}
				onHandleTextInputChange={this.handleTextInputChange}
				onHandleChangeData={this.handleChangeData}
				onHandleCKEditorChange={this.handleCKEditorChange}
				onOpenInsertProtectedContentReply={onOpenInsertProtectedContentReply}
				onResetInsertionText={onResetInsertionText}
				onValidateAgentSipStatus={onValidateAgentSipStatus}
				routeTags={getRouteTags(view, input.selectedArea, routeTagsList)} //TODO: maybe add as common tags instead specific ?
				tagsList={tagsList}
				{...props}
			>
				<EditorFooter
					hidden={hideBtns}
					left={
						<Fragment>
							{ !hideRemoveBtns &&
								<TableIconicButton
									type="button"
									className="btn bg-transparent action delete"
									iconClass={"icon-trash"}
									iconPosition={"left"}
									iconSize={"16px"}
									buttonTxtColor={centionTitleGrey}
									label={removeBtnLabel} title={I("Delete")}
									onClick={()=>{onHandleDelete(activeId)}}
								/>
							}
							{isFileArchive(view) &&
							<TableIconicButton
								hide={!activeId}
								type="button"
								className="btn bg-transparent action download"
								iconClass={"icon-download"}
								iconPosition="left"
								label={I("Download")} title={I("Download")}
								onClick={()=>{onDownloadFileArchive(this.props.activeId)}}
							/>}
						</Fragment>
					}
				>
					<div style={{display: 'inline-flex'}}>
						{buttons}
					</div>
				</EditorFooter>
			</AdminOneForm>
		</div>;
	}
}

class AdminPreview extends PureComponent {
	constructor(props) {
		super(props);
		this.handleTextInputChange = this.handleTextInputChange.bind(this);
	}
	handleTextInputChange (e) {
		let field = e.target.name, value = e.target.value;
		this.props.onChangeAdminInput(field, value);
	}
	render() {
		const {
			view,
			input,
			modal,
			langSrc,
			channels,
			activeId,
			agent,
			...props
		} = this.props;
		return <div className="edit-admin-form">
			<AdminPreviewForm
				activeId={activeId}
				modal={modal}
				view={view}
				input={input}
				langSrc={langSrc}
				agent={agent}
				channels={channels}
				onHandleTextInputChange={this.handleTextInputChange}
				{...props}
			>
			</AdminPreviewForm>
		</div>;
	}
}

const AdminEditForm = composeWithDisplayName(
	"AdminEditForm"
	, createWithPropAttachedAction("view", "onSaveAdmin")
	, withProps(({ activeId }) => ({createNew: activeId === 0}))
	, createWithPropAttachedAction("createNew", "onSaveAdmin")
	, createWithPropAttachedAction("activeId", "onSaveAdmin")
	, createWithPropAttachedAction("input", "onSaveAdmin")
)(AdminEditFormBase);

// Inline Form
export class AdminEditInline extends PureComponent {
	constructor(props) {
		super(props);
	}
	render() {
		const {
			showInline,
			view,
			data,
			input,
			onHandleDelete,
			tagsList,
			...props
		} = this.props;
		if (showInline) {
			return (
				<EditorBox>
					<FormOrDiv>
						<AdminEditForm
							modal={false}
							view={view}
							input={input}
							tagsList={tagsList}
							{...props}
							onHandleDelete={onHandleDelete}
						/>
					</FormOrDiv>
				</EditorBox>
			);
		} else {
			return null;
		}
	}
}

//Modal Form
export class AdminEdit extends PureComponent {
	constructor(props) {
		super(props);
		this.handleClose = this.handleClose.bind(this);
	}
	handleClose() {
		this.props.onClose(this.props.activeId);
	}
	render() {
		const {
			show,
			view,
			data,
			input,
			...props
		} = this.props;
		let cbClass = "edit admin popup", title = "Edit "+ ADMIN_TITLE[view];
		if(show) {
			cbClass = "edit admin popup open";
		}
		if(show) {
			return <div className={cbClass}>
				<div className="admin-inner popup-inner create-inner">
					<div className="edit-admin-box">
						<div className="popup-tabs">
							<span className={"toggle-tabs"} >
								{title}
							</span>
						</div>
							<FormOrDiv>
								<AdminEditForm modal={true} view={view} input={input} {...props} />
							</FormOrDiv>
					</div>
					<div data-qa-id="edit-admin-close" className="popup-close" onClick={this.handleClose}>
						<i className="icon-times"></i>
					</div>
				</div>
			</div>
		}else{
			return null;
		}
	}
}

export class AdminEditLeftView extends PureComponent {
	constructor(props) {
		super(props);
	}
	render() {
		const {
			showInline,
			view,
			data,
			input,
			...props
		} = this.props;
		if (showInline) {
			return <div className={classNames('form-wrapper clearfix', {'extended': input.customize})}>
						<FormOrDiv>
							<AdminEditForm modal={true} view={view} input={input} {...props} />
						</FormOrDiv>
					</div>
		}else {
			return null;
		}
	}
}

export class AdminEditRightView extends PureComponent {
	constructor(props) {
		super(props);
		this.handleClose = this.handleClose.bind(this);
	}
	handleClose() {
		this.props.onClose(this.props.activeId);
	}
	render() {
		const {
			showInline,
			view,
			data,
			input,
			agent,
			...props
		} = this.props;
		if (showInline) {
			return <div className='form-wrapper clearfix'>
						<FormOrDiv>
							<AdminPreview modal={true} view={view} input={input} agent={agent} {...props} />
						</FormOrDiv>
					</div>
		}else {
			return null;
		}
	}
}

const uploadButtonMap = {
	[M_REVIEW_KEYWORDS]: true
	, [M_ROUTING_AUTOTAGS]: true
	, [M_AGENTS]: true
	, [M_TAG]: true
	, [M_ADDRESSBOOK]: true
};

const Download = withIdAttachedOnClick(DownloadButton);

const Upload = createWithPropAttachedAction("id", "onConfirm")(UploadButton);

const UploadHelperText = ({view}) => {
	if(view === M_ADDRESSBOOK) {
		return (
			<div>
				{I("Upload a file with names and addresses.")}
				<br /><br />
				{I("For excel file: Put name in column A and address in column B.")}
				<br /><br />
				{I("For txt file: Type name and address, separated with a comma. Example: Cention, info@cention.com")}
				<br />
			</div>
		)
	} else {
		return (
			<div>
				{I("Upload a file to import")}
			</div>
		)
	}
}

const DownloadUploadBase = ({ id, onDownload, downloadText, uploadText, hideDownload, showHelper, ...props }) => (
	<div className="c3-download-upload">
		{ !hideDownload &&
			<Download id={id} text={downloadText} onClick={onDownload} />}
		<Upload id={id} text={uploadText} {...props} />
		{ showHelper && <Helper><UploadHelperText view={id} /></Helper> }
	</div>
);

const DownloadUpload = withUnmountWhenHidden(DownloadUploadBase);

const ALERT_AUTOHIDE_TIMEOUT = 5000;
export const AlertWrapper = ({id, hidden = true, onDismiss, ...props}) => {
	useEffect(() => {
		if(!hidden) {
			if(onDismiss) {
				onDismiss();
			}
		}
	},
	[id]);
	useEffect(() => {
		if(hidden === false) {
			const timer = setTimeout(() => {
				if(onDismiss) {
					onDismiss();
				}
			}, ALERT_AUTOHIDE_TIMEOUT);
			return () => clearTimeout(timer);
		}
	}
	, [hidden, props]);
	return !hidden ? <div className='adminError generalAlert section' {...props} /> : null;
}


const StyledDiv = styled.div`
	padding-bottom: 40px;
`
export class AdminList extends PureComponent {
	constructor(props) {
		super(props);
		this.renderPaginationPanel = this.renderPaginationPanel.bind(this);
		this.handleOpenEdit = this.handleOpenEdit.bind(this);
		this.handleOpenModalToEdit = this.handleOpenModalToEdit.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleFilterList = this.handleFilterList.bind(this);
		this.handleRowClick = this.handleRowClick.bind(this);
		this.handleActivateAgent = this.handleActivateAgent.bind(this);
		this.handleUnlockAgent = this.handleUnlockAgent.bind(this);
		this.handleOnDownload = this.handleOnDownload.bind(this);
		this.handleDownloadTemplate = this.handleDownloadTemplate.bind(this);
		this.handleUploadAgents = this.handleUploadAgents.bind(this);
		this.handleOnChangeUpload = this.handleOnChangeUpload.bind(this);
	}
	componentDidMount() {
		let view = this.props.view, freshLoad = false;
		if(view === ""){
			// TODO: this part need to be touch-ed up. It do not fix react code and
			// using window.location is not right. We shoudl base on react-router
			// helpers. 'view' should not be in redux-state but it should be something
			// derived from react-router.
			freshLoad = true;
			let url = window.location.href.split('#')[0];
			let array = url.split('/');
			let lastPath = array[array.length-1];
			view = lastPath;
			let obj = ADMIN_VIEW_MAP;
			if(obj.hasOwnProperty(view)){
				view = obj[view];
			}
		}
		if(this.props.onLoad){
			this.props.onLoad(view, freshLoad);
		}
	}
	componentWillUnmount() {
		if(this.props.view === ADMIN_VIEW_MAP["agents"]){
			this.props.onStopCheckImportStatus();
		}
	}
	handleOpenEdit(id, createNew) {
		const p = this.props;
		if(createNew && p.view === ADMIN_VIEW_MAP["agents"]){
			p.onLoadToEdit(UNSELECT, p.view, false, p.list);
		}else if(createNew == true && (p.view === M_CHATWIDGETDL || p.view === M_FAQWIDGETDL || p.view === M_VOICEWIDGETDL)){
			p.onLoadToEdit(id, p.view, false, p.defaultList);
		}else if(p.view === M_TAG){
			p.onLoadToEdit(id, p.view, false, p.allTagsList);
		}else{
			p.onLoadToEdit(id, p.view, false, p.list);
		}
	}
	handleOpenModalToEdit() {
		this.props.onLoadToEdit(this.props.activeId, this.props.view, true);
	}
	handleActivateAgent(status,id) {
		if(this.props.onActivateAgent){
			this.props.onActivateAgent(status, id);
		}
	}
	handleUnlockAgent = (id) =>{
		if(this.props.onUnlockAgent){
			this.props.onUnlockAgent(id);
		}
	}
	handleDelete(id, name, areas) {
		let field = { id, name, areas };
		let confirmDelMsg = I('Are you sure you want to delete?');
		if (confirm(confirmDelMsg)) { // TODO: avoid using primitive confirm box
			this.props.onDeleteFromList(field, this.props.view);
		}
	}
	handleFilterList(val, field) {
		this.props.onFilterAdminList(this.props.view, val, field);
	}
	handleRowClick(row, rowIndex, columnIndex, event) {
		if((event.target.tagName !== "I" && event.target.tagName !== "BUTTON" && event.target.className !== "agentStatusHandler" && event.target.className !== "agentUnlockHandler" &&
			event.target.className !== "stunTurnStatusHandler")
			&& CLICK_ROW_TO_EDIT && this.props.view !== M_REVIEW_AGENTS){
				this.props.onLoadToEdit(row.id, this.props.view, false, this.props.list);
		}
	}
	handleDownloadTemplate() {
		//TODO: Review this old way
		var exportFilename = 'agent_list.csv';
		var csv = 'Login_Name,Password,Admin_Level,User_Name,Email_Address,External_ID,Bright_Reference_Code,Signature_Name,Chat_Name\n';
		if(this.props.view === M_ROUTING_AUTOTAGS) {
			exportFilename = 'autotag_list.csv';
			csv = 'Tag name,Area name,Content,Type,Color(optional)\n';
		} else if (this.props.view === M_TAG) {
			exportFilename = 'tag_list.csv';
			csv = 'Tag level 1,Tag level 2,Tag level 3,Area name\n';
		} else if (this.props.view === M_ADDRESSBOOK) {
			exportFilename = 'addresss_book.csv';
			csv = 'Cention, info@cention.com\n'
		}
		var csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
		if (navigator.msSaveBlob) {
			navigator.msSaveBlob(csvData, exportFilename);
		} else {
			var link = document.createElement('a');
			link.href = window.URL.createObjectURL(csvData);
			link.setAttribute('download', exportFilename);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}
	handleDownloadFileArchive = (id) => {
		this.props.onDownloadFileArchive(id);
	}
	handleUploadAgents(files, uploadTo) {
		this.props.onUploadAgents(files[0], uploadTo);
	}
	renderPaginationPanel(props){
		return (
			<div className="wf-pagination">
				<div className="wf-pagination-page">
				{  props.totalPages > 1 && props.components.pageList }
				</div>
				<div className="wf-pagination-current">
					{
						(this.props.list && this.props.list.length > 0) &&
							I('Showing {CURRENT_PAGE} of {TOTAL_PAGES}')
								.replace('{CURRENT_PAGE}', props.currPage)
								.replace('{TOTAL_PAGES}', props.totalPages)
					}
				</div>
		</div>
		);
	}
	handleCloseForm = () => {
		this.props.onCloseForm();
	}
	handleOnDownload() {
		if (this.props.view === ADMIN_VIEW_MAP["agents"]
			|| this.props.view === M_ROUTING_AUTOTAGS || this.props.view === M_TAG || this.props.view === M_ADDRESSBOOK) {
				this.handleDownloadTemplate();
		} else {
			this.props.onDownload(this.props.view);
		}
	}
	handleOnChangeUpload(event) {
		if (this.props.view === ADMIN_VIEW_MAP["agents"]
			|| this.props.view === M_ROUTING_AUTOTAGS
			|| this.props.view === M_TAG
			|| this.props.view === M_ADDRESSBOOK) {
			event.preventDefault();
			let dataFiles = [];
			Object.keys(event.target.files).forEach( i =>{
				const formData = new FormData();
				let boundary = Math.floor(Math.random() * 6)+ ''+ i +''+ Math.floor(''+new Date() / 1000) ;
				formData.append( 'uploadfile', event.target.files[i]);
				formData.append( 'fileNameOnly', event.target.files[i].name);
				formData.append( 'eventType', 'upload');
				formData.append( 'random', parseFloat(boundary));
				dataFiles.push(formData);
			})
			if(this.props.view === M_ROUTING_AUTOTAGS) {
				this.props.onUploadAutoTag(dataFiles[0]);
			} else if(this.props.view === M_TAG) {
				this.props.onUploadTags(dataFiles[0]);
			} else if(this.props.view === M_ADDRESSBOOK) {
				this.props.onUploadAddressBook(dataFiles[0]);
			} else {
				this.props.onLoadToEdit(UNSELECT, this.props.view, false, this.props.list);
				this.props.onUploadAgents(dataFiles[0]);
			}
		}
	}
	handleReload = () => {
		this.props.onLoad(this.props.view, false);
	}
	render() {
		const {
				view
				, list
				, langSrc
				, areaList
				, onConfirmUpload
				, onDownload
				, onChangeUpload
				, onError
				, order
				, orgAreas
				, selectedAreaFilter
				, showInline
				, showList
				, show
				, filter
				, activeId
				, agentUploadInProgress
				, onDownloadFileArchive
				, routeTagsList
				, agentList
				, allTagsList
				, nonExpandablesTags
				, adminActionAlert
				, onDismissAlert
				, isLoading
				, activeUserLevel
			} = this.props;
		const options = {
			sizePerPage: getListSizeBasedOnHeight(),
			pageStartIndex: 1,
			paginationSize: 3,
			withFirstAndLast: true,
			hideSizePerPage: true,
			onDeleteRow: this.handleDelete,
			noDataText: I("Empty list"),
			paginationPanel: this.renderPaginationPanel,
			onRowClick: this.handleRowClick
		};
		let filterType = [FILTER_BY_SELECT]
			, filterLabel = I("Filter")
			, filterOpt = []
			, filterNoValue
			, filterPreSelected
			, filterClassname = "admin-filter-select"
			, hideAddNew = {} // TODO: useless now
			, haveFilter = true
			, downloadText = I("Download")
			, uploadText = I("Upload")
			;
		CLICK_ROW_TO_EDIT = true ; // default
		const adminView = ADMIN_VIEW_MAP;
		if(view === adminView["wordlist"]) {
			filterOpt = [ {id: 0, value: I("All Languages")}];
			if(langSrc && langSrc.length > 0){
				filterOpt = filterOpt.concat(langSrc);
				filterNoValue = I("Choose Language");
				filterOpt = formatValueToNameObj(filterOpt)
			}
		}else if(view === adminView["addressbook-personal"] || view === adminView["addressbook"]
			|| view === adminView["agents"] || view === M_COMPANIES || view === M_CHATWIDGETDL
			|| view === M_FAQWIDGETDL || view === M_VOICEWIDGETDL) {
			filterType = [FILTER_BY_TEXT];
			filterLabel = "";
			filterClassname += " search-field";
			if(view === adminView["agents"]){
				filterType = [FILTER_BY_TEXT, FILTER_BY_AGENT_STATUS];
				filterNoValue = I("Select an item");
			}
		}else if(view === M_CONFIGCHANGELOG ) {
			filterType = [FILTER_BY_TEXT, FILTER_BY_DATE_BETWEEN];
			filterLabel = "";
			filterClassname += " search-field";
		} else if (isExternalExpert(view) || view === adminView["keywords"] ||
			view === M_ROUTING_AUTOTAGS || view === M_REVIEW_KEYWORDS || view === M_TAG) {
			if (areaList && areaList.length > 0) {
				filterOpt = areaList;
				filterType = [FILTER_BY_AREA];
			}
		} else if (view === M_REVIEW_AGENTS) {
			if (agentList && agentList.length > 0) {
				filterOpt = agentList;
				filterType = [FILTER_BY_AGENTS];
			}
		} else if (view === M_AGENTSIPSTATUS) {
			filterType = [FILTER_BY_AGENT_SIP_STATUS];
		} else if (isInternalState(view)) {
			hideAddNew = {display: 'none'};
		}else {
			haveFilter = false;
		}
		let hideUploadAgents = true;
		if(view === adminView["agents"] && activeId == -1){
			hideUploadAgents = false;
		}

		let importStatusText =  I("You are right now importing a list of new agents in the background. When the job is finished, the imported agents will be shown in the list.");
		let hideImportStatus = true;
		let showUploadHelper = false;
		let telavoxHideIcons = false;
		if(view === adminView["agents"]) {
			if(agentUploadInProgress == UPLOAD_AGENT_INPROGRESS) {
				hideImportStatus = false;
			}
			downloadText = I("Download template");
			uploadText = I("Upload Agent List");
			if(isTelavoxEnabled()){
				telavoxHideIcons = true;
			}
		} else if(view === M_ROUTING_AUTOTAGS || view === M_TAG) {
			downloadText = I("Download template");
			uploadText = I("Upload tags");
		} else if(view === M_ADDRESSBOOK) {
			downloadText = I("Download template");
			showUploadHelper = true;
		}

		let showAdminList = true;
		if(!isAdminList(view)) {
			showAdminList = showList;
		}
		let hideAddIcon = isInternalState(view);
		if(view === M_ROUTING_SIP && list.length > 0){
			hideAddIcon = true;
		}
		if(view === M_CONFIGCHANGELOG ){
			hideAddIcon = true;
			CLICK_ROW_TO_EDIT = false ;
		}
		let hideFilter = !haveFilter;
		if(isSST(view) || isFileArchive(view) || isInternalState(view) || view === M_CLASSIFIER || view === M_STUN_TURN
		|| view === M_ROUTING_SIP || view == M_QUICK_REPLY || view == M_API_CALLBACK || view == M_API_ACCESSTOKENS || view == M_AGENT_ASSIST){
			hideFilter = true;
		}
		let refreshButton;
		if(view === M_GENERATIVE_AI_DOCUMENT){
			refreshButton = <button title={I("Reload")} className="btn bg-transparent btn-round" type="submit" onClick={this.handleReload}>
				{isLoading ? <i className="fa fa-spinner fa-spin"></i> : <i className="icon-refresh"></i>}
			</button>
		}
		hideAddIcon = hideAddIcon || telavoxHideIcons;
		let hideUploadButton = !uploadButtonMap[view] || telavoxHideIcons;
		return (
			<SingleListSekeleton
				view={view}
				listHeader={
					<Fragment>
						{/* TODO: add for other admin pages? Currently only file Archive */}
						<TableHeader
							addActive={showInline}
							hideAddIcon={hideAddIcon}
							onClickAdd={e => this.handleOpenEdit(0, true)}
							title={ADMIN_TITLE[view]}>
							{refreshButton}
						</TableHeader>
						{showAdminList && <FormatAdminFilter
							hide={hideFilter} // TODO: allow filtering for review addresses
							view={view}
							type={filterType}
							id="filter"
							name="filter"
							className={filterClassname}
							label={filterLabel}
							textNoItemSelected={filterNoValue}
							option={filterOpt}
							filter={filter}
							onChange={this.handleFilterList}
						/>}
						{isAdminList(view) && <DownloadUpload
							hidden={hideUploadButton}
							id={view}
							downloadText={downloadText}
							uploadText={uploadText}
							onConfirm={onConfirmUpload}
							onDownload={this.handleOnDownload}
							onChange={this.handleOnChangeUpload}
							onError={onError}
							showFilename
							showHelper={showUploadHelper}
						/>}
					</Fragment>
				}
				listContent={ 
					(showAdminList && view !== M_TAG) ?
						<FormatAdminTable
							areaList={areaList}
							view={view}
							list={list}
							options={options}
							onHandleOpenEdit={this.handleOpenEdit}
							onHandleDelete={this.handleDelete}
							order={order}
							orgAreas={orgAreas}
							onHandleActivateAgent={this.handleActivateAgent}
							onHandleActivateSipStatus={this.props.onActivateAgentSipStatus}
							onHandleActivateStunTurnStatus={this.props.onActivateStunTurnStatus}
							onHandleUnlockAgent={this.handleUnlockAgent}
							selectedAreas={filter.selectedAreaFilter}
							onHandleDownload={onDownloadFileArchive}
							currentSortField={this.props.currentSortField}
							currentSortOrder={this.props.currentSortOrder}
							onSortFields={this.props.onSortFields}
							onSortOrder={this.props.onSortOrder}
							activeId={activeId}
						/> : (showAdminList && view === M_TAG) ?
						<TagAdminTable
							list={list}
							allTagsList={allTagsList}
							nonExpandablesTags={nonExpandablesTags}
							activeId={activeId}
							onEdit={this.handleOpenEdit}
							onDelete={this.handleDelete}
							order={order}
							selectedAreas={filter.selectedAreaFilter}
							currentSortField={this.props.currentSortField}
							currentSortOrder={this.props.currentSortOrder}
							onSortFields={this.props.onSortFields}
							onSortOrder={this.props.onSortOrder}
						/>
						: ""
				}
				editor={
					!isAdminList(view) && <AdminEditLeftViewCtnr
						list={list}
						onHandleDelete={this.handleDelete}
					/>
				}
			>
				<AlertWrapper id={"AdminGeneralAlert-"+view} hidden={adminActionAlert.show ? false : true} onDismiss={onDismissAlert}>
					<DismissableAlert show={adminActionAlert.show ? true : false}
						role={adminActionAlert.type === "error" ? "danger" : "success"}
						text={adminActionAlert.msg}
						icon={adminActionAlert.type === "error" ? "icon-caution" : "icon-new-tick-single"}
						onDismiss={onDismissAlert}
					/>
				</AlertWrapper>
				<EditorHeader
					hidden={!showInline}
					isNew={activeId <= 0}
					onClickClose={this.handleCloseForm}
					title={editorTitleByViewFA(activeId <= 0, view)}
				/>
				<div className="uploadAgentStatus" hidden={hideImportStatus}>
					{importStatusText}
				</div>
				<StyledDiv>
					{isAdminList(view) && <AdminEditInlineCtnr
						tagsList={routeTagsList}
						onHandleDelete={this.handleDelete}
						// TODO: add for download
						onDownloadFileArchive={this.handleDownloadFileArchive}
					/>}
					{!isAdminList(view) && <AdminEditRightViewCtnr
						list={list}
					/>}
				</StyledDiv>
			</SingleListSekeleton>
		);
	}
}

const FilterSelectBase = ({
	className
	, id
	, name
	, onChange
	, option
	, textNoItemSelected
	, value
}) => (
	<SelectInputRow
		id={id}
		name={name}
		className={className}
		label={I('Filter')}
		textNoItemSelected={textNoItemSelected}
		option={option}
		value={value}
		onSelect={useCallbackWithValue("selectedLangFilter", onChange)}
	/>
);

const FilterSelect = composeWithDisplayName(
	"FilterSelect"
	, memo
	, withUnmountWhenHidden
)(FilterSelectBase);

const FilterTextBase = ({ className, id, name, onChange, value }) => {
	const onChangeAddConst = useCallbackWithValue("wordFilter", onChange)
		, changeHandler = useCallback(
			e => onChangeAddConst(e.target.value)
			, [onChangeAddConst]
		)
		;
		return (
			<TextInputRow
				id={id}
				name={name}
				className={className}
				icon="icon-search"
				placeholder={I("Search for...")}
				value={value}
				onChange={changeHandler}
			/>
		);
};

export const FilterText = composeWithDisplayName(
	"FilterText"
	, memo
	, withUnmountWhenHidden
)(FilterTextBase);

const FilterAreaBase = ({ onChange, option, value }) => (
	<SelectArea
		data={option}
		selected={value}
		onSelect={useCallbackWithValue("selectedAreaFilter", onChange)}
	/>
);

const FilterArea = composeWithDisplayName(
	"FilterArea"
	, memo
	, withUnmountWhenHidden
)(FilterAreaBase);

const FilterByAgentBase = ({
	onChange
	, option
	, value
}) => (
	<SelectAgent
		data={option}
		selected={value}
		onSelect={useCallbackWithValue("selectedAgentFilter", onChange)}
	/>
);

//const FilterByAgentWithToggle = withShowToggle(FilterByAgentBase);

const FilterByAgent = composeWithDisplayName(
	"FilterByAgent"
	, memo
	, withUnmountWhenHidden
)(FilterByAgentBase);

const agentStatus = [
	{id: 0, name: I('Active')}
	, {id: 1, name: I('Inactive')}
	, {id: 2, name: I('Both')}
];

const agentFilter = [
	{ Id: 0, Name: I('Status'),
	Areas:
	[
		{Id: 0, Name: I('Active')}
		, {Id: 1, Name: I('Inactive')}
		, {Id: 2, Name: I('Both')}
	]}
	,{ Id: 1, Name: I('Level'),
	Areas:
	[
		{Id: 3, Name: I('Agent')}
		, {Id: 4, Name: I('Observer')}
		, {Id: 5, Name: I('Administrator')}
		, {Id: 6, Name: I('All')}
	]}
];

const FilterAgentBase = ({
	className
	, id
	, name
	, onChange
	, textNoItemSelected
	, value
}) => (
	<SelectAgentFilter
		id={id}
		name={name}
		className={className}
		data={agentFilter}
		selected={value}
		textNoItemSelected={textNoItemSelected}
		onSelect={useCallbackWithValue("agentTypeFilter", onChange)}
	/>
);

const FilterAgent = composeWithDisplayName(
	"FilterAgent"
	, memo
	, withUnmountWhenHidden
)(FilterAgentBase);

const FilterAgentSipStatusBase = ({
	className
	, id
	, name
	, onChange
	, textNoItemSelected
	, value
}) => (
	<SelectInputRow
		id={id+"_agentSipStatus"}
		name={name+"_agentSipStatus"}
		className={className}
		label={I('Filter')}
		textNoItemSelected={textNoItemSelected}
		option={agentStatus}
		value={value}
		onSelect={useCallbackWithValue("agentSipStatusFilter", onChange)}
	/>
);

const FilterAgentSipStatus = composeWithDisplayName(
	"FilterAgentSipStatus"
	, memo
	, withUnmountWhenHidden
)(FilterAgentSipStatusBase);

const FilterDateBetweenBase = ({
	 dateFromSelected
	, dateToSelected
	, onSelect
	, withSeparator
}) => (
	<div className="label-row1">
		{withSeparator &&
			<div>
				<p>&ensp;</p>
			</div>
		}
		<div className="label-block1">
			<div className="export-input2" style={{"display": "flex"}}>
				<SingleCalenderDD
					id="DateFrom"
					title="From"
					selected={dateFromSelected}
					onSelect={useCallbackWithValue("dateFromFilter", onSelect)}
				/>
				{withSeparator && <p>&ensp;</p> }
				<SingleCalenderDD
					id="DateTo"
					title=" To"
					selected={dateToSelected}
					onSelect={useCallbackWithValue("dateToFilter", onSelect)}
				/>
			</div>
		</div>
	</div>
);

const FilterDateBetween = composeWithDisplayName(
	"FilterDateBetween"
	, memo
	, withUnmountWhenHidden
)(FilterDateBetweenBase);


export class FormatAdminFilter extends React.Component {
	constructor(props){
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}
	handleChange(field, val) {
		if (typeof this.props.onChange === "function") {
			this.props.onChange(val, field);
		}
	}
	render() {
		const {
				className
				, filter
				, hide
				, id
				, name
				, onChange
				, option
				, selectedArea
				, textNoItemSelected
				, type
				, view
				, withResetButton
				, onResetFilter
			} = this.props
			, {
				agentTypeFilter
				, selectedAreaFilter
				, selectedLangFilter
				, wordFilter
				, selectedAgentFilter
				, agentSipStatusFilter
				, dateFromFilter
				, dateToFilter
			} = filter
			;
		return (
			<div
				className={classNames(
					"adminFilter"
					, {agentListFilter: view === ADMIN_VIEW_MAP["agents"]}
					, {searchAndMultiFilter: view === M_CALL_RECORDINGS}
				)}
				hidden={hide}
			>
				<FilterSelect
					hidden={!type.includes(FILTER_BY_SELECT)}
					id={id}
					name={name}
					className={className}
					textNoItemSelected={textNoItemSelected}
					option={option}
					value={selectedLangFilter}
					onChange={this.handleChange}
				/>
				<FilterText
					hidden={!type.includes(FILTER_BY_TEXT)}
					id={id}
					name={name}
					className={className}
					value={wordFilter}
					onChange={this.handleChange}
				/>
				<FilterArea
					hidden={!type.includes(FILTER_BY_AREA)}
					option={option}
					value={selectedAreaFilter}
					onChange={this.handleChange}
				/>
				<FilterAgent
					hidden={!type.includes(FILTER_BY_AGENT_STATUS)}
					id={id}
					name={name}
					className={className}
					textNoItemSelected={textNoItemSelected}
					option={agentFilter}
					value={agentTypeFilter}
					onChange={this.handleChange}
				/>
				<FilterByAgent
					hidden={!type.includes(FILTER_BY_AGENTS)}
					option={option}
					value={selectedAgentFilter}
					onChange={this.handleChange}
				/>
				<FilterAgentSipStatus
					hidden={!type.includes(FILTER_BY_AGENT_SIP_STATUS)}
					id={id}
					name={name}
					className={className}
					textNoItemSelected={textNoItemSelected}
					option={agentStatus}
					value={agentSipStatusFilter}
					onChange={this.handleChange}
				>
				</FilterAgentSipStatus>
				<FilterDateBetween
					hidden={!type.includes(FILTER_BY_DATE_BETWEEN)}
					dateFromSelected={dateFromFilter}
					dateToSelected={dateToFilter}
					onSelect={this.handleChange}
					withSeparator={view === M_CALL_RECORDINGS ? false : true }
				>
				</FilterDateBetween>
				{
					withResetButton && <Button color="link" className="btn-reset-filter" onClick={onResetFilter}>{I("Reset All")}</Button>
				}
			</div>
		);
	}
}

// TODO: refactor
const tooltipDelayAttr = {show: 0, hide: 0};

// TODO: refactor
const Tooltip = props => (
	<BSTooltip
		{...props}
		className="cell-preview"
		placement="bottom-start"
		autohide={false}
		hideArrow={true}
		delay={tooltipDelayAttr}
		container="body"
	/>
);

// TODO: refactor to use Table.CellAreasPreview
class FormatCellPreview extends React.Component {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.state = {
			tooltipOpen: false
		};
	}
	toggle() {
		this.setState({
			tooltipOpen: !this.state.tooltipOpen
		});
	}
	formatPreviewData(items) {
		let tooltipdata = [];
		tooltipdata = items.map(function(line, i) {
			if( i == 0 ) {
				return (<span key={i}><b>{L(line)}</b><br/></span>);
			} else {
				return (<span key={i}>{line}<br/></span>);
			}
		});
		return tooltipdata;
	}
	render() {
		const {rowId, title, body, 'data-qa-id': dataQAId} = this.props;
		return (
			<span>
				<div id={'PreviewTooltip-' + rowId} data-qa-id={dataQAId}>
					<div>{title}</div>
				</div>
				<Tooltip
					isOpen={this.state.tooltipOpen}
					target={'PreviewTooltip-' +rowId}
					toggle={this.toggle}
				>
					<div className="cell-preview-body">
						{this.formatPreviewData(body)}
					</div>
				</Tooltip>
			</span>
		);
	}
}

function renderAreaIDsWithPreview(cell, row, { byArea, byOrg }) {
	const items = [I("Connected areas")]
		, cellLength = cell.length
		;
	for (let i=0; i<cellLength; i++) {
		const areaId = cell[i]
			, areaObject = byArea[areaId]
			;
		if (!areaObject) {
			items.push(TXT_AREA_AVAILABLE.replace("{AREA_ID}", areaId))
			if (process.env.NODE_ENV !== 'production') {
				console.log(`Area ${areaId} having inconsistent database as it shouldn't appear here.`
					+ ` Please check workflow_areaagentgroupos table for detail.`
					+ ` Make sure this area belong to the right system group.`);
			}
		} else {
			const { org, index } = areaObject
				, orgData = byOrg[org]
				;
			items.push(orgData.Name + " - " + orgData.Areas[index].Name);
		}
	}
	return (
		<FormatCellPreview
			data-qa-id={"preview"+row.id}
			id={row.id}
			title={cellLength}
			body={items}
			rowId={row.id}
		/>
	);
}

const Delete = ({ id, name, onClick, selectedAreas }) => {
	const clickWithId = useCallbackWithValue(id, onClick)
		, clickWithName = useCallbackWithValue(name, clickWithId)
		, clickWithSelectedAreas = useCallbackWithValue(
			selectedAreas
			, clickWithName
		)
		;
	return <ActionDelete onClick={clickWithSelectedAreas} />;
};

const DownloadFile = ({ id, hide, onClick }) => (
	<ActionDownload hidden={hide} onClick={useCallbackWithValue(id, onClick)} />
);

const Edit = ({ id, onClick }) => (
	<ActionEdit onClick={useCallbackWithValue(id, onClick)} />
);

const PreviewStructure = ({ id, onClick }) => (
	<ActionPreviewStructure onClick={useCallbackWithValue(id, onClick)} />
)

function renderEditOnlyButton(
	cell
	, { id, name }
	, { onDelete, onEdit}
) {
	return (
		<ActionsWrapper>
			<Edit id={id} onClick={onEdit} />
		</ActionsWrapper>
	);
}

export function renderEditButton(
	cell
	, { id, name }
	, { onDelete, onEdit, selectedAreas }
) {
	return (
		<ActionsWrapper>
			{/*<Edit hidden={!!CLICK_ROW_TO_EDIT} id={id} onClick={onEdit} />*/}
			{ onEdit && <Edit id={id} onClick={onEdit} /> }
			{ onDelete && <Delete
				id={id}
				name={name}
				onClick={onDelete}
				selectedAreas={selectedAreas}
			/>}
		</ActionsWrapper>
	);
}

function renderOnlyDeleteButton(
	cell
	, { id, name }
	, { onDelete }
) {
	return (
		<ActionsWrapper>
			<Delete
				id={id}
				name={name}
				onClick={onDelete}
			/>
		</ActionsWrapper>
	);
}

//todo: use this as the sole purpose for EDIT,DELETE,DOWNLOAD across pages?
export const renderFileActionBtns = (
	cell
	, { id, name }
	, { onDelete, onDownload, onEdit, onPreviewStructure, selectedAreas, view }
) => {
	return (
		<ActionsWrapper>
			{onEdit && <Edit id={id} onClick={onEdit} />}
			{onDownload && <DownloadFile
				id={id}
				name={name}
				// hide={!isFileArchive(view)}
				onClick={onDownload}
			/>}
			{onPreviewStructure && <PreviewStructure
				id={id}
				name={name}
				onClick={onPreviewStructure}
			/>}
			{onDelete && <Delete
				id={id}
				name={name}
				onClick={onDelete}
				selectedAreas={selectedAreas}
			/>}
		</ActionsWrapper>
	)
}

// NOTE: work because the react component only use once else this memoize won't
// work.
const compositeEditDeleteProps = memoizeOne((
	onDelete
	, onEdit
	, selectedAreas
) => ({
	onDelete
	, onEdit
	, selectedAreas
}));

export const compositeFileActionProps = memoizeOne((
	onDelete,
	onDownload,
	onEdit,
	selectedAreas,
	view
) => ({
	onDelete
	, onDownload
	, onEdit
	, selectedAreas
	, view
}))

const TAG_LEVEL_INDENT = 30;

class TagAdminTable extends PureComponent {
	constructor(props) {
		super(props);
	}
	renderAreas = (cell, row) => {
		let areaLen = 1, areaList = "";;
		if(typeof cell != "undefined") {
			if(cell && cell.length > 0) {
				areaLen = cell.length-1;
				if(areaLen > 0) {
					areaList = cell.join("\n");
				}
			}
		}
		return (
			<div title={areaList}>{areaLen}</div>
		);
	}
	renderActions = (cell, row) => {
		return renderEditButton(cell, row, this.props);
	}
	handleNextPage = ({page,onPageChange}) => () => {
		onPageChange(page + 1);
	}
	handlePrevPage = ({page,onPageChange}) => () => {
		onPageChange(page - 1);
	}
	handleSizePerPage = ({page,onSizePerPageChange}, newSizePerPage) => {
		onSizePerPageChange(newSizePerPage, page);
	}
	renderExpandColumn = (expanded, rowKey, expandable) => {
		let lvl = 1;
		if(expandable) {
			const arr = rowKey.match(/[0-9]+$/); //the end of the rowKey string is the tag level
			lvl = parseInt(arr[0], 10);
		}
		const padValue = (parseInt(lvl,10) * 5)+(lvl == 1 ? 20 : TAG_LEVEL_INDENT);
		return <div style={{cursor: "pointer", paddingLeft: padValue+"px"}}>
				{ expandable ? (expanded ? <i className='icon-chevron-up' /> :  <i className='icon-chevron-down' />) : "" }
		</div>
	}
	render() {
		const { list, activeId } = this.props;
		//TODO: use selector to define columns for future use
		const columns = [{
			dataField: 'id',
			text: I("ID"),
			hidden: true
		}, {
			dataField: 'name',
			text: I("Name"),
			sort: true,
			style: function callback(cell, row, rowIndex, colIndex) {
				const { level } = row;
				const padValue = (parseInt(level,10) * 5)+(level == 1 ? 20 : TAG_LEVEL_INDENT);;
				return { paddingLeft: padValue+"px", width: "45%" };
			},
			headerStyle: (colum, colIndex) => {
				return { width: '45%' };
			}
		}, {
			dataField: 'levelsUnderneath',
			text: I("Levels"),
			sort: true
		}, {
			dataField: 'tagDelete',
			text: I("Delete tag")
		}, {
			dataField: 'connectedAreasName',
			text: I("Areas"),
			formatter: (cell, row) => {
				return this.renderAreas(cell, row);
			}
		}, {
			isDummyField: true,
			dataField: 'actions',
			text: I("Actions"),
			formatter: (cell, row) => {
				return this.renderActions(cell, row);
			},
		}];

		const rowClasses = (row, rowIndex) => {
			if(row && row.id) {
				if(row.id === activeId) {
					return "active";
				}
			}
			return ""
		};

		const expandSubRow = {
			renderer: (row, rowIndex) => {
				return (
					<BootstrapTable
						classes={TABLE_BASE_CLASSNAME+" sub-table"}
						keyField='keyId'
						headerClasses="thead-hide"
						bordered={false}
						data={ row.connectedTagsList }
						columns={ columns }
						expandRow={ expandSubRow }
						rowEvents={ rowEvents }
						rowClasses={ rowClasses }
					/>
				);
			},
			nonExpandable: this.props.nonExpandablesTags,
			className: 'row-expanded',
			showExpandColumn: true,
			expandByColumnOnly: true,
			expandHeaderColumnRenderer: () => (
				""
			),
			expandColumnRenderer: ({ expanded, rowKey, expandable }) => {
				return this.renderExpandColumn(expanded, rowKey, expandable)
			},
		  };

		const expandRow = {
			renderer: (row, rowIndex) => {
				return (
					<BootstrapTable
						classes={TABLE_BASE_CLASSNAME+" sub-table"}
						keyField='keyId'
						headerClasses="thead-hide"
						bordered={false}
						data={ row.connectedTagsList }
						columns={ columns }
						expandRow={ expandSubRow }
						rowEvents={ rowEvents }
						rowClasses={ rowClasses }
					/>
				);
			},
			nonExpandable: this.props.nonExpandablesTags,
			className: 'row-expanded',
			showExpandColumn: true,
			expandByColumnOnly: true,
			expandHeaderColumnRenderer: () => (
				""
			),
			expandColumnRenderer: ({ expanded, rowKey, expandable }) => {
				return this.renderExpandColumn(expanded, rowKey, expandable);
			},
			parentClassName: 'row-expandable',
		  };

		const rowEvents = {
			onClick: (e, row, rowIndex) => {
				return this.props.onEdit(row.id);
			}
		};

		const paginationOptions = {
			custom: false,
			totalSize: list.length,
			hideSizePerPage: true,
			hidePageListOnlyOnePage: true,
			withFirstAndLast: true,
			showTotal: true,
			paginationTotalRenderer: customPageTotal,
		};

		//Using 'keyId' as rowKey so each row key is changed once its' connected tags changed
		//so 'expandable' (+/-) state is changed accordingly once subtags are added/removed

		return (
			<ToolkitProvider
				keyField="keyId"
				data={list}
				columns={columns}
				search
			>
				{
					props => (
						<PaginationProvider
							pagination={paginationFactory(paginationOptions)}
						>
							{
								({
									paginationTableProps
								}) => (
									<div className='tag-admin-table-wrapper'>
										<TagCustomSearchBar
											{ ...props.searchProps } />
										<BootstrapTable
											classes={TABLE_BASE_CLASSNAME + " admin-expandable"}
											keyField='keyId'
											headerClasses={list.length === 0 ? "thead-hide" : ""}
											bordered={false}
											hover={true}
											data={list}
											columns={columns}
											expandRow={expandRow}
											rowEvents={rowEvents}
											rowClasses={rowClasses}
											{ ...props.baseProps }
											{...paginationTableProps}
										/>
									</div>
								)
							}
						</PaginationProvider>
					)
				}
			</ToolkitProvider>
		);
	}
}

const TagCustomSearchBar = (props) => {
	const ref = useRef();
	const [txt, setTxt] = useState("");

	const handleChange = () => {
		setTxt(ref.current.value);
		props.onSearch(ref.current.value);
	};
	return (
		<div className='adminFilter'>
			<TextInputRow
				id={"tag-search"}
				name={"tag-search-name"}
				className={"admin-filter-select search-field"}
				icon="icon-search"
				placeholder={I("Search for...")}
				value={txt}
				onChange={handleChange}
				ref={ref}
				helperTxt={I("Search for level 1 tags")}
			/>
		</div>
	);
  };

class FormatAdminTableBase extends PureComponent {
	constructor(props){
		super(props);
		this.handleOpenEdit = this.handleOpenEdit.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.renderCellWithTitle = this.renderCellWithTitle.bind(this);
		this.renderFileArchiveInfo = this.renderFileArchiveInfo.bind(this);
		this.renderGroupButton = this.renderGroupButton.bind(this);
		this.handleActivate = this.handleActivate.bind(this);
		this.renderStatus = this.renderStatus.bind(this);
		this.renderSipStatus = this.renderSipStatus.bind(this);
		this.renderStunTurnStatus = this.renderStunTurnStatus.bind(this);
		this.handleActivateSipStatus = this.handleActivateSipStatus.bind(this);
		this.renderLock = this.renderLock.bind(this);
	}
	handleOpenEdit(id) {
		this.props.onHandleOpenEdit(id);
	}
	handleDelete(id, name) {
		this.props.onHandleDelete(id, name);
	}
	renderCellWithTitle(cell, row){
		return <div title={cell}>{cell}</div>
	}
	
	renderFileArchiveInfo(cell, row, col) {
		let data;
		switch (col) {
			case "fileName":
				data = (
					<div>
						<span style={{ marginRight: '10px' }}>{row.fileName}</span>
					</div>
				);
				break;
			case "description":
				data = (
					<div>
						<span style={{ marginRight: '10px' }}>{row.description}</span>
					</div>
				);
				break;
			case "timestampUploaded":
				data = (
					<div>
						<span style={{ marginRight: '10px' }}>{row.timestampUploaded}</span>
					</div>
				);
				break;
			case "userName":
				data = (
					<div>
						<span style={{ marginRight: '10px' }}>{row.userName}</span>
					</div>
				);
				break;
			default:
				data = null; 
		}
	
		return (
			<div>
				{data}
			</div>
		);
	}
	
	
	
	
	
	
	
	handleOpenGroup(id){
		//TODO: To open a new v5 group connection page
		let url = window.location.host;
		window.location.href = pathPrefix + "admin.html#groups/connections/list/-/agent/"+id; // TODO: use redux push
	}
	renderGroupButton(cell, row){
		return (
			<ActionsWrapper>
				<TableIconicButton
					hide={false}
					type="button"
					className="btn-blue"
					iconClass={"fa fa-group"}
					title={I("Group")}
					onClick={e => this.handleOpenGroup(row.id)}
				/>
			</ActionsWrapper>
		);
	}
	handleActivate(row) {
		if (this.props.onHandleActivateAgent) {
			if (window.confirm("Are you sure you want to change the status?")) {
				this.props.onHandleActivateAgent(!row.status, row.id);
			}
		}
	}
	handleActivateSipStatus(row) {
		if(this.props.onHandleActivateSipStatus){
			this.props.onHandleActivateSipStatus(row.id, !row.active);
		}
	}
	handleActivateStunTurnStatus(row) {
		if(this.props.onHandleActivateStunTurnStatus){
			this.props.onHandleActivateStunTurnStatus(row.id, !row.active);
		}
	}
	handleUnlock(row) {
		if(this.props.onHandleUnlockAgent){
			this.props.onHandleUnlockAgent(row.id);
		}
	}
	renderStatus(cell, row) {
		let icon = "far fa-check-circle";
		if (row.status) {
			icon = "far fa-times-circle";
		}
		return (
			<div className="agentStatusHandler" onClick={e => this.handleActivate(row)}>
				<i className={icon}></i> {row.status ? I("Deactivate") : I("Activate")}
			</div>
		);
	}
	renderSipStatus(cell, row){
		let icon = "far fa-check-circle";
		if(row.active) {
			icon = "far fa-times-circle";
		}
		return <div className="agentStatusHandler" onClick={e => this.handleActivateSipStatus(row)}><i className={icon}></i> {row.active ? I("Deactivate") : I("Activate")}</div>
	}
	renderStunTurnStatus(cell, row){
		let icon = "far fa-check-circle";
		if(row.active) {
			icon = "far fa-times-circle";
		}
		return <div className="stunTurnStatusHandler" onClick={e => this.handleActivateStunTurnStatus(row)}><i className={icon}></i> {row.active ? I("Deactivate") : I("Activate")}</div>
	}
	renderLock(cell, row){
		let icon = "fas fa-lock-open";
		if(row.locked) {
			icon = "fas fa-lock";
		}
		if (row.locked) {
			return <div className="agentUnlockHandler" onClick={e => this.handleUnlock(row)}><i className={icon}></i> {I("Yes")}</div>
		} else {
			return <div className="agentUnlockHandler"><i className={icon}></i> {I("No")}</div>
		}
	}
	shouldHideCol(arr, col) {
		 return arr.includes(col);
	}
	renderConnectedAreaCellWithPreview(cell, row) {
		return <FormatCellPreview
			key={"CellPreviewItem-"+row.id}
			data-qa-id={"preview"+row.id}
			id={row.id}
			title={row.areaCount}
			body={row.connectedAreaName}
			rowId={row.id}
		/>;
	}
	renderAuditNote(cell, row) {
		return <div>
			<EllipsisText
				text={row.note}
			/>
		</div>
	}
	getColumnHeaderName(col) {
		//TODO: Get appropriate column header name based on key
		let colTitle = col;
		if(col === "emailAddress"){
			colTitle = I("Email Address");
		}else if(col === "areaCount"){
			colTitle = I("Added for areas")
		}else if(col === "CreatedBy" || col === "createdBy"){
			colTitle = I("Created by");
		}else if(col === "fileName"){
            colTitle = I("File name");
        }else if(col === "timestampUploaded"){
            colTitle = I("Date");
        }else if(col === "userName"){
            colTitle = I("User name");
        }else if(col === "FileName"){
			colTitle = I("Name");
		}else if(col === "agentName"){
			colTitle = I("Agent Name");
		}else if(col === "companyName"){
			colTitle = I("Name");
		}else if(col === "name"){
			colTitle = I("Name");
		}else if(col === "groups"){
			colTitle = I("Groups");
		}else if(col === "locked"){
			colTitle = I("Locked");
		}else if(col === "level"){
			colTitle = I("Level");
		}else if(col === "internalName"){
			colTitle = I("Internal");
		}else if(col === "active"){
			colTitle = I("Status");
		}else if(col === "className"){
			colTitle = I("Class");
		}else if(col === "routeSipId"){
			colTitle = I("SIP id");
		}else if(col === "createdOn" || col === "timestampCreated"){
			colTitle = I("Created on");
		}else if(col === "expireDate"){
			colTitle = I("Expiration date");
		}
		return <h5>{colTitle}</h5>
	}
	rowClassNameFormat = (row, rowIdx) => {
		const p = this.props;
		let extraClass = "";
		if(isFileArchive(p.view)) {
			extraClass = "verticalCol";
		}
		if(p.activeId !== 0) {
			if(row.id === p.activeId) {
				return "admin-row active "+extraClass;
			}
		}
		if(this.props.view == M_STUN_TURN){
			return "admin-row higherRow " + extraClass;
		}
		return "admin-row "+extraClass;
	}
	render(){
		const {
				areaList
				, fetchInfo
				, currentSortField
				, currentSortOrder
				, list
				, onHandleDelete
				, onHandleDownload
				, onHandleOpenEdit
				, onSortFields
				, onSortOrder
				, options
				, order
				, orgAreas
				, selectedAreas
				, view
			} = this.props
			;
		let keys, keyCol = "id", colToHide = "service";
		let myOrders = {...order} //spread for object assign
		let myOrder2 = Object.assign({}, order);
		let sortOrderList = []
		, tableStyle = "default"
		, withSortDD = false
		, tableType = ""
		;
	      if(order){
		   for(let i = 0; i< order.length; i++){
			sortOrderList.push({
				id: i,
				name: order[i],
			});
		    }
	      }		
		let actionButtons = renderEditButton;
		tableType = 'tbl-admin'
		//TODO: make Custom table style reuseable for other admin page
		if (isFileArchive(view)) {
			withSortDD = true;
			actionButtons = renderFileActionBtns;
		} else if(isInternalState(view)){
			actionButtons = renderEditOnlyButton;
		} else if(view === M_REVIEW_AGENTS || view === M_GENERATIVE_AI_DOCUMENT){
			actionButtons = renderOnlyDeleteButton;
		} else if (view === M_AGENTSIPSTATUS) {
			actionButtons = renderEditOnlyButton;
		} else if (view === M_CONFIGCHANGELOG) {
			tableType = 'tbl-admin full-width'
		}
		tableStyle = "custom " + tableType;

		//FIXME
		const colsToHide = ["service", "connectedArea", "connectedAreaName", "eeAvatar", "serviceType", "questionType",
							"companyEmail", "companyAddress", "companyCity", "companyZipcode",
							"companyPhone", "companyMobile", "companyFax"];
		if(list && list.length > 0) {
			if (typeof order !== "undefined") {
				keys = order;
			} else {
				// TODO: should not base on keys as order of object keys is not
				// determinise because js spec did not menioned about order of
				// keys.
				//This also throws error if the key's field does not hold single value (e.g array/object)
				keys = Object.keys(list[0]);
			}
		return (
			<div className={classNames("table-style1", tableStyle)}> {/* TODO-maybe use ID */}
				{withSortDD &&
				<SortAdminTableSelectBox
					// onSortOrder={onSortOrder}
					onSortFields={onSortFields}
					currentSortAttribute={options.sortName}
					currentSortDirection={options.sortOrder}
					dataSortAttributes={sortOrderList}
					className="admin-table"
				/>}
				<div className="admin-table default-header" hidden>
					<div className="default-col name"><h5>{I("Name")}</h5></div>
					<div className="default-col action"><h5>{I("Action")}</h5></div>
				</div>
				<OldBootstrapTable
					bordered={false}
					data={list}
					fetchInfo={{dataTotalSize: list.length}}
					hover
					pagination={true}
					options={options}
					tableBodyClass={classNames(TABLE_BASE_CLASSNAME, "c3-admin-table-v5")}
					tableHeaderClass={TABLE_BASE_CLASSNAME}
					trClassName={this.rowClassNameFormat}
				>			
				{
					keys.map((col, index) => {
						let colTitle = this.getColumnHeaderName(col);
						if(col == keyCol){
							return <TableHeaderColumn key={"admin-table-th-"+index+"-"+col} hidden={true} isKey thStyle={TABLE_HEADER_STYLE} dataField={keyCol}>{ colTitle }</TableHeaderColumn>
						}else if(col === "areaCount"){
							return <TableHeaderColumn key={"admin-table-th-"+index+"-"+col} thStyle={TABLE_HEADER_STYLE} dataFormat={this.renderConnectedAreaCellWithPreview} dataField={col} dataSort={true}>{ colTitle }</TableHeaderColumn>
						}else if(this.shouldHideCol(colsToHide, col)){
							return <TableHeaderColumn hidden key={"admin-table-th-"+index+"-"+col} thStyle={TABLE_HEADER_STYLE} dataFormat={this.renderCellWithTitle} dataField={col} dataSort={true}>{ colTitle }</TableHeaderColumn>
						}else if(view === ADMIN_VIEW_MAP["agents"] && col == "groups") {
							return <TableHeaderColumn key={"admin-table-th-"+index+"-"+col} thStyle={TABLE_HEADER_STYLE} dataFormat={this.renderGroupButton} dataField={col} dataSort={true}>{ colTitle }</TableHeaderColumn>
						}else if(view === ADMIN_VIEW_MAP["agents"] && col == "status") {
							return <TableHeaderColumn key={"admin-table-th-"+index+"-"+col} thStyle={TABLE_HEADER_STYLE} dataFormat={this.renderStatus} dataField={col} dataSort={true}>{ colTitle }</TableHeaderColumn>
						}else if(view === ADMIN_VIEW_MAP["agents"] && col == "locked") {
							return <TableHeaderColumn key={"admin-table-th-"+index+"-"+col} thStyle={TABLE_HEADER_STYLE} dataFormat={this.renderLock} dataField={col} dataSort={true}>{ colTitle }</TableHeaderColumn>
						}else if (isFileArchive(view)) {
							if (col === "fileName" || col === "description" || col === "timestampUploaded" || col === "userName") {
								return <TableHeaderColumn key={"admin-table-th-"+index+"-"+col} thStyle={TABLE_HEADER_STYLE} columnClassName={col} dataFormat={(cell, row) => this.renderFileArchiveInfo(cell, row, col)}  dataField={col} dataSort={true}>{colTitle}</TableHeaderColumn>
							}else{
								return <TableHeaderColumn key={"admin-table-th-"+index+"-"+col} thStyle={TABLE_HEADER_STYLE} dataFormat={this.renderFileArchiveInfo} dataField={col} dataSort={true}></TableHeaderColumn>;
							}
						}else if ((view === M_REVIEW_KEYWORDS || view === M_GENERATIVE_AI_DOCUMENT || isRoutingView(view)) && col === "areas") {
							return (
								<TableHeaderColumn
									dataField={col}
									dataFormat={renderAreaIDsWithPreview}
									dataSort={true}
									formatExtraData={orgAreas}
									key={"admin-table-th-"+index+"-"+col}
									thStyle={TABLE_HEADER_STYLE}
								>
									{colTitle}
								</TableHeaderColumn>
							);
						}else if(view === M_AGENTSIPSTATUS && col == "active") {
							return <TableHeaderColumn key={"admin-table-th-"+index+"-"+col} thStyle={TABLE_HEADER_STYLE} dataFormat={this.renderSipStatus} dataField={col} dataSort={true}>{ colTitle }</TableHeaderColumn>
						}else if(view === M_STUN_TURN){
							if(col == "status") {
								return <TableHeaderColumn key={"admin-table-th-"+index+"-"+col} thStyle={TABLE_HEADER_STYLE} dataFormat={this.renderStunTurnStatus} dataField={col} dataSort={true}>{ colTitle }</TableHeaderColumn>
							} else if(col=="url" || col=="name"){
								return <TableHeaderColumn key={"admin-table-th-"+index+"-"+col} thStyle={TABLE_HEADER_STYLE} dataFormat={this.renderCellWithTitle} dataField={col} dataSort={true} tdStyle={{whiteSpace:'normal'}}>{ colTitle }</TableHeaderColumn>
							} else {
								return <TableHeaderColumn key={"admin-table-th-"+index+"-"+col} thStyle={TABLE_HEADER_STYLE} dataFormat={this.renderCellWithTitle} dataField={col} dataSort={true}>{ colTitle }</TableHeaderColumn>
							}
						}else if(view === M_CONFIGCHANGELOG && col == "note") {
							const TABLE_HEADER_STYLE_WIDER = {
								color: "#6f6f6f"
								, textTransform: "capitalize"
								, fontSize: "11px"
								, border: "none"
								, paddingTop: "30px"
								, width: "60%"
							}
							const TABLE_COL_STYLE_WIDER = {
								width: "60%"
							}
							return <TableHeaderColumn key={"admin-table-th-"+index+"-"+col} thStyle={TABLE_HEADER_STYLE_WIDER} tdStyle={TABLE_COL_STYLE_WIDER} dataFormat={this.renderAuditNote} dataField={col} dataSort={true}>{ colTitle }</TableHeaderColumn>
						}else{
							return <TableHeaderColumn key={"admin-table-th-"+index+"-"+col} thStyle={TABLE_HEADER_STYLE} dataFormat={this.renderCellWithTitle} dataField={col} dataSort={true}>{ colTitle }</TableHeaderColumn>
						}
					})
				}
					<TableHeaderColumn
						className="table-header action"
						columnClassName={isFileArchive(view) ? "action file-archive" : "action"}
						key="admin-table-th-actionCol"
						thStyle={TABLE_HEADER_STYLE}
						dataAlign={"center"}
						dataFormat={actionButtons}
						formatExtraData={compositeFileActionProps(
							onHandleDelete
							, onHandleDownload
							, onHandleOpenEdit
							, selectedAreas
							, view
						)}
						hidden={view === ADMIN_VIEW_MAP["agents"] || view === M_CONFIGCHANGELOG}
						>
						<h5>{I("Action")}</h5>
					</TableHeaderColumn>
				</OldBootstrapTable>
				</div>
			);
		}else{
			return <div className="admin-list-no-data">{I("No data")}</div>
		}
	}
}

const withControlledSorting = Component => ({ options, ...props }) => {
	const {
			currentSortField
			, currentSortOrder
			, onSortFields
			, onSortOrder
		} = props
		, onSortChange = useCallback((sortName, sortOrder) => {
			onSortFields(sortName);
			onSortOrder(sortOrder);
		}, [onSortFields, onSortOrder])
		;
	options = useMemo(
		() => update(options, {
			onSortChange: {$set: onSortChange}
			, sortName: {$set: currentSortField}
			, sortOrder: {$set: currentSortOrder}
		})
		, [onSortChange, options, currentSortField, currentSortOrder]
	);
	return <Component options={options} {...props} />;
};

export const FormatAdminTable = composeWithDisplayName(
	"FormatAdminTable"
	, memo
	, withControlledSorting
)(FormatAdminTableBase)

const Wrapper = ({ children, className }) => (
	<div className="admin-main-wrapper">
		<div id="page-wrapper">
			<section className={className} data-app-layout>
				{children}
			</section>
		</div>
	</div>
);

const AdminBase = ({
	contentComponent: Component,
	onChangeView,
	onCollapseSideBar,
	onLoad,
	onToggleSideBar,
	showSideBar,
	collapseSideBar,
	subMenus,
	view,
	onMainView,
	routerView,
	id,
	subPath,
	adminStatus
}) => {
	const handleSideBarCollapse = useCallback(() => {
		onCollapseSideBar(!collapseSideBar);
	}, [collapseSideBar, onCollapseSideBar]);
	const handleSideBarToggle = useCallback(() => {
		onToggleSideBar(!showSideBar);
	}, [onToggleSideBar, showSideBar]);
	useEffect(() => onLoad(), [onLoad]);
	let customStyledAppHeader = { borderRadius: "initial"}
	return (
		<Wrapper
			className={classNames(
				"layout slim",
				{"closed-sidebar": !showSideBar}
			)}
		>
			<div className={classNames("app-sidebar", {collapsed: collapseSideBar})}>
				<AppHeader
					showSideBar={showSideBar}
					collapseSideBar={collapseSideBar}
					backNavHide={true}
					handleSideBarToggle={handleSideBarToggle}
					handleSideBarCollapse={handleSideBarCollapse}
					onMainView={onMainView}
				/>
				<WorkflowInnerContainerCtnr className="app-inner-content" hidden={false} data-custom-scrollbar>
					{(subMenus && subMenus.length > 0) &&
						<AdminSubMenus
							list={subMenus}
							collapseSideBar={collapseSideBar}
							showSideBar={showSideBar}
							onChangeView={onChangeView}
							handleSideBarToggle={handleSideBarToggle}
							handleSideBarCollapse={handleSideBarCollapse}
						/>
					}
					<SidebarSettingsCtnr
						hideManual={true}
						hidePickupNext={true}
					/>
				</WorkflowInnerContainerCtnr>
			</div>
			<div className="app-content">
				<div className="app-header" style={customStyledAppHeader}>
					<ToggleSideBar
						visible={!showSideBar || collapseSideBar}
						collapsed={collapseSideBar}
						wrapped={true}
						onClick={handleSideBarToggle}
						onCollapse={handleSideBarCollapse}
					/>
					<AdminTitle
						mainView={ADMINISTRATION}
						view={view}
						title={ADMIN_TITLE[view]}
						level={2}
						onBackToMain={onChangeView}
					/>
				</div>
				<WorkflowInnerContainerCtnr className="app-inner-content" >
					<Component routerView={routerView} id={id} subPath={subPath} adminStatus={adminStatus} />
				</WorkflowInnerContainerCtnr>
			</div>
		</Wrapper>
	);
};

const customAdminListComponents = {
	[A_CHATBOTS]: ChatbotCtnr,
	[A_CALL_RECORDINGS]: CallRecordingCtnr,
	[A_AGENT_SIP_LOGINS]: AgentSipLoginCtnr,
	[A_CORS_WHITELIST]: CORSwhiteList,
	[A_CHAT_IP_BLACKLIST]: ChatIPBlacklist,
	[A_CONTACT_CARDS]: ContactCardsCtnr,
	[A_GROUPS]: AdminGroups,
	[A_CLOSE_RECEIPTS]: ReceiptGreetingCtnr,
	[A_FEEDBACKS]: ReceiptGreetingCtnr,
	[A_GREETINGS]: ReceiptGreetingCtnr,
	[A_KNOWLEDGE_BASES]: KnowledgeBaseCtnr,
	[A_ROUTING_GROUPS]: RoutingGroupsCtnr,
	[A_RECEIVE_RECEIPTS]: ReceiptGreetingCtnr,
	[A_SMTP]: SMTPCtnr,
	[A_ACCOUNTS]: AccountsV5Ctnr,
	[A_ORGANISATIONS_V5]: OrganisationCtnr,
	[A_CALL_SIP_TRUNK]: CallSIPTrunkCtnr,
	[A_CALL_IVR]: CallIVRCtnr,
	[A_SIP_PRIORITY_NUMBERS]: SipPriorityNumberCtnr,
	[A_SKILLS]: SkillsCtnr,
	[A_AGENT_ASSIST]: AgentAssistCtnr,
};

const Admin = composeWithDisplayName(
	"Admin",
	memo,
	withProps(({ routerView }) => {
		let view = routerView;
		if (isAccount(ADMIN_VIEW_MAP[routerView])) {
			view = A_ACCOUNTS;
		}
		let contentComponent = customAdminListComponents[view];

		if (typeof contentComponent === "undefined") {
			contentComponent = AdminListCtnr;
		}
		return { contentComponent };
	})
)(AdminBase);

export default Admin;
