import React, { PureComponent } from 'react';
import { Button } from 'reactstrap';
import { I, webRoot } from '../../../common/v5/config';
import { Area } from '../../../components/v5/ManualErrand';
import {
	TextInputRow,
	ReadOnlyTextField,
	CustomInputRow
} from '../../../reactcomponents/Form';
import {
	LabelToggleSwitch as ToggleSwitch
} from '../../../components/v5/ToggleSwitch';
import { Spinner } from '../../../reactcomponents/Spinner'
import { INPUT_EMPTY_WARNING, FORM_SUBMIT_EMPTY_WARNING } from "../../../common/v5/constants"
import update from 'immutability-helper';
import { IdValueSingleSelect, UnnestedAreaSingleSelect } from '../../../reactcomponents/Dropdown'
import { TXT_NO_SELECTION } from '../../../common/v5/receiptGreetingConstants'
import { windowPopupCenter } from '../../../common/v5/helpers';

const helperForName = <div>
	<div>{I("This is the name that will be displayed in the list of accounts. This name is only used to help you identify the account.")}</div>
</div>

const helperForArea = <div>
	<div>{I("An area must be chosen because the authenticated GoogleReview account needs to be connected with an area.")}</div>
</div>

const helperForEnable = <div>
	<div>{I("If the account is enabled the system will fetch posts and comments from your Google Review timeline.")}</div>
	<div>{I("If it is not enabled the system will not do anything with the account.")}</div>
</div>

const helperForAuthorization = <div>
	<div>{I("For the system to be able to manage your Google Review comments, you need to give the Cention application permission to do that.")}</div>
	<br/>
	<div>{I("When you click the button a new window will open where you will be asked to login to gmail account. After you have logged in you will be asked to accept the permissions the Cention Google Review application requires to manage your timeline.")}</div>
	<br/>
	<div>{I("Click the Authorize button to start the authorization process.")}</div>
</div>

const helperForChannel = <div>
	<div>{I("Here you choose which Google Review channel you should manage within Cention Workflow.")}</div>
	<br/>
	<div>{I("Cention Workflow will automatically fetch all posts and comments made after you have activated your account so that you can immediately start answering questions.")}</div>
	<br/>
	<div>{I("Please Note")}</div>
	<div>{I("If no Google Review channels were found with the Google Review account you used to authenticate with, it either means your Google Review account does not have any channels it can manage, or that an error occurred.")}</div>
	<br/>
	<div>{I("If the problem persists and you are confident that your Google Review account has channels it can manage, please contact Cention Support.")}</div>
</div>


const GoogleReviewAuthButton = ({label, onFetchKey}) => {
	const handleClick = () => {
		if(onFetchKey) {
			onFetchKey();
		}
		const msg = I("Google Review authentication failed! Please contact Cention support.");
		let callbackURL = webRoot + "media/googlereview/handletoken";
		$.post(webRoot + 'googlereview/oauthurl', {redirectUrl: callbackURL})
		.then(function( oauthUrl ) {
			if( oauthUrl != "" ){
				windowPopupCenter(oauthUrl, 800, 600);
			}else{
				alert(msg);
			}
		})
		.fail(function(err){
			alert(msg);
		});	
	}
	return (
		<Button className="btn-outline blue btn-icon-text"
			outline
			color="primary"
			onClick={handleClick}
			title={label}
		>
			<i className={"icon-member"} />
			<span className="label">{label}</span>
		</Button>
	);
};

export default class AccountGoogleReviewForm extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			id: 0,
			name: "",
			accountList: [],
			authenticated: false,
			authorizationStr: I("Authorize"),
			showAuthBtn: true,
			reqInputs: {
				name: {touched:false},
			}
		};

	}

	componentDidMount = () => {
		let ds = this.props.input;
		if(typeof ds.id !== 'undefined' && ds.id > 0){
			this.setState({
				channelId: ds.channelId,
				channelName: ds.channelName,
				authenticated: true,
				authorizationStr: I("Re-authorize"),
			});
		}
	}

	componentWillUnmount = () =>{
		$('body').off('googleReview.authorization.accounts');
	}

	handleSelectArea = (val) => {
		this.props.onChangeAdminInput("selectedArea", val, this.props.view);
	}
	

	handleToggle = (value, field) => {
		this.props.onChangeAdminInput(field, value)
	}

	handleChannel = ( id ) =>{
		this.props.onChangeAdminInput("channelId", id);
		let cl = this.state.accountList;
		let chFound = false;
		if (cl.length > 0){
			cl.forEach((v, i) => {
				if(v.id === id){
					chFound = true;
					this.setState({channelName: v.value, errPageEmpty: ""});
					this.props.onChangeAdminInput("channelName", v.value);
				}
			})
		}
		if(!chFound){
			this.setState({channelId: "", channelName: ""});
		}
	}

	getParameterByName = (name, url) => {
		if (!url) url = window.location.href;
		name = name.replace(/[\[\]]/g, "\\$&");
		var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, " "));
	}

	handleAuth = (e) => {
		$("body").on('googleReview.authorization.accounts', function(e, rd) {
			const msg = I("We are really sorry, Google Review authentication failed! Please contact Cention Support.");
			let code = this.getParameterByName("code", rd);
			console.log("Code = ", code);
			if(code != ""){
				$.post(webRoot + 'googlereview/accesstoken', {code: code, aid: this.state.id, accName: this.state.name})
				.done(function( data ) {
					let cl = [];
					data.accountList.forEach((v, i) => {
						cl.push({id: v.id, value: v.name});
					});
					this.setState({
						id: data.id,
						accountName: data.name,
						authenticated: true,
						showAuthBtn: false,
						path: data.filepath,
						accountList: cl,
						grAccountSelectShow: (cl.length > 0 ? true: false)
					});
					this.props.onChangeAdminInput("id",data.id);
					this.props.onChangeAdminInput("path",data.filepath);
				}.bind(this))
				.fail(function(err){
					alert( msg );
				});
			}else{
				alert(msg);
			}
		}.bind(this));
	}

	handleFieldWarning = (e) => {
		let field = e.target.name;
		let reqInputs = this.state.reqInputs;
		reqInputs = update(reqInputs,{
			[field]: {
				touched:{$set: true}
			},
		})
		this.setState({reqInputs:reqInputs})
	}

	handleSubmit = (e) => {
		e.preventDefault();
		let reqInputs = this.state.reqInputs;
		let input = this.props.input;
		let valid = true;
		if (input.selectedArea === "0") {
			return alert(I("Please select area"));
		}
		$.each(reqInputs, (field, v)=>{
			//set required inputs touched
			reqInputs = update(reqInputs,{
				[field]: {
					touched:{$set: true}
				},
			})
			//check if empty
			if(input[field] === "" || input[field] === undefined) {
				valid = false;
			}
		});
		this.setState({reqInputs:reqInputs})
		if(input.channelId === "" || input.channelId === undefined) {
			return alert(I("Channel is not valid. Ensure authorized channel is selected."))
		}
		if(valid){
			this.props.onSave();
		} else {
			alert(FORM_SUBMIT_EMPTY_WARNING);
		}
	}

	render() {
		const {
			areaList
			, input
			, onHandleTextInputChange
			, hidden
			, baseButtons
		} = this.props;

		let selectedArea = parseInt(input.selectedArea, 10);

		const st = this.state;
		const field = st.reqInputs;
		const warnTxt = INPUT_EMPTY_WARNING;

		return (
			<form onSubmit={this.handleSubmit} className="admin-one-form edit-admin-form" hidden={hidden}>
				<CustomInputRow
					id={"area"}
					name={"area"}
					label={I('Area')}
					className={"admin-dropdown-select"}
					mandatory={true}
					helper={helperForArea}
				>
					<UnnestedAreaSingleSelect
						data={areaList}
						invalidSelectedText={TXT_NO_SELECTION}
						onSelect={this.handleSelectArea}
						selected={selectedArea}
						nested={{key: 'Areas'}}
						idFields={{id: "Id", name: "Name"}}
					/>
				</CustomInputRow>
				<TextInputRow
					id={"name"}
					name={"name"}
					className="admin-text-input"
					label={I("Name")}
					value={input.name || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.name && field.name.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
					helperTxt={helperForName}
				/>
				<ToggleSwitch
					id={"enable"}
					name={"enable"}
					data-qa-id="enableGoogleReview"
					label={I("Activated")}
					checked={input.enable}
					onClick={this.handleToggle}
					listWrap={false}
					className="admin-text-input"
					helperTxt={helperForEnable}
				/>
				{st.showAuthBtn &&
				<CustomInputRow
					id={"fbAuthorization"}
					name={"fbAuthorization"}
					label={I("Authorization")}
					className={"admin-auth-button"}
					helper={helperForAuthorization}
					mandatory={true}
				>
					<GoogleReviewAuthButton onFetchKey={this.handleAuth} label={st.authorizationStr}/>
				</CustomInputRow>}
				<CustomInputRow
					id={"channels"}
					name={"channels"}
					label={I('Google Review channels')}
					className={"admin-select-review-option"}
					mandatory={true}
					hide={st.accountList.length < 1}
					helper={helperForChannel}
				>
					<IdValueSingleSelect
						data={st.accountList}
						invalidSelectedText={TXT_NO_SELECTION}
						onSelect={this.handleChannel}
						selected={input.channelId || ""}
					/>
				</CustomInputRow>
				<ReadOnlyTextField
					id={"authentication"}
					name={"authentication"}
					className="admin-text-input"
					label={I("Google Review account")}
					value={I("@Authenticated")}
					hidden={!st.authenticated}
					mandatory={true}
				/>
				{baseButtons}
			</form>
		)
	}
}