import React, { PureComponent } from 'react';
import { Button } from 'reactstrap';
import { I, webRoot } from '../../../common/v5/config';
import { Area } from '../../../components/v5/ManualErrand';
import {
	TextInputRow,
	ReadOnlyTextField,
	CustomInputRow
} from '../../../reactcomponents/Form';
import {
	LabelToggleSwitch as ToggleSwitch
} from '../../../components/v5/ToggleSwitch';
import { Spinner } from '../../../reactcomponents/Spinner'
import { INPUT_EMPTY_WARNING, FORM_SUBMIT_EMPTY_WARNING } from "../../../common/v5/constants"
import update from 'immutability-helper';
import { windowPopupCenter } from '../../../common/v5/helpers';
import { UnnestedAreaSingleSelect } from '../../../reactcomponents/Dropdown';
import { TXT_NO_SELECTION } from '../../../common/v5/receiptGreetingConstants';

const helperForName = <div>
	<div>{I("This is the name that will be displayed in the list of accounts. This name is only used to help you identify the account.")}</div>
</div>

const helperForArea = <div>
	<div>{I("An area must be chosen because the authenticated HubSpot account needs to be connected with an area.")}</div>
</div>

const helperForEnable = <div>
	<div>{I("If the account is enabled the system will create or update contacts and create tickets to your HubSpot CRM")}</div>
	<br/>
	<div>{I("If it is not enabled the system will not do anything with the account.")}</div>
</div>

const helperForAuthorization = <div>
	<div>{I("For the system to be able to manage your HubSpot contacts and tickets you need to give the Cention application permission to do that.")}</div>
	<br/>
	<div>{I("When you click the button a new window will open where you will be asked to login to HubSpot account. After you have logged in you will be asked to accept the permissions the Cention HubSpot application requires to manage contacts and tickets")}</div>
	<br/>
	<div>{I("Click the Authorize button to start the authorization process.")}</div>
</div>



const HubSpotAuthButton = ({label, onFetchKey}) => {
	const handleClick = () => {
		if(onFetchKey) {
			onFetchKey();
		}
		
		const msg = I("HubSpot authentication failed, please contact Cention support.");
		let callbackURL = webRoot + "media/hubspot/handletoken";
		$.post(webserverRoot + 'hubspot/oauthurl', {redirectUrl: callbackURL})
		.then(function( oauthUrl ) {
			if( oauthUrl != "" ){
				windowPopupCenter(oauthUrl, 800, 600);
			}else{
				alert(msg);
			}
		})
		.fail(function(err){
			alert(msg);
		});
	}
	return (
		<Button className="btn-outline blue btn-icon-text"
			outline
			color="primary"
			onClick={handleClick}
			title={label}
		>
			<i className={"icon-member"} />
			<span className="label">{label}</span>
		</Button>
	);
};

export default class AccountHubSpotForm extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			id: 0,
			name: "",
			connectedArea: "",
			authenticated: false,
			authLoading: false,
			showAuthBtn: true,
			reqInputs: {
				name: {touched:false}
			},
			accountName: ""
		};

	}

	componentDidMount = () => {
		let ds = this.props.input;
		if(typeof ds.id !== 'undefined' && ds.id > 0){
			this.setState({
				id: ds.id,
				name: ds.name,
				connectedArea: ds.connectedArea,
				authenticated: true,
				accountName: ds.ownerName + " - " + ds.ownerEmail,
			});
		}
	}

	componentWillUnmount = () =>{
		$('body').off('hubspot.authorization.accounts');
	}

	handleSelectArea = (val) => {
		this.props.onChangeAdminInput("selectedArea", val, this.props.view);
	}

	handleToggle = (value, field) => {
		this.props.onChangeAdminInput(field, value)
	}

	getParameterByName = (name, url) => {
		if (!url) url = window.location.href;
		name = name.replace(/[\[\]]/g, "\\$&");
		var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, " "));
	}

	handleAuth = (e) => {
		$("body").on('hubspot.authorization.accounts', function(e, rd) {
			const msg = I("We are really sorry, HubSpot authentication failed! Please contact Cention Support.");
			let code = this.getParameterByName("code", rd);
			let callbackURL = webserverRoot + "media/hubspot/handletoken";
			if(code != ""){
				$.post(webRoot + 'hubspot/accesstoken', {code: code, id: this.state.id, redirectUrl: callbackURL})
				.done(function( data ) {
					let accountName = data.displayName + " - " + data.email;
					this.setState({
						id: data.id, //table unique ID
						accountName: accountName,
						authenticated: true
					});
					this.props.onChangeAdminInput("id",data.id);
					this.props.onChangeAdminInput("accountName",accountName);
				}.bind(this))
				.fail(function(err){
					alert( msg );
				});
			}else{
				alert(msg);
			}
		}.bind(this));
	}

	handleFieldWarning = (e) => {
		let field = e.target.name;
		let reqInputs = this.state.reqInputs;
		reqInputs = update(reqInputs,{
			[field]: {
				touched:{$set: true}
			},
		})
		this.setState({reqInputs:reqInputs})
	}

	handleSubmit = (e) => {
		e.preventDefault();
		let reqInputs = this.state.reqInputs;
		let input = this.props.input;
		let valid = true;
		if (input.selectedArea === "0") {
			return alert(I("Please select area"));
		}
		$.each(reqInputs, (field, v)=>{
			reqInputs = update(reqInputs,{
				[field]: {
					touched:{$set: true}
				},
			})
			if(input[field] === "" || input[field] === undefined) {
				valid = false;
			}
		});
		this.setState({reqInputs:reqInputs})
		if(!this.state.authenticated) {
			return alert(I("Account not authorized. Please try again"))
		}
		if(valid){
			this.props.onSave();
		} else {
			alert(FORM_SUBMIT_EMPTY_WARNING);
		}
	}

	render() {
		const {
			areaList
			, activeId
			, input
			, onHandleTextInputChange
			, hidden
			, baseButtons
		} = this.props;

		let selectedArea = parseInt(input.selectedArea, 10);
		const st = this.state;
		const field = st.reqInputs;
		const warnTxt = INPUT_EMPTY_WARNING;

		let authorizationStr = st.authenticated ? I("Re-authorize") : I("Authorize") ;
		
		return (
			<form onSubmit={this.handleSubmit} className="admin-one-form edit-admin-form" hidden={hidden}>
				<CustomInputRow
					id={"area"}
					name={"area"}
					label={I('Area')}
					className={"admin-dropdown-select"}
					mandatory={true}
					helper={helperForArea}
				>
					<UnnestedAreaSingleSelect
						data={areaList}
						invalidSelectedText={TXT_NO_SELECTION}
						onSelect={this.handleSelectArea}
						selected={selectedArea}
						nested={{key: 'Areas'}}
						idFields={{id: "Id", name: "Name"}}
					/>
				</CustomInputRow>
				<TextInputRow
					id={"name"}
					name={"name"}
					className="admin-text-input"
					label={I("Name")}
					value={input.name || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.name && field.name.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
					helperTxt={helperForName}
				/>
				<ToggleSwitch
					id={"enable"}
					name={"enable"}
					data-qa-id="enableHubspot"
					label={I("Activated")}
					checked={input.enable}
					onClick={this.handleToggle}
					listWrap={false}
					className="admin-text-input"
					helperTxt={helperForEnable}
				/>
				{st.pageLoading ? <div className='center p-3'><Spinner/></div>
				: 
				<ReadOnlyTextField
					id={"ownerID"}
					name={"ownerID"}
					className="admin-text-input"
					label={I("HubSpot Owner account")}
					value={`@${st.accountName}` || ""}
					hidden={!st.accountName}
				/>}
				{st.showAuthBtn &&
				<CustomInputRow
					id={"authorization"}
					name={"authorization"}
					label={I("Authorization")}
					className={"admin-auth-button"}
					helper={helperForAuthorization}
					mandatory={true}
					hide={activeId === 0 && st.authenticated}
				>
					<HubSpotAuthButton onFetchKey={this.handleAuth} label={authorizationStr}/>
				</CustomInputRow>}
				{st.authLoading 
				? <div className='center p-3'><Spinner/></div>
				: <ReadOnlyTextField
					id={"authentication"}
					name={"authentication"}
					className="admin-text-input"
					label={I("HubSpot account")}
					value={I("@Authenticated")}
					hidden={!st.authenticated}
					mandatory={true}
				/>}
				{baseButtons}
			</form>
		)
	}
}