import { connect } from 'react-redux'
import {
	ADMIN_VIEW_MAP
	, emptyArray
	, M_ACCOUNTS
	, M_ACC_EMAIL
	, M_ACC_TWILIO
	, M_ACC_SMPP
	, M_ACC_SLACK
	, ACCOUNT_ENDPOINTS
	, FORM_EMPTY
	, FORM_EDIT
} from '../../../common/v5/constants';
import { push } from '../../../common/v5/utils';
import {
	insertEditorContent
	, resetInsertionEditorContent
	, toggleAdminSidebar
	, changeAdminView
	, toggleAgentEdit
	, setAgentInput
	, toggleAdminEdit
	, toggleAdminFormType
	, setAdminInput
	, resetAdminInput
	, showEmailTestPopup
	, closeTestEmailPopup
} from '../../../redux/actions/admin';

import {
	adminAdmin
	, currentSortFieldMemo
	, currentSortOrder
	, getAdminListSelector
	, getAdminChannelSelector
	, listColumnSelector
	, getAdminListDefSelector
} from '../../../redux/selectors/admin';
import { onlyActiveAreasSelector } from '../../../redux/selectors/workflow'
import {
	openEditForm,
	fetchAdminList,
	setAdminData,
	removeAdminData,
	adminAccountSaveHandler,
	accountsAsyncs
} from '../../../redux/actions/async/admin';
import { activateAccount, activateSMPP, activateTwilio, activateSlack } from '../../../redux/actions/async/ajax'
import { servicesByType, constants } from '../../../redux/selectors/server';
import { orgAreaMemo } from '../../../redux/selectors/server'
import {
	togglePopWaiting
	, clearPopWaiting
	, popErrorOnly
} from '../../../redux/actions/hmf'
import { pleaseWaitString } from '../../../common/v5/helpers';
import { I } from '../../../common/v5/config';
import { TXT_FETCHING_DATA, TXT_DELETING } from '../../../common/v5/chatbotConstants'



const mapStateToProp = store => {
	const server = store.server, app = store.app, admin = app.admin, ui = admin.ui;
	const wf = store.app.workflow, adminSt = admin.admin, wfs = store.app.workflow.fetchWfSettings;
	const s = {
		pageId: wfs?.data?.pageId,
		configId: wfs?.data?.configId,
		showSideBar: wf.ui.showSideBar,
		collapseSideBar: wf.ui.collapseSideBar,
		subMenus: server.menu.accountMenu ? server.menu.accountMenu : emptyArray,
        	view: ui.view,
		list: getAdminListSelector(store),
		// data: adminListMemo(store),
		langSrc: adminSt.langSrc,
		order: listColumnSelector(store),
		show: adminSt.show,
		showInline: adminSt.showInline,
		showList: adminSt.showList,
		listFetching: adminSt.listFetching,
		activeId: adminSt.activeId,
		areaList: onlyActiveAreasSelector(store),
		// orgAreas: normalizedOrgAreasMemoize(store),
		filter: adminSt.filter,
		currentSortField: currentSortFieldMemo(store),
		currentSortOrder: currentSortOrder(store),
		oauthConstants: constants(store).oauth,
		defaultList:getAdminListDefSelector(store),
		input: adminSt.input,
		dataSrc: adminSt.dataSrc,
		orgArea: orgAreaMemo(store),
		formType: adminSt.formType,
		showEmailTestPopup: app.modals.showEmailTestPopup,
		isWaCloud: (wfs.data ? wfs.data["whatsapp-meta-cloud-api"] : false),
		adminStatus: adminSt.adminStatus,
	};
	return s;
};

const mapDispatchToProp = (dispatch, props) => {
	return {
        onLoad: (view, freshLoad) => {
			dispatch(changeAdminView(view));
			if(!freshLoad){
				//todo tk: checkback this code
				dispatch(fetchAdminList(view));
			}
		},
        onChangeView: (view, url) => {
			dispatch(toggleAdminFormType(FORM_EMPTY));
			//dispatch reset
			dispatch(push(url)).then(() => dispatch(changeAdminView(view)));
		},
		onToggleAdminFormType: (formType) => {
			dispatch(resetAdminInput());
			dispatch(toggleAdminFormType(formType));
		},
		onLoadToEdit: (id, view, popout, list) => {
			dispatch(toggleAdminFormType(FORM_EMPTY));
			// dispatch(toggleAdminFormType(1));
			dispatch(toggleAdminEdit(id, false, popout, true));
			if(!popout) {
				let param = {};
				let area=0;
				let loadString = TXT_FETCHING_DATA;
				if(id === 0){
					loadString = I("loading settings");
					dispatch(resetAdminInput());
				}else {
					$.each(list, (i,v) => {
						if(v.id === id){
							area = v.areaID;
						}
					})
				}
				// todo: remove and replace the reset elsewhere
				dispatch(resetAdminInput());//reset back again to clear password
				param = {id:id,area:area}
				dispatch(togglePopWaiting(pleaseWaitString(loadString)));
				dispatch(accountsAsyncs[view].one(param))
				.then((rs) => {
					dispatch(clearPopWaiting());
					dispatch(toggleAdminFormType(FORM_EDIT));
				})
				.catch(err => {
					console.log("error getting account edits: ", err);
					dispatch(clearPopWaiting());
					return dispatch(popErrorOnly(err));
				});
			}
		},
		onLoadToEditTwilio: (id, view, popout, list) => {
			dispatch(toggleAdminFormType(FORM_EDIT));
			dispatch(toggleAdminEdit(id, false, popout, true));
			if(!popout) {
				let area=0, twilioAccountId;
				let param = { id, area };
				let loadString = TXT_FETCHING_DATA , fname;
				if(id === 0){
					loadString = I("loading settings");
					dispatch(resetAdminInput());
				}else {
					$.each(list, (i,v) => {
						if(v.id === id){
							area = v.areaID;
							// param = {id:id,area:area}
							fname = v.twilioAccountFriendlyName;
							twilioAccountId = v.twilioAccountId;
							param = {
								id:id,
								aid: v.twilioAccountId,
								fname: v.twilioAccountFriendlyName
							}
						}
					})
				}
				dispatch(setAdminInput("twilioAccountFriendlyName", fname));
				dispatch(setAdminInput("twilioAccountId", twilioAccountId));
				dispatch(togglePopWaiting(pleaseWaitString(loadString)));
				dispatch(accountsAsyncs[view].one(param))
				.then((rs) => {
					dispatch(clearPopWaiting());
				})
				.catch(err => {
					console.log("error getting account edits: ", err);
					dispatch(clearPopWaiting());
					return dispatch(popErrorOnly(err));
				});
			}
		},
		onActivateAccount: (id, area, view, checked, data ) => {
			let param = {}
			let endpoint = ACCOUNT_ENDPOINTS[view]
			param = {id:id, area:area, endpoint: endpoint }
			let activater = activateAccount;

			if(view === M_ACC_SMPP) {
				if(checked) {
					endpoint = endpoint + `/deactivate/`;
				} else {
					endpoint = endpoint + `/activate/`;
				}
				param = {id:id, endpoint: endpoint }
				activater = activateSMPP;
			};
			if(view === M_ACC_TWILIO) {
				endpoint = endpoint + `/${data.twilioAccountId}/phones/${data.phoneId}`;
				let enable = checked ? false : true;
				param = {
					id:data.twilioAccountId,
					enable: enable,
					endpoint: endpoint,
					pid: data.phoneId
				}
				activater = activateTwilio;
			};
			if(view === M_ACC_SLACK) {
				endpoint = endpoint + `/deactivate/`;
				param = {id:id, area:area, endpoint: endpoint }
				activater = activateSlack;
			};
			dispatch(toggleAdminFormType(FORM_EMPTY));
			dispatch(toggleAdminEdit(0, false, false, true));
			dispatch(togglePopWaiting(pleaseWaitString(I("applying settings"))));
			dispatch(activater(param))
			.then((rs) => {
				dispatch(fetchAdminList(view));
			})
			.then((rs) => {
				dispatch(clearPopWaiting());
			})
			.catch(err => {
				dispatch(clearPopWaiting());
				console.log("error activating account :", err)
				return dispatch(popErrorOnly(err));
			});
		},
		onDeleteFromList: (field, view, active) => {
			let data = {
				id: field.id,
				area: field.selectedArea
			}
			if(view === M_ACC_TWILIO) {
				data = {
					id: field.id,
					aid: field.aid
				}
			};
			dispatch(togglePopWaiting(pleaseWaitString(TXT_DELETING)));
			dispatch(accountsAsyncs[view].remove(data))
			.then((rs) => {
				dispatch(toggleAdminFormType(FORM_EMPTY));
				dispatch(fetchAdminList(view));
				active && dispatch(toggleAdminEdit(0, false, false, true)); //close form if active
				dispatch(clearPopWaiting());
			})
			.catch(err => {
				console.log("error deleting account: ", err);
				dispatch(clearPopWaiting());
				return dispatch(popErrorOnly(err));
			});
		},
		onChangeAdminInput: (field, value, key) => {
			dispatch(setAdminInput(field, value, key));
		},
		onSaveAdmin: (view, createNew, id, input) => {
			dispatch(setAdminData(view, createNew, id, input));
		},
		onSaveAdminData: (view, data) => {
			//this passes data arguments received from states
			dispatch(adminAccountSaveHandler(view, data));
		},
		onCloseForm: () => {
			dispatch(toggleAdminFormType(FORM_EMPTY));
			dispatch(resetAdminInput());
			dispatch(toggleAdminEdit(0, false, false, true));
		},
		onOpenNewForm: () => {
			dispatch(toggleAdminFormType(FORM_EDIT));
			dispatch(resetAdminInput());
			dispatch(toggleAdminEdit(0, true, false, true));
		},
		onToggleEmailTestPopup: (id, area, value) => {
			let param = {id:id,area:area}
			dispatch(accountsAsyncs[M_ACC_EMAIL].one(param))
			.then((rs) => {
				// dispatch(clearPopWaiting());
				dispatch(showEmailTestPopup(value));
			})
			.catch(err => {
				console.log("error getting test email id: ", err);
				return dispatch(popErrorOnly(err));
			});
		},
		onClosePopUp: ()=>{
			dispatch(toggleAdminEdit(0, false, false, true));
			dispatch(closeTestEmailPopup());
		}
	}
};
  const withAccount = connect(mapStateToProp, mapDispatchToProp)
  
  export default withAccount
  
  
