import { connect } from 'react-redux';
import AgentSipLogin from './agentlogins';
import {
	ADMIN_VIEW_MAP
	, emptyArray
	, TXT_DOWNLOADING_DATA
	, TXT_DOWNLOAD_FAIL
	, M_AGENT_SIP_LOGINS
} from '../../../common/v5/constants';
import {
	changeAdminView
	, toggleAdminEdit
	, setAdminInput
	, resetAdminInput
	, adminActionStatus
} from '../../../redux/actions/admin';
//
import {
	getAdminListSelector
	, listColumnSelector
	, agentNoSipLoginsSelector
} from '../../../redux/selectors/admin';
import {
	fetchAdminList,
	sstAsyncs
} from '../../../redux/actions/async/admin';
import {
	togglePopWaiting
	, clearPopWaiting
	, popErrorOnly
} from '../../../redux/actions/hmf'
import { pleaseWaitString } from '../../../common/v5/helpers';
import { TXT_FETCHING_DATA, TXT_DELETING } from '../../../common/v5/chatbotConstants'

const mapAgentSipLoginState = (store, props) => {
    const server = store.server
		, app = store.app
		, admin = app.admin
		, ui = admin.ui
		, adminSt = admin.admin
		, wf = app.workflow
		;
	return {
		view: ui.view,
		list: getAdminListSelector(store),
		noSipList: agentNoSipLoginsSelector(store),
		langSrc: adminSt.langSrc,
		order: listColumnSelector(store),
		show: adminSt.show,
		showInline: adminSt.showInline,
		showList: adminSt.showList,
		activeId: adminSt.activeId,
		input: adminSt.input,
		filter: adminSt.filter,
		adminStatus: adminSt.adminStatus
	};
};

const mapAgentSipLoginAction = (dispatch, props) => {
	return {
		onLoad: (view, freshLoad) => {
			dispatch(changeAdminView(view));
		},
		onLoadToEdit: (id, view, popout, list) => {
			dispatch(resetAdminInput());
			dispatch(toggleAdminEdit(0, false, false, true));
			dispatch(togglePopWaiting(pleaseWaitString(TXT_FETCHING_DATA)));
			dispatch(sstAsyncs[M_AGENT_SIP_LOGINS].one(id))
				.then((rs) => {
					dispatch(clearPopWaiting());
					dispatch(toggleAdminEdit(id, true, false, true));
				})
				.catch(err => {
					console.log("error getting records:", err);
					dispatch(clearPopWaiting());
					return dispatch(popErrorOnly(err));
				})
		},
		onChangeAdminInput: (field, value, key) => {
			dispatch(setAdminInput(field, value, key));
		},
		onSaveAdmin: (params, view, popout, list) => {
			dispatch(adminActionStatus({ status: 1, msg: I("Pending") }));
			dispatch(sstAsyncs[M_AGENT_SIP_LOGINS].save(params))
			.then((rs) => {
				if(typeof rs.status !== 'undefined'){
					if(rs.status == "success"){
						dispatch(toggleAdminEdit(0, false, false, true));
						dispatch(fetchAdminList(M_AGENT_SIP_LOGINS));
						dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
					} else {
						if(typeof rs.message !== 'undefined' &&
							rs.message != ""){
							dispatch(popErrorOnly(rs.message));
							dispatch(adminActionStatus({ status: 3, msg: rs.message }));
						} else {
							dispatch(popErrorOnly(I("Error updating agent information")));
							dispatch(adminActionStatus({ status: 3, msg: I("Error updating agent information")}));
						}
					}
				}
			})
		},
		onDeleteFromList: (id, view, popout, list) => {
			dispatch(sstAsyncs[M_AGENT_SIP_LOGINS].remove(id))
			.then((rs) => {
				dispatch(fetchAdminList(M_AGENT_SIP_LOGINS));
				dispatch(toggleAdminEdit(0, false, false, true));
				dispatch(clearPopWaiting());
				dispatch(fetchAdminList(M_AGENT_SIP_LOGINS));
			})
			.catch(err => {
				console.log("error deleting contact :", err)
				dispatch(clearPopWaiting());
				return dispatch(popErrorOnly(err));
			});
		},
		onCloseForm: (id, view, popout, list) => {
			dispatch(resetAdminInput());
			dispatch(toggleAdminEdit(0, false, false, true));
		}
	}
};

const AgentSipLoginCtnr = connect(
	mapAgentSipLoginState
	, mapAgentSipLoginAction
)(AgentSipLogin);

export default AgentSipLoginCtnr;
