import React, {
	Fragment
	, PureComponent
} from 'react';
import { Button } from 'reactstrap';
import { I, webRoot } from '../../../common/v5/config';
import { Area } from '../../../components/v5/ManualErrand';
import {
	TextInputRow,
	SelectInputRow,
	ReadOnlyTextField,
	CustomInputRow
} from '../../../reactcomponents/Form';
import {
	LabelToggleSwitch as ToggleSwitch
} from '../../../components/v5/ToggleSwitch';
import { INPUT_EMPTY_WARNING, FORM_SUBMIT_EMPTY_WARNING } from "../../../common/v5/constants"
import update from 'immutability-helper';
import { IdValueSingleSelect, UnnestedAreaSingleSelect } from '../../../reactcomponents/Dropdown'
import { TXT_NO_SELECTION } from '../../../common/v5/receiptGreetingConstants'
import { windowPopupCenter } from '../../../common/v5/helpers';

const helperForName = <div>
	<div>{I("This is the name that will be displayed in the list of accounts. This name is only used to help you identify the account.")}</div>
</div>

const helperForArea = <div>
	<div>{I("An area must be chosen because the authenticated Youtube account needs to be connected with an area.")}</div>
</div>

const helperForEnable = <div>
	<div>{I("If the account is enabled the system will fetch posts and comments from your Youtube timeline.")}</div>
	<div>{I("If it is not enabled the system will not do anything with the account.")}</div>
</div>

const helperForAuthorization = <div>
	<div>{I("For the system to be able to manage your Youtube comments, you need to give the Cention application permission to do that.")}</div>
	<br/>
	<div>{I("When you click the button a new window will open where you will be asked to login to the gmail account. After you have logged in, you will be asked to accept the permissions the Cention youtube application requires to manage comments from videos and channels.")}</div>
	<br/>
	<div>{I("Click the Authorize button to start the authorization process.")}</div>
</div>

const helperForChannel = <div>
	<div>{I("Here you choose which youtube channel you should manage within Cention Workflow.")}</div>
	<br/>
	<div>{I("Cention Workflow will automatically fetch all posts and comments made after you have activated your account so that you can immediately start answering questions.")}</div>
	<br/>
	<div>{I("Please Note")}</div>
	<div>{I("If no Youtube channels were found with the Youtube account you used to authenticate with, it either means your Youtube account does not have any channels it can manage, or that an error occurred.")}</div>
	<br/>
	<div>{I("If the problem persists and you are confident that your Youtube account has pages it can manage, please contact Cention Support.")}</div>
</div>


const YoutubeAuthButton = ({label, onFetchKey}) => {
	const handleClick = () => {
		if(onFetchKey) {
			onFetchKey();
		}
		const msg = I("Youtube authentication failed! Please contact Cention support.");
		//let callbackURL = AUTHSERVERURL + "twaccesstoken?source=" + webRoot + "media/youtube/handletoken";
		let callbackURL = webRoot + "media/youtube/handletoken";
		$.post(webRoot + 'youtube/oauthurl', {redirectUrl: callbackURL})
		.then(function( oauthUrl ) {
			if( oauthUrl != "" ){
				windowPopupCenter(oauthUrl, 800, 600);
			}else{
				alert(msg);
			}
		})
		.fail(function(err){
			alert(msg);
		});		
	}
	return (
		<Button className="btn-outline blue btn-icon-text"
			outline
			color="primary"
			onClick={handleClick}
			title={label}
		>
			<i className={"icon-member"} />
			<span className="label">{label}</span>
		</Button>
	);
};

export default class AccountYoutubeForm extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			id: 0,
			name: "",
			authenticated: false,
			channelList: [],
			authorizationStr: I("Authorize"),
			showAuthBtn: true,
			reqInputs: {
				name: {touched:false}
			}
		};

	}

	componentDidMount = () => {
		let ds = this.props.input;
		if(typeof ds.id !== 'undefined' && ds.id > 0){
			this.setState({
				channelId: ds.channelId,
				channelName: ds.channelName,
				name: ds.name,
				authenticated: true,
				authorizationStr: I("Re-authorize"),
			});
		}
	}

	componentWillUnmount = () =>{
		$('body').off('youtube.authorization.accounts');
	}


	handleSelectArea = (val) => {
		this.props.onChangeAdminInput("selectedArea", val, this.props.view);
	}
	

	handleToggle = (value, field) => {
		this.props.onChangeAdminInput(field, value)
	}

	handlePage = ( value ) =>{
		this.setState({channelId: value});
		this.props.onChangeAdminInput("channelId",value);
		//todo: can also set for channelName (seems not needed as for now)
	}

	getParameterByName = (name, url) => {
		if (!url) url = window.location.href;
		name = name.replace(/[\[\]]/g, "\\$&");
		var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, " "));
	}

	handleAuth = (e) => {
		$("body").on('youtube.authorization.accounts', function(e, rd) {
			const msg = I("We are really sorry, Youtube authentication failed! Please contact Cention Support.");
			let code = this.getParameterByName("code", rd);
			console.log("Code = ", code);
			if(code != ""){
				$.post(webRoot + 'youtube/accesstoken', {code: code, aid: this.state.id, accName: this.state.name})
				.done(function( data ) {
					let cl = []
					data.channelList.forEach((v,i) => {
						cl.push({id: v.id, value: v.name});
					});
					this.setState({
						id: data.id,
						authenticated: true,
						accountName: data.accName,
						showAuthBtn: false,
						path: data.filepath,
						channelList: cl,
						ytPageSelectShow: (cl.length > 0 ? true: false)
					});
					this.props.onChangeAdminInput("id",data.id);
					this.props.onChangeAdminInput("path",data.filepath);
				}.bind(this))
				.fail(function(err){
					alert( msg );
				});
			}else{
				alert(msg);
			}
		}.bind(this));
	}

	handleFieldWarning = (e) => {
		let field = e.target.name;
		let reqInputs = this.state.reqInputs;
		reqInputs = update(reqInputs,{
			[field]: {
				touched:{$set: true}
			},
		})
		this.setState({reqInputs:reqInputs})
	}

	handleSubmit = (e) => {
		e.preventDefault();
		let reqInputs = this.state.reqInputs;
		let input = this.props.input;
		let valid = true;
		if (input.selectedArea === "0") {
			return alert(I("Please select area"));
		}
		$.each(reqInputs, (field, v)=>{
			//set required inputs touched
			reqInputs = update(reqInputs,{
				[field]: {
					touched:{$set: true}
				},
			})
			//check if empty
			if(input[field] === "" || input[field] === undefined) {
				valid = false;
			}
		});
		this.setState({reqInputs:reqInputs})
		if(input.channelId === "" || input.channelId === undefined) {
			return alert(I("Channel is not valid. Ensure authorized youtube channel is selected."))
		}
		if(valid){
			this.props.onSave();
		} else {
			alert(FORM_SUBMIT_EMPTY_WARNING);
		}
	}

	render() {
		const {
			areaList
			, input
			, onHandleTextInputChange
			, hidden
			, baseButtons
		} = this.props;

		let selectedArea = parseInt(input.selectedArea, 10);

		const st = this.state;
		const field = st.reqInputs;
		const warnTxt = INPUT_EMPTY_WARNING;

		return (
			<form onSubmit={this.handleSubmit} className="admin-one-form edit-admin-form" hidden={hidden}>
				<CustomInputRow
					id={"area"}
					name={"area"}
					label={I('Area')}
					className={"admin-dropdown-select"}
					mandatory={true}
					helper={helperForArea}
				>
					<UnnestedAreaSingleSelect
						data={areaList}
						invalidSelectedText={TXT_NO_SELECTION}
						onSelect={this.handleSelectArea}
						selected={selectedArea}
						nested={{key: 'Areas'}}
						idFields={{id: "Id", name: "Name"}}
					/>
				</CustomInputRow>
				<TextInputRow
					id={"name"}
					name={"name"}
					className="admin-text-input"
					label={I("Name")}
					value={input.name || ""}
					onChange={onHandleTextInputChange}
					mandatory={true}
					warning={(!input.name && field.name.touched) && warnTxt}
					onBlur={this.handleFieldWarning}
					helperTxt={helperForName}
				/>
				<ToggleSwitch
					id={"enable"}
					name={"enable"}
					data-qa-id="enableYoutube"
					label={I("Activated")}
					checked={input.enable}
					onClick={this.handleToggle}
					listWrap={false}
					className="admin-text-input"
					helperTxt={helperForEnable}
				/>
				{st.showAuthBtn &&
				<CustomInputRow
					id={"fbAuthorization"}
					name={"fbAuthorization"}
					label={I("Authorization")}
					className={"admin-auth-button"}
					helper={helperForAuthorization}
					mandatory={true}
				>
					<YoutubeAuthButton onFetchKey={this.handleAuth} label={st.authorizationStr}/>
				</CustomInputRow>}
				<CustomInputRow
					id={"channels"}
					name={"channels"}
					label={I('Youtube channels')}
					className={"admin-select-review-option"}
					mandatory={true}
					hide={st.channelList.length < 1}
					helper={helperForChannel}
				>
					<IdValueSingleSelect
						data={st.channelList}
						invalidSelectedText={TXT_NO_SELECTION}
						onSelect={this.handlePage}
						selected={st.channelId || ""}
					/>
				</CustomInputRow>
				<ReadOnlyTextField
					id={"authentication"}
					name={"authentication"}
					className="admin-text-input"
					label={I("Youtube account")}
					value={I("@Authenticated")}
					hidden={!st.authenticated}
					mandatory={true}
				/>
				{baseButtons}
			</form>
		)
	}
}